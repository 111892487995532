import React, { useState, useContext, useEffect, useRef } from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { Clock, Check, AlertCircle, Plus } from 'react-feather';

import { languages } from '../../Data/languages';

import { NotificationContext } from '../../Context/NotificationContext';
import { DataContext } from '../../Context/DataContext';

import './Users.css';
import './Translations.css';
import { useScreenTransition } from '../../Hooks/useScreenTransition';

const pageLimit = 50;

const Translations = ({ windowSize }) => {

  const { transitionTo } = useScreenTransition();

  const { showNotification } = useContext(NotificationContext);

  const { userData } = useContext(DataContext);

  const [orders, setOrders] = useState([]);
  const [loadedOrders, setLoadedOrders] = useState(false);

  const [users, setUsers] = useState([]);
  const [loadedUsers, setLoadedUsers] = useState(false);

  const orderSearchRef = useRef();
  const [orderSearch, setOrderSearch] = useState('');

  const [dueSort, setDueSort] = useState(false);

  const [translatorSearch, setTranslatorSearch] = useState('');

  const [statusFilter, setStatusFilter] = useState('');

  const [triggerFilter, setTriggerFilter] = useState(true);

  let firstLoad = useRef();
  if (!firstLoad.current) firstLoad.current = true;

  let nextPointer = useRef();
  if (!nextPointer.current) nextPointer.current = null;

  useEffect(() => {
    const applyOrder = e => {
      if (e.keyCode === 13) {
        setTriggerFilter(tf => !tf);
      }
    }

    const orderSearchRefVar = orderSearchRef.current;

    orderSearchRef.current.addEventListener("keyup", applyOrder);

    return () => {
      orderSearchRefVar.removeEventListener("keyup", applyOrder);
    }
  }, []);

  useEffect(() => {
    const firestore = firebase.firestore();

    const unsubscribe = firestore.collection('users')
      .where('role', '==', 'Translator')
      .onSnapshot(querySnapshot => {
        setUsers(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        setLoadedUsers(true);
      }, error => {
        //console.log(error);
        showNotification('Error', "There was an error loading the users. Please, reload the page.", 'error');
        setLoadedUsers(true);
      });

    return () => unsubscribe();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    firstLoad.current = true;

    const firestore = firebase.firestore();

    let query = firestore.collection('orders');

    if (orderSearch !== '') {
      query = query.where('orderNumber', '==', parseInt(orderSearch,10));
    };

    if (translatorSearch !== '') {
      query = query.where('translator.id', '==', translatorSearch);
    };

    if (statusFilter !== '') {
      query = query.where('status', '==', statusFilter);
    };

    if (dueSort) {
      query = query.where('status', 'in', ['new', 'assigned', 'in-progress', 'support-review', 'revision']).orderBy('due', 'asc')
    } else {
      query = query.orderBy('timestamp', 'desc');
    }

    const unsubscribe = 
      query
        .limit(pageLimit)
        .onSnapshot(querySnapshot => {
          if (firstLoad.current) {
            nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];
            firstLoad.current = false;
            
            setOrders(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
            setLoadedOrders(true);
          } else {
            let newOrders = [];

            querySnapshot.docChanges().forEach((change) => {
              if (change.type === "added") {
                newOrders.push({ id: change.doc.id, ...change.doc.data() });
              }
            });
            
            setOrders(u => newOrders.concat(u));
            setLoadedOrders(true);
          }
        }, error => {
          //console.log(error);
          showNotification('Error', "There was an error loading the orders. Please, reload the page.", 'error');
          setLoadedOrders(true);
        });

    return () => unsubscribe();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerFilter, translatorSearch, dueSort, statusFilter]);

  const loadMoreOrders = () => {
    if (nextPointer.current) {
      const firestore = firebase.firestore();

      let query = firestore.collection('orders');

      if (orderSearch !== '') {
        query = query.where('orderNumber', '==', orderSearch);
      };
  
      if (translatorSearch !== '') {
        query = query.where('translator.id', '==', translatorSearch);
      };

      if (statusFilter !== '') {
        query = query.where('status', '==', statusFilter);
      };

      if (dueSort) {
        query = query.where('status', 'in', ['new', 'assigned', 'in-progress', 'support-review', 'revision']).orderBy('due', 'asc')
      } else {
        query = query.orderBy('timestamp', 'desc');
      }

      query
        .startAfter(nextPointer.current)
        .limit(pageLimit)
        .get().then((querySnapshot) => {
          nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];

          let oldOrders = [];

          querySnapshot.forEach((doc) => {
            oldOrders.push({ id: doc.id, ...doc.data() });
          });
          
          setOrders(u => u.concat(oldOrders));
        })
        .catch((error) => {
          showNotification('Error', "There was an error loading the orders. Please, reload the page.", 'error');
          //console.log("Error getting documents: ", error);
        });
    }
  }

  const handleAssignTranslator = (orderID, translatorID) => {
    const getDue = orderID => {
      const order = orders.find(o => o.id === orderID);

      let limits = [0, 750];
  
      for (let i = 2; i < 200; i++) {
        limits.push(limits[limits.length - 1] + 1500 + (i-2) * 250);
      }
  
      let count = 0;
  
      if (order.certified && order.pagesCount !== '') {
        count = order.pagesCount * 250;
      } else if (!order.certified && order.wordCount !== '') {
        count = order.wordCount;
      };
  
      const ceiling = limits.filter(limit => count <= limit).sort((a,b) => a - b)[0];
      let ceilingIndex = limits.findIndex(limit => limit === ceiling);

      if (order.expedited) {
        ceilingIndex = ceilingIndex / 2;
      }

      if (ceilingIndex > 0.5) {
        ceilingIndex = Math.ceil(ceilingIndex);
      }

      let miliseconds = ceilingIndex * 24 * 60 * 60 * 1000;

      const now = new Date();

      const dueMiliseconds = now.getTime() + miliseconds;
  
      return dueMiliseconds;
    }

    const getRate = (orderID, translatorID) => {
      const order = orders.find(o => o.id === orderID);

      if (order.expedited) {
        return users.find(user => user.id === translatorID).expeditedRate;
      } else {
        return users.find(user => user.id === translatorID).standardRate;
      }
    }

    const assignTranslator = (orderID, translatorID) => {
      const firestore = firebase.firestore();
      
      const now = new Date();

      const due = getDue(orderID);

      firestore.collection('orders').doc(orderID)
        .update({
          translator: {
            id: translatorID,
            name: users.find(user => user.id === translatorID).name,
            orderRate: getRate(orderID, translatorID)
          },
          status: 'assigned',
          due: due,
          overdue: false,
          assigned: now.getTime()
        })
        .then(() => {
          showNotification('Success', "The translator has been successfully assigned.", 'success');
        })
        .catch(error => {
          showNotification('Error', "There was an error updating the order. Please, try again.", 'error');
        })
    }
    
    if (!orders.find(order => order.id === orderID).translator) {
      assignTranslator(orderID, translatorID);
    } else {
      if (window.confirm('Are you sure you want to change the assigned translator?')) {
        assignTranslator(orderID, translatorID);
      }
    }
  }

  const formatDue = timestamp => {
    const date = new Date(timestamp);

    const splitted = date.toDateString().split(' ');

    return splitted[1] + ' ' + splitted[2] + ', ' + splitted[3] + ' @ ' + date.toTimeString().substr(0, 5);
  }

  const expediteOrder = orderID => {
    if (window.confirm('Are you sure you want to expedite this order? This action cannot be undone.')) {
      const firestore = firebase.firestore();

      firestore.collection('orders').doc(orderID)
        .update({
          expedited: true
        })
        .then(() => {
          showNotification('Success', "The order has been successfully assigned Expedited status.", 'success');
        })
        .catch(error => {
          showNotification('Error', "There was an error expediting the order. Please, try again.", 'error');
        })
    }
  }

  return (
    <div className="translationsContainer">
      <div className="translationsInnerContainer">
        <div className="translationsTitleRow">
          <div className="translationsTitle">
            Orders
            {userData && (userData.role === 'owner' || userData.role === 'Support') && <div className="newOrderButton" onClick={(e) => transitionTo('/office/new-order',e)}>
              <Plus className="newOrderIcon" />
            </div>}
          </div>
          <div className="usersFunctionsContainer">
            <div className="userLanguageFilterContainer">
              <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} className="quoteDetailsSelect">
                <option key="" value="">All</option>
                <option key="created" value="created">Created</option>
                <option key="new" value="new">New</option>
                <option key="assigned" value="assigned">Assigned</option>
                <option key="in-progress" value="in-progress">In Progress</option>
                <option key="support-review" value="support-review">Support Review</option>
                <option key="client-review" value="user-review">Client Review</option>
                <option key="revision" value="revision">Revision</option>
                <option key="completed" value="completed">Completed</option>
                <option key="cancelled" value="cancelled">Cancelled</option>
              </select>
            </div>
            <div onClick={() => setDueSort(d => !d)} className="userFunctionsCheckboxContainer">
              <div className="userFunctionsCheckbox">
                <Check className="userFunctionsCheck" style={{ opacity: dueSort ? 1 : 0 }} />
              </div>
              Sort by due date
            </div>
            <div className="userLanguageFilterContainer" style={{ display: windowSize.orientation === 'portrait' && windowSize.width <= 1024 ? 'none' : 'flex' }}>
              <input type="text" value={orderSearch} ref={orderSearchRef} onChange={(e) => setOrderSearch(e.target.value)} className="userNameFilter" placeholder="Search by order number" />
            </div>
            <div className="userLanguageFilterContainer" style={{ marginRight: 0 }}>
              <select value={translatorSearch} onChange={(e) => setTranslatorSearch(e.target.value)} className="quoteDetailsSelect">
                <option key="" value="">No translator selected</option>
                {users.map(user => 
                  <option key={user.id} value={user.id}>{user.name}</option>
                )}
              </select>
            </div>
          </div>
        </div>
        <div className="translationsTableHeader">
          <div className="usersHeaderColumn translationsTimerColumn">
            <Clock className="translationsColumnIcon" />
          </div>
          <div className="usersHeaderColumn translationsTimerColumn">
            <AlertCircle className="translationsColumnIcon" />
          </div>
          <div className="usersHeaderColumn translationsOrderColumn">Order</div>
          <div className="usersHeaderColumn translationsInvoiceColumn">Invoice</div>
          <div className="usersHeaderColumn translationsLanguageColumn">From</div>
          <div className="usersHeaderColumn translationsLanguageColumn">To</div>
          <div className="usersHeaderColumn translationsDueColumn">Due</div>
          <div className="usersHeaderColumn translationsTranslatorColumn">Translator</div>
          <div className="usersHeaderColumn translationsStatusColumn">Status</div>
        </div>
        { loadedOrders && orders.length > 0
        ? orders.map(order => 
          <div key={order.id} className="translationsTableLine">
            <div className="usersLineColumn translationsTimerColumn" style={{ opacity: !order.expedited && order.status !== 'in-progress' && order.status !== 'new' && order.status !== 'assigned' ? 0 : 1 }}>
              <Clock className="translationsColumnIcon translationsLineColumnIcon" style={{ cursor: order.expedited || (order.status !== 'in-progress' && order.status !== 'new' && order.status !== 'assigned') ? 'default' : 'pointer', opacity: order.expedited ? 1 : 0 }} onClick={!order.expedited && (order.status === 'in-progress' || order.status === 'new' || order.status === 'assigned') ? () => expediteOrder(order.id) : null} />
            </div>
            <div className="usersLineColumn translationsTimerColumn" style={{ opacity: !order.expedited && order.status !== 'in-progress' && order.status !== 'new' && order.status !== 'assigned' ? 0 : 1 }}>
              {((order.status === 'in-progress' && order.due < (new Date()).getTime()) || ((order.status === 'revision' || order.status === 'support-review') && order.revisionDue < (new Date()).getTime())) && <AlertCircle className="translationsColumnIcon" style={{ backgroundColor: '#E1504A', color: '#fff', borderRadius: 100 }} />}
            </div>
            <div className="usersLineColumn translationsOrderColumn" style={{ cursor: 'pointer', color: '#609ddb' }} onClick={(e) => transitionTo('/office/translation/' + order.id + "/details",e)}>{order.orderNumber}</div>
            <div className="usersLineColumn translationsInvoiceColumn" style={{ cursor: 'pointer', color: '#609ddb' }} onClick={(e) => transitionTo('/office/translation/' + order.id + "/invoice",e)}>{order.status !== 'new' && order.status !== 'assigned' && order.invoice && order.invoice.invoiceNumber}</div>
            <div className="usersLineColumn translationsLanguageColumn">{languages.find(language => language.value === order.languageFrom) ? languages.find(language => language.value === order.languageFrom).label : null}</div>
            <div className="usersLineColumn translationsLanguageColumn">{languages.find(language => language.value === order.languageTo) ? languages.find(language => language.value === order.languageTo).label : null}</div>
            <div className="usersLineColumn translationsDueColumn">{order.due ? formatDue(order.due) : ''}</div>
            <div className="usersLineColumn translationsTranslatorColumn">
              {loadedUsers && (order.status === 'new' || order.status === 'assigned' || order.status === 'in-progress') 
              ? <select value={order.translator ? order.translator.id : ''} onChange={(e) => handleAssignTranslator(order.id, e.target.value)} className="userStatusSelect">
                  <option key={''} value={''} disabled>Translator</option>
                  { users.filter(u => u.status !== 'paused' && (u.language === order.languageTo || u.language === order.languageFrom)).map(user => 
                    <option key={user.id} value={user.id}>{user.name}</option>
                  )}
                </select>
              : loadedUsers && order.translator ? order.translator.name : ''
              }
            </div>
            <div className="usersLineColumn translationsStatusColumn">
              <div className="translationsStatusContainer" style={{ backgroundColor: order.status === 'created' ? '#F6C907' : order.status === 'new' ? '#FF7C1D' : order.status === 'assigned' ? '#61dbfb' : order.status === 'in-progress' ? '#1491F8' : order.status === 'support-review' ? '#e91e63' : order.status === 'user-review' ? '#30d5c8' : order.status === 'revision' ? '#333' : order.status === 'cancelled' ? '#E1504A' : '#17BC5B' }}>
                { order.status === 'created' ? "CREATED" : order.status === 'new' ? "NEW ORDER" : order.status === 'assigned' ? 'ASSIGNED' : order.status === 'in-progress' ? 'IN PROGRESS' : order.status === 'support-review' ? 'SUPPORT' : order.status === 'user-review' ? 'CLIENT' : order.status === 'revision' ? 'REVISION' : order.status === 'cancelled' ? 'CANCELLED' : 'COMPLETED'}
              </div>
            </div>
          </div>)
        : loadedOrders ? <div className="loadingTranslations">No orders.</div> : <div className="loadingTranslations">Loading orders...</div>
        }
        { loadedOrders && orders.length >= 50 && nextPointer.current &&
          <div className="loadMoreUsersButton" onClick={() => loadMoreOrders()}>
            Load more orders...
          </div>
        }
      </div>
    </div>
  );
};

export default Translations;