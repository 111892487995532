import React, { useContext, useEffect, useState } from "react";
import { useSpring, animated, config } from "react-spring";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { Navbar } from "../../Components/Navbar";
import { AccountFooter } from "../../Components/AccountFooter";

import { useScreenTransition } from "../../Hooks/useScreenTransition";


import "react-datepicker/dist/react-datepicker.css";

import Toggle from 'react-toggle';
import "react-toggle/style.css";
import "../Account/Quotes.css";
import { DataContext } from "../../Context/DataContext";
import { NotificationContext } from "../../Context/NotificationContext";
import { TransitionContext } from "../../Context/TransitionContext";
import PaymentsPopUp from "./PaymentsPopUp";
import TopBar from "../../Components/TopBar";


const TeamOptions = ({ windowSize }) => {
  const { transitionTo } = useScreenTransition();
  const {userData} = useContext(DataContext);
  const { showNotification } = useContext(NotificationContext);
  const { setColor } = useContext(TransitionContext);

  const phoneScreen =
    windowSize.width > 767 || windowSize.orientation === "landscape"
      ? false
      : true;

  const [menuOpen, setMenuOpen] = useState(!phoneScreen);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [orderComplete, setOrderComplete] = useState(false);
  const [quotePlaced, setQuotePlaced] = useState(false);
  const [quoteReady, setQuoteReady] = useState(false);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [notificationEmail, setNotificationEmail] = useState("");
  const [selectedAlertDays, setSelectedAlertDays] = useState(7);


  const accountMenuProps = useSpring({
    maxHeight: menuOpen || !phoneScreen ? "calc(50vh)" : "calc(2.5vh)",
    config: config.slow,
  });

  useEffect(()=> {
    if(userData && userData.hasTeam){
        const firestore = firebase.firestore();

        firestore.collection("teams").doc(userData.teamId).get().then(doc => {
            if(doc.exists){
                const teamData = doc.data();
                setOrderPlaced(teamData.orderPlacedNotifyToAll);
                setOrderComplete(teamData.orderCompletedNotifyToAll);
                setQuotePlaced(teamData.quotePlacedNotifyToAll);
                setQuoteReady(teamData.quoteReadyNotifyToAll);
                setQuoteReady(teamData.quoteReadyNotifyToAll);
                if(teamData.notifyEmail){
                  setNotificationEmail(teamData.notifyEmail);
                }else{
                  getUserForNotification();
                }
                if(teamData.overDueAlertDays){
                  setSelectedAlertDays(teamData.overDueAlertDays);
                }else{
                  setSelectedAlertDays(7);
                }
            }
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  const updateOption = (data) => {
    const firestore = firebase.firestore();
    firestore.collection("teams").doc(userData.teamId).update(data)
    .then(() => {
        showNotification("Option Updated","updated successfully!", "success");
    })
    .catch((error) => {
      console.log(error)
        showNotification("Error","Error updating document", "error");
    });
  }

  useEffect(() => {
    setColor(true);
  }, [setColor]);

  const updateOverDueAlertDays = (days) => {
    const firestore = firebase.firestore();
    setSelectedAlertDays(days);
    firestore.collection("teams").doc(userData.teamId).update({overDueAlertDays: days})
    .then(() => {
      showNotification("Invoice Overdue Alerts Updated","updated successfully!", "success");
    })
    .catch((error) => {
      console.log(error)
        showNotification("Error","Error updating document", "error");
    });

  }

  const updateNotifyEmail = () => {
    const firestore = firebase.firestore();
    firestore.collection("teams").doc(userData.teamId).update({notifyEmail: notificationEmail})
    .then(() => {
        showNotification("Email Updated","updated successfully!", "success");
    })
    .catch((error) => {
      console.log(error)
        showNotification("Error","Error updating document", "error");
    });

  }


  const getUserForNotification = () => {
    const firestore = firebase.firestore();
    firestore.collection("users")
    .where("teamId", "==", userData.teamId)
    .orderBy('timestamp', 'asc').limit(1)
    .get().then((querySnapshot => {

      if(querySnapshot.empty){
        return true;
      }else{
        querySnapshot.forEach(doc => {
          setNotificationEmail(doc.data().email)
        })
        return true;
      }
    })).catch((error) => {
      console.log(error);
      showNotification("Error","Error updating document", "error");
    });
  }

  return (
    <>
    <TopBar/>
    <div className="homeContainer">
      <Navbar windowSize={windowSize} />
        <div className="accountContainer">
            <animated.div style={accountMenuProps} className="accountSidebar">
                    <p className="accountSidebarTitle" onClick={phoneScreen ? () => setMenuOpen(i => !i) : null}>{windowSize.width > 767 || windowSize.orientation === 'landscape' ? 'Team' : 'Team Menu'}</p>
                    <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/members',e)}>Members</p>
                    <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/shipping',e)}>Shipping</p>
                    <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/quotes',e)}>Quotes</p>
                    <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/orders',e)}>Orders</p>
                    <p className="accountSidebarLink" style={{ color: '#666' }}>Options</p>
                    <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/payments',e)}>Payments</p>
                </animated.div>
            <div
            className="accountMainContainer"
            style={{ width: !phoneScreen ? "73%" : "86%" }}
            >
                <div className="accountMainTitleRow">
                    <h1 className="accountMainTitle">Options</h1>
                </div>
                <div>
                    <div className="accountMainTitleRow" style={{marginRight:"15px", alignItems:"flex-start", gap: "0.5rem"}}>
                    <p className="optionTitle">When an order is placed, send an email to all members of the team:</p>
                    <Toggle className="toggle--checked"
                        checked={orderPlaced}
                        icons={false}
                        onChange={(e) => {setOrderPlaced(e.target.checked); updateOption({orderPlacedNotifyToAll: e.target.checked})}} />
                    </div>
                    <div className="accountMainTitleRow" style={{marginRight:"15px", alignItems:"flex-start"}}>
                    <p className="optionTitle">When an order is completed, send an email to all members of the team:</p>
                    <Toggle className="toggle--checked"
                        checked={orderComplete}
                        icons={false}
                        onChange={(e) => {setOrderComplete(e.target.checked); updateOption({orderCompletedNotifyToAll: e.target.checked})}} />
                    </div>
                    <div className="accountMainTitleRow" style={{marginRight:"15px", alignItems:"flex-start"}}>
                    <p className="optionTitle">When a quote is placed, send an email to all members of the team:</p>
                    <Toggle className="toggle--checked"
                        checked={quotePlaced}
                        icons={false}
                        onChange={(e) => {setQuotePlaced(e.target.checked); updateOption({quotePlacedNotifyToAll: e.target.checked})}} />
                    </div>
                    <div className="accountMainTitleRow" style={{marginRight:"15px", alignItems:"flex-start"}}>
                    <p className="optionTitle">When a quote is ready, send an email to all members of the team:</p>
                    <Toggle className="toggle--checked"
                        checked={quoteReady}
                        icons={false}
                        onChange={(e) => {setQuoteReady(e.target.checked); updateOption({quoteReadyNotifyToAll: e.target.checked})}} />
                    </div>
                </div>

                <div style={{width:"100%"}}>
                  <div className="accountSidebarTitle notficationTitle">Notifications Setting</div>
                  <div>
                    <div className="emailInputWrapper">
                      <label className="optionTitle notificationText" for="email">Emails: </label>
                      <input id="email" type="text" className="userNameFilter" value={notificationEmail} onChange={(e) => setNotificationEmail(e.target.value)} placeholder="" onKeyDown={(e)=> e.key === "Enter" && updateNotifyEmail(e)} />
                    </div>
                    <p className="optionTitle notificationText">Here’s where we’ll send all the updates on invoices, payments, and gentle
                    reminders.</p>
                  </div>
                  <div className="accountSidebarLink invoiceAlert">Invoice Overdue Alerts:</div>
                  <div className="notifyOverdueWrapper">
                  <div className="optionTitle notificationText">Notify me when an invoice has been overdue for</div>
                  <button className={`frequencySelector ${selectedAlertDays === 7 && "selectedFrequency"}`} onClick={(e) => updateOverDueAlertDays(7)}>7 Days</button>
                  <button className={`frequencySelector ${selectedAlertDays === 15 && "selectedFrequency"}`} onClick={(e) => updateOverDueAlertDays(15)}>15 Days</button>
                  </div>
                </div>
            </div>
      </div>
      <AccountFooter windowSize={windowSize} />
      <PaymentsPopUp windowSize={windowSize} openConfirmation={openConfirmation} setOpenConfirmation={setOpenConfirmation}/>
    </div>
    </>
  );
};

export default TeamOptions;
