import React, { useState, useContext, useEffect } from 'react';
import { useSpring, animated, config } from 'react-spring';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

import { Navbar } from '../../Components/Navbar';
import { AccountFooter } from '../../Components/AccountFooter';
import { FormTextInput } from '../../Components/FormTextInput';
import { AltAnimatedButton } from '../../Components/AltAnimatedButton';

import { useScreenTransition } from '../../Hooks/useScreenTransition';

import { AuthContext } from '../../Context/AuthContext';
import { TransitionContext } from '../../Context/TransitionContext';
import { DataContext } from '../../Context/DataContext';
import { NotificationContext } from '../../Context/NotificationContext';

import { useEnterKey } from '../../Hooks/useEnterKey';

import './Profile.css';
import TopBar from '../../Components/TopBar';

const Profile = ({ windowSize }) => {

  const { transitionTo } = useScreenTransition();

  const { setColor } = useContext(TransitionContext);
  const { userData } = useContext(DataContext);
  const { signedIn } = useContext(AuthContext);
  const { showNotification } = useContext(NotificationContext);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [website, setWebsite] = useState('');
  const [phone, setPhone] = useState('');

  const [infoButton, setInfoButton] = useState('Save');

  const phoneScreen = windowSize.width > 767 || windowSize.orientation === 'landscape' ? false : true;

  const [menuOpen, setMenuOpen] = useState(!phoneScreen);

  const accountMenuProps = useSpring({ maxHeight: menuOpen || !phoneScreen ? 'calc(50vh)' : 'calc(2.5vh)', config: config.slow });

  useEffect(() => {
    setName(userData ? userData.name : '');
    setEmail(userData ? userData.email : '');
    setCompany(userData && userData.company ? userData.company : '');
    setWebsite(userData && userData.website ? userData.website : '');
    setPhone(userData && userData.phone ? userData.phone : '');
  }, [userData]);

  useEffect(() => {
    setColor(true);
  }, [setColor]);

  useEffect(() => {
    if (!signedIn) {
      transitionTo('/sign-in');
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedIn]);

  const handleSaveInfo = () => {
    if (name !== '' && email !== '') {
      const firestore = firebase.firestore();
      setInfoButton('Saving...');
  
      firestore.collection('users').doc(firebase.auth().currentUser.uid)
        .update({
          name: name,
          email: email.toLowerCase(),
          company: company,
          website: website,
          phone: phone
        })
        .then(() => {
          showNotification('Information Saved', 'Your information has been saved successfully.', 'success');
          setInfoButton('Save');
        })
        .catch(error => {
          setInfoButton('Save');
          showNotification('Error', 'An error occurred when saving your information. Please, try again. If it persists, contact Support.', 'error');
        })
    }
  }

  useEnterKey(() => handleSaveInfo());

  return (
    <>
    <TopBar/>
    <div className="homeContainer">
      <Navbar windowSize={windowSize} />
      <div className="accountContainer">
        <animated.div style={accountMenuProps} className="accountSidebar">
          <p className="accountSidebarTitle" onClick={phoneScreen ? () => setMenuOpen(i => !i) : null}>{windowSize.width > 767 || windowSize.orientation === 'landscape' ? 'Account' : 'Account Menu'}</p>
          <p className="accountSidebarLink" style={{ color: '#666' }}>Profile</p>
          <p className="accountSidebarLink" onClick={(e) => transitionTo('/account/shipping',e)}>Shipping</p>
          <p className="accountSidebarLink" onClick={(e) => transitionTo('/account/quotes',e)}>Quotes</p>
          <p className="accountSidebarLink" onClick={(e) => transitionTo('/account/orders',e)}>Orders</p>
          <p className="accountSidebarLink" onClick={(e) => transitionTo('/account/password',e)}>Password</p>
        </animated.div>
        <div className="accountMainContainer">
          <div className="accountMainTitleRow">
            <h1 className="accountMainTitle">Profile Details</h1>
          </div>
          <div className="accountProfileFormLine">
            <FormTextInput placeholder="Name(*)" value={name} onChange={setName} color='#666' />
          </div>
          <div className="accountProfileFormLine">
            <FormTextInput disabled={true} placeholder="Email(*)" value={email} onChange={setEmail} color='#666' />
          </div>
          <div className="accountProfileFormLine">
            <FormTextInput placeholder="Company" value={company} onChange={setCompany} color='#666' />
          </div>
          <div className="accountProfileFormLine">
            <FormTextInput placeholder="Website" value={website} onChange={setWebsite} color='#666' />
          </div>
          <div className="accountProfileFormLine">
            <FormTextInput placeholder="Phone" value={phone} onChange={setPhone} color='#666' />
          </div>
          <div className="accountProfileFormButton">
            <AltAnimatedButton text={infoButton} disabled={name === '' || email === ''} onClick={() => handleSaveInfo()} fontSize={windowSize.width > 1024 ? 1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.4 : 1) : (windowSize.orientation === 'portrait' ? 4 : 1.3)} />
          </div>
        </div>
      </div>
      <AccountFooter windowSize={windowSize} />
    </div>
    </>
  );
}

export default Profile;