import React, { useState, useContext, useEffect } from 'react';
import { useSpring, animated, config } from 'react-spring';
import { v4 as uuidv4 } from 'uuid';

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { X, CheckCircle, MoreVertical, Check } from 'react-feather';

import { Navbar } from '../../Components/Navbar';
import { AccountFooter } from '../../Components/AccountFooter';
import { AltAnimatedButton } from '../../Components/AltAnimatedButton';
import { FormTextInput } from '../../Components/FormTextInput';

import { countries } from '../../Data/countries';

import { useScreenTransition } from '../../Hooks/useScreenTransition';
import { useEnterKey } from '../../Hooks/useEnterKey';

import { TransitionContext } from '../../Context/TransitionContext';
import { NotificationContext } from '../../Context/NotificationContext';
import { DataContext } from '../../Context/DataContext';

import '../Account/Shipping.css';
import PaymentsPopUp from './PaymentsPopUp';
import TopBar from '../../Components/TopBar';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const TeamShipping = ({ windowSize }) => {

  const { transitionTo } = useScreenTransition();

  const { setColor } = useContext(TransitionContext);
  const { userData } = useContext(DataContext);
  const { showNotification } = useContext(NotificationContext);

  const [recipient, setRecipient] = useState('');
  const [street, setStreet] = useState('');
  const [apartment, setApartment] = useState('');
  const [city, setCity] = useState('');
  const [region, setRegion] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('');

  const [saveDefault, setSaveDefault] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState('new');
  const [modalAddressID, setModalAddressID] = useState(null);
  const [submitAddressButton, setSubmitAddressButton] = useState('Save Address');

  const [openConfirmation, setOpenConfirmation] = useState(false);


  const modalProps = useSpring({ opacity: modalOpen ? 1 : 0, pointerEvents: modalOpen ? 'all' : 'none' });

  const phoneScreen = windowSize.width > 767 || windowSize.orientation === 'landscape' ? false : true;

  const [menuOpen, setMenuOpen] = useState(!phoneScreen);

  const accountMenuProps = useSpring({ maxHeight: menuOpen || !phoneScreen ? 'calc(50vh)' : 'calc(2.5vh)', config: config.slow });

  useEffect(() => {
    setColor(true);
  }, [setColor]);

  useEffect(()=> {
    if(userData && userData.teamId === "" && !userData.hasTeam){
      transitionTo('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  const handleClickNewAddress = () => {
    setModalMode('new');
    setModalAddressID(null);
    setModalOpen(true);
    setRecipient('');
    setStreet('');
    setApartment('');
    setCity('');
    setRegion('');
    setZipCode('');
    setCountry('');
    setSaveDefault(false);
  }

  const handleClickEditAddress = id => {
    setModalMode('edit');
    setModalAddressID(id);
    setRecipient(userData.shipping.find(address => address.id === id).recipient);
    setStreet(userData.shipping.find(address => address.id === id).street);
    setApartment(userData.shipping.find(address => address.id === id).apartment);
    setCity(userData.shipping.find(address => address.id === id).city);
    setRegion(userData.shipping.find(address => address.id === id).region);
    setZipCode(userData.shipping.find(address => address.id === id).zipCode);
    setCountry(userData.shipping.find(address => address.id === id).country);
    setSaveDefault(userData.shipping.find(address => address.id === id).default);
    setModalOpen(true);
  }

  const handleSaveAddress = () => {
    setSubmitAddressButton('Saving...');
    const firestore = firebase.firestore();

    const address = { id: modalAddressID, recipient: recipient, street: street, apartment: apartment, city: city, region: region, zipCode: zipCode, country: country, default: saveDefault };

    const mShipping = saveDefault ? userData.shipping.filter(address => address.id !== modalAddressID).map(address => ({ ...address, default: false })).concat([address]) : userData.shipping.filter(address => address.id !== modalAddressID).concat([address]);

    firestore.collection('users').doc(firebase.auth().currentUser.uid)
      .update({
        shipping: mShipping
      })
      .then(() => {
        showNotification('Success', "The address data has been saved.", 'success');
        setSubmitAddressButton('Save Address');
        setModalOpen(false);

        sleep(500).then(() => {
          setModalMode('new');
          setModalAddressID(null);
          setRecipient('');
          setStreet('');
          setApartment('');
          setCity('');
          setRegion('');
          setZipCode('');
          setCountry('');
        });
      })
      .catch(error => {
        setSubmitAddressButton('Save Address');
        showNotification('Error', "An error ocurred while saving the address new data. Please, try again. If the problem persists, please contact us.", 'error');
      })
  }

  const handleAddAddress = () => {
    setSubmitAddressButton('Saving...');
    const firestore = firebase.firestore();

    const addressID = uuidv4();

    const address = { id: addressID, recipient: recipient, street: street, apartment: apartment, city: city, region: region, zipCode: zipCode, country: country, default: saveDefault };

    const mShipping = userData.shipping ? (saveDefault ? userData.shipping.map(address => ({ ...address, default: false })).concat([address]) : userData.shipping.concat([address])) : [address];

    firestore.collection('users').doc(firebase.auth().currentUser.uid)
      .update({
        shipping: mShipping
      })
      .then(() => {
        showNotification('Success', "The address has been added.", 'success');
        setSubmitAddressButton('Save Address');
        setModalOpen(false);

        sleep(500).then(() => {
          setModalMode('new');
          setModalAddressID(null);
          setRecipient('');
          setStreet('');
          setApartment('');
          setCity('');
          setRegion('');
          setZipCode('');
          setCountry('');
        });
      })
      .catch(error => {
        setSubmitAddressButton('Save Address');
        showNotification('Error', "An error ocurred while saving the address. Please, try again. If the problem persists, please contact us.", 'error');
      })
  }

  const handleDeleteAddress = id => {
    const firestore = firebase.firestore();

    const resetDefault = userData.shipping.find(address => address.id === id).default;

    const mShipping = userData.shipping.filter(address => address.id !== id);

    if (resetDefault && mShipping.length > 0) {
      mShipping[0].default = true;
    }

    firestore.collection('users').doc(firebase.auth().currentUser.uid)
      .update({
        shipping: mShipping
      })
      .then(() => {
        showNotification('Success', "The address has been deleted.", 'success');
      })
      .catch(error => {
        showNotification('Error', "An error ocurred while deleting the address. Please, try again. If the problem persists, please contact us.", 'error');
      })
  }

  const handleSetDefault = id => {
    const firestore = firebase.firestore();

    const mShipping = userData.shipping.map(address => ({ ...address, default: false }));

    mShipping.find(address => address.id === id).default = true;

    firestore.collection('users').doc(firebase.auth().currentUser.uid)
      .update({
        shipping: mShipping
      })
      .then(() => {
        showNotification('Success', "The address has been set as default.", 'success');
      })
      .catch(error => {
        showNotification('Error', "An error ocurred while setting the address as default. Please, try again. If the problem persists, please contact us.", 'error');
      })
  }

  const handleCloseModal = () => {
    setModalOpen(false);

    sleep(500).then(() => {
      setModalMode('new');
      setModalAddressID(null);
    });
  }

  const submitForm = () => {
    if (modalOpen && modalMode === 'new' && !(recipient === '' || street === '' || city === '' || region === '' || zipCode === '' || country === '')) {
      handleAddAddress();
    } else if (modalOpen && modalMode === 'edit' && !(recipient === '' || street === '' || city === '' || region === '' || zipCode === '' || country === '')) {
      handleSaveAddress();
    }
  }

  useEnterKey(() => submitForm());

  return (
    <>
    <TopBar/>
    <div className="homeContainer">
      {modalOpen && <animated.div style={modalProps} className="newAddressModalOuter" onClick={() => handleCloseModal()}/>}
      {modalOpen && <animated.div style={modalProps} className="newAddressModal">
        <X className="newAddressModalCloseIcon" onClick={() => handleCloseModal()} />
        <p className="newAddressTitle">{modalMode === 'new' ? 'NEW ADDRESS' : 'EDIT ADDRESS'}</p>
        <div className="quoteDetailsInputsContainer">
          <FormTextInput placeholder="Recipient (*)" value={recipient} onChange={setRecipient} color='#666' />
        </div>
        <div className="quoteDetailsInputsContainer">
          <FormTextInput placeholder="Street (*)" value={street} onChange={setStreet} color='#666' />
        </div>
        <div className="quoteDetailsInputsContainer">
          <FormTextInput placeholder="Apartment, Suite or Floor Number" value={apartment} onChange={setApartment} color='#666' />
        </div>
        <div className="quoteDetailsInputsContainer">
          <div className="orderPaymentTripleLine">
            <div className="orderPaymentTripleField">
              <FormTextInput placeholder="City (*)" value={city} onChange={setCity} color='#666' />
            </div>
            <div className="orderPaymentTripleField">
              <FormTextInput placeholder={country === 'United States' ? "State (*)" : "Region (*)"} value={region} onChange={setRegion} color='#666' />
            </div>
            <div className="orderPaymentTripleField">
              <FormTextInput placeholder="Postal Code (*)" value={zipCode} onChange={setZipCode} color='#666' />
            </div>
          </div>
        </div>
        <div className="quoteDetailsInputsContainer">
          <select value={country} onChange={(e) => setCountry(e.target.value)} className="quoteDetailsSelect">
            <option key="" value="" disabled>Country (*)</option>
            {countries.map(country => 
              <option key={country} value={country}>{country}</option>
            )}
          </select>
        </div>
        {!(modalAddressID && userData.shipping.find(address => address.id === modalAddressID).default) && <div className="hardCopyCheckboxLine" onClick={() => setSaveDefault(ss => !ss)}>
          <div className="hardCopyCheckbox">
            <Check className="hardCopyCheck" style={{ opacity: saveDefault ? 1 : 0 }} />
          </div>
          <p className="hardCopyCheckboxText">Set as default</p>
        </div>}
        <div className="newAddressButtonContainer">
          <AltAnimatedButton text={submitAddressButton} disabled={recipient === '' || street === '' || city === '' || region === '' || zipCode === '' || country === ''} onClick={modalMode === 'new' ? () => handleAddAddress() : () => handleSaveAddress()} fontSize={windowSize.width > 1024 ? 1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.9 : 1.2) : (windowSize.orientation === 'portrait' ? 4.2 : 1)} />
        </div>
      </animated.div>}

      <Navbar windowSize={windowSize} />
      <div className="accountContainer">
        <animated.div style={accountMenuProps} className="accountSidebar">
            <p className="accountSidebarTitle" onClick={phoneScreen ? () => setMenuOpen(i => !i) : null}>{windowSize.width > 767 || windowSize.orientation === 'landscape' ? 'Team' : 'Team Menu'}</p>
            <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/members',e)}>Members</p>
            <p className="accountSidebarLink" style={{ color: '#666' }}>Shipping</p>
            <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/quotes',e)}>Quotes</p>
            <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/orders',e)}>Orders</p>
            <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/options',e)}>Options</p>
        <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/payments',e)}>Payments</p>
        </animated.div>
        <div className="accountMainContainer" style={{ width: !phoneScreen ? '73%' : '86%' }}>
          <div className="accountMainTitleRow">
            <h1 className="accountMainTitle">Shipping Addresses</h1>
            <div style={{ marginRight: 0 }}>
              <AltAnimatedButton text="New Address" onClick={() => handleClickNewAddress()} fontSize={windowSize.width > 1024 ? 1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.7 : 1.2) : (windowSize.orientation === 'portrait' ? 3.2 : 1)} />
            </div>
          </div>
          <div className="accountShippingListContainer">
            { userData && userData.shipping && userData.shipping.length > 0 ? userData.shipping.map((address, index) => 
              <div key={index} className="accountShippingLine">
                <div className="accountShippingLineInfo">
                  <p className="accountShippingLineRecipient">{address.recipient}</p>
                  {address.street}<br/>
                  {address.apartment}{address.apartment !== '' && <br/>}
                  {address.city + ', ' + address.region + ', ' + address.zipCode}<br/>
                  {address.country}
                </div>
                <div className="accountShippingExtra">
                  {address.default && 
                    <div className="accountShippingDefaultContainer">
                      <CheckCircle className="accountShippingDefaultIcon" />
                      Default
                    </div>
                  }
                  <div className="accountShippingMoreContainer">
                    <MoreVertical className="accountShippingMoreIcon" />
                    <div className="accountShippingMoreMenu">
                      {!address.default && <p className="accountShippingMoreMenuLink" onClick={() => handleSetDefault(address.id)}>Set as Default</p>}
                      <p className="accountShippingMoreMenuLink" onClick={() => handleClickEditAddress(address.id)}>Edit Address</p>
                      <p className="accountShippingMoreMenuLink" onClick={() => handleDeleteAddress(address.id)}>Delete Address</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : userData && (!userData.shipping || userData.shipping.length === 0) ? <div className="accountShippingLoading">You currently have no addresses.</div> : <div className="accountShippingLoading">Loading addresses...</div>}
          </div>
        </div>
      </div>
      <AccountFooter windowSize={windowSize} />
      <PaymentsPopUp windowSize={windowSize} openConfirmation={openConfirmation} setOpenConfirmation={setOpenConfirmation}/>
    </div>
    </>
  );
}

export default TeamShipping;