import React from 'react';

import './AnimatedButton.css';

const AnimatedButton = ({ text, onClick, fontSize = 1.1, disabled = false }) => {

  return (
    <button disabled={disabled} style={{ opacity: disabled ? 0.5 : 1, paddingTop: `calc(${fontSize * 0.5}vw)`, paddingBottom: `calc(${fontSize * 0.5}vw)`, paddingRight: `calc(${fontSize * 2.2}vw)`, paddingLeft: `calc(${fontSize * 2.2}vw)`, borderRadius: `calc(${fontSize * 3}vw)` }} className="animatedButton" onClick={onClick}>
      {!disabled ? <div className="buttonBackground" /> : null}
      <p style={{ fontSize: `calc(${fontSize}vw)` }} className="buttonText">{text}</p>
    </button>
  );
}

export { AnimatedButton };