import React, { useState, useContext, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { v4 as uuidv4 } from 'uuid';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

import { Trash2, Copy, Edit2 } from 'react-feather';

import { AltAnimatedButton } from '../../Components/AltAnimatedButton';
import { FormSimpleTextInput } from '../../Components/FormSimpleTextInput';

import { NotificationContext } from '../../Context/NotificationContext';

const pageLimit = 50;

const Coupons = ({ windowSize }) => {
  const { showNotification } = useContext(NotificationContext);

  const [modalOpen, setModalOpen] = useState(false);
  const modalProps = useSpring({ opacity: modalOpen ? 1 : 0, pointerEvents: modalOpen ? 'all' : 'none' });
  const [modalMode, setModalMode] = useState('create');
  const [couponToEdit, setCouponToEdit] = useState(null);

  const [coupons, setCoupons] = useState([]);
  const [loadedCoupons, setLoadedCoupons] = useState(false);

  const [newUses, setNewUses] = useState('0');
  const [newDiscount, setNewDiscount] = useState('0');

  const [createCouponButton, setCreateCouponButton] = useState('Create Coupon');

  let firstLoad = useRef();
  if (!firstLoad.current) firstLoad.current = true;

  let nextPointer = useRef();
  if (!nextPointer.current) nextPointer.current = null;

  useEffect(() => {
    const firestore = firebase.firestore();

    const unsubscribe = 
      firestore.collection('coupons')
        .orderBy('timestamp', 'desc')
        .limit(pageLimit)
        .onSnapshot(querySnapshot => {
          if (firstLoad.current) {
            nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];
            firstLoad.current = false;
            
            setCoupons(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id, status: 'active' })));
            setLoadedCoupons(true);
          } else {
            let newCoupons = [];

            querySnapshot.docChanges().forEach((change) => {
              if (change.type === "added") {
                newCoupons.push({ id: change.doc.id, ...change.doc.data(), status: 'active' });
              }
            });
            
            setCoupons(c => newCoupons.concat(c));
            setLoadedCoupons(true);
          }
        }, error => {
          //console.log(error);
          showNotification('Error', "There was an error loading the coupons. Please, reload the page.", 'error');
          setLoadedCoupons(true);
        });

    return () => unsubscribe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMoreCoupons = () => {
    if (nextPointer.current) {
      const firestore = firebase.firestore();

      firestore.collection('coupons')
        .orderBy("timestamp", "desc")
        .startAfter(nextPointer.current)
        .limit(pageLimit)
        .get().then((querySnapshot) => {
          nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];

          let oldCoupons = [];

          querySnapshot.forEach((doc) => {
            oldCoupons.push({ id: doc.id, ...doc.data(), status: 'active' });
          });
          
          setCoupons(c => c.concat(oldCoupons));
        })
        .catch((error) => {
          showNotification('Error', "There was an error loading the coupons. Please, reload the page.", 'error');
          //console.log("Error getting documents: ", error);
        });
    }
  }

  const openNewCouponModal = () => {
    setModalMode('create');
    setNewUses('');
    setNewDiscount('');
    setCreateCouponButton('Create Coupon');
    setCouponToEdit(null);
    setModalOpen(true);
  }

  const inputNewUses = uses => {
    var reg = /^\d+$/;

    if (uses === '' || reg.test(uses)) {
      setNewUses(uses);
    }
  }

  const inputNewDiscount = discount => {
    var reg = /^\d+$/;

    if (discount === '' || (reg.test(discount) && parseInt(discount, 10) < 100)) {
      setNewDiscount(discount);
    }
  }

  const createCoupon = () => {
    setCreateCouponButton('Creating Coupon...');

    const code = uuidv4().split('-');

    const firestore = firebase.firestore();

    firestore.collection('coupons')
      .add({
        code: [code[0], code[1]].join('').toUpperCase(),
        active: true,
        uses: parseInt(newUses, 10),
        discount: newDiscount,
        timestamp: Date.now(),
      })
      .then(() => {
        showNotification("Coupon Created", "The coupon has been created successfully.", "success");     
        setCreateCouponButton('Create Coupon');
        setModalOpen(false);
      })
      .catch(() => {
        showNotification("Error", "There was an error creating the coupon. Please, try again.", "error");
        setCreateCouponButton('Create Coupon');
      });
  }

  const handleEditCoupon = couponID => {
    setModalMode('edit');
    setNewUses(coupons.find((coupon) => coupon.id === couponID).uses.toString());
    setNewDiscount(coupons.find((coupon) => coupon.id === couponID).discount);
    setCreateCouponButton('Save');
    setCouponToEdit(couponID);
    setModalOpen(true);
  }

  const handleSaveCoupon = () => {
    const firestore = firebase.firestore();
    setCreateCouponButton('Saving...');

    firestore.collection('coupons').doc(couponToEdit)
      .update({
        uses: parseInt(newUses, 10),
        discount: newDiscount,
      })
      .then(() => {
        showNotification("Coupon Updated", "The coupon has been updated successfully.", "success");
        setCreateCouponButton('Save');
        setModalOpen(false);
      })
      .catch(() => {
        showNotification("Error", "There was an error updating the coupon. Please, try again.", "error");
        setCreateCouponButton('Save');
      });
  }

  const handleDeleteCoupon = couponID => {
    setCoupons(c => c.map(coupon => coupon.id === couponID ? { ...coupon, status: 'deleting' } : coupon));

    const firestore = firebase.firestore();

    firestore.collection('coupons').doc(couponID).delete()
      .then(() => {
        showNotification("Coupon Deleted", "The coupon has been deleted successfully.", "success");     
      })
      .catch(error => {
        showNotification("Error", "An error ocurred deleting the coupon. Please, try again. If it persists, contact technical support.", "error");
      })
  }

  const handleChangeStatus = (couponID, isActive) => {
    const firestore = firebase.firestore();

    firestore.collection('coupons').doc(couponID)
      .update({ active: isActive ? false : true })
      .then(() => {
        showNotification("Status Updated", "The coupon's status has been successfully updated.", "success");     
      })
      .catch(error => {
        showNotification("Error", "An error ocurred changing the coupon's status. Please, try again. If it persists, contact technical support.", "error");
      })
  }

  const copyCode = code => {
    const el = document.createElement('textarea');
    el.value = code;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    showNotification("Code Copied", "The code has been copied to the clipboard.", "success");     
  }

  return (
    <div className="translationsContainer">
      {modalOpen && <animated.div style={modalProps} className="userModalUnderlay" onClick={() => setModalOpen(false)}/>}
      {modalOpen && <animated.div style={modalProps} className="userModal">
        <div className="singleQuoteMainPrimaryFilesTitle" style={{ marginTop: 0, marginBottom: 'calc(2vh)' }}>
          {`${modalMode === 'create' ? 'New' : 'Edit'} Coupon`}
          <div className="singleOrderRevisionButtons">
            <AltAnimatedButton color={'#E1504A'} text={'Close'} onClick={() => setModalOpen(false)} backend fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.9 : 1.2) : (windowSize.orientation === 'portrait' ? 3.7 : 1.1)} />
          </div>
        </div>
        <div className="userModalLine">
          <div className="userModalHalf">
            <FormSimpleTextInput placeholder="Number of uses (*)" value={newUses} onChange={inputNewUses} color='#666' />
          </div>
          <div className="userModalHalf">
            <FormSimpleTextInput placeholder="Discount (*)" value={newDiscount} onChange={inputNewDiscount} color='#666' />
          </div>
        </div>
        <div className="userModalButtonContainer">
          <AltAnimatedButton text={createCouponButton} backend disabled={newUses === '' || newUses === '0' || newDiscount === '' || newDiscount === '0'} onClick={modalMode === 'create' ? () => createCoupon() : () => handleSaveCoupon()} fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.7 : 1.1) : (windowSize.orientation === 'portrait' ? 3.5 : 1)} />
        </div>
      </animated.div>}

      <div className="translationsInnerContainer">
        <div className="translationsTitleRow">
          <p className="translationsTitle">Coupons</p>
          <div className="usersFunctionsContainer">
            <AltAnimatedButton text={"Add Coupon"} onClick={() => openNewCouponModal()} backend fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.7 : 1.1) : (windowSize.orientation === 'portrait' ? 3.5 : 1)} />
          </div>
        </div>
        <div className="translationsTableHeader">
          <div className="usersHeaderColumn usersIDColumn">Code</div>
          <div className="usersHeaderColumn usersStatusColumn">Status</div>
          <div className="usersHeaderColumn usersRoleColumn"># of uses</div>
          <div className="usersHeaderColumn usersLanguageColumn">Discount</div>
          <div className="usersHeaderColumn usersDeleteColumn"></div>
          <div className="usersHeaderColumn usersDeleteColumn" style={{ display: 'flex' }}></div>
        </div>
        { loadedCoupons && coupons.length > 0
        ? coupons.map(coupon => 
          <div key={coupon.id} className="translationsTableLine">
            <div className="usersLineColumn usersIDColumn" style={{ cursor: 'pointer' }} onClick={() => copyCode(coupon.code)}>
              <strong>{coupon.code}</strong>
              <Copy className="usersDeleteButton" style={{ color: '#333', marginLeft: 8 }} />
            </div>
            <div className="usersLineColumn usersStatusColumn">
              {coupon.status === 'deleting' 
                ? <p className="userStatusText">Deleting...</p>
                : (
                  <div 
                    className="translationsStatusContainer"
                    style={{ backgroundColor: coupon.active ? '#17BC5B' : '#E1504A', cursor: 'pointer' }}
                    onClick={() => handleChangeStatus(coupon.id, coupon.active)}
                  >
                    { coupon.active ? "ACTIVE" : 'INACTIVE'}
                  </div>
                )
              }
            </div>
            <div className="usersLineColumn usersRoleColumn">{coupon.uses}</div>
            <div className="usersLineColumn usersLanguageColumn">{coupon.discount}</div>
            <div className="usersHeaderColumn usersDeleteColumn">
              <Edit2 className="usersDeleteButton" style={{ color: '#333' }} onClick={() => handleEditCoupon(coupon.id)} />
            </div>
            <div className="usersLineColumn usersDeleteColumn" style={{ display: 'flex' }}>
              {coupon.status !== 'deleting' ? <Trash2 className="usersDeleteButton" onClick={() => handleDeleteCoupon(coupon.id)} /> : null}
            </div>
          </div>)
        : loadedCoupons ? <div className="loadingTranslations">No coupons.</div> : <div className="loadingTranslations">Loading coupons...</div>
        }
        { loadedCoupons && coupons.length >= 50 && nextPointer.current &&
          <div className="loadMoreUsersButton" onClick={() => loadMoreCoupons()}>
            Load more coupons...
          </div>
        }
      </div>
    </div>
  );
};

export default Coupons;