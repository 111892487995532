import React, { useContext } from 'react';

import { Navbar } from '../Components/Navbar';
import { Footer } from '../Components/Footer';
import { AltAnimatedButton } from '../Components/AltAnimatedButton';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import { ArrowRight } from 'react-feather';

import { TransitionContext } from '../Context/TransitionContext';

import './SingleUseCase.css';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { AuthContext } from '../Context/AuthContext';
import TopBar from '../Components/TopBar';

const SingleUseCase = ({ windowSize, useCase }) => {

  const { webP } = useContext(TransitionContext);

  const { transitionTo } = useScreenTransition();
  
  const { signedIn } = useContext(AuthContext);

  return (
    <>
    <TopBar/>
    <div className="homeContainer">
      { webP ? <img src={require(`../assets/${webP === 'webp' ? 'headerBg.webp' : 'headerBg.png'}`)} alt="Decorative Heading Background" className="homeHeaderBg" /> : null}
      <Navbar windowSize={windowSize} />
      <div className="headerContainer">
        <div className="headerInfoContainer">
          <h1 className="singleUseCaseHeading">{useCase.title}</h1>
          <h2 className="singleUseCaseSubheading">
            {useCase.sub}
          </h2>
          <div className="singleLanguageSeparator" />
          <AltAnimatedButton text="Order Now" onClick={(e) => transitionTo(`${signedIn && firebase.auth().currentUser ? '/order/documents' : '/order/contact'}`,e)} fontSize={windowSize.width > 1024 ? 1.3 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 2.4 : 1.6) : (windowSize.orientation === 'portrait' ? 6 : 2.2)} />
        </div>
        <img src={require(`../assets/useCases/${useCase.image}.png`)} alt="Person holding Planet Earth" className="singleUseCaseHeaderImg" />
      </div>
      <div className="certificationContainer">
        <div className="certificationItemContainer">
          <img src={require('../assets/ata-logo.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">ATA Corporate Member #273062</p>
        </div>
        <div className="certificationItemContainer">
          <img src={require('../assets/icon-3.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">100% guaranteed acceptance by USCIS</p>
        </div>
        <div className="certificationItemContainer">
          <img src={require('../assets/icon-1.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">Trusted by +350 law firms</p>
        </div>
        <div className="certificationItemContainer">
          <img src={require('../assets/icon-2.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">Same-day delivery available</p>
        </div>
      </div>
      <div className="singleUseCaseMainBlockContainer">
        <h3 className="documentsHeading">{useCase.blockTitle}</h3>
        {useCase.block}
        <div className="singleUseCaseSeparator" />
        <p className="documentsHeading">{useCase.type === 'certified' ? "Certified Translation" : "Standard Translation"}</p>
        { useCase.type === "certified" 
        ? <p className="servicesDisplaySubheading">
            This use case typically requires a certified translation. A word-for-word human translation of documents delivered on eVerbary letterhead including a signed and stamped Certificate of Translation Accuracy for official use as required by U.S. Immigration (USCIS), universities, courts, and many local, state, and federal governments. <span className="hoveringTextLink" onClick={(e) => transitionTo('/certified',e)}>Learn more</span>.
          </p>
        : <p className="servicesDisplaySubheading">
            A human translation of documents or text-based content delivered in an editable format for business or personal use. Perfect for legal documents, employee manuals, business communications, blog posts, product descriptions, and many other documents and types of content. <span className="hoveringTextLink" onClick={(e) => transitionTo('/standard',e)}>Learn more</span>.
          </p>
        }
        <div className="singleUseCaseLinkContainer" onClick={(e) => transitionTo('/use-cases',e)}>
          <p className="singleUseCaseLinkText">All use cases</p>
          <ArrowRight size={20} color="#18b7bb" />
        </div>
      </div>
      <Footer windowSize={windowSize} />
    </div>
    </>
  );
}

export default SingleUseCase;