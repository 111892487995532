import React, { useState, useContext, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { Editor, EditorState, convertFromRaw, convertToRaw } from 'draft-js';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

import { FormSimpleTextInput } from '../../Components/FormSimpleTextInput';
import { AltAnimatedButton } from '../../Components/AltAnimatedButton';

import { NotificationContext } from '../../Context/NotificationContext';
import { DataContext } from '../../Context/DataContext';

import { countries } from '../../Data/countries';
import { timezones } from '../../Data/timezones';
import { languages } from '../../Data/languages';

import './Preferences.css';
import { useScreenTransition } from '../../Hooks/useScreenTransition';

const Preferences = ({ windowSize }) => {

  const { transitionTo } = useScreenTransition();

  const { showNotification } = useContext(NotificationContext);

  const { userData } = useContext(DataContext);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [savePassButton, setSavePassButton] = useState('Save');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [timezone, setTimezone] = useState('');

  const [daysOffNotes, setDaysOffNotes] = React.useState(() => EditorState.createEmpty());

  const [saveInfoButton, setSaveInfoButton] = useState('Save');

  const [promptPassword, setPromptPassword] = useState('');

  const [modalOpen, setModalOpen] = useState(false);
  const modalProps = useSpring({ opacity: modalOpen ? 1 : 0, pointerEvents: modalOpen ? 'all' : 'none' });

  useEffect(() => {
    if (userData) {
      setName(userData.name);
      setEmail(userData.email);
      setPhone(userData.role === 'Translator' ? userData.phone : '');
      setCountry(userData.role === 'Translator' ? userData.country : '');
      setTimezone(userData.role === 'Translator' ? userData.timezone : '');
      if (userData.daysOffNotes) {
        setDaysOffNotes(EditorState.createWithContent(convertFromRaw(userData.daysOffNotes)));
      }
    } else {
      showNotification('Error', "There was an error loading your preferences. Please, try again. If it persists, please contact technical support.", 'error');
      transitionTo('/office/translations');
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const updatePassword = () => {
    if (confirmPassword !== newPassword) {
      showNotification('Error', "The passwords don't match.", 'error');
      return false;
    }

    if (newPassword.length < 8) {
      showNotification('Error', "New password must be 8 characters long or more.", 'error');
      return false;
    }

    if (firebase.auth().currentUser) {
      setSavePassButton('Saving...');

      firebase.auth().signInWithEmailAndPassword(firebase.auth().currentUser.email, oldPassword)
        .then(credential => {
          return credential.user.updatePassword(newPassword);
        })
        .then(() => {
          showNotification("Success", "Your password has been successfully updated.", "success");
          setSavePassButton('Save');
          setOldPassword('');
          setNewPassword('');
          setConfirmPassword('');
        })
        .catch(error => {
          var errorCode = error.code;
          setSavePassButton('Save');
          //console.log(error);
          
          switch(errorCode) {
            case "auth/invalid-email":
              showNotification("Error", "An unexpected error ocurred. Please, try again. If it persists, contact technical support.", "error");
              break;
            case "auth/user-disabled":
              showNotification("User Disabled", "The user has been disabled. Contact us for more information.", "error");
              break;
            case "auth/user-not-found":
              showNotification("Wrong User/Password", "The user/password are wrong. Please, try again.", "error");
              break;
            case "auth/wrong-password":
              showNotification("Wrong User/Password", "The user/password are wrong. Please, try again.", "error");
              break;
            case "auth/weak-password":
              showNotification('Error', "New password is too weak. Please, try again with a different one.", 'error');
              break;
            default:
              showNotification("Error", "An unexpected error ocurred. Please, try again.", "error");
          }
        });
    } else {
      showNotification("Error", "An unexpected error ocurred. Please, try again. If it persists, contact technical support.", "error");
    } 
  }

  const inputPhone = phone => {
    var reg = /^\d+$/;

    if (phone === '' || reg.test(phone)) {
      setPhone(phone);
    }
  }

  const updateInfo = () => {
    setSaveInfoButton("Saving...");

    if (email !== userData.email) {
      setModalOpen(true);
    } else {
      saveInfo(false);
    }
  }

  const updateEmail = () => {
    setModalOpen(false);

    if (firebase.auth().currentUser) {
      firebase.auth().signInWithEmailAndPassword(firebase.auth().currentUser.email, promptPassword)
        .then(credential => {
          return credential.user.updateEmail(email);
        })
        .then(() => {
          setPromptPassword('');
          saveInfo(true);
        })
        .catch(error => {
          var errorCode = error.code;
          setSavePassButton('Save');
          //console.log(error);
          
          switch(errorCode) {
            case "auth/invalid-email":
              showNotification("Error", "An unexpected error ocurred. Please, try again. If it persists, contact technical support.", "error");
              break;
            case "auth/user-disabled":
              showNotification("User Disabled", "The user has been disabled. Contact us for more information.", "error");
              break;
            case "auth/user-not-found":
              showNotification("Wrong User/Password", "The user/password are wrong. Please, try again.", "error");
              break;
            case "auth/wrong-password":
              showNotification("Wrong User/Password", "The user/password are wrong. Please, try again.", "error");
              break;
            default:
              showNotification("Error", "An unexpected error ocurred. Please, try again.", "error");
          }
        });
    } else {
      showNotification("Error", "An unexpected error ocurred. Please, try again. If it persists, contact technical support.", "error");
      setSaveInfoButton("Save");
    } 
  }

  const saveInfo = changedEmail => {
    const firestore = firebase.firestore();

    firestore.collection('users').doc(userData.id)
      .update({
        name: name,
        email: changedEmail ? email.toLowerCase() : userData.email,
        phone: phone,
        country: country,
        timezone: timezone
      })
      .then(() => {
        setSaveInfoButton("Save");
        showNotification("Success", "Your information has been successfully updated.", "success");
      })
      .catch(error => {
        setSaveInfoButton("Save");
        showNotification("Error", "There was an error updating your information. Please, try again. If it persists, contact technical support.", "error");
      })
  }

  const formatRate = rate => {
    if (!rate) {
      return '';
    }

    return "$" + rate.toFixed(2) + "/word";
  }

  const toggleStatus = () => {
    const firestore = firebase.firestore();

    const now = new Date();

    firestore.collection('users').doc(userData.id)
      .update({
        status: userData.status === 'paused' ? (userData.wasTest || userData.wasTest === undefined ? 'test' : 'active') : 'paused',
        wasTest: userData.status === 'test' ? true : false
      })
      .then(() => {
        firestore.collection('notifications').add({
          timestamp: now.getTime(),
          type: 'translatorStatus',
          data: {
            userID: userData.id,
            name: userData.name,
            oldStatus: userData.status,
            newStatus: userData.status === 'paused' ? (userData.wasTest || userData.wasTest === undefined ? 'test' : 'active') : 'paused'
          },
          target: ['owner', 'Support'],
          read: []
        });
      })
      .catch(error => {
        showNotification("Error", "There was an error updating your status. Please, try again. If it persists, contact technical support.", "error");
      })
  }

  const saveDaysOffNotes = () => {
    const firestore = firebase.firestore();

    firestore.collection('users').doc(userData.id)
      .update({
        daysOffNotes: convertToRaw(daysOffNotes.getCurrentContent())
      })
      .then(() => {
        showNotification("Success", "Notes have been successfully saved.", "success");
      })
      .catch(error => {
        showNotification("Error", "There was an error updating your notes. Please, try again. If it persists, contact technical support.", "error");
      })
  }

  return (
    <div className="singleTranslationContainer">
      {modalOpen && <animated.div style={modalProps} className="userModalUnderlay" onClick={() => { setModalOpen(false); setSaveInfoButton("Save"); setPromptPassword(''); }}/>}
      {modalOpen && <animated.div style={modalProps} className="userModal">
        <div className="singleQuoteMainPrimaryFilesTitle" style={{ marginTop: 0, marginBottom: 'calc(3vh)' }}>
          Update Email
          <div className="singleOrderRevisionButtons">
            <AltAnimatedButton color={'#E1504A'} text={'Close'} onClick={() => { setModalOpen(false); setSaveInfoButton("Save"); setPromptPassword(''); }} backend fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.9 : 1.2) : (windowSize.orientation === 'portrait' ? 3.7 : 1.1)} />
          </div>
        </div>
        <div className="preferencesModalText">
          Changing your associated email is a security sensitive operation that requires the user to have recently signed in. We do this to prevent account hijacking. Please enter below your account password so we can make sure its you and proceed to change your email.
        </div>
        <div className="userModalLine">
          <FormSimpleTextInput password placeholder="Account Password (*)" value={promptPassword} onChange={setPromptPassword} color='#666' />
        </div>
        <div className="userModalButtonContainer">
          <AltAnimatedButton text="Continue" backend disabled={promptPassword === ''} onClick={() => updateEmail()} fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.7 : 1.1) : (windowSize.orientation === 'portrait' ? 3.5 : 1)} />
        </div>
      </animated.div>}

      <div className="singleTranslationHeader">
        <p className="singleTranslationOrderNumber">Preferences</p>
      </div>
      {userData
      ? <div className="singleTranslationDetailsOuter">
          <div className="singleTranslationDetailsMain">
            <div className="singleTranslationDetailsDates" style={{ minHeight: 100 }}>
              <div className="singlePaymentDecideTitleContainer">
                <p className="singlePaymentDecideTitle">Profile Information</p>
              </div>
              <div className="singleTranslationDecideMainContainer">
                <div className="preferencesDecideInfoContainer">
                  <FormSimpleTextInput placeholder="Name (*)" value={name} onChange={setName} color='#666' />
                  <FormSimpleTextInput placeholder="Email (*)" value={email} onChange={setEmail} color='#666' />
                  {userData.role === 'Translator' && <FormSimpleTextInput placeholder="Phone (*)" value={phone} onChange={inputPhone} color='#666' />}
                  {userData.role === 'Translator' && <div className="preferencesInfoLine">
                    <select value={country} onChange={(e) => setCountry(e.target.value)} className="quoteDetailsSelect">
                      <option key="" value="" disabled>Country (*)</option>
                      {countries.map(c => 
                        <option key={c} value={c}>{c}</option>
                      )}
                    </select>
                  </div>}
                  {userData.role === 'Translator' && <div className="preferencesInfoLine">
                    <select value={timezone} onChange={(e) => setTimezone(e.target.value)} className="quoteDetailsSelect">
                      <option key="" value="" disabled>Timezone (*)</option>
                      {timezones.map(timezone => 
                        <option key={timezone.text} value={timezone.text}>{timezone.text}</option>
                      )}
                    </select>
                  </div>}
                  <div className="userModalButtonContainer">
                    <AltAnimatedButton text={saveInfoButton} backend disabled={name === '' || email === '' || (userData.role === 'Translator' && (phone === '' || country === '' || timezone === ''))} onClick={() => updateInfo()} fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.7 : 1.1) : (windowSize.orientation === 'portrait' ? 3.5 : 1)} />
                  </div>
                </div>
              </div>
            </div>
            <div className="singleTranslationDetailsDates">
              <div className="singlePaymentDecideTitleContainer">
                <p className="singlePaymentDecideTitle">Update Password</p>
              </div>
              <div className="singleTranslationDecideMainContainer">
                <div className="preferencesDecideInfoContainer">
                  <FormSimpleTextInput password placeholder="Old Password (*)" value={oldPassword} onChange={setOldPassword} color='#666' />
                  <FormSimpleTextInput password placeholder="New Password (*)" value={newPassword} onChange={setNewPassword} color='#666' />
                  <FormSimpleTextInput password placeholder="Confirm Password (*)" value={confirmPassword} onChange={setConfirmPassword} color='#666' />
                  <div className="userModalButtonContainer">
                    <AltAnimatedButton text={savePassButton} backend disabled={oldPassword === '' || newPassword === ''} onClick={() => updatePassword()} fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.7 : 1.1) : (windowSize.orientation === 'portrait' ? 3.5 : 1)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {userData.role === 'Translator' && 
            <div className="singleRevisionDetailsSecondary">
              <div className="singlePaymentInformation">
                <p className="singleTranslationDecideTitle">Translator Details</p>
                <p className="singleTranslationDetailsSecondaryText">
                  <span className="preferencesSubtitle">Languages</span><br/>
                  English
                  {" - "}
                  {languages.find(lang => lang.value === userData.language).label}
                  <br/><br/>
                  <span className="preferencesSubtitle">Payouts</span><br/>
                  {`- Method: ${userData.method}`}<br/>
                  {userData.methodID &&
                    <>
                      {`- Method ID/Email: ${userData.methodID}`}<br/>
                    </>
                  }
                  {`- Standard Rate: ${formatRate(userData.standardRate)}`}<br/>
                  {`- Expedited Rate: ${formatRate(userData.expeditedRate)}`}<br/>
                </p>
              </div>
              <div className="singlePaymentInformation">
                <p className="singleTranslationDecideTitle">Days Off</p>
                <p className="singleTranslationDetailsSecondaryText">
                  <span className="preferencesSubtitle">Current Status</span>
                </p>
                <div className="singleTranslationStatusContainer" style={{ marginTop: windowSize.width < 768 && windowSize.orientation === 'portrait' ? 'calc(0.5vh)' : 'calc(1vh)' ,backgroundColor: userData.status === 'paused' ? '#E1504A' : '#17BC5B' }}>
                  { userData.status === 'paused' ? 'PAUSED' : 'ACTIVE' }
                </div>
                <div className="preferencesScheduleSeparator" />
                <div className="singleTranslationNotesContainer">
                  <div className="singleTranslationNotesTitle" style={{ backgroundColor: '#FF7C1D' }}>
                    <strong>Notes</strong>
                    <div className="singleOrderTextButtons">
                      <div className="singleTranslationNotesAuxButton" onClick={() => saveDaysOffNotes()}>
                        Save
                      </div>
                    </div>
                  </div>
                  <div className="singleTranslationRichTextEditor">
                    <Editor
                      editorState={daysOffNotes}
                      onChange={daysOffNotes => setDaysOffNotes(daysOffNotes)}
                    />
                  </div>
                </div>
                <AltAnimatedButton space color={userData.status === 'paused' ? '#17BC5B' : '#E1504A'} text={userData.status === 'paused' ? "Activate" : "Deactivate"} width={'100%'} onClick={() => toggleStatus()} backend fontSize={windowSize.width > 1024 ? 1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.9 : 1.2) : (windowSize.orientation === 'portrait' ? 3.7 : 1.1)} />
              </div>
            </div>
          }
        </div>
      : null
      }
    </div>
  );
};

export default Preferences;