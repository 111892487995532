import React from 'react'
import './PasswordChanged.css'
import { ReactComponent as Close } from "../../assets/icons/x.svg";
import {ReactComponent as Success} from "../../assets/icons/Seccess.svg"

export default function PasswordChanged({backToSignIn, isLoginOpen}) {
  return (
    <div className="passwordChangedForm">
    <div className="passwordChangedFormHeader">
      <h2 className="passwordChangedFormTitle">Password Changed</h2>
      <Close className="signInFormIcon" onClick={isLoginOpen} />
    </div>
    <Success className='passwordChangedFormSuccess' />
    <span className="passwordChangedFormText">
      Your password has been changed succesfully
    </span>
    <button className="passwordChangedFormButton" onClick={()=>backToSignIn()}>Back to Sign in</button>
  </div>
  )
}
