import React, { useState, useContext, useEffect } from 'react';
import { useSpring, animated, config } from 'react-spring';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

import { Navbar } from '../../Components/Navbar';
import { AccountFooter } from '../../Components/AccountFooter';
import { FormTextInput } from '../../Components/FormTextInput';
import { AltAnimatedButton } from '../../Components/AltAnimatedButton';

import { useScreenTransition } from '../../Hooks/useScreenTransition';

import { AuthContext } from '../../Context/AuthContext';
import { TransitionContext } from '../../Context/TransitionContext';
import { NotificationContext } from '../../Context/NotificationContext';

import { useEnterKey } from '../../Hooks/useEnterKey';

import './Profile.css';
import { DataContext } from '../../Context/DataContext';
import TopBar from '../../Components/TopBar';

const Password = ({ windowSize }) => {

  const { transitionTo } = useScreenTransition();

  const { setColor } = useContext(TransitionContext);
  const { signedIn } = useContext(AuthContext);
  const { userData } = useContext(DataContext);
  const { showNotification } = useContext(NotificationContext);

  console.log(userData)

  const [oldPass, setOldPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');

  const [infoButton, setInfoButton] = useState('Save');

  const phoneScreen = windowSize.width > 767 || windowSize.orientation === 'landscape' ? false : true;

  const [menuOpen, setMenuOpen] = useState(!phoneScreen);

  const accountMenuProps = useSpring({ maxHeight: menuOpen || !phoneScreen ? 'calc(50vh)' : 'calc(2.5vh)', config: config.slow });

  useEffect(() => {
    setColor(true);
  }, [setColor]);

  useEffect(() => {
    if (!signedIn) {
      transitionTo('/sign-in');
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedIn]);

  const handleSaveInfo = () => {
    if(userData && userData.email){
      setInfoButton('Saving...');
      const user = firebase.auth().currentUser;

      const credential = firebase.auth.EmailAuthProvider.credential(userData.email, oldPass);

      user.reauthenticateWithCredential(credential).then(() => {
          // User re-authenticated.
          user.updatePassword(newPass).then(() => {
            showNotification('Information Saved', 'Your password has been updated successfully.', 'success');
                setInfoButton('Save');
          }).catch(error => {
            setInfoButton('Save');
            showNotification('Error', 'Your password is incorrect. Please, check your password. If it persists, contact Support.', 'error');
          })
      }).catch((error) => {
          setInfoButton('Save');
          showNotification('Error', 'Your password is incorrect. Please, check your password. If it persists, contact Support.', 'error');
        }); 
    }
  }

  useEnterKey(() => handleSaveInfo());


  
  return (
    <>
    <TopBar/>
    <div className="homeContainer">
      <Navbar windowSize={windowSize} />
      <div className="accountContainer">
        <animated.div style={accountMenuProps} className="accountSidebar">
          <p className="accountSidebarTitle" onClick={phoneScreen ? () => setMenuOpen(i => !i) : null}>{windowSize.width > 767 || windowSize.orientation === 'landscape' ? 'Account' : 'Account Menu'}</p>
          <p className="accountSidebarLink" onClick={(e) => transitionTo('/account/profile',e)}>Profile</p>
          <p className="accountSidebarLink" onClick={(e) => transitionTo('/account/shipping',e)}>Shipping</p>
          <p className="accountSidebarLink" onClick={(e) => transitionTo('/account/quotes',e)}>Quotes</p>
          <p className="accountSidebarLink" onClick={(e) => transitionTo('/account/orders',e)}>Orders</p>
          <p className="accountSidebarLink" style={{ color: '#666' }}>Password</p>
        </animated.div>
        <div className="accountMainContainer">
          <div className="accountMainTitleRow">
            <h1 className="accountMainTitle">Update Password</h1>
          </div>
          <div className="accountProfileFormLine">
            <FormTextInput password={true} placeholder="Old Password(*)" value={oldPass} onChange={setOldPass} color='#666' />
          </div>
          <div className="accountProfileFormLine">
            <FormTextInput  password={true} placeholder="New Password(*)" value={newPass} onChange={setNewPass} color='#666' />
          </div>
          <div className="accountProfileFormLine">
            <FormTextInput  password={true} placeholder="Confirm Password(*)" value={confirmPass} onChange={setConfirmPass} color='#666' />
          </div>
          <div className="accountProfileFormButton">
            <AltAnimatedButton text={infoButton} disabled={ oldPass === '' || newPass === '' || newPass !== confirmPass} onClick={() => handleSaveInfo()} fontSize={windowSize.width > 1024 ? 1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.4 : 1) : (windowSize.orientation === 'portrait' ? 4 : 1.3)} />
          </div>
        </div>
      </div>
      <AccountFooter windowSize={windowSize} />
    </div>
    </>
  );
}

export default Password;