import React, { useRef } from 'react';

import './AltAnimatedButton.css';

const AltAnimatedButton = ({ text, onClick, fontSize = 1.1, disabled = false, backend = false, color = '#FF7C1D', width = 'auto', space = false, minWidth = 'auto' }) => {

  const textRef = useRef();

  const onHover = status => {
    if (status) {
      textRef.current.style.color = color;
    } else {
      textRef.current.style.color = '#fff';
    }
  }

  return (
    <button onMouseEnter={() => onHover(true)} onMouseLeave={() => onHover(false)} disabled={disabled} style={{ minWidth: minWidth, paddingTop: `calc(${space ? fontSize * 0.7 : fontSize * 0.5}vw)`, paddingBottom: `calc(${space ? fontSize * 0.7 : fontSize * 0.5}vw)`, paddingRight: `calc(${fontSize * 2.2}vw)`, paddingLeft: `calc(${fontSize * 2.2}vw)`, borderRadius: backend ? '7px' : `calc(${fontSize * 3}vw)`, backgroundColor: color, borderColor: color, width: width }} className="altAnimatedButton" onClick={onClick}>
      <div className="altButtonBackground" />
      <p ref={textRef} style={{ fontSize: `calc(${fontSize}vw)` }} className="altButtonText">{text}</p>
    </button>
  );
}

export { AltAnimatedButton };