import React, { useState, useContext, useEffect } from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

import { Navbar } from '../Components/Navbar';
import { FormTextInput } from '../Components/FormTextInput';
import { AltAnimatedButton } from '../Components/AltAnimatedButton';
import { Footer } from '../Components/Footer';

import { useScreenTransition } from '../Hooks/useScreenTransition';
import { useEnterKey } from '../Hooks/useEnterKey';

import { TransitionContext } from '../Context/TransitionContext';
import { NotificationContext } from '../Context/NotificationContext';
import { AuthContext } from '../Context/AuthContext';

import './CreateAccount.css';

import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import TopBar from '../Components/TopBar';

const JoinTeam = ({ windowSize }) => {

  const { transitionTo } = useScreenTransition();

  const { search } = useLocation()
  const {email: userEmail, name: userName} = queryString.parse(search)

  const { setColor } = useContext(TransitionContext);

  const { showNotification } = useContext(NotificationContext);

  const { setSignedIn } = useContext(AuthContext);

  useEffect(() => {
    setColor(true);
  }, [setColor]);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirm] = useState('');

  const [submitButton, setSubmitButton] = useState('Submit');



  useEffect(()=> {
    if(userEmail !== "" && userName !== ""){
        firebase.auth().signInWithEmailAndPassword(userEmail, "tempPassword")
        .then((userCredential) => {
            const user = userCredential.user
            setName(userName);
            setEmail(user.email);
            setSignedIn(true);
        })
        .catch((error) => {
            console.log(error)
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail, userName])

  const setNewPassword = () => {
    setSubmitButton("Submitting...");

    const user = firebase.auth().currentUser;

    user.updatePassword(password).then(() => {
    // Update successful.
        setSubmitButton("Submit");
        showNotification("Success", "New Password Created successfully", "success");
        transitionTo('/account/profile');
    })
      .catch(error =>  {
        //console.log(error);
        var errorCode = error.code;
        setSubmitButton("Submit");

        switch(errorCode) {
          case "auth/invalid-email":
            showNotification("Invalid Email", "The email address is invalid. Please, try again.", "error");
            break;
          case "auth/weak-password":
            showNotification("Weak Password", "The password is too weak. Please, try again.", "error");
            break;
          default:
            showNotification("Error", "An unexpected error ocurred. Please, try again.", "error");
        }
      });
  }

  const triggerSetPassword = () => {
    if (!(name === "" || email === "" || password === "" || confirmPassword === "" || password !== confirmPassword)) {
      setNewPassword();
    }
  }

  useEnterKey(() => triggerSetPassword());

  return (
    <>
    <TopBar/>
    <div className="homeContainer">
      <Navbar windowSize={windowSize} />
            {email ?
      <div className="createAccountContainer">
        <div className="createAccountInfoContainer">
          <h1 className="documentsHeading">Change your Team Account Password</h1>
          <h2 className="subheading">Access your team quote and order history, saved addresses, payment methods, and site preferences anytime.</h2>
        </div>
        <div className="createAccountFormContainer">
          <FormTextInput disabled placeholder="Name" value={name} onChange={setName} color='#666' />
          <FormTextInput disabled placeholder="Email" value={email} onChange={setEmail} color='#666' />
          <FormTextInput password placeholder="Password" value={password} onChange={setPassword} color='#666' />
          <FormTextInput password placeholder="Confirm Password" value={confirmPassword} onChange={setConfirm} color='#666' />
          <div className="createAccountFormSeparator" />
          <AltAnimatedButton text={submitButton} onClick={() => setNewPassword()} disabled={name === "" || email === "" || password === "" || confirmPassword === "" || password !== confirmPassword} fontSize={windowSize.width > 1024 ? 1.1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.6 : 1.1) : (windowSize.orientation === 'portrait' ? 5 : 1.5)}/>
        </div>
      </div>
           :  <div className="createAccountContainer">
            <h1 className="documentsHeading">No Account found...</h1>
            </div>}
      <Footer windowSize={windowSize} />
    </div>
    </>
  );
}

export default JoinTeam;