import { useEffect } from 'react';

function useEnterKey(handleSaveInfo) {
  useEffect(() => {
    const onKey = e => {
      if (e.keyCode === 13) {
        handleSaveInfo();
      }
    }

    window.addEventListener('keyup', onKey);

    return () => window.removeEventListener('keyup', onKey);
  });
}

export { useEnterKey };