import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Editor, EditorState, convertFromRaw } from "draft-js";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { NotificationContext } from "../../Context/NotificationContext";
import { DataContext } from "../../Context/DataContext";

import { languages } from "../../Data/languages";

import "./SingleUser.css";
import { useScreenTransition } from "../../Hooks/useScreenTransition";

const SingleUser = ({ windowSize }) => {
  const { userID } = useParams();

  const { transitionTo } = useScreenTransition();

  const { showNotification } = useContext(NotificationContext);

  const { userData } = useContext(DataContext);

  const [daysOffNotes, setDaysOffNotes] = React.useState(() =>
    EditorState.createEmpty()
  );

  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (userData.role === "owner" && userID) {
      const firestore = firebase.firestore();

      const unsubscribe = firestore
        .collection("users")
        .doc(userID)
        .onSnapshot(
          (doc) => {
            if (doc.exists) {
              setUserInfo({ ...doc.data(), id: doc.id });

              if(doc.data().hasTeam && doc.data().teamId){
                firestore.collection('teams').doc(doc.data().teamId).get().then(teamDoc => {
                    if(teamDoc.exists){
                        setUserInfo({ ...doc.data(), id: doc.id, teamName: teamDoc.data().name, discount: teamDoc.data().discount });
                    }
                })
              }else{
                setUserInfo({ ...doc.data(), id: doc.id });
              }

              if (doc.data().daysOffNotes) {
                setDaysOffNotes(
                  EditorState.createWithContent(
                    convertFromRaw(doc.data().daysOffNotes)
                  )
                );
              }
            } else {
              showNotification(
                "Error",
                "There is no user with that ID. Please, try again.",
                "error"
              );
              transitionTo("/office/users");
            }
          },
          (error) => {
            showNotification(
              "Error",
              "There was an error loading the user info. Please, reload the page.",
              "error"
            );
          }
        );

      return () => unsubscribe();
    } else {
      showNotification(
        "Error",
        "You don't have sufficient permissions to see this page. If you think you have the correct permissions, please contact our support team.",
        "error"
      );
      transitionTo("/office/translations");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID]);

  const formatRate = (rate) => {
    if (!rate) {
      return "";
    }

    return "$" + rate.toFixed(2) + "/word";
  };

  return (
    <div className="singleTranslationContainer">
      <div className="singleTranslationHeader">
        <p className="singleTranslationOrderNumber">User Information</p>
      </div>
      {userInfo ? (
        <div className="singleTranslationDetailsOuter">
          <div className="singleTranslationDetailsMain">
            <div
              className="singleTranslationDetailsDates"
              style={{ minHeight: 0 }}
            >
              <div className="singlePaymentDecideTitleContainer">
                <p className="singlePaymentDecideTitle">User Details</p>
              </div>
              <div className="singleTranslationDecideMainContainer">
                <div
                  className="singleTranslationDecideInfoContainer"
                  style={{ width: "100%" }}
                >
                  <div className="singleTranslationDecideInfoLine">
                    <p className="singleTranslationDecideInfoTitle">Name</p>
                    <p className="singleTranslationDecideInfoText">
                      {userInfo.name}
                    </p>
                  </div>
                  <div className="singleTranslationDecideInfoSeparator" />
                  <div className="singleTranslationDecideInfoLine">
                    <p className="singleTranslationDecideInfoTitle">Email</p>
                    <p className="singleTranslationDecideInfoText">
                      {userInfo.email}
                    </p>
                  </div>
                  <div className="singleTranslationDecideInfoSeparator" />
                  <div className="singleTranslationDecideInfoLine">
                    <p className="singleTranslationDecideInfoTitle">Role</p>
                    <p className="singleTranslationDecideInfoText">
                      {userInfo.role}
                    </p>
                  </div>

                  {userInfo && userInfo.hasTeam && userInfo.teamName && (
                      <div className="singleTranslationDecideInfoLine">
                        <p className="singleTranslationDecideInfoTitle">Team</p>
                        <p className="singleTranslationDecideInfoText">
                          {userInfo.teamName}
                        </p>
                      </div>
                  )}
                  {userInfo && userInfo.hasTeam && (
                      <div className="singleTranslationDecideInfoLine">
                        <p className="singleTranslationDecideInfoTitle">Discount</p>
                        <p className="singleTranslationDecideInfoText">
                          {userInfo.discount ? userInfo.discount :  0}
                        </p>
                      </div>
                  )}

                  {userInfo.role === "Translator" && (
                    <>
                      <div className="singleTranslationDecideInfoSeparator" />
                      <div className="singleTranslationDecideInfoLine">
                        <p className="singleTranslationDecideInfoTitle">
                          Language
                        </p>
                        <p className="singleTranslationDecideInfoText">
                          <strong>English</strong>
                          {" to "}
                          <strong>
                            {
                              languages.find(
                                (lang) => lang.value === userInfo.language
                              ).label
                            }
                          </strong>
                        </p>
                      </div>
                      <div className="singleTranslationDecideInfoSeparator" />
                      <div className="singleTranslationDecideInfoLine">
                        <p className="singleTranslationDecideInfoTitle">
                          Phone
                        </p>
                        <p className="singleTranslationDecideInfoText">
                          {userInfo.phone}
                        </p>
                      </div>
                      <div className="singleTranslationDecideInfoSeparator" />
                      <div className="singleTranslationDecideInfoLine">
                        <p className="singleTranslationDecideInfoTitle">
                          Country
                        </p>
                        <p className="singleTranslationDecideInfoText">
                          {userInfo.country}
                        </p>
                      </div>
                      <div className="singleTranslationDecideInfoSeparator" />
                      <div className="singleTranslationDecideInfoLine">
                        <p className="singleTranslationDecideInfoTitle">
                          Timezone
                        </p>
                        <p className="singleTranslationDecideInfoText">
                          {userInfo.timezone}
                        </p>
                      </div>
                      <div className="singleTranslationDecideInfoSeparator" />
                      <div className="singleTranslationDecideInfoLine">
                        <p className="singleTranslationDecideInfoTitle">
                          Method
                        </p>
                        <p className="singleTranslationDecideInfoText">
                          {userInfo.method}
                        </p>
                      </div>
                      {userInfo.methodID && (
                        <div className="singleTranslationDecideInfoSeparator" />
                      )}
                      {userInfo.methodID && (
                        <div className="singleTranslationDecideInfoLine">
                          <p className="singleTranslationDecideInfoTitle">
                            Method ID
                          </p>
                          <p className="singleTranslationDecideInfoText">
                            {userInfo.methodID}
                          </p>
                        </div>
                      )}
                      <div className="singleTranslationDecideInfoSeparator" />
                      <div className="singleTranslationDecideInfoLine">
                        <p className="singleTranslationDecideInfoTitle">
                          Standard Rate
                        </p>
                        <p className="singleTranslationDecideInfoText">
                          {formatRate(userInfo.standardRate)}
                        </p>
                      </div>
                      <div className="singleTranslationDecideInfoSeparator" />
                      <div className="singleTranslationDecideInfoLine">
                        <p className="singleTranslationDecideInfoTitle">
                          Expedited Rate
                        </p>
                        <p className="singleTranslationDecideInfoText">
                          {formatRate(userInfo.expeditedRate)}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {userInfo.role === "Translator" && (
            <div className="singleRevisionDetailsSecondary">
              <div className="singlePaymentInformation">
                <p className="singleTranslationDecideTitle">
                  {"Status & Schedule"}
                </p>
                <p className="singleTranslationDetailsSecondaryText">
                  <span className="preferencesSubtitle">Current Status</span>
                </p>
                <div
                  className="singleTranslationStatusContainer"
                  style={{
                    backgroundColor:
                      userInfo.status === "paused" ? "#E1504A" : "#17BC5B",
                  }}
                >
                  {userInfo.status === "paused"
                    ? "PAUSED"
                    : userInfo.status === "active"
                    ? "ACTIVE"
                    : "TEST"}
                </div>
                <div className="preferencesScheduleSeparator" />
                <div className="singleTranslationNotesContainer">
                  <div
                    className="singleTranslationNotesTitle"
                    style={{ backgroundColor: "#FF7C1D" }}
                  >
                    <strong>Notes</strong>
                  </div>
                  <div className="singleTranslationRichTextEditor">
                    <Editor
                      editorState={daysOffNotes}
                      onChange={(daysOffNotes) => setDaysOffNotes(daysOffNotes)}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default SingleUser;
