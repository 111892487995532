import React, { useRef, useState, useEffect, useContext } from "react";
import "./VerificationCode.css";
import { ReactComponent as Close } from "../../assets/icons/x.svg";

import { NotificationContext } from "../../Context/NotificationContext";
import axios from "axios";
import { Loader } from "react-feather";

export default function VerificationCode({
  backToSignIn,
  isLoginOpen,
  goToResetPassword,
  email,
}) {
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);

  const [inputValues, setInputValues] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
  });

  const [time, setTime] = useState(20);
  const [validTime, setValidTime] = useState(true);
  const [isCodeRight, setIsCodeRight] = useState(true);
  const [isLoading, setIsLoading]=useState(false);

  const { showNotification } = useContext(NotificationContext);
  const [btnText, setBtnText] = useState("Verify");


  useEffect(
    (e) => {
      if (time === 20) {
        setInputValues({ input1: "", input2: "", input3: "", input4: "" });
        input1Ref.current.focus();

        setTimeout(() => {
          setValidTime(false);
        }, 1000 * 60 * 10);
      }
    },
    [time]
  );

  useEffect(() => {
    let interval;
    if (time > 0) {
      interval = setInterval(() => {
        setTime((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (time === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [time]);

  const handleInputChange = (e, nextInputRef, inputName) => {
    const value = e.target.value;
    setValidTime(true);
    if (value.length <= 1) {
      setInputValues({
        ...inputValues,
        [inputName]: value,
      });
    }
    if (value.length === 1 && nextInputRef) {
      nextInputRef.current.focus();
    }
  };

  const handleKeyDown = (e, prevInputRef) => {
    if (e.target.value.length >= e.target.maxLength && e.key !== "Backspace") {
      e.preventDefault();
    }
    if (e.target.value.length === 0 && e.key === "Backspace" && prevInputRef) {
      prevInputRef.current.focus();
    }
  };

  const handleSendCodeAgain = () => {
    axios({
        method: "post",
        url: "https://us-central1-project-everbary.cloudfunctions.net/generateForgotPassCode",
        data: {
          email: email.toLowerCase()
        },
      }).then(() => {
        showNotification(
            "Success",
            "Verficiation code resent, Please check your email for new verifcation code!",
            "success"
          );
        setTime(20);
      })
        .catch((error) => {
          console.log(error)
        });
  };

  const isVerifyAvailable =
    Object.values(inputValues).every((value) => value.length === 1);
  
  const inputStyles =
    !isCodeRight && !validTime
      ? "sendVarificationCodeFormNumber sendVarificationCodeFormNumberError"
      : "sendVarificationCodeFormNumber";

  const handleVerfication = async () => {
    setBtnText("Verifing");
    setIsLoading(true);
    try {
      const enteredCode = Number(
        inputValues.input1 + inputValues.input2 + inputValues.input3 + inputValues.input4
      );
      const response = await axios.post(
        "https://us-central1-project-everbary.cloudfunctions.net/verifyForgotPassCode",
        {
          email: email.toLowerCase(),
          code: enteredCode,
        }
      );
  
      if (response.status === 200 && response.data === "Code Verifed") {
        goToResetPassword();
      } else if (response.data === "Expired" || response.data === "Invalid") {
        setIsCodeRight(false);
        setValidTime(false);
        input4Ref.current.focus();
        showNotification(
          "Error",
          "Invalid Verification code. The code entered is incorrect or expired, Please, try again.",
          "error"
        );
      } else {
        setIsCodeRight(false);
        setValidTime(false);
        showNotification(
          "Error",
          "Invalid Verification code. The code entered is incorrect or expired, Please, try again. If it persists, contact technical support at support@everbary.com",
          "error"
        );
      }
    } catch (error) {
      console.log(error);
      showNotification(
        "Error",
        "An error occurred while verifying the code. Please try again later.",
        "error"
      );
    } finally {
      setBtnText("Verify"); 
      setIsLoading(false);
    }
  };


  return (
    <div className="sendVarificationCodeForm">
      <div className="sendVarificationCodeFormHeader">
        <h2 className="sendVarificationCodeFormHeaderTitle">
          Verification Code
        </h2>
        <Close
          className="sendVarificationCodeFormHeaderClose"
          onClick={() => isLoginOpen()}
        />
      </div>

      <span className="sendVarificationCodeFormText">We've sent a code to</span>
      <span className="sendVarificationCodeFormEmail">{email}</span>

      <div className="sendVarificationCodeFormCodes">
        <input
          className={inputStyles}
          maxLength={1}
          type="number"
          ref={input1Ref}
          value={inputValues.input1}
          onChange={(e) => handleInputChange(e, input2Ref, "input1")}
          onKeyDown={(e) => handleKeyDown(e, null)}
          onMouseDown={(e) => input2Ref.current.value.length !== 0 && e.preventDefault()}
          // disabled={!isCodeRight && !validTime ? true : false}
        />
        <input
          className={inputStyles}
          maxLength={1}
          type="number"
          ref={input2Ref}
          value={inputValues.input2}
          onChange={(e) => handleInputChange(e, input3Ref, "input2")}
          onKeyDown={(e) => handleKeyDown(e, input1Ref)}
          onMouseDown={(e) => (input1Ref.current.value.length === 0 || input3Ref.current.value.length !== 0) && e.preventDefault()}
          // disabled={!isCodeRight && !validTime ? true : false}
        />
        <input
          className={inputStyles}
          maxLength={1}
          type="number"
          ref={input3Ref}
          value={inputValues.input3}
          onChange={(e) => handleInputChange(e, input4Ref, "input3")}
          onKeyDown={(e) => handleKeyDown(e, input2Ref)}
          onMouseDown={(e) => (input2Ref.current.value.length === 0 || input4Ref.current.value.length !== 0) && e.preventDefault()}
          // disabled={!isCodeRight && !validTime ? true : false}
        />
        <input
          className={inputStyles}
          maxLength={1}
          type="number"
          ref={input4Ref}
          value={inputValues.input4}
          onChange={(e) => handleInputChange(e, null, "input4")}
          onKeyDown={(e) => isVerifyAvailable && e.key === "Enter" ? handleVerfication() : handleKeyDown(e, input3Ref)}
          onMouseDown={(e) => input3Ref.current.value.length === 0 && e.preventDefault()}
          // disabled={!isCodeRight && !validTime ? true : false}
        />
      </div>

      {!validTime && !isCodeRight ? (
        <span className="sendVarificationCodeFormValidation">
          Wrong code, please try again
        </span>
      ) : null}

      <div className="sendVarificationCodeFormTimer">
        <span
          className={
            time.toString().padStart(2, "0") === "00"
              ? "sendVarificationCodeAgainAvalible"
              : "sendVarificationCodeFormTimerText"
          }
          onClick={handleSendCodeAgain}
        >
          Send code again
        </span>
        {time.toString().padStart(2, "0") === "00" ? null : (
          <span className="sendVarificationCodeFormTimerTime">
            00:{time.toString().padStart(2, "0")}
          </span>
        )}
      </div>

      <button
        className="sendVarificationCodeFormButton"
        disabled={!isVerifyAvailable || isLoading}
        onClick={() => handleVerfication()}
      >
        {btnText}
        {isLoading && <Loader className="btnLoader"/>}
      </button>
      <span
        className="sendVarificationCodeFormSignIn"
        onClick={() => backToSignIn()}
      >
        Back to Sign In
      </span>
    </div>
  );
}
