import React from "react";
import { Check } from "react-feather";
import { useSpring, animated } from "react-spring";

import CardIcon from "../../assets/credit-card.png";
import { AltAnimatedButton } from "../../Components/AltAnimatedButton";

const PaymentsPopUp = ({
  windowSize,
  openConfirmation,
  setOpenConfirmation,
}) => {
  const confirmationModalProps = useSpring({
    opacity: openConfirmation ? 1 : 0,
    pointerEvents: openConfirmation ? "all" : "none",
  });

  return (
    <>
      {openConfirmation && (
        <animated.div
          style={confirmationModalProps}
          className="revisionModalBg"
          onClick={() => setOpenConfirmation(false)}
        />
      )}
      {openConfirmation && (
        <animated.div
          style={confirmationModalProps}
          className="confirmationModalOuter"
        >
          <div className="singleTranslationNotesContainer">
            <div className="paymentIconWrap" >
              {/* <Check className="confirmationModalCheck" /> */}
              <img
              className="confirmationModalCheck"
                src={CardIcon}
                alt=""
              />
            </div>
            <p className="confirmationModalTitle">Payments</p>
            <p className="documentsSubheading">
              Exciting news! Our Payments section is on its way, designed to
              simplify invoice management, online payments, and payment records.
            </p>
            <br />
            <p className="businessLineInfoTitle">What to expect: </p>
            <br />
            <div className="businessLine">
              <Check size={windowSize.width > 1024 ? 30 : windowSize.width > 767 ? 33 : 45} color='#18b7bb' className="businessLineGlyph" />

              <div className="businessLineInfo">
                <h3 className="businessLineInfoTitle" style={{ flex: 1 }}>
                  Invoice Management
                </h3>
                <p className="documentsSubheading">
                  Easily access, view, and download invoices for transparency
                  and organization.
                </p>
              </div>
            </div>
            <div className="businessLine">
              <Check size={windowSize.width > 1024 ? 30 : windowSize.width > 767 ? 33 : 45} color='#18b7bb' className="businessLineGlyph" />

              <div className="businessLineInfo" style={{ flex: 1 }}>
                <h3 className="businessLineInfoTitle">Online Payments</h3>
                <p className="documentsSubheading">
                  Make secure payments directly on our website.
                </p>
              </div>
            </div>
            <div className="businessLine">
              <Check size={windowSize.width > 1024 ? 30 : windowSize.width > 767 ? 33 : 45} color='#18b7bb' className="businessLineGlyph" />

              <div className="businessLineInfo" style={{ flex: 1 }}>
                <h3 className="businessLineInfoTitle">Payment History</h3>
                <p className="documentsSubheading">
                  Track your payment activity with a comprehensive overview.
                </p>
              </div>
            </div>
            <div className="businessLine">
              <Check size={windowSize.width > 1024 ? 30 : windowSize.width > 767 ? 33 : 45} color='#18b7bb' className="businessLineGlyph" />
              
              <div className="businessLineInfo" style={{ flex: 1 }}>
                <h3 className="businessLineInfoTitle">
                  Automatic Payment Reminders
                </h3>
                <p className="documentsSubheading">
                  Receive timely reminders via email or WhatsApp, including
                  convenient payment links.
                </p>
              </div>
            </div>

            <p className="documentsSubheading">
              We appreciate your patience as we finalize this feature. We will
              notify you once the Payments section is live.
            </p>
            <p className="documentsSubheading">
              Thank you for your continued support!
            </p>
            <div className="closeBtnWrap">
              <AltAnimatedButton text={"Close"} backend  onClick={() => setOpenConfirmation(false)} fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.7 : 1.1) : (windowSize.orientation === 'portrait' ? 3.5 : 1)} />
            </div>
          </div>
        </animated.div>
      )}
    </>
  );
};

export default PaymentsPopUp;
