import React, { useState, useContext, useEffect } from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

import { User, FileText, Check } from 'react-feather';

import { FormTextInput } from '../../Components/FormTextInput';
import { AltAnimatedButton } from '../../Components/AltAnimatedButton';

import { useScreenTransition } from '../../Hooks/useScreenTransition';
import { useEnterKey } from '../../Hooks/useEnterKey';

import { AuthContext } from '../../Context/AuthContext';
import { NotificationContext } from '../../Context/NotificationContext';
import { DataContext } from '../../Context/DataContext';

import './QuoteContact.css';
import TopBar from '../../Components/TopBar';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const emailRegex = new RegExp("^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,}(\\.?[a-z]{2})?$");

const QuoteContact = ({ windowSize }) => {

  const { transitionTo } = useScreenTransition();
  const { showNotification } = useContext(NotificationContext);

  const { signedIn, setSignedIn } = useContext(AuthContext);

  const { quote, setQuote } = useContext(DataContext);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [newAccount, setNewAccount] = useState(false);

  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');

  const [signEmail, setSignEmail] = useState('');
  const [signPassword, setSignPassword] = useState('');

  const [signIn, setSignIn] = useState(false);

  const [submitButton, setSubmitButton] = useState('Continue to Details');

  useEffect(() => {

    if (signedIn && firebase.auth().currentUser) {
      transitionTo('/quote/details');
    } else {
      sleep(600).then(() => {
        if (signedIn && firebase.auth().currentUser) {
          transitionTo('/quote/details');
        }
      })
    }

    if (quote) {
      setName(quote.name);
      setEmail(quote.email);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedIn]);

  const handleSignUp = () => {
    setSubmitButton("Processing...");

    firebase.auth().createUserWithEmailAndPassword(email.toLowerCase(), password)
      .then(credential => {
        const firestore = firebase.firestore();

        const docRef = firestore.collection('users').doc(credential.user.uid);

        docRef.set({
          role: 'client',
          name: name,
          email: email.toLowerCase()
        }, { merge: true });

        setSignedIn(true);
        setSubmitButton("Continue to Details");
        showNotification("Success", "Your account has been created!", "success");
        transitionTo('/quote/details');
      })
      .catch(error =>  {
        //console.log(error);
        var errorCode = error.code;
        setSubmitButton("Continue to Details");

        switch(errorCode) {
          case "auth/email-already-in-use":
            showNotification("Email Already in Use", "The email is already in use. Please, try another one.", "error");
            break;
          case "auth/invalid-email":
            showNotification("Invalid Email", "The email address is invalid. Please, try again.", "error");
            break;
          case "auth/weak-password":
            showNotification("Weak Password", "The password is too weak. Please, try again.", "error");
            break;
          default:
            showNotification("Error", "An unexpected error ocurred. Please, try again.", "error");
        }
      });
  }

  const handleSignIn = () => {
    setSubmitButton('Logging in...');

    firebase.auth().signInWithEmailAndPassword(signEmail.toLowerCase(), signPassword)
      .then(credential => {
        setSubmitButton("Continue to Details");
        setSignedIn(true);
        showNotification("Success", "You're logged in!", "success");
        transitionTo('/quote/details');
      })
      .catch(error => {
        var errorCode = error.code;
        //console.log(error);
        
        switch(errorCode) {
          case "auth/invalid-email":
            showNotification("Invalid Email", "The email is invalid. Please, try again.", "error");
            break;
          case "auth/user-disabled":
            showNotification("User Disabled", "The user has been disabled. Contact us for more information.", "error");
            break;
          case "auth/user-not-found":
            showNotification("Wrong User/Password", "The user/password are wrong. Please, try again.", "error");
            break;
          case "auth/wrong-password":
            showNotification("Wrong User/Password", "The user/password are wrong. Please, try again.", "error");
            break;
          default:
            showNotification("Error", "An unexpected error ocurred. Please, try again.", "error");
        }
      });
  }

  const goToDetails = () => {
    if (emailRegex.test(email.toLowerCase())) {
      localStorage.setItem('quote', JSON.stringify({ ...quote, name: name, email: email.toLowerCase() }));
      setQuote(q => ({ ...q, name: name, email: email }));
      transitionTo('/quote/details');
    } else {
      showNotification("Invalid Email", "The email address is invalid. Please, try again.", "error");
    }
  };

  const handleClickDetails = () => {
    if (quote.name !== '' && quote.email !== '') {
      transitionTo('/quote/details');
    }
  }

  const triggerEnter = () => {
    if (!((signIn && (signEmail === '' || signPassword === '')) || (!signIn && (name === "" || email === "" || (newAccount && (password === "" || confirm === "" || password !== confirm)))))) {
      if (signIn) {
        handleSignIn();
      } else if (newAccount) {
        handleSignUp();
      } else {
        goToDetails();
      }
    }
  }

  useEnterKey(() => triggerEnter());

  return (
    <>
    <TopBar/>
    <div className="quoteContainer">
      <div className="quoteHeader">
        <img src={require('../../assets/logoAlt.png')} alt="Logo eVerbary" className="quoteLogo" onClick={(e) => transitionTo('/',e)} />
        <div className="quoteHeaderExtra">
          <div className="quoteHeaderExtraBlock">
            <User className="quoteHeaderIcon" />
            <p className="quoteHeaderExtraBlockText">Contact</p>
          </div>
          <div className="quoteHeaderConnector" />
          <div className="quoteHeaderExtraBlock" onClick={() => handleClickDetails()} style={{ opacity: 0.5, cursor: quote.name !== '' && quote.email !== '' ? 'pointer' : 'default' }}>
            <FileText className="quoteHeaderIcon" />
            <p className="quoteHeaderExtraBlockText">Details</p>
          </div>
        </div>
      </div>
      <div className="quoteMainContainer">
        <div className="quoteMainPrimaryContainer">
          <div className="quotePrimaryInner">
            <h3 className="quotePrimaryTitle">Contact Info</h3>
            {!signIn
            ? <p className="quotePrimarySub"><span className="quoteSignInButton" onClick={() => setSignIn(true)}>Sign in</span> if you have an account or continue as a guest.</p>
            : <p className="quotePrimarySub"><span className="quoteSignInButton" onClick={() => setSignIn(false)}>Cancel sign in</span> to continue as a guest.</p>
            }
            {!signIn && <div className="quotePrimaryForm">
              <div className="quotePrimaryFormElement">
                <FormTextInput placeholder="Full Name(*)" value={name} onChange={setName} color='#666' />
              </div>
              <div className="quotePrimaryFormElement">
                <FormTextInput placeholder="Email(*)" value={email} onChange={setEmail} color='#666' />
              </div>
            </div>}
            {signIn && <div className="quotePrimaryForm">
              <div className="quotePrimaryFormElement">
                <FormTextInput placeholder="Email(*)" value={signEmail} onChange={setSignEmail} color='#666' />
              </div>
              <div className="quotePrimaryFormElement">
                <FormTextInput password placeholder="Password(*)" value={signPassword} onChange={setSignPassword} color='#666' />
              </div>
            </div>}
            {signIn && <p className="quoteForgotPassword">Forgot password?</p>}
            {newAccount && !signIn ? <div className="quotePrimaryForm">
              <div className="quotePrimaryFormElement">
                <FormTextInput password placeholder="Password(*)" value={password} onChange={setPassword} color='#666' />
              </div>
              <div className="quotePrimaryFormElement">
                <FormTextInput password placeholder="Confirm Password(*)" value={confirm} onChange={setConfirm} color='#666' />
              </div>
            </div> : null}
            {newAccount && !signIn ? <p className="quoteExtraMessage">Creating an account allows you to access your quote and order history, saved addresses, payment methods, and site preferences anytime.</p> : null}
            {!signIn && <div className="quotePrimaryCheckboxContainer" onClick={() => setNewAccount(n => !n)}>
              <div className="quoteCheckbox">
                { newAccount ? <Check size={15} color="#666" /> : null }
              </div>
              <p className="quoteCheckboxText">I want to create an account</p>
            </div>}
            <div className="quoteFormButton">
              <AltAnimatedButton text={submitButton} onClick={signIn ? () => handleSignIn() : newAccount ? () => handleSignUp() : () => goToDetails()} disabled={(signIn && (signEmail === '' || signPassword === '')) || (!signIn && (name === "" || email === "" || (newAccount && (password === "" || confirm === "" || password !== confirm))))} fontSize={windowSize.width > 1024 ? 1.1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 2 : 1.1) : (windowSize.orientation === 'portrait' ? 5 : 1.5)}/>
            </div>
          </div>
        </div>
        <div className="quoteMainSecondaryContainer">
          <h3 className="quoteSecondaryTitle">WHAT TO EXPECT</h3>
          <div className="quoteSecondarySub">We’ll respond quickly with a formal quote covering the cost and turnaround time for your translation project.</div>
          <div className="quoteSecondaryLine">
            <Check size={15} color="#18b7bb" />
            <div className="quoteSecondaryLineText">Free quote, no obligation to buy</div>
          </div>
          <div className="quoteSecondaryLine">
            <Check size={15} color="#18b7bb" />
            <div className="quoteSecondaryLineText">Final pricing with no surprises</div>
          </div>
          <div className="quoteSecondaryLine">
            <Check size={15} color="#18b7bb" />
            <div className="quoteSecondaryLineText">Estimated turnaround time</div>
          </div>
          <div className="quoteSecondaryLine">
            <Check size={15} color="#18b7bb" />
            <div className="quoteSecondaryLineText">Quick convert to order when ready</div>
          </div>
          <div className="quoteSecondaryLine">
            <Check size={15} color="#18b7bb" />
            <div className="quoteSecondaryLineText">Quote is guaranteed for 30 days</div>
          </div>
          <div className="quoteSecondaryExtraInfo">
            <h3 className="quoteSecondaryTitle">SECURE AND PRIVATE</h3>
            <div className="quoteSecondarySub">All data processed by our site, including uploaded files, is encrypted. Only essential staff with signed NDAs can access your information.</div>
          </div>
          <img src={require('../../assets/encryptionIcons.png')} alt="Encryption Certificates" className="quoteSecondaryEncryptionImg" />
        </div>
      </div>
      <div className="quoteFooter">
        <div className="quoteFooterLinksContainer">
          <div className="quoteFooterLink">Contact</div>
          <div className="quoteFooterLinkSeparator" />
          <div className="quoteFooterLink">Terms</div>
          <div className="quoteFooterLinkSeparator" />
          <div className="quoteFooterLink">Refunds</div>
          <div className="quoteFooterLinkSeparator" />
          <div className="quoteFooterLink">Privacy</div>
        </div>
      </div>
    </div>
    </>
  );
}

export default QuoteContact;