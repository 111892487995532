import React, { useState, useContext, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import axios from 'axios';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

import { Trash2 } from 'react-feather';

import { AltAnimatedButton } from '../../Components/AltAnimatedButton';


import { NotificationContext } from '../../Context/NotificationContext';

import { useEnterKey } from '../../Hooks/useEnterKey';

import './Teams.css';
import { useScreenTransition } from '../../Hooks/useScreenTransition';
import { FormSimpleTextInput } from '../../Components/FormSimpleTextInput';

const pageLimit = 50;

const Teams = ({ windowSize }) => {
    const { showNotification } = useContext(NotificationContext);

  const { transitionTo } = useScreenTransition();


  const [modalOpen, setModalOpen] = useState(false);
  const modalProps = useSpring({ opacity: modalOpen ? 1 : 0, pointerEvents: modalOpen ? 'all' : 'none' });

  const [userType, setUserType] = useState('New User');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [name, setName] = useState('');
  const [role] = useState('client');
  const [teamOption, setTeamOption] = useState('');
  const [teamsData, setTeamsData] = useState([]);
  const [teamId, setTeamId] = useState('');
  const [teamName, setTeamName] = useState('');
  const [teamDiscount, setTeamDiscount] = useState(0);

  const [createUserButton, setCreateUserButton] = useState('Create User');

  const [teamFilter, setTeamFilter] = useState('');

  const nameSearchRef = useRef();
  const [nameSearch, setNameSearch] = useState('');

  const emailSearchRef = useRef();
  const [emailSearch, setEmailSearch] = useState('');

  const [triggerFilter, setTriggerFilter] = useState(true);

  const [users, setUsers] = useState([]);
  const [loadedUsers, setLoadedUsers] = useState(false);

  let firstLoad = useRef();
  if (!firstLoad.current) firstLoad.current = true;

  let nextPointer = useRef();
  if (!nextPointer.current) nextPointer.current = null;

  useEffect(() => {
    const applyEmail = e => {
      if (e.keyCode === 13) {
        setTriggerFilter(tf => !tf);
      }
    }

    const applyName = e => {
      if (e.keyCode === 13) {
        setTriggerFilter(tf => !tf);
      }
    }

    const nameSearchRefVar = nameSearchRef.current;
    const emailSearchRefVar = emailSearchRef.current;

    nameSearchRef.current.addEventListener("keyup", applyName);
    emailSearchRef.current.addEventListener("keyup", applyEmail);

    return () => {
      nameSearchRefVar.removeEventListener("keyup", applyName);
      emailSearchRefVar.removeEventListener("keyup", applyEmail);
    }
  }, []);


  useEffect(()=> {

    const firestore = firebase.firestore();

    var docRef = firestore.collection("teams").orderBy('name', 'asc');

    docRef.get().then((querySnapshot) => {
        setTeamsData(querySnapshot.docs.map((doc) => 
            ({name: doc.data().name, discount: doc.data().discount, id: doc.id})
        ));
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    });

  }, [teamOption, users])

  useEffect(() => {
    const firestore = firebase.firestore();

    let query = firestore.collection('users').where('hasTeam', '==', true);

    if (teamFilter !== '') {
        query = query.where('teamId', '==', teamFilter);
      };
  
      if (nameSearch !== '') {
        query = query.where('name', '==', nameSearch);
      };
  
      if (emailSearch !== '') {
        query = query.where('email', '==', emailSearch.toLowerCase());
      };

    const unsubscribe = 
      query
      .orderBy('timestamp', 'desc')
        .limit(pageLimit)
        .onSnapshot((querySnapshot) => {
          if (firstLoad.current) {
            nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];
            firstLoad.current = false;

            setUsers(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
            setLoadedUsers(true);
          } else {
            let newUsers = [];

            querySnapshot.docChanges().forEach(async(change) => {
              if (change.type === "added") {
                newUsers.push({ id: change.doc.id, ...change.doc.data() });
              }
            });
            
            setUsers(u => newUsers.concat(u));
            setLoadedUsers(true);
          }
        }, error => {
          console.log(error);
          showNotification('Error', "There was an error loading the users. Please, reload the page.", 'error');
          setLoadedUsers(true);
        });

    return () => unsubscribe();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [teamFilter, triggerFilter]);


  const loadMoreUsers = () => {
    if (nextPointer.current) {
      const firestore = firebase.firestore();

      let query = firestore.collection('users').where('hasTeam', '==', true)

      if (teamFilter !== '') {
        query = query.where('teamId', '==', teamFilter);
      };
  
      if (nameSearch !== '') {
        query = query.where('name', '==', nameSearch);
      };
  
      if (emailSearch !== '') {
        query = query.where('email', '==', emailSearch.toLowerCase());
      };

      query
      .orderBy('timestamp', 'desc')
        .startAfter(nextPointer.current)
        .limit(pageLimit)
        .get().then((querySnapshot) => {
          nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];

          let oldUsers = [];

          querySnapshot.forEach(async(doc) => {
            oldUsers.push({ id: doc.id, ...doc.data() });
          });
          
          setUsers(u => u.concat(oldUsers));
        })
        .catch((error) => {
          showNotification('Error', "There was an error loading the users. Please, reload the page.", 'error');
          //console.log("Error getting documents: ", error);
        });
    }
  }
  
  const openNewUserModal = () => {
    setModalOpen(true);
  }

  const onChangeDiscount = (discount) => {
    var reg = /^[0-9]+\.?[0-9]{0,3}$/;

    if (discount === '' || reg.test(discount)) {
      setTeamDiscount(discount);
    }
  }

  const createTeam = () => {
    const firestore = firebase.firestore();
    firestore.collection('teams').add({
        name: capitalize(teamName), 
        discount: Number(teamDiscount),
        orderPlacedNotifyToAll: false,
        orderCompletedNotifyToAll: false,
        quotePlacedNotifyToAll: false,
        quoteReadyNotifyToAll: false
    }).then((teamRef)=> { 
        teamRef.get().then(doc => {
            if (doc.exists) {
                createUser({ 
                    teamDiscount: doc.data().discount, 
                    teamId: doc.id});
             } else {
                 // doc.data() will be undefined in this case
                  setCreateUserButton('Create User');
                 console.log("No such document!");
             }
         }).catch((error) => {
              setCreateUserButton('Create User');
             console.log("Error getting document:", error);  
         }); 
     })
  }

  const createUser = (team) => {
    const firestore = firebase.firestore();

    if(userType === "New User"){
    axios({
        method:'post',
        url: "https://us-central1-project-everbary.cloudfunctions.net/createTeamUser",
        data: {
            role: role,
            name: name,
            email: email.toLowerCase(),
            password: password,
            teamId: team.teamId,
            hasTeam: true,
        }
      })
        .then((response) => {
          if (response.data.code === 'auth/email-already-exists') {
            showNotification("Error", "There's already a user with this email. Please, try again.", "error");
            setCreateUserButton('Create User');
          } else if (response.data.code === 'auth/invalid-email') {
            showNotification('Error', "The email address is invalid. Please, try again.", 'error');
            setCreateUserButton('Create User');
          } else if (response.data.code === 'auth/invalid-password') {
            showNotification('Error', "Password is too weak. Please, try again with another one.", 'error');
            setCreateUserButton('Create User');
          } else {
            firestore.collection("users").where("email", "==", email).get().then(querySnapshot => {
              if(!querySnapshot.empty){
                querySnapshot.forEach(async userDoc => {
                  updateTeamUserOrders(team, userDoc)
                })
              }
            }).then(()=>{
              setCreateUserButton('Create User');
              showNotification("User Created", "The user has been created successfully.", "success");     
              setModalOpen(false);
              setCreateUserButton('Create User');
              setEmail('');
              setName('');
              setPassword('');
              setConfirm('');
              setTeamId('');
              setTeamName('');
              setTeamDiscount('');
              setTeamOption('');
            })
            
          }
        })
        .catch((error) => {
          setCreateUserButton('Create User');
          //console.log(error.code);
          showNotification("Error", "An error ocurred creating the user. Please, try again. If it persists, contact technical support.", "error");
        })}
      else{
          firestore.collection("users").where("email", "==", email).get().then(querySnapshot => {
            if(!querySnapshot.empty){
            querySnapshot.forEach(async userDoc => {
              updateTeamUserOrders(team, userDoc);
              
              await firestore.collection("users").doc(userDoc.id).update( {
                teamId: team.teamId,
                hasTeam: true,
                timestamp: new Date().getTime()
              }).then(()=> {
                  setCreateUserButton('Create User');
                  showNotification("User Created", "The user has been added to a team successfully.", "success");     
                  setModalOpen(false);
                  setCreateUserButton('Create User');
                  setEmail('');
                  setName('');
                  setPassword('');
                  setConfirm('');
                  setTeamId('');
                  setTeamName('');
                  setTeamDiscount('');
                  setTeamOption('');
                })
              })
            }else{
              setCreateUserButton('Create User');
              showNotification("Error", "No User Found in our DB, please create new user. If it persists, contact technical support.", "error");
            
            }
          })
          .catch((error) => {
            setCreateUserButton('Create User');
            showNotification("Error", "An error ocurred creating the user. Please, try again. If it persists, contact technical support.", "error");
          })

        }
}

const updateTeamUserOrders = async(team, userDoc) => {
  const firestore = firebase.firestore();

  await firestore.collection("orders").where("email", "==", email).get().then(ordersSnap => {
    if(!ordersSnap.empty){
      ordersSnap.forEach(order => {
        firestore.collection("orders").doc(order.id).update( {
          teamId: team.teamId,
          userID: userDoc.id,
        })
      })
    }
  });
  await firestore.collection("quotes").where("email", "==", email).get().then(quotesSnap => {
    if(!quotesSnap.empty){
      quotesSnap.forEach(quote => {
        firestore.collection("quotes").doc(quote.id).update( {
          teamId: team.teamId,
          userID: userDoc.id
        })
      })
    }
  });
  await firestore.collection("orders").where("teamId", "==",team.teamId).get().then(ordersSnap => {
    if(!ordersSnap.empty){
      ordersSnap.forEach(orderDoc => {
        if(orderDoc.data().ccEmails){
          const ccEmails = orderDoc.data().ccEmails;
          var selectedCCEmails = [];
          const customCCEmails = ccEmails.customCCEmails.includes(email) ? ccEmails.customCCEmails.filter(customEmail => customEmail !== email): ccEmails.customCCEmails;
          const selectedCustomCCEmails = ccEmails.selectedCustomCCEmails.includes(email)? ccEmails.selectedCustomCCEmails.filter(customEmail => customEmail !== email): ccEmails.selectedCustomCCEmails;
          if(ccEmails.selectAllCCEmails || ccEmails.selectAllTeamEmails || ccEmails.selectedCustomCCEmails.includes(email)){
            if(ccEmails.selectedCCEmails.includes(email)){
              selectedCCEmails = ccEmails.selectedCCEmails;
            }else{
              selectedCCEmails = [...ccEmails.selectedCCEmails, email];
            }
          }else{
            selectedCCEmails = ccEmails.selectedCCEmails.filter(customEmail => customEmail !== email);
          }
            firestore.collection("orders").doc(orderDoc.id).update( {
              ccEmails: {
                customCCEmails: customCCEmails,
                selectAllCCEmails: ccEmails.selectAllCCEmails,
                selectAllTeamEmails: ccEmails.selectAllTeamEmails,
                selectedCCEmails: selectedCCEmails,
                selectedCustomCCEmails: selectedCustomCCEmails
              }
            })
        }
      })
    }
  });
}

  const onCreateUser = async() => {
     //eslint-disable-next-line
     var reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
     let emailExists = false;
     
     users.forEach(user => {if(user.email === email){
       emailExists = true; 
       return;}
      })

     if (userType === "New User" && password.length < 8) {
       showNotification('Error', "Password must have more than 8 characters. Please, try again.", 'error');
     } else if (!reg.test(email)) {
       showNotification('Error', "The email address is invalid. Please, try again.", 'error');
     }  else if (emailExists) {
      showNotification('Error', "This email address is already part of a team. Please, try another email or remove it from team.", 'error');
     }  else if (teamNameMatch()) {
      showNotification('Error', "This Team name alerady exists. Please, try another name for this team.", 'error');
    }else {
       setCreateUserButton('Creating User...');

       try{
           if(teamOption === 'Create New'){
            createTeam();
            }else{
              createUser({teamDiscount, teamId})
            }
          
       }
       catch(error){
        setCreateUserButton('Create User');
        // console.log(error)
        showNotification("Error", "An error ocurred creating the user. Please, try again. If it persists, contact technical support.", "error");
       }
  }
}

const teamNameMatch = () => {
  let nameMatched = false;
  if(teamsData.length > 0){
    teamsData.forEach(team => {
      if(team.name === capitalize(teamName)) {
        nameMatched = true;
        return true;
      }
    })
  }
  console.log("2", nameMatched)

  return nameMatched;
}

  const triggerCreateUser = () => {
    if (modalOpen && userType !=="" && !(email === '' || (userType === "New User" && (password === '' || confirm === '' || password !== confirm || name === '')) || teamOption === '' || (teamOption === 'Existing Team' && teamId === "") || (teamOption === 'Create New' && teamName === "" && teamDiscount))) {
      onCreateUser();
    }
  }


  const handleDeleteUser = async (userID, userEmail, teamId) => {
    setUsers(u => u.map(user => user.id === userID ? { ...user, status: 'deleting' } : user));

    const firestore = firebase.firestore();

    // axios({
    //   method:'post',
    //   url: "https://us-central1-project-everbary.cloudfunctions.net/deleteUser",
    //   data: {
    //     userID: userID
    //   }
    // })
    await firestore.collection("orders").where("userID", "==", userID).get().then(ordersSnap => {
      if(!ordersSnap.empty){
        ordersSnap.forEach(order => {
          firestore.collection("orders").doc(order.id).update( {
            teamId: "",
            ccEmails: firebase.firestore.FieldValue.delete(),
          })
        })
      }
    });
    await firestore.collection("quotes").where("userID", "==", userID).get().then(quotesSnap => {
      if(!quotesSnap.empty){
        quotesSnap.forEach(quote => {
          firestore.collection("quotes").doc(quote.id).update( {
            teamId: "",
          })
        })
      }
    })
    await firestore.collection("orders").where("teamId", "==", teamId).get().then(ordersSnap => {
        if(!ordersSnap.empty){
          ordersSnap.forEach(orderDoc => {
            if(orderDoc.data().ccEmails){
              const ccEmails = orderDoc.data().ccEmails;
              console.log(ccEmails.selectedCCEmails)
              const selectedCCEmails = ccEmails.selectedCCEmails && ccEmails.selectedCCEmails.filter(email => email !== userEmail)
              firestore.collection("orders").doc(orderDoc.id).update( {
                ccEmails: {
                  customCCEmails: ccEmails.customCCEmails,
                  selectAllCCEmails: ccEmails.selectAllCCEmails,
                  selectAllTeamEmails: ccEmails.selectAllTeamEmails,
                  selectedCCEmails: selectedCCEmails,
                  selectedCustomCCEmails: ccEmails.selectedCustomCCEmails
                }
              })
            }
          })
        }
      });
    
    await firestore.collection("users").doc(userID).update({
        teamId: "",
        hasTeam: false
      }).then(()=>{
          showNotification("User Removed", "The user has been removed from team successfully.", "success");     
        })
      .catch(error => {
        console.log(error)
        showNotification("Error", "An error ocurred removing the user. Please, try again. If it persists, contact technical support.", "error");
      })
  }

  useEnterKey(() => triggerCreateUser());

  const capitalize = value => {
    if(value.length > 1){
    const array = value.split(" ");
    const capitalizeWordArray = array.map(data => {
      if(data.length > 1){
        return data.slice(0,1).toUpperCase() + data.slice(1,-1) + data.slice(-1)
      }
      return data.toUpperCase();
    });
    return capitalizeWordArray.join(" ");
    }else{
      return value.toUpperCase();
    }
  }

  return (
    <div className="translationsContainer">
    {modalOpen && <animated.div style={modalProps} className="userModalUnderlay" onClick={() => setModalOpen(false)}/>}
    {modalOpen && <animated.div style={modalProps} className="userModal">
      <div className="singleQuoteMainPrimaryFilesTitle" style={{ marginTop: 0, marginBottom: 'calc(2vh)' }}>
        New User
        <div className="singleOrderRevisionButtons">
          <AltAnimatedButton color={'#E1504A'} text={'Close'} onClick={() => setModalOpen(false)} backend fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.9 : 1.2) : (windowSize.orientation === 'portrait' ? 3.7 : 1.1)} />
        </div>
      </div>
      <div className="userModalLine">
        <div className="userModalHalf">
          <select value={userType} onChange={(e) => setUserType(e.target.value)} className="quoteDetailsSelect">
            <option key="" value="" disabled>Select User (*)</option>
           <option key="New User" value="New User">New User</option>
            <option key="Existing User" value="Existing User">Existing User</option>
          </select>
        </div>
      </div>
      <div className="userModalLine">
      {userType === "New User" && <div className="userModalHalf">
          <FormSimpleTextInput placeholder="Name (*)" value={name} onChange={setName} color='#666' />
        </div>
      }
        <div className="userModalHalf">
          <FormSimpleTextInput placeholder="Email (*)" value={email} onChange={setEmail} color='#666' />
        </div>
       
      </div>
      {userType === "New User" && <div className="userModalLine">
        <div className="userModalHalf">
          <FormSimpleTextInput password placeholder="Password (*)" value={password} onChange={setPassword} color='#666' />
        </div>
        <div className="userModalHalf">
          <FormSimpleTextInput password placeholder="Confirm Password (*)" value={confirm} onChange={setConfirm} color='#666' />
        </div>
      </div>}
      <div className="userModalLine">
        <div className="userModalHalf">
          <select value={teamOption} onChange={(e) => setTeamOption(e.target.value)} className="quoteDetailsSelect">
            <option key="" value="" disabled>Select Team (*)</option>
           {teamsData.length > 0 && <option key="Existing Team" value="Existing Team">Existing Team</option>}
            <option key="Create New" value="Create New">Create New</option>
          </select>
        </div>
        {teamOption === 'Existing Team' && <div className="userModalHalf">
          <select value={teamId} onChange={(e) => setTeamId(e.target.value)} 
            className="quoteDetailsSelect">
            <option key="" value="" disabled>Team Name (*)</option>
            {teamsData.length > 0 && teamsData.map(team => 
              <option key={team.id} value={team.id}>{team.name}</option>
            )}
          </select>
        </div>}
      </div>
      {teamOption === 'Create New' && <div className="userModalLine userModalLanguageLine">
        <div className="userModalHalf">
          <FormSimpleTextInput placeholder="Team Name (*)" value={teamName} onChange={setTeamName} color='#666' />
        </div>
        <div className="userModalHalf">
          <FormSimpleTextInput placeholder="Discount (*)" value={teamDiscount} onChange={onChangeDiscount} color='#666' />
        </div>
      </div>}
      
      <div className="userModalButtonContainer">
        <AltAnimatedButton text={createUserButton} backend disabled={createUserButton==="Creating User..." || userType ==='' || email === '' || (userType === 'New User' && (password === '' || confirm === '' || password !== confirm || name === '')) || teamOption === '' || (teamOption === 'Existing Team' && teamId === "") || (teamOption === 'Create New' && teamName === "" && teamDiscount)} onClick={() => onCreateUser()} fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.7 : 1.1) : (windowSize.orientation === 'portrait' ? 3.5 : 1)} />
      </div>
    </animated.div>}

    <div className="translationsInnerContainer">
      <div className="translationsTitleRow">
        <p className="translationsTitle">Teams</p>
        <div className="usersFunctionsContainer">
          <div className="userLanguageFilterContainer">
            <input type="text" value={nameSearch} ref={nameSearchRef} onChange={(e) => setNameSearch(e.target.value)} className="userNameFilter" placeholder="Search by name" />
          </div>
          <div className="userLanguageFilterContainer">
            <input type="text" value={emailSearch} ref={emailSearchRef} onChange={(e) => setEmailSearch(e.target.value)} className="userNameFilter" placeholder="Search by email" />
          </div>
          <div className="userLanguageFilterContainer" style={{ display: windowSize.width > 1024 || windowSize.orientation === 'landscape' ? 'flex' : 'none' }}>
            <select value={teamFilter} onChange={(e) => setTeamFilter(e.target.value)} className="quoteDetailsSelect">
              <option key="" value="">No team selected</option>
              {teamsData.map(team => 
                <option key={team.id} value={team.id}>{team.name}</option>
              )}
            </select>
          </div>
          <AltAnimatedButton text={"Add User / Team"} onClick={() => openNewUserModal()} backend fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.7 : 1.1) : (windowSize.orientation === 'portrait' ? 3.5 : 1)} />
        </div>
      </div>
      <div className="translationsTableHeader">
        <div className="usersHeaderColumn usersNameColumn">Team</div>
        <div className="usersHeaderColumn usersNameColumn">Name</div>
        <div className="usersHeaderColumn usersIDColumn">Email</div>
        <div className="usersHeaderColumn usersRoleColumn">Discount</div>
        <div className="usersHeaderColumn usersDeleteColumn"></div>
        {/* <div className="usersHeaderColumn usersExtraColumn"></div> */}
      </div>
      { loadedUsers && users.length > 0 
      ? users.map(user => 
        <div key={user.id} className="translationsTableLine">
          <div className="usersLineColumn usersNameColumn">{teamsData && teamsData.find(team => team.id === user.teamId) && teamsData.find(team => team.id === user.teamId).name}</div>
          <div className="usersLineColumn usersNameColumn">{user.name}</div>
          <div className="usersLineColumn usersIDColumn" style={{ cursor: 'pointer', color: '#609ddb' }} onClick={(e) => transitionTo('/office/user/' + user.id, e)}>{user.email}</div>
          <div className="usersLineColumn usersRoleColumn">{teamsData && teamsData.find(team => team.id === user.teamId) && teamsData.find(team => team.id === user.teamId).discount}</div>
         
          <div className="usersLineColumn usersDeleteColumn">
            {user.status !== 'deleting' ? <Trash2 className="usersDeleteButton" onClick={() => handleDeleteUser(user.id, user.email, user.teamId)} /> : <p className="userStatusText">Deleting...</p>}
          </div>
          
        </div>)
      : loadedUsers ? <div className="loadingTranslations">No users.</div> : <div className="loadingTranslations">Loading users...</div>
      }
      { loadedUsers && users.length !== 0 && users.length >= 50 && nextPointer.current &&
        <div className="loadMoreUsersButton" onClick={() => loadMoreUsers()}>
          Load more users...
        </div>
      }
    </div>
  </div>
  )
}

export default Teams