import React from 'react';
import { /*ChevronRight,*/ Check } from 'react-feather';

// import { useScreenTransition } from '../Hooks/useScreenTransition';

import './DocumentsDisplay.css';

const DocumentsDisplay = () => {

  // const { transitionTo } = useScreenTransition();

  return (
    <div className="documentsDisplayContainer">
      <div className="documentsDisplayInfo">
        <h2 className="documentsDisplayInfoTitle">Popular Documents</h2>
        <h3 className="documentsDisplayInfoText">Our translators bring a wide range of industry and document specific experience. We'll pair you with the perfect translator for your project to ensure success.</h3>
        {/* <div className="documentsDisplayInfoButtonContainer" onClick={(e) => transitionTo('/documents', e)}>
          <p className="documentsDisplayInfoButtonText">See all documents</p>
          <ChevronRight color='#fff' size={20} />
        </div> */}
      </div>
      <div className="documentsDisplayListContainer">
        {/* <h3 className="documentsDisplayListTitle">Popular Documents</h3> */}
        <div className="documentsDisplayListInner">
          <div className="documentsDisplayListColumn">
            <div className="documentsDisplayListItem" /*onClick={(e) => transitionTo('/document/birth-certificate', e)}*/>
              <Check color='#18b7bb' size={20} />
              <p className="documentsDisplayListItemText">Birth Certificate</p>
            </div>
            <div className="documentsDisplayListItem" /*onClick={(e) => transitionTo('/document/academic-transcripts',e)}*/>
              <Check color='#18b7bb' size={20} />
              <p className="documentsDisplayListItemText">Transcripts</p>
            </div>
            <div className="documentsDisplayListItem" /*onClick={(e) => transitionTo('/document/vaccination-record',e)}*/>
              <Check color='#18b7bb' size={20} />
              <p className="documentsDisplayListItemText">Vaccination Records</p>
            </div>
            <div className="documentsDisplayListItem" /*onClick={(e) => transitionTo('/document/drivers-license',e)}*/>
              <Check color='#18b7bb' size={20} />
              <p className="documentsDisplayListItemText">Driver's License</p>
            </div>
            <div className="documentsDisplayListItem" /*onClick={(e) => transitionTo('/document/diploma',e)}*/>
              <Check color='#18b7bb' size={20} />
              <p className="documentsDisplayListItemText">Diploma</p>
            </div>
          </div>
          <div className="documentsDisplayListColumn documentsDisplaySecondColumn">
            <div className="documentsDisplayListItem" /*onClick={(e) => transitionTo('/document/medical-records',e)}*/>
              <Check color='#18b7bb' size={20} />
              <p className="documentsDisplayListItemText">Medical Records</p>
            </div>
            <div className="documentsDisplayListItem" /*onClick={(e) => transitionTo('/document/bank-statement',e)}*/>
              <Check color='#18b7bb' size={20} />
              <p className="documentsDisplayListItemText">Bank Statement</p>
            </div>
            <div className="documentsDisplayListItem" /*onClick={(e) => transitionTo('/document/marriage-certificate',e)}*/>
              <Check color='#18b7bb' size={20} />
              <p className="documentsDisplayListItemText">Marriage Certificate</p>
            </div>
            <div className="documentsDisplayListItem" /*onClick={(e) => transitionTo('/document/apostille',e)}*/>
              <Check color='#18b7bb' size={20} />
              <p className="documentsDisplayListItemText">Apostille</p>
            </div>
            <div className="documentsDisplayListItem" /*onClick={(e) => transitionTo('/document/death-certificate',e)}*/>
              <Check color='#18b7bb' size={20} />
              <p className="documentsDisplayListItemText">Death Certificate</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { DocumentsDisplay };