import React, { useState, useContext, useEffect, useRef } from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { languages } from '../../Data/languages';

import { NotificationContext } from '../../Context/NotificationContext';
import { DataContext } from '../../Context/DataContext';

import './Invoices.css';
import { useScreenTransition } from '../../Hooks/useScreenTransition';


const pageLimit = 50;

const OwnInvoices = ({ windowSize }) => {

  const { transitionTo } = useScreenTransition()


  const { userData } = useContext(DataContext);

  const { showNotification } = useContext(NotificationContext);

  const [orders, setOrders] = useState([]);
  const [loadedOrders, setLoadedOrders] = useState(false);

  const orderSearchRef = useRef();
  const [orderSearch, setOrderSearch] = useState('');

  const invoiceSearchRef = useRef();
  const [invoiceSearch, setInvoiceSearch] = useState('');

  const [triggerFilter, setTriggerFilter] = useState(true);

  let firstLoad = useRef();
  if (!firstLoad.current) firstLoad.current = true;

  let nextPointer = useRef();
  if (!nextPointer.current) nextPointer.current = null;

  useEffect(() => {
    const applyOrder = e => {
      if (e.keyCode === 13) {
        setTriggerFilter(tf => !tf);
      }
    }

    const orderSearchRefVar = orderSearchRef.current;
    const invoiceSearchRefVar = invoiceSearchRef.current;

    orderSearchRef.current.addEventListener("keyup", applyOrder);
    invoiceSearchRef.current.addEventListener("keyup", applyOrder);

    return () => {
      orderSearchRefVar.removeEventListener("keyup", applyOrder);
      invoiceSearchRefVar.removeEventListener("keyup", applyOrder);
    }
  }, []);

  useEffect(() => {
    if (userData) {

      firstLoad.current = true;

      const firestore = firebase.firestore();

      let query = firestore.collection('orders');

      if (orderSearch !== '') {
        query = query.where('orderNumber', '==', parseInt(orderSearch,10));
      };

      if (invoiceSearch !== '') {
        query = query.where('invoice.invoiceNumber', '==', parseInt(invoiceSearch,10));
      };

      const unsubscribe = 
        query
          .where('translator.id', '==', userData.id)
          .where('status', 'in', ['in-progress', 'support-review', 'revision', 'user-review', 'cancelled', 'completed'])
          .orderBy('timestamp', 'desc')
          .limit(pageLimit)
          .onSnapshot(querySnapshot => {
            if (firstLoad.current) {
              nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];
              firstLoad.current = false;
              
              setOrders(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
              setLoadedOrders(true);
            } else {
              let newOrders = [];

              querySnapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                  newOrders.push({ id: change.doc.id, ...change.doc.data() });
                }
              });
              
              setOrders(u => newOrders.concat(u));
              setLoadedOrders(true);
            }
          }, error => {
            //console.log(error);
            showNotification('Error', "There was an error loading the orders. Please, reload the page.", 'error');
            setLoadedOrders(true);
          });

      return () => unsubscribe();

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerFilter]);

  const loadMoreOrders = () => {
    if (nextPointer.current && userData) {
      const firestore = firebase.firestore();

      let query = firestore.collection('orders');

      if (orderSearch !== '') {
        query = query.where('orderNumber', '==', parseInt(orderSearch,10));
      };
  
      if (invoiceSearch !== '') {
        query = query.where('invoice.invoiceNumber', '==', parseInt(invoiceSearch,10));
      };

      query
        .where('translator.id', '==', userData.id)
        .where('status', 'in', ['in-progress', 'support-review', 'revision', 'user-review', 'cancelled', 'completed'])
        .orderBy('timestamp', 'desc')
        .startAfter(nextPointer.current)
        .limit(pageLimit)
        .get().then((querySnapshot) => {
          nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];

          let oldOrders = [];

          querySnapshot.forEach((doc) => {
            oldOrders.push({ id: doc.id, ...doc.data() });
          });
          
          setOrders(u => u.concat(oldOrders));
        })
        .catch((error) => {
          showNotification('Error', "There was an error loading the orders. Please, reload the page.", 'error');
          //console.log("Error getting documents: ", error);
        });
    }
  }

  const formatDate = timestamp => {
    const date = new Date(timestamp);

    const splitted = date.toDateString().split(' ');

    return splitted[1] + ' ' + splitted[2] + ', ' + splitted[3];
  }

  const formatWordCount = order => {
    if (!order.invoice || order.invoice.lines.length === 0) {
      return '';
    }

    return order.invoice.lines.reduce((acc, cur) => acc + parseInt(cur.wordCount, 10), 0);
  }

  const formatInvoiceTotal = order => {
    if (!order.invoice || order.invoice.lines.length === 0) {
      return '';
    }

    return "$" + (order.invoice.lines.reduce((acc, cur) => acc + parseInt(cur.wordCount, 10), 0) * order.translator.orderRate).toFixed(2);
  }

  return (
    <div className="translationsContainer">
      <div className="translationsInnerContainer">
        <div className="translationsTitleRow">
          <p className="translationsTitle">Invoices</p>
          <div className="usersFunctionsContainer">
            <div className="userLanguageFilterContainer">
              <input type="text" value={invoiceSearch} ref={invoiceSearchRef} onChange={(e) => setInvoiceSearch(e.target.value)} className="userNameFilter" placeholder="Search by invoice number" />
            </div>
            <div className="userLanguageFilterContainer">
              <input type="text" value={orderSearch} ref={orderSearchRef} onChange={(e) => setOrderSearch(e.target.value)} className="userNameFilter" placeholder="Search by order number" />
            </div>
          </div>
        </div>
        <div className="translationsTableHeader">
          <div className="usersHeaderColumn invoicesInvoiceColumn">Invoice</div>
          <div className="usersHeaderColumn invoicesOrderColumn">Order</div>
          <div className="usersHeaderColumn invoicesPaymentColumn">Payment</div>
          <div className="usersHeaderColumn invoicesFromColumn">From</div>
          <div className="usersHeaderColumn invoicesToColumn">To</div>
          <div className="usersHeaderColumn invoicesWordsColumn">Words</div>
          <div className="usersHeaderColumn invoicesRateColumn">Rate</div>
          <div className="usersHeaderColumn invoicesSubmittedColumn">Submitted</div>
          <div className="usersHeaderColumn invoicesStatusColumn">Status</div>
          <div className="usersHeaderColumn invoicesTotalColumn">Total</div>
        </div>
        { loadedOrders && orders.length > 0
        ? orders.map(order => 
          <div key={order.id} className="translationsTableLine">
            <div className="usersLineColumn invoicesInvoiceColumn" style={{ cursor: 'pointer', color: '#609ddb' }} onClick={(e) => transitionTo('/office/translation/' + order.id + "/invoice",e)}>{order.status !== 'new' && order.status !== 'assigned' && order.invoice && order.invoice.invoiceNumber}</div>
            <div className="usersLineColumn invoicesOrderColumn" style={{ cursor: 'pointer', color: '#609ddb' }} onClick={(e) => transitionTo('/office/translation/' + order.id + "/details",e)}>{order.orderNumber}</div>
            <div className="usersLineColumn invoicesPaymentColumn" style={{ cursor: 'pointer', color: '#609ddb' }} onClick={(e) =>order.invoice && order.invoice.paymentID ? transitionTo('/office/payment/' + order.invoice.paymentID,e) : null}>{order.invoice && order.invoice.paymentNumber}</div>
            <div className="usersLineColumn invoicesFromColumn">
              <p>{languages.find(language => language.value === order.languageFrom) ? languages.find(language => language.value === order.languageFrom).label : null}</p>
            </div>
            <div className="usersLineColumn invoicesToColumn">
              <p>{languages.find(language => language.value === order.languageTo) ? languages.find(language => language.value === order.languageTo).label : null}</p>
            </div>
            <div className="usersLineColumn invoicesWordsColumn">{formatWordCount(order)}</div>
            <div className="usersLineColumn invoicesRateColumn">{order.translator ? "$" + order.translator.orderRate.toFixed(2) : ''}</div>
            <div className="usersLineColumn invoicesSubmittedColumn">{order.invoice && order.invoice.submitted ? formatDate(order.invoice.submitted) : ''}</div>
            <div className="usersLineColumn invoicesStatusColumn">
              <div className="translationsStatusContainer" style={{ backgroundColor: order.invoice?.status === 'created' ? '#FF7C1D' : order.invoice?.status === 'submitted' ? '#AE5FD2' : order.invoice?.status === 'approved' ? '#1491F8' : order.invoice?.status === 'review' ? '#e91e63' : order.invoice?.status === 'paid' ? '#17BC5B' : 'transparent' }}>
                { order.invoice?.status === 'created' ? "CREATED" : order.invoice?.status === 'submitted' ? 'SUBMITTED' : order.invoice?.status === 'approved' ? 'APPROVED' : order.invoice?.status === 'review' ? 'REVIEW' : order.invoice?.status === 'paid' ? 'PAID' : ''}
              </div>
            </div>
            <div className="usersLineColumn invoicesTotalColumn">{formatInvoiceTotal(order)}</div>
          </div>)
        : loadedOrders ? <div className="loadingTranslations">No invoices.</div> : <div className="loadingTranslations">Loading invoices...</div>
        }
        { loadedOrders && orders.length >= 50 && nextPointer.current &&
          <div className="loadMoreUsersButton" onClick={() => loadMoreOrders()}>
            Load more invoices...
          </div>
        }
      </div>
    </div>
  );
};

export default OwnInvoices;