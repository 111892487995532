import React, { useState, useContext, useEffect, useRef } from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { Plus } from 'react-feather';

import { languages } from '../../Data/languages';

import { NotificationContext } from '../../Context/NotificationContext';
import { DataContext } from '../../Context/DataContext';

import './Quotes.css';
import { useScreenTransition } from '../../Hooks/useScreenTransition';

const pageLimit = 50;

const Quotes = ({ windowSize }) => {

  const { transitionTo } = useScreenTransition();

  const { showNotification } = useContext(NotificationContext);

  const { userData } = useContext(DataContext);

  const [quotes, setQuotes] = useState([]);
  const [loadedQuotes, setLoadedQuotes] = useState(false);

  const quoteSearchRef = useRef();
  const [quoteSearch, setQuoteSearch] = useState('');

  const [statusFilter, setStatusFilter] = useState('');

  const [triggerFilter, setTriggerFilter] = useState(true);

  let firstLoad = useRef();
  if (!firstLoad.current) firstLoad.current = true;

  let nextPointer = useRef();
  if (!nextPointer.current) nextPointer.current = null;

  useEffect(() => {
    const applyOrder = e => {
      if (e.keyCode === 13) {
        setTriggerFilter(tf => !tf);
      }
    }

    const quoteSearchRefVar = quoteSearchRef.current;

    quoteSearchRef.current.addEventListener("keyup", applyOrder);

    return () => {
      quoteSearchRefVar.removeEventListener("keyup", applyOrder);
    }
  }, []);

  useEffect(() => {
    firstLoad.current = true;

    const firestore = firebase.firestore();

    let query = firestore.collection('quotes');

    if (quoteSearch !== '') {
      query = query.where('quoteNumber', '==', parseInt(quoteSearch,10));
    };

    if (statusFilter !== '') {
      query = query.where('status', '==', statusFilter);
    };

    const unsubscribe = 
      query
        .orderBy('timestamp', 'desc')
        .limit(pageLimit)
        .onSnapshot(querySnapshot => {
          if (firstLoad.current) {
            nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];
            firstLoad.current = false;
            
            setQuotes(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
            setLoadedQuotes(true);
          } else {
            let newQuotes = [];

            querySnapshot.docChanges().forEach((change) => {
              if (change.type === "added") {
                newQuotes.push({ id: change.doc.id, ...change.doc.data() });
              }
            });
            
            setQuotes(u => newQuotes.concat(u));
            setLoadedQuotes(true);
          }
        }, error => {
          //console.log(error);
          showNotification('Error', "There was an error loading the quotes. Please, reload the page.", 'error');
          setLoadedQuotes(true);
        });

    return () => unsubscribe();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerFilter, statusFilter]);

  const loadMoreQuotes = () => {
    if (nextPointer.current) {
      const firestore = firebase.firestore();

      let query = firestore.collection('quotes');

      if (quoteSearch !== '') {
        query = query.where('quoteNumber', '==', quoteSearch);
      };

      if (statusFilter !== '') {
        query = query.where('status', '==', statusFilter);
      };

      query
        .orderBy('timestamp', 'desc')
        .startAfter(nextPointer.current)
        .limit(pageLimit)
        .get().then((querySnapshot) => {
          nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];

          let oldQuotes = [];

          querySnapshot.forEach((doc) => {
            oldQuotes.push({ id: doc.id, ...doc.data() });
          });
          
          setQuotes(u => u.concat(oldQuotes));
        })
        .catch((error) => {
          showNotification('Error', "There was an error loading the quotes. Please, reload the page.", 'error');
          //console.log("Error getting documents: ", error);
        });
    }
  }

  const formatDate = timestamp => {
    if (!timestamp) {
      return '';
    }

    const date = new Date(timestamp);

    const splitted = date.toDateString().split(' ');

    return splitted[1] + ' ' + splitted[2] + ', ' + splitted[3] + ' @ ' + date.toTimeString().substr(0, 5);
  }

  return (
    <div className="translationsContainer">
      <div className="translationsInnerContainer">
        <div className="translationsTitleRow">
          <div className="translationsTitle">
            Quotes
            {userData && (userData.role === 'owner' || userData.role === 'Support') && <div className="newOrderButton" onClick={(e) => transitionTo('/office/new-quote',e)}>
              <Plus className="newOrderIcon" />
            </div>}
          </div>
          <div className="usersFunctionsContainer">
            <div className="userLanguageFilterContainer">
              <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} className="quoteDetailsSelect">
                <option key="" value="">All</option>
                <option key="submitted" value="submitted">New</option>
                <option key="replied" value="replied">Replied</option>
                <option key="ordered" value="ordered">Ordered</option>
              </select>
            </div>
            <div className="userLanguageFilterContainer">
              <input type="text" value={quoteSearch} ref={quoteSearchRef} onChange={(e) => setQuoteSearch(e.target.value)} className="userNameFilter" placeholder="Search by quote number" />
            </div>
          </div>
        </div>
        <div className="translationsTableHeader">
          <div className="usersHeaderColumn quotesNumberColumn">Quote Number</div>
          <div className="usersHeaderColumn quotesLanguageColumn">From</div>
          <div className="usersHeaderColumn quotesLanguageColumn">To</div>
          <div className="usersHeaderColumn quotesSubmittedColumn">Submitted</div>
          <div className="usersHeaderColumn quotesTypeColumn">Type</div>
          <div className="usersHeaderColumn quotesStatusColumn">Status</div>
        </div>
        { loadedQuotes && quotes.length > 0
        ? quotes.map(quote => 
          <div key={quote.id} className="translationsTableLine">
            <div className="usersLineColumn quotesNumberColumn" style={{ cursor: 'pointer', color: '#609ddb' }} onClick={(e) => transitionTo('/office/quote/' + quote.id,e)}>{quote.quoteNumber}</div>
            <div className="usersLineColumn quotesLanguageColumn">{languages.find(language => language.value === quote.languageFrom) ? languages.find(language => language.value === quote.languageFrom).label : null}</div>
            <div className="usersLineColumn quotesLanguageColumn">{languages.find(language => language.value === quote.languageTo) ? languages.find(language => language.value === quote.languageTo).label : null}</div>
            <div className="usersLineColumn quotesSubmittedColumn">{quote.timestamp ? formatDate(quote.timestamp) : ''}</div>
            
            <div className="usersLineColumn quotesTypeColumn">{quote.certified ? 'Certified' : 'Standard'}</div>
            <div className="usersLineColumn quotesStatusColumn">
              <div className="translationsStatusContainer" style={{ backgroundColor: quote.status === 'submitted' ? "#FF7C1D" : quote.status === 'replied' ? '#30d5c8' : '#17BC5B' }}>
                {quote.status === 'submitted' ? 'IN REVIEW' : quote.status === 'replied' ? 'READY' : 'ORDERED'}
              </div>
            </div>
          </div>)
        : loadedQuotes ? <div className="loadingTranslations">No quotes.</div> : <div className="loadingTranslations">Loading quotes...</div>
        }
        { loadedQuotes && quotes.length >= 50 && nextPointer.current &&
          <div className="loadMoreUsersButton" onClick={() => loadMoreQuotes()}>
            Load more quotes...
          </div>
        }
      </div>
    </div>
  );
};

export default Quotes;