import React from 'react';
import { Switch, Route, useRouteMatch} from "react-router-dom";

import SingleUseCase from '../Screens/SingleUseCase';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import { getSingleUseCase } from '../Data/getSingleUseCase';

const useCases = ["immigration", "government", "academic", "financial", "legal", "medical", "legalStandard", "digital", "communications", "documents"];

const UseCasesRouter = ({ windowSize }) => {

  const { transitionTo } = useScreenTransition();

  let { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        { useCases.map((use, index) => {

          const useCaseResult = getSingleUseCase(use, transitionTo);

          return (
            <Route key={index} path={`${path}/${use}`}>
              <SingleUseCase windowSize={windowSize} useCase={useCaseResult} />
            </Route>
          );
        })}
      </Switch>
    </div>
  );
}

export default UseCasesRouter;