import React, { useContext, useEffect, useRef, useState } from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { CheckCircle } from 'react-feather';

import { Navbar } from '../Components/Navbar';
import { Footer } from '../Components/Footer';

import { TransitionContext } from '../Context/TransitionContext';
import { NotificationContext } from '../Context/NotificationContext';

import './Reviews.css';
import TopBar from '../Components/TopBar';
import SignInForm from './SignIn/SignInForm';
import CreateAccount from './CreateAccount/CreateAccount';

const pageLimit = 25;

const months = {
  'Jan': 'January',
  'Feb': 'February',
  'Mar': 'March',
  'Apr': 'April',
  'May': 'May',
  'Jun': 'June',
  'Jul': 'July',
  'Aug': 'August',
  'Sep': 'September',
  'Oct': 'October',
  'Nov': 'November',
  'Dec': 'December'
};

const Reviews = ({ windowSize }) => {

  const { setColor, webP } = useContext(TransitionContext);

  const { showNotification } = useContext(NotificationContext);

  useEffect(() => {
    setColor(true);
  }, [setColor]);

  const [reviews, setReviews] = useState([]);
  const [loadedReviews, setLoadedReviews] = useState(false);
  const [isModalOpen, setIsModalOpen]=useState(false);
  const [isCreateAccountOpen, setIsCreateAccountOpen]=useState(false);


  const handleLoginOpen=(newState)=>{
    setIsModalOpen(newState);
  }

  const handleCreateAccountOpen=(newState)=>{
    setIsCreateAccountOpen(newState);
  }

  let nextPointer = useRef();
  if (!nextPointer.current) nextPointer.current = null;

  useEffect(() => {
    
    const firestore = firebase.firestore();

    const unsubscribe = 
      firestore.collection('reviews')
        .orderBy('date', 'desc')
        .limit(pageLimit)
        .onSnapshot(querySnapshot => {
          nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];
          
          setReviews(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
          setLoadedReviews(true);
        }, error => {
          //console.log(error);
          showNotification('Error', "There was an error loading the reviews. Please, reload the page.", 'error');
          setLoadedReviews(true);
        });

    return () => unsubscribe();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMoreReviews = () => {
    if (nextPointer.current) {
      const firestore = firebase.firestore();

      firestore.collection('reviews')
        .orderBy('date', 'desc')
        .startAfter(nextPointer.current)
        .limit(pageLimit)
        .get().then((querySnapshot) => {
          nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];

          let oldReviews = [];

          querySnapshot.forEach((doc) => {
            oldReviews.push({ id: doc.id, ...doc.data() });
          });
          
          setReviews(u => u.concat(oldReviews));
        })
        .catch((error) => {
          showNotification('Error', "There was an error loading the reviews. Please, reload the page.", 'error');
          //console.log("Error getting documents: ", error);
        });
    }
  }

  const formatDate = timestamp => {
    if (!timestamp) return '';

    const date = new Date(timestamp);

    const splitted = date.toDateString().split(' ');

    return months[splitted[1]] + ' ' + splitted[2] + ', ' + splitted[3];
  }

  const sortName = (name) => {
    const nameArray = name.split(/[ -]/g);
    console.log(nameArray)
    if(nameArray.length > 3){
      if(nameArray[0] && nameArray[0].length < 2){
        return nameArray[0] + " " + nameArray[1] + " " + nameArray[2].charAt(0)  + ".";
      }
    }
    if(nameArray.length > 1) {
      if(nameArray[0] && nameArray[0].length > 2){
        return nameArray[0] + " " + nameArray[1].charAt(0) + ".";
      }
      return nameArray[0] + " " + nameArray[1] + " " + nameArray[2].charAt(0)  + ".";
    }

    return nameArray[0];
  }

  return (
    <>
     <SignInForm open={isModalOpen} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/>
     <CreateAccount open={isCreateAccountOpen} isCreateAccountOpen={handleCreateAccountOpen} isLoginFormOpen={handleLoginOpen}/>

    <TopBar/>
    <div className="homeContainer">
    <Navbar windowSize={windowSize} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/>
      <div className="headerContainer">
        <div className="headerInfoContainer">
          <h1 className="heading headingReviews">Verified Customer Reviews</h1>
          <h2 className="subheading subheadingReviews">We are extremely commited to quick turnaround times and quality. Read through some of our customer testimonials to gain a better understanding of how we work.</h2>
          <div className="extraInfoContainer">
            <div className="extraInfoTextContainer" style={{ cursor: 'default' }}>
              <CheckCircle className="extraInfoButton infoButtonReviews" />
              <p className="extraInfoText">All reviews from verified customers.</p>
            </div>
          </div>
        </div>
        { webP ? <img src={require(`../assets/${webP === 'webp' ? 'reviews.webp' : 'reviews.png'}`)} alt="People assigning rating stars" className="documentsHeaderImg" /> : null}
      </div>
      <div className="reviewsContainer">
        <h3 className="reviewsTitle">CUSTOMER REVIEWS</h3>
        { loadedReviews && reviews.length > 0
        ? reviews.map(review =>
          <div key={review.id} className="reviewLine">
            <div className="reviewScoreContainer">{parseFloat(review.score).toFixed(1)}</div>
            <div className="reviewInnerContainer">
              <div className="reviewName">{sortName(review.name)}</div>
              <div className="reviewLanguages">{`${review.languageFrom} to ${review.languageTo}`}</div>
              <div className="reviewText">{review.text}</div>
              <div className="reviewDate">Posted <strong>{formatDate(review.date)}</strong></div>
            </div>
          </div>)
        : loadedReviews ? <div className="loadingReviews">No reviews.</div> : <div className="loadingReviews">Loading reviews...</div>
        }
        { loadedReviews && reviews.length >= pageLimit && nextPointer.current &&
          <div className="loadMoreReviewsButton" onClick={() => loadMoreReviews()}>
            Load more reviews...
          </div>
        }
      </div>
      <Footer windowSize={windowSize} />
    </div>
    </>
  );
}

export default Reviews;