import React from 'react';

import './Guidelines.css';

const Guidelines = ({ windowSize }) => {

  return (
    <div className="singleTranslationContainer">
      <div className="singleTranslationDetailsOuter">
        <div className="singleTranslationDetailsMain guidelinesDetailsMain">
          <div className="singleTranslationDetailsDates">
            <div className="faqHeader guidelinesHeader" style={{ alignItems: 'flex-start' }}>
              <div className="heading guidelinesHeading">Translation Guidelines</div>
              <div className="faqSubheading guidelinesSubheading">Please follow these guidelines to ensure you maintain the quality and consistency eVerbary requires. If at any time you have questions about processes, procedures or guidelines, please contact us at <span className="hoveringTextLink" onClick={() => window.open('mailto:support@everbary.com', '_blank')}>support@everbary.com</span>.</div>
            </div>
            <div className="termsContainer">
              <div className="termsHeading guidelinesAuxHeading">Direct Assignment Jobs</div>
              <p className="termsText guidelinesText">
                Please be aware that all jobs are assigned via our platform and you will need to login to the translator portal to retrieve the source file(s). For each assignment, a notification will be sent to the email address on file in your profile. Please do your best to accept/decline assignments as soon as possible. This will allow you to manage multiple assignments with greater ease as you can view the source material and properly allocate your time.
                <br/><br/>If you will not be available for several days, please change your account status to Paused in your profile settings. If possible, provide a descriptive reason so we know how long you might be unavailable for new assignments.
              </p>
              <div className="termsHeading guidelinesAuxHeading">Translation Types</div>
              <p className="termsText guidelinesText">
                There are two types of translation services offered on our site: certified and standard.
                <br/><br/>
                - Certified Translations: are literal, word-for-word, line-by-line translation of the source material. Translations must be uploaded in both DOCX and PDF format (the PDF should be created by exporting or saving directly from MS Word).
                <br/><br/>
                - Standard Translations: should read more naturally in the target language and the original intent and meaning should convey fully in the translation. Translations only need be uploaded in DOCX format (or XLSX or PPTX format to match source material).
              </p>
              <div className="termsHeading guidelinesAuxHeading">Requirements for Certified Translations</div>
              <div className="termsText guidelinesText">
                <span className="guidelinesTextStrong">Coversheet</span>
                <br/>
                Certified translations are delivered on company letterhead. This is done by generating a coversheet for each new order. When generating a coversheet you’ll be asked to input a description of the document, which will then be used in the file name and in the coversheet itself. It is critical to name the document appropriately and in title case (first letter of each word is uppercase). Common examples include:
                <br/><br/>
                - Bank Statement
                <br/>- Birth Certificate
                <br/>- Marriage Certificate
                <br/>- Driver’s License
                <br/><br/>
                Follow the following steps with each new job for certified translations:
                <br/><br/>1. View the source file to determine the document type (ex: birth certificate)
                <br/>2. Enter a general descriptor in text box under "Document Name", then click "Generate Coversheet". The coversheet will be created for the order and it will display in the list of files.
                <br/>3. Click on the coversheet file to download to your local machine.
                <br/>4. Open the coversheet document and begin the translation on page two.
                <br/><br/>
                Please note you must generate a new coversheet for each document type that the order may have. Rarely, an order may contain two Birth Certificates, and you will not be able to create two coversheets with the same name; instead, use “[Name] Birth Certificate” to generate them.
                <br/><br/>
                <span className="guidelinesTextStrong">Document Formatting</span>
                <br/>
                The generated coversheet document (required on all certified translations) will have proper paper size and margins set automatically. Do not modify these values. Paper size must be 8.5"x11" (aka "Letter"). Margins must be 1.2" on top and 1" on the left, right and bottom. These margins must be maintained throughout the document.
                <br/><br/>It is critical that our company letterhead and footer content – which is automatically inserted in the generated coversheet – not be modified in any way. Files submitted with altered margins or modifications to the coversheet, letterhead or footer will be returned. The best way to ensure proper formatting is retained is to always work within the coversheet that is generated for each new translation job.
              </div>
              <div className="termsHeading guidelinesAuxHeading">Requirements for All Translations</div>
              <div className="termsText guidelinesText">
                <span className="guidelinesTextStrong">Layouts</span>
                <br/>Replicate the basic layout of the original document, but do not try to replicate the design. Use simple tables and text formatting only. Do not embed images or use complex formatting that makes the document difficult to edit.
                <br/><br/>Match spacing between elements as closely as possible. If the original document is on one page then the translation should be one page, if at all possible.
                <br/><br/><span className="guidelinesTextStrong">Orientation</span>
                <br/>Portrait is the preferred orientation, but some documents will require a shift to landscape. Only shift pages to landscape that truly require the additional space. Never change the orientation of the coversheet in a certified translation.
                <br/><br/><span className="guidelinesTextStrong">Fonts</span>
                <br/>Do not attempt to match font families to the original document. Use the pre-selected base font from the generated coversheet document; Arial 12pt. Font weight, color and sizing can be adjusted to replicate the original document, when appropriate.
                <br/><br/><span className="guidelinesTextStrong">Non-legible Content</span>
                <br/>If a portion of a document is non-legible you should mark it as such. We will work with the client to obtain a more legible copy or get additional information to help fill in text that was marked non-legible. Use the following format:
                <br/><br/>- [NON-LEGIBLE]
                <br/><br/><span className="guidelinesTextStrong">Stamps, Seals and Signatures</span>
                <br/>All stamps and seals should be noted and translated. Signatures should only be noted. Do not include an image of the signature and only include the name if it is typed out above or below the signature. Use the following format:
                <br/><br/>- [STAMP: Translated contents of stamp]
                <br/>- [SEAL: Translated contents of seal]
                <br/>- [SIGNATURE]
                <br/><br/><span className="guidelinesTextStrong">Dates and Currencies</span>
                <br/>Be sure to use the appropriate formatting for dates and currencies based on the target language. Currencies should retain the denomination of the source material. The overwhelming majority of our translations are into English for use in the United States so the formatting would be:
                <br/>- Date: MM/DD/YYYY or Month DD, YYYY
                <br/>- Currency: $12,434.90
                <br/><br/><span className="guidelinesTextStrong">Special Characters</span>
                <br/>Documents will often contain special characters as content dividers such as ----- or *****. Do not replicate this as Word will count each special character and throw off the word count for the page. Instead use a graphic horizontal line, which is built into Word.       
              </div>
              <div className="termsHeading guidelinesAuxHeading">Proofreading</div>
              <div className="termsText guidelinesText">
                All rates posted for jobs are inclusive of proofreading. Accuracy is critical as our clients use these translations to apply for citizenship, university, etc. Any mistake could be detrimental. With this in mind, please carefully proofread all documents before submitting. Pay extra attention to dates, names, and places.
              </div>
              <div className="termsHeading guidelinesAuxHeading">Revisions</div>
              <div className="termsText guidelinesText">
                All rates posted for jobs are inclusive of revisions. When a translation requires a revision, we will send a notification to the email address in your profile. You can then access the revision request from the order page in the translator portal. You are allowed 12 hours to complete the revision request, but please do your best to return the revised translation ASAP.
              </div>
              <div className="termsHeading guidelinesAuxHeading">Metrics</div>
              <div className="termsText guidelinesText">
                We track the following metrics on translator performance:
                <br/><br/>- Customer satisfaction rating
                <br/>- Ontime rate for jobs
                <br/>- Revision rate
                <br/>- Unclaimed jobs rate
              </div>
              <div className="termsHeading guidelinesAuxHeading">Invoices</div>
              <div className="termsText guidelinesText">
                For direct assignments, you will be asked to submit an invoice before completing the order. Please use the translated word count (do not include the words in the coversheet document) from MS Word. For certain languages (Chinese, Japanese, Korean, etc.) the invoice will need to reflect the English word count, regardless of whether it is the source or target language.
                <br/><br/>Payments are issued on all approved invoices twice monthly, within 2 days of the end of the previous period. The periods are defined as follows:
                <br/><br/>- Invoices submitted between the 1st - 15th
                <br/>- Invoices submitted between the 16th - EOM
              </div>
              <div className="termsHeading guidelinesAuxHeading">Communication</div>
              <div className="termsText guidelinesText">
                We ask that you notify us if you will be unavailable to accept new assignments so that we can deactivate you in the system. Likewise, let us know when you are ready to accept new assignments and we will activate you in the system.
              </div>
              <div className="termsHeading guidelinesAuxHeading">Support</div>
              <div className="termsText guidelinesText">
                For issues related to specific translation jobs, and general concerns or issues related to payments, processes, etc., please contact us at <span className="hoveringTextLink" onClick={() => window.open('mailto:support@everbary.com', '_blank')}>support@everbary.com</span>.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guidelines;