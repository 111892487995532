import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Editor,
  EditorState,
  convertFromRaw,
  convertToRaw,
  RichUtils,
} from "draft-js";
import { v4 as uuidv4 } from "uuid";
import { animated } from "react-spring";
import axios from "axios";

import {
  Clock,
  StopCircle,
  Trash2,
  PlusCircle,
  Bold,
  Italic,
  Underline,
  List,
} from "react-feather";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { getBlob } from "@firebase/storage";

import { FileUploaderButton } from "../../Components/FileUploaderButton";
import { AltAnimatedButton } from "../../Components/AltAnimatedButton";
import { FormSimpleTextInput } from "../../Components/FormSimpleTextInput";

import { languages } from "../../Data/languages";

import { NotificationContext } from "../../Context/NotificationContext";
import { DataContext } from "../../Context/DataContext";

import "./SingleTranslation.css";
import { useScreenTransition } from "../../Hooks/useScreenTransition";

const SingleTranslation = ({ windowSize }) => {
  const { id, tabParam } = useParams();

  const { transitionTo } = useScreenTransition();

  const { showNotification } = useContext(NotificationContext);

  const { userData } = useContext(DataContext);

  const [tab, setTab] = useState(tabParam || "details");

  const [invoiceEdit, setInvoiceEdit] = useState(true);

  const [files, setFiles] = useState([]);

  const [orderInfo, setOrderInfo] = useState(null);

  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );

  const [supportNotes, setSupportNotes] = React.useState(() =>
    EditorState.createEmpty()
  );

  const [openReplyBox, setOpenReplyBox] = useState(false);
  const [supportReplyNotes, setSupportReplyNotes] = React.useState(() =>
    EditorState.createEmpty()
  );

  const [translatorNotes, setTranslatorNotes] = React.useState(() =>
    EditorState.createEmpty()
  );

  const [clientRevisionNotes, setClientRevisionNotes] = React.useState(() =>
    EditorState.createEmpty()
  );

  const [translatorNotesClient, setTranslatorNotesClient] = React.useState(() =>
    EditorState.createEmpty()
  );

  const [documentName, setDocumentName] = useState("");

  const [invoiceLines, setInvoiceLines] = useState([]);

  const [invoiceMemo, setInvoiceMemo] = useState("");

  const [invoiceReview, setInvoiceReview] = useState("");

  const [invoiceReviewSaveButton, setInvoiceReviewSaveButton] =
    useState("Save");

  const [coversheetButton, setCoversheetButton] = useState(
    "Generate Coversheet"
  );
  const [reUploadingCoversheet, setReUploadingCoversheet] = useState(
    ""
  );

  const [saveInvoiceButton, setSaveInvoiceButton] = useState("Save");
  const [submitInvoiceButton, setSubmitInvoiceButton] =
    useState("Save and Submit");

  const [completeTranslationButton, setCompleteTranslationButton] = useState(
    "Complete Translation"
  );
  const [cancelTranslationButton, setCancelTranslationButton] =
    useState("Cancel Translation");

  const [supportApproveButton, setSupportApproveButton] = useState(
    "Approve Translation"
  );
  const [supportRejectButton, setSupportRejectButton] =
    useState("Reject Translation");

  const [notarizationStatus, setNotarizationStatus] = useState("");
  const [hardCopyStatus, setHardCopyStatus] = useState("");
  const [hardCopyTrackingID, setHardCopyTrackingID] = useState("");

  const [discount, setDiscount] = useState(0);

  useEffect(() => {
    if (id) {
      const firestore = firebase.firestore();

      const unsubscribe = firestore
        .collection("orders")
        .doc(id)
        .onSnapshot(
          (doc) => {
            if (doc.exists) {
              if (
                (doc.data().status === "created" ||
                  doc.data().status === "new" ||
                  doc.data().status === "assigned") &&
                tabParam === "invoice"
              ) {
                transitionTo("/office/translation/" + id + "/details");
                setTab("details");
              }

              setOrderInfo({ ...doc.data(), id: doc.id });

              if (
                doc.data().invoice &&
                (doc.data().invoice.status === "submitted" ||
                  doc.data().invoice.status === "review" ||
                  doc.data().invoice.status === "approved" ||
                  doc.data().invoice.status === "paid")
              ) {
                setInvoiceEdit(false);
              }

              if (doc.data().invoice) {
                setInvoiceMemo(doc.data().invoice.memo);
                setInvoiceLines(doc.data().invoice.lines);
              }

              if (doc.data().discount) {
                setDiscount(doc.data().discount);
              }

              if (doc.data().notes) {
                setEditorState(
                  EditorState.createWithContent(
                    convertFromRaw(doc.data().notes)
                  )
                );
              }

              if (doc.data().supportNotes) {
                setSupportNotes(
                  EditorState.createWithContent(
                    convertFromRaw(doc.data().supportNotes)
                  )
                );
              }

              if (doc.data().translatorNotes) {
                setTranslatorNotes(
                  EditorState.createWithContent(
                    convertFromRaw(doc.data().translatorNotes)
                  )
                );
              }

              if (doc.data().supportReply) {
                setSupportReplyNotes(
                  EditorState.createWithContent(
                    convertFromRaw(doc.data().supportReply)
                  )
                );
              }

              if (doc.data().clientRevisionNotes) {
                setClientRevisionNotes(
                  EditorState.createWithContent(
                    convertFromRaw(doc.data().clientRevisionNotes)
                  )
                );
              }

              if (doc.data().translatorNotesClient) {
                setTranslatorNotesClient(
                  EditorState.createWithContent(
                    convertFromRaw(doc.data().translatorNotesClient)
                  )
                );
              }

              if (doc.data().invoice && doc.data().invoice.invoiceReview) {
                setInvoiceReview(doc.data().invoice.invoiceReview);
              }

              if (doc.data().notarizationStatus) {
                setNotarizationStatus(doc.data().notarizationStatus);
              }

              if (doc.data().hardCopyStatus) {
                setHardCopyStatus(doc.data().hardCopyStatus);
              }

              if (doc.data().hardCopyTrackingID) {
                setHardCopyTrackingID(doc.data().hardCopyTrackingID);
              } else {
                setHardCopyTrackingID("");
              }

              if (
                !userData ||
                (userData.role === "Translator" &&
                  userData.id !== doc.data().translator.id)
              ) {
                showNotification(
                  "Error",
                  "You don't have sufficient permissions to see this order. If you think you have the correct permissions, please contact our support team.",
                  "error"
                );
                transitionTo("/office/translations");
              }
            } else {
              showNotification(
                "Error",
                "There is no order with that ID. Please, try again.",
                "error"
              );
              transitionTo("/office/translations");
            }
          },
          (error) => {
            showNotification(
              "Error",
              "There was an error loading the order info. Please, reload the page.",
              "error"
            );
          }
        );

      return () => unsubscribe();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (invoiceEdit && orderInfo && orderInfo.invoice) {
      setInvoiceLines(orderInfo.invoice.lines);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceEdit]);

  useEffect(() => {
    if (orderInfo && orderInfo.status === "in-progress") {
      const now = new Date();

      if (document.getElementById("progressCircle")) {
        var circle = document.getElementById("progressCircle");
        var radius = circle.r.baseVal.value;
        var circumference = radius * 2 * Math.PI;

        circle.style.strokeDasharray = `${circumference} ${circumference}`;
        circle.style.strokeDashoffset = `${circumference}`;

        const percent =
          (now.getTime() - orderInfo.assigned) /
          (orderInfo.due - orderInfo.assigned);

        const offset =
          percent <= 1 ? circumference - percent * circumference : 0;
        circle.style.strokeDashoffset = `${offset}`;
      }
    }

    setFiles((localFiles) =>
      localFiles.filter(
        (f) => !orderInfo.files.some((mFile) => mFile.name === f.name)
      )
    );
  }, [orderInfo, tab]);

  const acceptTranslation = async () => {
    if (id) {
      const firestore = firebase.firestore();

      const now = new Date();

      let invoiceNumber = 0;

      await axios({
        method: "post",
        url: "https://us-central1-project-everbary.cloudfunctions.net/getCounter",
        data: {
          type: "invoice",
        },
      })
        .then((response) => {
          invoiceNumber = parseInt(response.data.number, 10);
        })
        .catch((error) => {
          //console.log(error);
        });

      if (invoiceNumber === 0) {
        showNotification(
          "Error",
          "An error ocurred while accepting the translation. Please, try again. If it persists, contact support.",
          "error"
        );
        return false;
      }

      firestore
        .collection("orders")
        .doc(id)
        .update({
          status: "in-progress",
          accepted: now.getTime(),
          invoice: {
            invoiceNumber: invoiceNumber,
            status: "created",
            lines: [],
            memo: "",
          },
        })
        .then(() => {
          showNotification(
            "Success",
            "You have successfully accepted the translation.",
            "success"
          );
        })
        .catch((error) => {
          showNotification(
            "Error",
            "There was an error accepting the translation. Please, try again.",
            "error"
          );
        });
    } else {
      showNotification(
        "Error",
        "An unexpected error has ocurred. Please, reload the page and try again.",
        "error"
      );
    }
  };

  const declineTranslation = () => {
    if (id) {
      const firestore = firebase.firestore();

      firestore
        .collection("orders")
        .doc(id)
        .update({
          translator: null,
          status: "new",
          due: null,
          assigned: null,
        })
        .then(() => {
          showNotification(
            "Success",
            "You have successfully declined the translation.",
            "success"
          );
          transitionTo("/office/translations");
        })
        .catch((error) => {
          showNotification(
            "Error",
            "There was an error declining the translation. Please, try again.",
            "error"
          );
        });
    } else {
      showNotification(
        "Error",
        "An unexpected error has ocurred. Please, reload the page and try again.",
        "error"
      );
    }
  };

  const handleFiles = (e) => {
    const mFiles = e.target.files;

    for (let i = 0; i < mFiles.length; i++) {
      // if (!files.some(file => file.name === mFiles[i].name) && !orderInfo.files.some(file => file.name === mFiles[i].name)) {
      const fileID = uuidv4();
      setFiles((f) =>
        f.concat([
          {
            id: fileID,
            name: mFiles[i].name,
            file: mFiles[i],
            progress: 0,
            status: "uploading",
          },
        ])
      );
      // } else {
      //   showNotification("File already uploaded.", "You've already uploaded a file with this name. Try uploading with another name.", "error");
      // }
    }

    const storageRef = firebase.storage().ref();

    for (let i = 0; i < mFiles.length; i++) {
      // if (!files.some(file => file.name === mFiles[i].name) && !orderInfo.files.some(file => file.name === mFiles[i].name)) {
      const subFolder = uuidv4();
      const fileRef = storageRef.child(
        orderInfo.id + "/" + subFolder + "/" + mFiles[i].name
      );

      const downloadToken = uuidv4();

      const fileName = mFiles[i].name.replace("#", "%23").replace("+", "%2b");

      const url = `https://firebasestorage.googleapis.com/v0/b/project-everbary.appspot.com/o/${orderInfo.id}%2F${subFolder}%2F${fileName}?alt=media&token=${downloadToken}`;

      var metadata = {
        firebaseStorageDownloadTokens: downloadToken,
        customMetadata: {
          orderID: orderInfo.id,
        },
      };

      const uploadTask = fileRef.put(mFiles[i], metadata);

      setFiles((f) =>
        f.map((file) =>
          file.name === mFiles[i].name ? { ...file, task: uploadTask } : file
        )
      );

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setFiles((f) =>
            f.map((file) =>
              file.name === mFiles[i].name
                ? {
                    ...file,
                    progress: progress.toFixed(2),
                    type:
                      userData.role === "Translator" ||
                      userData.role === "owner" ||
                      userData.role === "Support"
                        ? "translation"
                        : "support",
                  }
                : file
            )
          );
        },
        (error) => {
          setFiles((f) =>
            f.map((file) =>
              file.name === mFiles[i].name
                ? {
                    ...file,
                    status: "failed",
                    type:
                      userData.role === "Translator" ||
                      userData.role === "owner" ||
                      userData.role === "Support"
                        ? "translation"
                        : "support",
                  }
                : file
            )
          );
          showNotification(
            "Error uploading.",
            "There was an error uploading the file " +
              mFiles[i].name +
              ". Please, try again.",
            "error"
          );
        },
        () => {
          setFiles((f) =>
            f.map((file) =>
              file.name === mFiles[i].name
                ? {
                    ...file,
                    status: "complete",
                    ref: orderInfo.id + "/" + subFolder + "/" + mFiles[i].name,
                    url: url,
                    type:
                      userData.role === "Translator" ||
                      userData.role === "owner" ||
                      userData.role === "Support"
                        ? "translation"
                        : "support",
                  }
                : file
            )
          );

          const firestore = firebase.firestore();

          firestore
            .collection("orders")
            .doc(orderInfo.id)
            .update({
              files: firebase.firestore.FieldValue.arrayUnion({
                name: mFiles[i].name,
                ref: orderInfo.id + "/" + subFolder + "/" + mFiles[i].name,
                url: url,
                type:
                  userData.role === "Translator" ||
                  userData.role === "owner" ||
                  userData.role === "Support"
                    ? "translation"
                    : "support",
                size: mFiles[i].size,
                format: mFiles[i].type,
                timestamp: new Date().getTime(),
              }),
            })
            .then(() => {})
            .catch((error) => {
              showNotification(
                "Error uploading.",
                "There was an error uploading the file " +
                  mFiles[i].name +
                  ". Please, try again.",
                "error"
              );
            });
        }
      );
      // }
    }
  };

  const handleDeleteFile = (file) => {
    if (
      (file.type === "support" ||
        file.type === "source" ||
        file.type === "reference") &&
      userData.role === "Translator"
    ) {
      showNotification(
        "Error deleting the file.",
        "You don't have the permissions to delete this file.",
        "error"
      );
      return false;
    }

    const storageRef = firebase.storage().ref();

    if (file.status === "complete") {
      const fileRef = storageRef.child(file.ref);
      setFiles((f) =>
        f.map((mFile) =>
          mFile.name === file.name && mFile.ref === file.ref
            ? { ...mFile, status: "deleting" }
            : mFile
        )
      );

      fileRef
        .delete()
        .then(function () {
          setFiles((f) => f.filter((mFile) => mFile.ref !== file.ref));
        })
        .catch(function (error) {
          showNotification(
            "Error deleting the file.",
            "There was an error deleting the file " +
              file.name +
              ". Please, try again.",
            "error"
          );
        });
    } else if (file.status === "failed") {
      setFiles((f) => f.filter((mFile) => mFile.ref !== file.ref));
    } else {
      file.task.cancel();
      setFiles((f) => f.filter((mFile) => mFile.ref !== file.ref));
    }
  };

  const handleDeleteUploadedFile = async (file) => {
    if (
      (file.type === "support" ||
        file.type === "source" ||
        file.type === "reference") &&
      userData.role === "Translator"
    ) {
      showNotification(
        "Error deleting the file.",
        "You don't have the permissions to delete this file.",
        "error"
      );
      return false;
    }

    if (
      !window.confirm(
        "Are you sure you want to delete this file? This action cannot be undone."
      )
    ) {
      return false;
    }

    const mFiles = orderInfo.files.filter((f) => f.ref !== file.ref);

    console.log("1", orderInfo.files);
    console.log("first", file.ref);
    console.log("2", mFiles);

    const firestore = firebase.firestore();

    const storage = firebase.storage();
    const storageRef = storage.ref();
    const fileRef = storageRef.child(file.ref);

    await fileRef.delete();

    firestore
      .collection("orders")
      .doc(orderInfo.id)
      .update({
        files: mFiles,
      })
      .then(() => {
        showNotification(
          "Success",
          "The file " + file.name + " has been successfully deleted.",
          "success"
        );
      })
      .catch((error) => {
        showNotification(
          "Error deleting the file.",
          "There was an error deleting the file " +
            file.name +
            ". Please, try again.",
          "error"
        );
      });
  };

  const formatSize = (size) => {
    if (!size) {
      return "";
    }

    if (size > 1000000) {
      return (size / 1000000).toFixed(2) + "MB";
    } else {
      return (size / 1000).toFixed(2) + "KB";
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) {
      return "";
    }

    const date = new Date(timestamp);

    const splitted = date.toDateString().split(" ");

    return (
      splitted[1] +
      " " +
      splitted[2] +
      ", " +
      splitted[3] +
      " @ " +
      date.toTimeString().substr(0, 5)
    );
  };

  const capitalize = (text) => {
    if (!text) {
      return "";
    }

    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  //const downloadFile = url => {
  //window.open(url);
  //}

  const downloadFile = (url) => {
    window.open(url);
};

const reUploadSheet = (path) => {
const storage = firebase.storage();
let oldRef = storage.ref().child(path);

getBlob(oldRef).then(blob => {
  oldRef.delete();
  oldRef.put(blob).then(() => {
        setReUploadingCoversheet("");
        setCoversheetButton("Generate Coversheet");
        showNotification(
          "Success",
          "The coversheet for your file has been successfully created.",
          "success"
        );
  })
})
}

const generateCoversheet = () => {
const mDocName = `${documentName}-${capitalize(
  orderInfo.languageFrom
)} to ${capitalize(orderInfo.languageTo)}-${orderInfo.orderNumber}`;
setDocumentName("");

if (
  !orderInfo.files.some(
    (file) => file.name === mDocName || file.name === mDocName + ".docx"
  )
) {
  axios({
    method: "post",
    url: "https://us-central1-project-everbary.cloudfunctions.net/generateCoversheet",
    data: {
      docName: mDocName,
      orderID: orderInfo.id,
      languageFrom: orderInfo.languageFrom,
      languageTo: orderInfo.languageTo,
      translatorCertification: orderInfo.translatorCertification
        ? orderInfo.translatorCertification
        : false,
    },
  })
    .then((res) => {
      reUploadSheet(res.data);
      setReUploadingCoversheet(res.data);
    })
    .catch((error) => {
      setCoversheetButton("Generate Coversheet");
      showNotification(
        "Error",
        "An error ocurred generating the coversheet. Please, try again. If it persists, contact technical support.",
        "error"
      );
    });
} else {
  setCoversheetButton("Generate Coversheet");
  showNotification(
    "Error",
    "There's already a file with that name. Please, try again.",
    "error"
  );
}
};

  const handleDeleteNewLine = (line) => {
    setInvoiceLines((ls) => ls.filter((l) => l.id !== line.id));
  };

  const handleChangeInvoiceLineName = (value, line) => {
    if (!invoiceLines.some((l) => l.filename === value)) {
      setInvoiceLines((ls) =>
        ls.map((l) => (l.id === line.id ? { ...l, filename: value } : l))
      );
    } else {
      showNotification(
        "Error",
        "There's already an invoice line for that file. Please, try again.",
        "error"
      );
    }
  };

  const handleChangeInvoiceLineCount = (value, line) => {
    var reg = /^[0-9]+$/;

    if (value === "" || reg.test(value)) {
      setInvoiceLines((ls) =>
        ls.map((l) => (l.id === line.id ? { ...l, wordCount: value } : l))
      );
    }
  };

  const addNewInvoiceLine = () => {
    const lineID = uuidv4();
    setInvoiceLines((ls) =>
      ls.concat({ id: lineID, filename: "", wordCount: "0" })
    );
  };

  const saveInvoice = (action) => {
    if (action === "submit") {
      setSubmitInvoiceButton(
        orderInfo.invoice.status === "submitted"
          ? "Resubmitting"
          : "Submitting..."
      );
    } else {
      setSaveInvoiceButton("Saving...");
    }

    const now = new Date();

    const firestore = firebase.firestore();

    firestore
      .collection("orders")
      .doc(orderInfo.id)
      .update({
        "invoice.status":
          action === "submit" ? "submitted" : orderInfo.invoice.status,
        "invoice.lines": invoiceLines.filter((l) => l.filename !== ""),
        "invoice.memo": invoiceMemo,
        "invoice.submitted":
          action === "submit"
            ? now.getTime()
            : orderInfo.invoice.status !== "created"
            ? orderInfo.invoice.submitted
            : null,
      })
      .then(() => {
        if (action === "submit") {
          setSubmitInvoiceButton(
            orderInfo.invoice.status === "submitted"
              ? "Resubmit"
              : "Save and Submit"
          );
        } else {
          setSaveInvoiceButton("Save");
        }
      })
      .catch((error) => {
        showNotification(
          "Error",
          "An error ocurred saving the invoice. Please, try again. If it persists, contact technical support.",
          "error"
        );

        if (action === "submit") {
          setSubmitInvoiceButton(
            orderInfo.invoice.status === "submitted"
              ? "Resubmit"
              : "Save and Submit"
          );
        } else {
          setSaveInvoiceButton("Save");
        }
      });
  };

  const toggleInvoiceEdit = () => {
    if (invoiceEdit) {
      setInvoiceEdit(false);
    } else {
      setSubmitInvoiceButton("Resubmit");
      setInvoiceEdit(true);
    }
  };

  const saveSupportNotes = () => {
    const firestore = firebase.firestore();

    firestore
      .collection("orders")
      .doc(orderInfo.id)
      .update({
        supportNotes: convertToRaw(supportNotes.getCurrentContent()),
        supportNotesFlag: orderInfo.supportNotesFlag ? false : true,
      })
      .then(() => {
        showNotification("Success", "Comments saved succesfully!", "success");
      })
      .catch((error) => {
        showNotification(
          "Error",
          "An error ocurred saving the comments. Please, try again. If it persists, contact technical support.",
          "error"
        );
      });
  };

  const completeTranslation = () => {
    if (
      userData &&
      (userData.role === "Translator" ||
        userData.role === "Support" ||
        userData.role === "owner") &&
      orderInfo.files.some((f) => f.type === "translation") &&
      (!orderInfo.certified ||
        (orderInfo.files.some(
          (f) => f.type === "translation" && f.format === "application/pdf"
        ) &&
          orderInfo.files.some(
            (f) =>
              f.type === "translation" &&
              f.format ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          )))
    ) {
      setCompleteTranslationButton("Completing...");

      const now = new Date();

      const firestore = firebase.firestore();

      firestore
        .collection("orders")
        .doc(orderInfo.id)
        .update({
          status:
            userData.status === "active" ? "user-review" : "support-review",
          supportApproved: userData.status === "active",
          clientApproved: false,
          revision: false,
          userReady: userData.status === "active" ? now.getTime() : null,
        })
        .then(() => {
          showNotification(
            "Success",
            "Translation has been marked as completed.",
            "success"
          );
          setCompleteTranslationButton("Complete Translation");
        })
        .catch((error) => {
          setCompleteTranslationButton("Complete Translation");
          showNotification(
            "Error",
            "An error ocurred completing the translation. Please, try again. If it persists, contact technical support.",
            "error"
          );
        });
    } else {
      showNotification(
        "Error",
        "An error ocurred completing the translation. Please, try again. If it persists, contact technical support.",
        "error"
      );
    }
  };

  const cancelTranslation = () => {
    if (
      userData &&
      (userData.role === "Support" || userData.role === "owner") &&
      window.confirm(
        "Are you sure you want to cancel the translation? This action cannot be undone."
      )
    ) {
      setCancelTranslationButton("Cancelling...");

      const firestore = firebase.firestore();

      firestore
        .collection("orders")
        .doc(orderInfo.id)
        .update({
          status: "cancelled",
        })
        .then(() => {
          showNotification(
            "Success",
            "Translation has been successfully cancelled.",
            "success"
          );
          setCancelTranslationButton("Cancel Translation");
        })
        .catch((error) => {
          setCancelTranslationButton("Cancel Translation");
          showNotification(
            "Error",
            "An error ocurred cancelling the translation. Please, try again. If it persists, contact technical support.",
            "error"
          );
        });
    } else {
      showNotification(
        "Error",
        "An error ocurred cancelling the translation. Please, try again. If it persists, contact technical support.",
        "error"
      );
    }
  };

  const supportApproveTranslation = () => {
    if (
      userData &&
      (userData.role === "Support" || userData.role === "owner")
    ) {
      setSupportApproveButton("Approving...");

      const firestore = firebase.firestore();

      const now = new Date();

      firestore
        .collection("orders")
        .doc(orderInfo.id)
        .update({
          status: "user-review",
          supportApproved: true,
          userReady: now.getTime(),
        })
        .then(() => {
          showNotification(
            "Success",
            "Translation has been sent to client for review.",
            "success"
          );
          setSupportApproveButton("Approve Translation");
        })
        .catch((error) => {
          setSupportApproveButton("Approve Translation");
          showNotification(
            "Error",
            "An error ocurred approving the translation. Please, try again. If it persists, contact technical support.",
            "error"
          );
        });
    } else {
      showNotification(
        "Error",
        "An error ocurred approving the translation. Please, try again. If it persists, contact technical support.",
        "error"
      );
    }
  };

  const supportRejectTranslation = () => {
    if (
      userData &&
      (userData.role === "Support" || userData.role === "owner")
    ) {
      setSupportRejectButton("Rejecting...");

      const firestore = firebase.firestore();

      const now = new Date();

      firestore
        .collection("orders")
        .doc(orderInfo.id)
        .update({
          status: "revision",
          supportApproved: false,
          revision: true,
          revisionAssigned: now.getTime(),
          revisionDue: now.getTime() + 43200000,
          revisionOverdue: false,
        })
        .then(() => {
          showNotification(
            "Success",
            "Translation has been sent back to translator for revision.",
            "success"
          );
          setSupportRejectButton("Reject Translation");
        })
        .catch((error) => {
          setSupportRejectButton("Reject Translation");
          showNotification(
            "Error",
            "An error ocurred rejecting the translation. Please, try again. If it persists, contact technical support.",
            "error"
          );
        });
    } else {
      showNotification(
        "Error",
        "An error ocurred rejecting the translation. Please, try again. If it persists, contact technical support.",
        "error"
      );
    }
  };

  const reopenSupportReview = () => {
    if (
      userData &&
      (userData.role === "Support" || userData.role === "owner") &&
      window.confirm(
        "Are you sure you want to reopen the review process? This may affect the user or translator if they are already reviewing this order."
      )
    ) {
      const firestore = firebase.firestore();

      firestore
        .collection("orders")
        .doc(orderInfo.id)
        .update({
          status: "support-review",
          supportApproved: false,
          revision: false,
        })
        .then(() => {
          showNotification(
            "Success",
            "Translation has been sent back to Support Review.",
            "success"
          );
        })
        .catch((error) => {
          showNotification(
            "Error",
            "An error ocurred reopening the review. Please, try again. If it persists, contact technical support.",
            "error"
          );
        });
    } else {
      showNotification(
        "Error",
        "An error ocurred reopening the review. Please, try again. If it persists, contact technical support.",
        "error"
      );
    }
  };

  const toggleSupportReply = () => {
    setOpenReplyBox((o) => !o);
  };

  const saveSupportReply = () => {
    const firestore = firebase.firestore();

    firestore
      .collection("orders")
      .doc(orderInfo.id)
      .update({
        supportReply: convertToRaw(supportReplyNotes.getCurrentContent()),
        supportReplyFlag: orderInfo.supportReplyFlag ? false : true,
      })
      .then(() => {
        showNotification("Success", "Reply notes have been saved.", "success");
      })
      .catch((error) => {
        showNotification(
          "Error",
          "An error ocurred saving the notes. Please, try again. If it persists, contact technical support.",
          "error"
        );
      });
  };

  const handleBoldClick = (target) => {
    if (target === "supportNotes") {
      setSupportNotes((editorState) =>
        RichUtils.toggleInlineStyle(editorState, "BOLD")
      );
    } else if (target === "supportReply") {
      setSupportReplyNotes((editorState) =>
        RichUtils.toggleInlineStyle(editorState, "BOLD")
      );
    }
  };

  const handleItalicClick = (target) => {
    if (target === "supportNotes") {
      setSupportNotes((editorState) =>
        RichUtils.toggleInlineStyle(editorState, "ITALIC")
      );
    } else if (target === "supportReply") {
      setSupportReplyNotes((editorState) =>
        RichUtils.toggleInlineStyle(editorState, "ITALIC")
      );
    }
  };

  const handleUnderlineClick = (target) => {
    if (target === "supportNotes") {
      setSupportNotes((editorState) =>
        RichUtils.toggleInlineStyle(editorState, "UNDERLINE")
      );
    } else if (target === "supportReply") {
      setSupportReplyNotes((editorState) =>
        RichUtils.toggleInlineStyle(editorState, "UNDERLINE")
      );
    }
  };

  const toggleUL = (target) => {
    if (target === "supportNotes") {
      setSupportNotes((editorState) =>
        RichUtils.toggleBlockType(editorState, "unordered-list-item")
      );
    } else if (target === "supportReply") {
      setSupportReplyNotes((editorState) =>
        RichUtils.toggleBlockType(editorState, "unordered-list-item")
      );
    }
  };

  const reviveOrder = () => {
    if (
      window.confirm(
        "Are you sure you want to reopen this order? It will reenter the system as a New Order."
      )
    ) {
      const firestore = firebase.firestore();

      firestore
        .collection("orders")
        .doc(orderInfo.id)
        .update({
          status: "new",
          accepted: null,
          assigned: null,
          due: null,
          clientApproved: false,
          supportApproved: false,
          invoice: null,
          revision: false,
          revisionAssigned: null,
          revisionDue: null,
          revisionSubmitted: null,
          clientRevisionNotes: null,
          supportNotes: null,
          supportReply: null,
          translator: null,
          translatorNotes: null,
          translatorNotesClient: null,
        })
        .then(() => {
          showNotification(
            "Success",
            "Order has been succesfully reopened.",
            "success"
          );
        })
        .catch((error) => {
          showNotification(
            "Error",
            "An error ocurred reopening the order. Please, try again. If it persists, contact technical support.",
            "error"
          );
        });
    }
  };

  const handleChangeFileType = (file, type) => {
    if (window.confirm("Are you sure you want to change this file's type?")) {
      const firestore = firebase.firestore();

      const mFiles = orderInfo.files.map((f) =>
        f.name === file.name ? { ...f, type: type } : f
      );

      firestore
        .collection("orders")
        .doc(orderInfo.id)
        .update({
          files: mFiles,
        })
        .then(() => {
          showNotification(
            "Success",
            "File's type has been successfully changed.",
            "success"
          );
        })
        .catch((error) => {
          showNotification(
            "Error",
            "An error ocurred changing the file's type. Please, try again. If it persists, contact technical support.",
            "error"
          );
        });
    }
  };

  const approveInvoice = () => {
    if (
      userData &&
      (userData.role === "Support" || userData.role === "owner") &&
      orderInfo &&
      orderInfo.invoice &&
      (orderInfo.invoice.status === "submitted" ||
        orderInfo.invoice.status === "review")
    ) {
      const firestore = firebase.firestore();

      firestore
        .collection("orders")
        .doc(orderInfo.id)
        .update({
          "invoice.status": "approved",
        })
        .then(() => {
          showNotification(
            "Success",
            "Invoice has been successfully approved.",
            "success"
          );
        })
        .catch((error) => {
          showNotification(
            "Error",
            "An error ocurred approving the invoice. Please, try again. If it persists, contact technical support.",
            "error"
          );
        });
    } else {
      showNotification(
        "Error",
        "An error ocurred approving the invoice. Please, try again. If it persists, contact technical support.",
        "error"
      );
    }
  };

  const reviewInvoice = () => {
    if (
      userData &&
      (userData.role === "Support" || userData.role === "owner") &&
      orderInfo &&
      orderInfo.invoice &&
      orderInfo.invoice.status === "submitted"
    ) {
      const firestore = firebase.firestore();

      firestore
        .collection("orders")
        .doc(orderInfo.id)
        .update({
          "invoice.status": "review",
        })
        .then(() => {})
        .catch((error) => {
          showNotification(
            "Error",
            "An error ocurred changing the invoice's state. Please, try again. If it persists, contact technical support.",
            "error"
          );
        });
    } else {
      showNotification(
        "Error",
        "An error ocurred changing the invoice's state. Please, try again. If it persists, contact technical support.",
        "error"
      );
    }
  };

  const saveInvoiceReview = () => {
    if (
      userData &&
      (userData.role === "Support" || userData.role === "owner") &&
      orderInfo &&
      orderInfo.invoice &&
      orderInfo.invoice.status === "review"
    ) {
      setInvoiceReviewSaveButton("Saving...");

      const firestore = firebase.firestore();

      firestore
        .collection("orders")
        .doc(orderInfo.id)
        .update({
          "invoice.invoiceReview": invoiceReview,
        })
        .then(() => {
          setInvoiceReviewSaveButton("Save");
          showNotification(
            "Success",
            "Invoice review message has been successfully added.",
            "success"
          );
        })
        .catch((error) => {
          setInvoiceReviewSaveButton("Save");
          showNotification(
            "Error",
            "An error ocurred changing the invoice's state. Please, try again. If it persists, contact technical support.",
            "error"
          );
        });
    } else {
      showNotification(
        "Error",
        "An error ocurred changing the invoice's state. Please, try again. If it persists, contact technical support.",
        "error"
      );
    }
  };

  const reopenInvoiceReview = () => {
    if (
      userData &&
      (userData.role === "Support" || userData.role === "owner") &&
      orderInfo &&
      orderInfo.invoice &&
      orderInfo.invoice.status === "approved"
    ) {
      const firestore = firebase.firestore();

      firestore
        .collection("orders")
        .doc(orderInfo.id)
        .update({
          "invoice.status": "review",
        })
        .then(() => {})
        .catch((error) => {
          showNotification(
            "Error",
            "An error ocurred reopening the invoice review. Please, try again. If it persists, contact technical support.",
            "error"
          );
        });
    } else {
      showNotification(
        "Error",
        "An error ocurred reopening the invoice review. Please, try again. If it persists, contact technical support.",
        "error"
      );
    }
  };

  // const handleChangeFileCertificateID = (file, certificateID) => {
  //   const firestore = firebase.firestore();

  //   const mFiles = orderInfo.files.map((f) =>
  //     f.name === file.name ? { ...f, certificateID: certificateID } : f
  //   );

  //   firestore
  //     .collection("orders")
  //     .doc(orderInfo.id)
  //     .update({
  //       files: mFiles,
  //     })
  //     .then(() => {
  //       showNotification(
  //         "Success",
  //         "File's Certificate ID has been successfully changed.",
  //         "success"
  //       );
  //     })
  //     .catch((error) => {
  //       showNotification(
  //         "Error",
  //         "An error ocurred changing the file's Certificate ID. Please, try again. If it persists, contact technical support.",
  //         "error"
  //       );
  //     });
  // };

  const onChangeDiscount = (discount) => {
    var reg = /^[0-9]+\.?[0-9]{0,3}$/;

    if (discount === "" || reg.test(discount)) {
      setDiscount(discount);
    }
  };

  const submitDiscount = () => {
    const firestore = firebase.firestore();

    firestore
      .collection("orders")
      .doc(orderInfo.id)
      .update({
        discount:
          discount !== 0 && discount !== "" && discount !== "0"
            ? Number(discount)
            : 0,
      })
      .then(() => {
        showNotification(
          "Success",
          "Discount has been successfully added to the order.",
          "success"
        );
      })
      .catch((error) => {
        showNotification(
          "Error",
          "An error ocurred adding the discount. Please, try again. If it persists, contact technical support.",
          "error"
        );
      });
  };

  const saveShippingInfo = () => {
    const firestore = firebase.firestore();

    firestore
      .collection("orders")
      .doc(orderInfo.id)
      .update({
        notarizationStatus: notarizationStatus,
        hardCopyStatus: hardCopyStatus,
        hardCopyTrackingID:
          hardCopyStatus === "shipped" ? hardCopyTrackingID : null,
      })
      .then(() => {
        showNotification(
          "Success",
          "Notarization & Hard Copy info successfully saved.",
          "success"
        );
      })
      .catch((error) => {
        showNotification(
          "Error",
          "An error ocurred updating the order information. Please, try again. If it persists, contact technical support.",
          "error"
        );
      });
  };

  return (
    <div className="singleTranslationContainer">
      <div className="singleTranslationHeader">
        <p className="singleTranslationOrderNumber">
          {orderInfo && "#" + orderInfo.orderNumber}
        </p>
        {orderInfo &&
          ((orderInfo.status !== "created" &&
            orderInfo.status !== "new" &&
            orderInfo.status !== "assigned") ||
            (userData &&
              (userData.role === "Support" || userData.role === "owner"))) && (
            <div className="singleTranslationTabsControl">
              <p
                className="singleTranslationTabButton"
                onClick={() => setTab("details")}
                style={{
                  color: tab === "details" ? "#FF7C1D" : "#666",
                  borderColor:
                    tab === "details"
                      ? "rgba(255, 124, 29, 1)"
                      : "rgba(255, 124, 29, 0)",
                }}
              >
                Details
              </p>
              <p
                className="singleTranslationTabButton"
                onClick={() => setTab("files")}
                style={{
                  color: tab === "files" ? "#FF7C1D" : "#666",
                  borderColor:
                    tab === "files"
                      ? "rgba(255, 124, 29, 1)"
                      : "rgba(255, 124, 29, 0)",
                }}
              >
                Files
              </p>
              {orderInfo.status !== "created" &&
                orderInfo.status !== "new" &&
                orderInfo.status !== "assigned" && (
                  <p
                    className="singleTranslationTabButton"
                    onClick={() => setTab("invoice")}
                    style={{
                      color: tab === "invoice" ? "#FF7C1D" : "#666",
                      borderColor:
                        tab === "invoice"
                          ? "rgba(255, 124, 29, 1)"
                          : "rgba(255, 124, 29, 0)",
                    }}
                  >
                    Invoice
                  </p>
                )}
            </div>
          )}
      </div>
      {orderInfo &&
      (orderInfo.status === "created" ||
        orderInfo.status === "new" ||
        orderInfo.status === "assigned") &&
      tab === "details" ? (
        <div className="singleTranslationDecideContainer">
          <p className="singleTranslationDecideTitle">Translation Overview</p>
          <div className="singleTranslationDecideMainContainer">
            <div className="singleTranslationDecideInfoContainer">
              <div className="singleTranslationDecideInfoLine">
                <p className="singleTranslationDecideInfoTitle">
                  Language Pair
                </p>
                <p className="singleTranslationDecideInfoText">
                  <strong>
                    {
                      languages.find(
                        (lang) => lang.value === orderInfo.languageFrom
                      ).label
                    }
                  </strong>
                  {" to "}
                  <strong>
                    {
                      languages.find(
                        (lang) => lang.value === orderInfo.languageTo
                      ).label
                    }
                  </strong>
                </p>
              </div>
              <div className="singleTranslationDecideInfoSeparator" />
              <div className="singleTranslationDecideInfoLine">
                <p className="singleTranslationDecideInfoTitle">Type</p>
                <p className="singleTranslationDecideInfoText">
                  {orderInfo.certified
                    ? "Certified Translation"
                    : "Standard Translation"}
                </p>
              </div>
              <div className="singleTranslationDecideInfoSeparator" />
              <div className="singleTranslationDecideInfoLine">
                <p className="singleTranslationDecideInfoTitle">Size</p>
                <p className="singleTranslationDecideInfoText">
                  {orderInfo.certified
                    ? "Less than " + orderInfo.pagesCount * 250 + " words"
                    : orderInfo.wordCount + " words"}
                </p>
              </div>
              <div className="singleTranslationDecideInfoSeparator" />
              <div className="singleTranslationDecideInfoLine">
                <p className="singleTranslationDecideInfoTitle">Rate</p>
                <p className="singleTranslationDecideInfoText">
                  {orderInfo.status === "assigned" && orderInfo.expedited
                    ? "Expedited Rate: $" +
                      orderInfo.translator.orderRate +
                      "/word"
                    : orderInfo.status === "assigned"
                    ? "Standard Rate: $" +
                      orderInfo.translator.orderRate +
                      "/word"
                    : null}
                </p>
              </div>
              <div className="singleTranslationDecideInfoSeparator" />
              <div className="singleTranslationDecideInfoLine">
                <p className="singleTranslationDecideInfoTitle">Assigned</p>
                <p className="singleTranslationDecideInfoText">
                  {formatDate(orderInfo.assigned)}
                </p>
              </div>
              <div className="singleTranslationDecideInfoSeparator" />
              <div className="singleTranslationDecideInfoLine">
                <p className="singleTranslationDecideInfoTitle">Due</p>
                <p className="singleTranslationDecideInfoText">
                  {formatDate(orderInfo.due)}
                </p>
              </div>
            </div>
            {orderInfo.status === "created" ? (
              <div className="singleTranslationDecideExtraContainer">
                <p
                  className="singleTranslationDecideExtraText"
                  style={{ backgroundColor: "#F6C907" }}
                >
                  Created
                </p>
                <div className="singleTranslationDetailsSecondarySeparator" />
                <div className="singleTranslationDiscountInputContainer">
                  <FormSimpleTextInput
                    placeholder={"Discount (%)"}
                    value={discount}
                    onChange={onChangeDiscount}
                    color="#666"
                  />
                </div>
                <div className="singleTranslationDetailsSecondarySeparator" />
                <div className="singleTranslationDiscountSubmitButtonContainer">
                  <AltAnimatedButton
                    color={"#FF7C1D"}
                    text="Submit"
                    disabled={discount === 0 || discount === ""}
                    onClick={() => submitDiscount()}
                    backend
                    fontSize={
                      windowSize.width > 1024
                        ? 1
                        : windowSize.width > 767
                        ? windowSize.orientation === "portrait"
                          ? 1.6
                          : 1.2
                        : windowSize.orientation === "portrait"
                        ? 3.2
                        : 1.1
                    }
                  />
                </div>
              </div>
            ) : orderInfo.status === "new" ? (
              <div className="singleTranslationDecideExtraContainer">
                <p className="singleTranslationDecideExtraText">New Order</p>
                <p className="singleTranslationDecideExtraSecondary">
                  Not assigned
                </p>
              </div>
            ) : userData &&
              (userData.role === "owner" || userData.role === "Support") ? (
              <div className="singleTranslationWrapper">
                <div className="singleTranslationDecideExtraContainer">
                  <p
                    className="singleTranslationDecideExtraText"
                    style={{ backgroundColor: "#61dbfb" }}
                  >
                    Assigned
                  </p>
                  <p className="singleTranslationDecideExtraSecondary">
                    <strong>Assigned to: </strong>
                    {orderInfo.translator.name}
                  </p>
                </div>
                <div className="singleTranslationDecideExtraContainer">
                  <AltAnimatedButton
                    minWidth={"60%"}
                    width="100%"
                    space
                    color={"#17BC5B"}
                    text={"Accept Translation"}
                    onClick={() => acceptTranslation()}
                    backend
                    fontSize={
                      windowSize.width > 1024
                        ? 1
                        : windowSize.width > 767
                        ? windowSize.orientation === "portrait"
                          ? 1.9
                          : 1.2
                        : windowSize.orientation === "portrait"
                        ? 3.7
                        : 1.1
                    }
                  />
                  <div className="singleTranslationDetailsSecondarySeparator" />
                  <AltAnimatedButton
                    minWidth={"60%"}
                    width="100%"
                    space
                    color={"#E1504A"}
                    text={"Decline Translation"}
                    onClick={() => declineTranslation()}
                    backend
                    fontSize={
                      windowSize.width > 1024
                        ? 1
                        : windowSize.width > 767
                        ? windowSize.orientation === "portrait"
                          ? 1.9
                          : 1.2
                        : windowSize.orientation === "portrait"
                        ? 3.7
                        : 1.1
                    }
                  />
                </div>
              </div>
            ) : userData &&
              userData.role === "Translator" &&
              orderInfo.translator.id === userData.id ? (
              <div className="singleTranslationDecideExtraContainer">
                <AltAnimatedButton
                  minWidth={"60%"}
                  space
                  color={"#17BC5B"}
                  text={"Accept Translation"}
                  onClick={() => acceptTranslation()}
                  backend
                  fontSize={
                    windowSize.width > 1024
                      ? 1
                      : windowSize.width > 767
                      ? windowSize.orientation === "portrait"
                        ? 1.9
                        : 1.2
                      : windowSize.orientation === "portrait"
                      ? 3.7
                      : 1.1
                  }
                />
                <div className="singleTranslationDetailsSecondarySeparator" />
                <AltAnimatedButton
                  minWidth={"60%"}
                  space
                  color={"#E1504A"}
                  text={"Decline Translation"}
                  onClick={() => declineTranslation()}
                  backend
                  fontSize={
                    windowSize.width > 1024
                      ? 1
                      : windowSize.width > 767
                      ? windowSize.orientation === "portrait"
                        ? 1.9
                        : 1.2
                      : windowSize.orientation === "portrait"
                      ? 3.7
                      : 1.1
                  }
                />
              </div>
            ) : null}
          </div>
          <div className="singleTranslatoionWrapper">
            <div
              className="singleTranslationDecideTurnaround"
              style={{ color: orderInfo.expedited ? "#FF7C1D" : "#333" }}
            >
              <Clock
                className="singleTranslationDecideTurnaroundIcon"
                style={{ color: orderInfo.expedited ? "#FF7C1D" : "#333" }}
              />
              {orderInfo.expedited
                ? "Expedited Turnaround"
                : "Standard Turnaround"}
            </div>
            {orderInfo &&
              (orderInfo.status === "created" ||
                orderInfo.status === "new" ||
                orderInfo.status === "assigned") &&
              userData &&
              (userData.role === "Support" || userData.role === "owner") && (
                <button
                  className="cancelBtn"
                  onClick={() => cancelTranslation()}
                >
                  Cancel Order
                </button>
              )}
          </div>
          <div
            className="singleTranslationNotesContainer"
            style={{ marginTop: "3vh" }}
          >
            <div className="singleTranslationNotesTitle">
              <strong>Client's Notes</strong>
              {!orderInfo.supportReply &&
                userData &&
                (userData.role === "owner" || userData.role === "Support") && (
                  <div
                    className="singleTranslationNotesAuxButton"
                    onClick={() => toggleSupportReply()}
                  >
                    {openReplyBox ? "Cancel" : "Reply"}
                  </div>
                )}
            </div>
            <div className="singleTranslationRichTextEditor">
              <Editor
                editorState={editorState}
                onChange={(editorState) => setEditorState(editorState)}
                readOnly
              />
            </div>
          </div>
          {(orderInfo.supportReply || openReplyBox) &&
            userData &&
            (userData.role === "owner" || userData.role === "Support") && (
              <div className="singleTranslationNotesContainer">
                <div
                  className="singleTranslationNotesTitle"
                  style={{ backgroundColor: "#e91e63" }}
                >
                  <strong>Support's Notes (for Client)</strong>
                  <div className="singleOrderTextButtons">
                    <Bold
                      className="singleOrderRichTextButton"
                      onClick={() => handleBoldClick("supportReply")}
                    />
                    <Italic
                      className="singleOrderRichTextButton"
                      onClick={() => handleItalicClick("supportReply")}
                    />
                    <Underline
                      className="singleOrderRichTextButton"
                      onClick={() => handleUnderlineClick("supportReply")}
                    />
                    <List
                      className="singleOrderRichTextButton"
                      onClick={() => toggleUL("supportReply")}
                    />
                    <div
                      className="singleTranslationNotesAuxButton"
                      onClick={() => saveSupportReply()}
                    >
                      Save
                    </div>
                  </div>
                </div>
                <div className="singleTranslationRichTextEditor">
                  <Editor
                    editorState={supportReplyNotes}
                    onChange={(supportReplyNotes) =>
                      setSupportReplyNotes(supportReplyNotes)
                    }
                  />
                </div>
              </div>
            )}
        </div>
      ) : orderInfo && tab === "details" ? (
        <div className="singleTranslationDetailsOuter">
          <div className="singleTranslationDetailsMain">
            <div className="singleTranslationDetailsDates">
              <p className="singleTranslationDecideTitle">Translation Dates</p>
              <div className="singleTranslationDecideMainContainer">
                <div className="singleTranslationDatesProgressContainer">
                  <p
                    style={{
                      color:
                        orderInfo.status === "in-progress"
                          ? "#333"
                          : orderInfo.status === "cancelled"
                          ? "#E1504A"
                          : "#17BC5B",
                    }}
                    className="singleTranslationDatesProgressText"
                  >
                    {orderInfo.status === "in-progress"
                      ? orderInfo.due - new Date().getTime() > 172800000
                        ? `${Math.floor(
                            (orderInfo.due - new Date().getTime()) /
                              (1000 * 60 * 60 * 24)
                          )} days from now`
                        : orderInfo.due - new Date().getTime() > 86400000
                        ? `1 day from now`
                        : orderInfo.due - new Date().getTime() > 0
                        ? `${Math.floor(
                            (orderInfo.due - new Date().getTime()) /
                              (1000 * 60 * 60)
                          )} hours from now`
                        : "Past due date"
                      : orderInfo.status === "cancelled"
                      ? "Cancelled"
                      : "Completed"}
                  </p>
                  <svg className="progress-ring" width="100%" height="100%">
                    <circle
                      className="progress-ring__circle"
                      stroke={
                        orderInfo.status === "in-progress"
                          ? "#1e7e81"
                          : orderInfo.status === "cancelled"
                          ? "#E1504A"
                          : "#17BC5B"
                      }
                      strokeWidth="12"
                      fill="transparent"
                      r="90"
                      cx="50%"
                      cy="50%"
                    />
                    <circle
                      id="progressCircle"
                      className="progress-ring__circle topCircle"
                      stroke={
                        orderInfo.status === "in-progress"
                          ? "#6FAEAD"
                          : orderInfo.status === "cancelled"
                          ? "#E1504A"
                          : "#17BC5B"
                      }
                      strokeWidth="12"
                      fill="transparent"
                      r="90"
                      cx="50%"
                      cy="50%"
                    />
                  </svg>
                </div>
                <div
                  className="singleTranslationDecideInfoContainer"
                  style={{
                    width:
                      windowSize.width <= 1024 &&
                      windowSize.orientation === "portrait"
                        ? "100%"
                        : "54%",
                  }}
                >
                  <div className="singleTranslationDecideInfoLine">
                    <p className="singleTranslationDecideInfoTitle">Assigned</p>
                    <p className="singleTranslationDecideInfoText">
                      {formatDate(orderInfo.assigned)}
                    </p>
                  </div>
                  <div className="singleTranslationDecideInfoSeparator" />
                  <div className="singleTranslationDecideInfoLine">
                    <p className="singleTranslationDecideInfoTitle">Accepted</p>
                    <p className="singleTranslationDecideInfoText">
                      {formatDate(orderInfo.accepted)}
                    </p>
                  </div>
                  <div className="singleTranslationDecideInfoSeparator" />
                  <div className="singleTranslationDecideInfoLine">
                    <p className="singleTranslationDecideInfoTitle">Due</p>
                    <p className="singleTranslationDecideInfoText">
                      {formatDate(orderInfo.due)}
                    </p>
                  </div>
                  <div
                    className="singleTranslationDecideTurnaround"
                    style={{ color: orderInfo.expedited ? "#FF7C1D" : "#333" }}
                  >
                    <Clock
                      className="singleTranslationDecideTurnaroundIcon"
                      style={{
                        color: orderInfo.expedited ? "#FF7C1D" : "#333",
                      }}
                    />
                    {orderInfo.expedited
                      ? "Expedited Turnaround"
                      : "Standard Turnaround"}
                  </div>
                </div>
              </div>
            </div>
            {(orderInfo.status === "in-progress" ||
              ((orderInfo.status === "support-review" ||
                orderInfo.status === "revision" ||
                orderInfo.status === "user-review" ||
                orderInfo.status === "completed") &&
                userData &&
                (userData.role === "owner" ||
                  userData.role === "Support"))) && (
              <div className="singleTranslationDetailsComments">
                <p className="singleTranslationDecideTitle">Comment History</p>
                <>
                  {(editorState.getCurrentContent().getPlainText() !== "" ||
                    (userData &&
                      (userData.role === "owner" ||
                        userData.role === "Support"))) && (
                    <div className="singleTranslationNotesContainer">
                      <div className="singleTranslationNotesTitle">
                        <strong>Client's Notes</strong>
                        {userData &&
                          (userData.role === "owner" ||
                            userData.role === "Support") &&
                          !orderInfo.supportReply && (
                            <div
                              className="singleTranslationNotesAuxButton"
                              onClick={() => toggleSupportReply()}
                            >
                              {openReplyBox ? "Cancel" : "Reply"}
                            </div>
                          )}
                      </div>
                      <div className="singleTranslationRichTextEditor">
                        <Editor
                          editorState={editorState}
                          onChange={(editorState) =>
                            setEditorState(editorState)
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  )}
                  {userData &&
                    (userData.role === "owner" ||
                      userData.role === "Support") &&
                    (orderInfo.supportReply || openReplyBox) && (
                      <div className="singleTranslationNotesContainer">
                        <div
                          className="singleTranslationNotesTitle"
                          style={{ backgroundColor: "#e91e63" }}
                        >
                          <strong>Support's Notes (for Client)</strong>
                          <div className="singleOrderTextButtons">
                            <Bold
                              className="singleOrderRichTextButton"
                              onClick={() => handleBoldClick("supportReply")}
                            />
                            <Italic
                              className="singleOrderRichTextButton"
                              onClick={() => handleItalicClick("supportReply")}
                            />
                            <Underline
                              className="singleOrderRichTextButton"
                              onClick={() =>
                                handleUnderlineClick("supportReply")
                              }
                            />
                            <List
                              className="singleOrderRichTextButton"
                              onClick={() => toggleUL("supportReply")}
                            />
                            <div
                              className="singleTranslationNotesAuxButton"
                              onClick={() => saveSupportReply()}
                            >
                              Save
                            </div>
                          </div>
                        </div>
                        <div className="singleTranslationRichTextEditor">
                          <Editor
                            editorState={supportReplyNotes}
                            onChange={(supportReplyNotes) =>
                              setSupportReplyNotes(supportReplyNotes)
                            }
                          />
                        </div>
                      </div>
                    )}
                  {((userData &&
                    (userData.role === "owner" ||
                      userData.role === "Support")) ||
                    (userData &&
                      userData.role === "Translator" &&
                      supportNotes.getCurrentContent().getPlainText() !== "" &&
                      (orderInfo.status === "in-progress" ||
                        orderInfo.status === "revision" ||
                        orderInfo.status === "user-review"))) && (
                    <div className="singleTranslationNotesContainer">
                      <div
                        className="singleTranslationNotesTitle"
                        style={{ backgroundColor: "#FF7C1D" }}
                      >
                        <strong>Support's Notes (for Translator)</strong>
                        {userData &&
                          (userData.role === "Support" ||
                            userData.role === "owner") &&
                          (orderInfo.status === "in-progress" ||
                            orderInfo.status === "support-review") && (
                            <div className="singleOrderTextButtons">
                              <Bold
                                className="singleOrderRichTextButton"
                                onClick={() => handleBoldClick("supportNotes")}
                              />
                              <Italic
                                className="singleOrderRichTextButton"
                                onClick={() =>
                                  handleItalicClick("supportNotes")
                                }
                              />
                              <Underline
                                className="singleOrderRichTextButton"
                                onClick={() =>
                                  handleUnderlineClick("supportNotes")
                                }
                              />
                              <List
                                className="singleOrderRichTextButton"
                                onClick={() => toggleUL("supportNotes")}
                              />
                              <div
                                className="singleTranslationNotesAuxButton"
                                onClick={() => saveSupportNotes()}
                              >
                                Save
                              </div>
                            </div>
                          )}
                      </div>
                      <div className="singleTranslationRichTextEditor">
                        <Editor
                          editorState={supportNotes}
                          onChange={(supportNotes) =>
                            setSupportNotes(supportNotes)
                          }
                          readOnly={
                            !userData ||
                            userData.role === "Translator" ||
                            !(
                              orderInfo.status === "in-progress" ||
                              orderInfo.status === "support-review"
                            )
                          }
                        />
                      </div>
                    </div>
                  )}
                  {(orderInfo.status === "support-review" ||
                    orderInfo.status === "user-review" ||
                    orderInfo.status === "completed") &&
                    translatorNotes.getCurrentContent().getPlainText() !== "" &&
                    userData &&
                    (userData.role === "Support" ||
                      userData.role === "owner") && (
                      <div className="singleTranslationNotesContainer">
                        <div
                          className="singleTranslationNotesTitle"
                          style={{ backgroundColor: "#333" }}
                        >
                          <strong>Translator Revision Notes</strong>
                        </div>
                        <div className="singleTranslationRichTextEditor">
                          <Editor
                            editorState={translatorNotes}
                            onChange={(translatorNotes) =>
                              setTranslatorNotes(translatorNotes)
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    )}
                  {clientRevisionNotes.getCurrentContent().getPlainText() !==
                    "" &&
                    userData &&
                    (userData.role === "Support" ||
                      userData.role === "owner") && (
                      <div className="singleTranslationNotesContainer">
                        <div className="singleTranslationNotesTitle">
                          <strong>Client's Revision Request</strong>
                        </div>
                        <div className="singleTranslationRichTextEditor">
                          <Editor
                            editorState={clientRevisionNotes}
                            onChange={(editorState) =>
                              setClientRevisionNotes(editorState)
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    )}
                  {(orderInfo.status === "support-review" ||
                    orderInfo.status === "user-review" ||
                    orderInfo.status === "completed") &&
                    translatorNotesClient.getCurrentContent().getPlainText() !==
                      "" &&
                    userData &&
                    (userData.role === "Support" ||
                      userData.role === "owner") && (
                      <div className="singleTranslationNotesContainer">
                        <div
                          className="singleTranslationNotesTitle"
                          style={{ backgroundColor: "#333" }}
                        >
                          <strong>
                            Translator Revision Notes (for Client)
                          </strong>
                        </div>
                        <div className="singleTranslationRichTextEditor">
                          <Editor
                            editorState={translatorNotesClient}
                            onChange={(translatorNotesClient) =>
                              setTranslatorNotesClient(translatorNotesClient)
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    )}
                </>
              </div>
            )}
          </div>
          <div className="singleRevisionDetailsSecondary">
            <div className="singleRevisionInformation">
              <p className="singleTranslationDecideTitle">
                Translation Information
              </p>
              {userData && userData.role === "Translator" ? (
                <div
                  className="singleTranslationStatusContainer"
                  style={{
                    backgroundColor:
                      orderInfo.status === "in-progress"
                        ? "#1491F8"
                        : orderInfo.status === "cancelled"
                        ? "#E1504A"
                        : "#17BC5B",
                  }}
                >
                  {orderInfo.status === "in-progress"
                    ? "IN PROGRESS"
                    : orderInfo.status === "cancelled"
                    ? "CANCELLED"
                    : "COMPLETED"}
                </div>
              ) : userData ? (
                <div
                  className="singleTranslationStatusContainer"
                  style={{
                    backgroundColor:
                      orderInfo.status === "new"
                        ? "#FF7C1D"
                        : orderInfo.status === "assigned"
                        ? "#61dbfb"
                        : orderInfo.status === "in-progress"
                        ? "#1491F8"
                        : orderInfo.status === "support-review"
                        ? "#e91e63"
                        : orderInfo.status === "user-review"
                        ? "#30d5c8"
                        : orderInfo.status === "revision"
                        ? "#333"
                        : orderInfo.status === "cancelled"
                        ? "#E1504A"
                        : "#17BC5B",
                  }}
                >
                  {orderInfo.status === "new"
                    ? "NEW ORDER"
                    : orderInfo.status === "assigned"
                    ? "ASSIGNED"
                    : orderInfo.status === "in-progress"
                    ? "IN PROGRESS"
                    : orderInfo.status === "support-review"
                    ? "SUPPORT"
                    : orderInfo.status === "user-review"
                    ? "CLIENT"
                    : orderInfo.status === "revision"
                    ? "REVISION"
                    : orderInfo.status === "cancelled"
                    ? "CANCELLED"
                    : "COMPLETED"}
                </div>
              ) : null}
              <p className="singleTranslationDetailsSecondaryText">
                <strong>
                  {
                    languages.find(
                      (lang) => lang.value === orderInfo.languageFrom
                    ).label
                  }
                </strong>
                {" to "}
                <strong>
                  {
                    languages.find(
                      (lang) => lang.value === orderInfo.languageTo
                    ).label
                  }
                </strong>
                <br />
                {orderInfo.certified
                  ? (orderInfo.pagesCount - 1) * 250 +
                    " - " +
                    orderInfo.pagesCount * 250 +
                    " est. words"
                  : orderInfo.wordCount + " est. words"}
              </p>
              <p className="singleTranslationDetailsSecondaryText">
                <strong>
                  {orderInfo.certified
                    ? "Certified Translation"
                    : "Standard Translation"}
                </strong>
                <br />
                {orderInfo.certified
                  ? "This is a word-for-word translation delivered as both a .docx and .pdf with a coversheet. Basic formatting to match the source document is required."
                  : "This is a standard translation delivered as a .docx only with no coversheet. Basic formatting is required."}
              </p>
              <div className="singleTranslationDetailsSecondarySeparator" />
              {orderInfo.status === "support-review" &&
                userData &&
                (userData.role === "Support" || userData.role === "owner") && (
                  <div className="singleTranslationDetailsSecondarySeparator" />
                )}
              {orderInfo.status === "in-progress" &&
                userData &&
                (userData.role === "Translator" ||
                  userData.role === "Support" ||
                  userData.role === "owner") && (
                  <AltAnimatedButton
                    space
                    color={"#17BC5B"}
                    text={completeTranslationButton}
                    disabled={
                      !(
                        userData &&
                        (userData.role === "Translator" ||
                          userData.role === "Support" ||
                          userData.role === "owner") &&
                        orderInfo.files.some((f) => f.type === "translation") &&
                        (!orderInfo.certified ||
                          (orderInfo.files.some(
                            (f) =>
                              f.type === "translation" &&
                              f.format === "application/pdf"
                          ) &&
                            orderInfo.files.some(
                              (f) =>
                                f.type === "translation" &&
                                f.format ===
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            )))
                      )
                    }
                    width={"100%"}
                    onClick={() => completeTranslation()}
                    backend
                    fontSize={
                      windowSize.width > 1024
                        ? 1
                        : windowSize.width > 767
                        ? windowSize.orientation === "portrait"
                          ? 1.9
                          : 1.2
                        : windowSize.orientation === "portrait"
                        ? 3.7
                        : 1.1
                    }
                  />
                )}
              <br />
              {orderInfo.status === "in-progress" &&
                userData &&
                (userData.role === "Support" || userData.role === "owner") && (
                  <AltAnimatedButton
                    space
                    color={"#E1504A"}
                    text={cancelTranslationButton}
                    width={"100%"}
                    onClick={() => cancelTranslation()}
                    backend
                    fontSize={
                      windowSize.width > 1024
                        ? 1
                        : windowSize.width > 767
                        ? windowSize.orientation === "portrait"
                          ? 1.9
                          : 1.2
                        : windowSize.orientation === "portrait"
                        ? 3.7
                        : 1.1
                    }
                  />
                )}
              {orderInfo.status === "support-review" &&
                userData &&
                (userData.role === "Support" || userData.role === "owner") && (
                  <>
                    <AltAnimatedButton
                      space
                      color={"#17BC5B"}
                      text={supportApproveButton}
                      width={"100%"}
                      onClick={() => supportApproveTranslation()}
                      backend
                      fontSize={
                        windowSize.width > 1024
                          ? 1
                          : windowSize.width > 767
                          ? windowSize.orientation === "portrait"
                            ? 1.9
                            : 1.2
                          : windowSize.orientation === "portrait"
                          ? 3.7
                          : 1.1
                      }
                    />
                    <div className="singleTranslationDetailsSecondarySeparator" />
                    <AltAnimatedButton
                      space
                      color={"#E1504A"}
                      text={supportRejectButton}
                      width={"100%"}
                      onClick={() => supportRejectTranslation()}
                      backend
                      fontSize={
                        windowSize.width > 1024
                          ? 1
                          : windowSize.width > 767
                          ? windowSize.orientation === "portrait"
                            ? 1.9
                            : 1.2
                          : windowSize.orientation === "portrait"
                          ? 3.7
                          : 1.1
                      }
                    />
                  </>
                )}
              {(orderInfo.status === "revision" ||
                orderInfo.status === "user-review") &&
                userData &&
                (userData.role === "Support" || userData.role === "owner") && (
                  <AltAnimatedButton
                    space
                    color={"#e91e63"}
                    text="Reopen Review"
                    width={"100%"}
                    onClick={() => reopenSupportReview()}
                    backend
                    fontSize={
                      windowSize.width > 1024
                        ? 1
                        : windowSize.width > 767
                        ? windowSize.orientation === "portrait"
                          ? 1.9
                          : 1.2
                        : windowSize.orientation === "portrait"
                        ? 3.7
                        : 1.1
                    }
                  />
                )}
              {orderInfo.status === "cancelled" &&
                userData &&
                (userData.role === "Support" || userData.role === "owner") && (
                  <AltAnimatedButton
                    space
                    color={"#E1504A"}
                    text="Reopen Order"
                    width={"100%"}
                    onClick={() => reviveOrder()}
                    backend
                    fontSize={
                      windowSize.width > 1024
                        ? 1
                        : windowSize.width > 767
                        ? windowSize.orientation === "portrait"
                          ? 1.9
                          : 1.2
                        : windowSize.orientation === "portrait"
                        ? 3.7
                        : 1.1
                    }
                  />
                )}
            </div>
            {userData &&
              userData.role !== "Translator" &&
              orderInfo &&
              (orderInfo.notarization || orderInfo.hardCopy) && (
                <div className="singleRevisionInformation">
                  <p className="singleTranslationDecideTitle">
                    Notarization & Hard Copy
                  </p>
                  {orderInfo.notarization && (
                    <p className="singleTranslationDetailsSecondaryText">
                      <strong>Notarization</strong>
                    </p>
                  )}
                  {orderInfo.notarization && (
                    <select
                      value={notarizationStatus}
                      onChange={(e) => setNotarizationStatus(e.target.value)}
                      className="notarizationStatusSelect"
                    >
                      <option key={"pending"} value={"pending"}>
                        {"Approval Pending"}
                      </option>
                      <option key={"queue"} value={"queue"}>
                        {"In the Queue"}
                      </option>
                      <option key={"completed"} value={"completed"}>
                        {"Completed"}
                      </option>
                    </select>
                  )}
                  {orderInfo.hardCopy && (
                    <p className="singleTranslationDetailsSecondaryText">
                      <strong>Hard Copy</strong>
                    </p>
                  )}
                  {orderInfo.hardCopy && (
                    <select
                      value={hardCopyStatus}
                      onChange={(e) => setHardCopyStatus(e.target.value)}
                      className="notarizationStatusSelect"
                    >
                      <option key={"pending"} value={"pending"}>
                        {"Approval Pending"}
                      </option>
                      <option key={"queue"} value={"queue"}>
                        {"In the Queue"}
                      </option>
                      <option key={"shipped"} value={"shipped"}>
                        {"Shipped"}
                      </option>
                    </select>
                  )}
                  {hardCopyStatus === "shipped" && (
                    <div
                      className="userLanguageFilterContainer"
                      style={{ display: "flex" }}
                    >
                      <input
                        type="text"
                        value={hardCopyTrackingID}
                        onChange={(e) => setHardCopyTrackingID(e.target.value)}
                        className="coversheetInput"
                        placeholder="Tracking ID (*)"
                      />
                    </div>
                  )}
                  <div
                    className="singleTranslationDiscountSubmitButtonContainer"
                    style={{ marginTop: 20 }}
                  >
                    <AltAnimatedButton
                      text="Save"
                      onClick={() => saveShippingInfo()}
                      backend
                      disabled={
                        notarizationStatus === "" ||
                        hardCopyStatus === "" ||
                        (hardCopyStatus === "shipped" &&
                          hardCopyTrackingID === "")
                      }
                      fontSize={
                        windowSize.width > 1024
                          ? 0.9
                          : windowSize.width > 767
                          ? windowSize.orientation === "portrait"
                            ? 1.7
                            : 1.1
                          : windowSize.orientation === "portrait"
                          ? 3.5
                          : 1
                      }
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
      ) : orderInfo && tab === "files" ? (
        <div className="singleTranslationFilesOuter">
          <div className="singleTranslationFilesMain">
            <p className="singleTranslationDecideTitle">Translation Files</p>
            <div className="singleTranslationFilesTableHeader">
              <div className="singleTranslationFilesTableHeaderColumn singleTranslationFilesTableHeaderNameColumn">
                Name
              </div>
              {/* {userData && userData.role !== "Translator" && (
                <div className="singleTranslationFilesTableHeaderColumn singleTranslationFilesTableHeaderCertificateColumn">
                  Certificate
                </div>
              )} */}
              <div
                className="singleTranslationFilesTableHeaderColumn singleTranslationFilesTableHeaderTypeColumn"
                style={{
                  display:
                    windowSize.width <= 767 &&
                    windowSize.orientation === "portrait"
                      ? "none"
                      : "flex",
                }}
              >
                Type
              </div>
            </div>
            {orderInfo.files.map((file) => (
              file.type === "coversheet" && reUploadingCoversheet === file.ref ? null :
              <div key={file.ref} className="singleTranslationFilesTableLine">
                <div className="singleTranslationFilesTableLineTextColumn singleTranslationFilesTableHeaderNameColumn">
                  <p
                    className="singleTranslationFilesTableLineText"
                    style={{
                      cursor:
                        orderInfo.status === "in-progress" ||
                        (userData &&
                          (userData.role === "Support" ||
                            userData.role === "owner"))
                          ? "pointer"
                          : "default",
                    }}
                    onClick={
                      orderInfo.status === "in-progress" ||
                      (userData &&
                        (userData.role === "Support" ||
                          userData.role === "owner"))
                        ? () => {
                            downloadFile(file.url);
                          }
                        : null
                    }
                  >
                    <strong>{file.name}</strong>
                    {file.size && file.timestamp && <br />}
                    <span style={{ opacity: 0.7 }}>
                      {file.size &&
                        file.timestamp &&
                        `Uploaded ${formatDate(file.timestamp)} - ${formatSize(
                          file.size
                        )}`}
                    </span>
                  </p>
                  {(file.type === "translation" ||
                    file.type === "revision" ||
                    file.type === "coversheet") &&
                    orderInfo.status === "in-progress" &&
                    userData &&
                    userData.role === "Translator" && (
                      <Trash2
                        className="fileUploaderLineIcon"
                        onClick={() => handleDeleteUploadedFile(file)}
                      />
                    )}
                  {userData &&
                    (userData.role === "owner" ||
                      userData.role === "Support") && (
                      <Trash2
                        className="fileUploaderLineIcon"
                        onClick={() => handleDeleteUploadedFile(file)}
                      />
                    )}
                </div>
                {/* {userData && userData.role !== "Translator" && (
                  <div className="singleTranslationFilesTableLineTextColumn singleTranslationFilesTableHeaderCertificateColumn">
                    {file.format === "application/pdf" && (
                      <select
                        value={file.certificateID}
                        onChange={(e) =>
                          handleChangeFileCertificateID(file, e.target.value)
                        }
                        className="userStatusSelect"
                      >
                        <option key="" value="">
                          None
                        </option>
                        {orderInfo.credentials &&
                        orderInfo.credentials.length > 0
                          ? orderInfo.credentials
                              .filter(
                                (credential) =>
                                  !orderInfo.files
                                    .filter((f) => f.ref !== file.ref)
                                    .some(
                                      (f) =>
                                        f.certificateID ===
                                        credential.certificateID
                                    )
                              )
                              .map((credential, index) => (
                                <option
                                  key={index}
                                  value={credential.certificateID}
                                >
                                  {credential.certificateID}
                                </option>
                              ))
                          : null}
                      </select>
                    )}
                  </div>
                )} */}
                <div
                  className="singleTranslationFilesTableLineColumn singleTranslationFilesTableHeaderTypeColumn"
                  style={{
                    display:
                      windowSize.width <= 767 &&
                      windowSize.orientation === "portrait"
                        ? "none"
                        : "flex",
                  }}
                >
                  {userData && userData.role === "Translator" ? (
                    capitalize(file.type)
                  ) : userData ? (
                    <select
                      value={file.type}
                      onChange={(e) =>
                        handleChangeFileType(file, e.target.value)
                      }
                      className="userStatusSelect"
                    >
                      <option key={"source"} value={"source"}>
                        {"Source"}
                      </option>
                      <option key={"reference"} value={"reference"}>
                        {"Reference"}
                      </option>
                      <option key={"support"} value={"support"}>
                        {"Support"}
                      </option>
                      <option key={"translation"} value={"translation"}>
                        {"Translation"}
                      </option>
                      <option key={"revision"} value={"revision"}>
                        {"Revision"}
                      </option>
                      <option key={"client"} value={"client"}>
                        {"Client"}
                      </option>
                      <option key={"coversheet"} value={"coversheet"}>
                        {"Coversheet"}
                      </option>
                    </select>
                  ) : null}
                </div>
              </div>
            ))}
            {files
              .filter(
                (f) =>
                  !orderInfo.files.some(
                    (mFile) => mFile.name === f.name && mFile.ref === f.ref
                  )
              )
              .map((file) => (
                <div key={file.ref} className="singleTranslationFilesTableLine">
                  <div className="singleTranslationFilesTableLineColumn singleTranslationFilesTableHeaderNameColumn">
                    <div className="fileUploaderLine">
                      <div className="fileUploaderLineInfo">
                        <p className="fileUploaderLineName">{file.name}</p>
                        <p className="fileUploaderLineSize">
                          {formatSize(file.file.size)}
                        </p>
                      </div>
                      {file.status === "uploading" ? (
                        <StopCircle
                          className="fileUploaderLineIcon"
                          onClick={() => handleDeleteFile(file)}
                        />
                      ) : (
                        <Trash2
                          className="fileUploaderLineIcon"
                          onClick={() => handleDeleteFile(file)}
                        />
                      )}
                    </div>
                    <div className="fileUploaderLineProgressContainer">
                      <animated.div
                        style={{
                          width: `${file.progress}%`,
                          borderColor:
                            file.status === "complete" ? "#4CAF50" : "#FF7C1D",
                        }}
                        className="fileUploaderLineProgress"
                      />
                      <p className="fileUploaderLineProgressText">
                        {file.status === "complete"
                          ? "Completed"
                          : file.status === "failed"
                          ? "Upload Failed."
                          : file.status === "deleting"
                          ? "Deleting..."
                          : "Uploading (" + file.progress + "%)"}
                      </p>
                    </div>
                  </div>
                  {userData && userData.role !== "Translator" && (
                    <div className="singleTranslationFilesTableLineTextColumn singleTranslationFilesTableHeaderCertificateColumn" />
                  )}
                  <div
                    className="singleTranslationFilesTableLineColumn singleTranslationFilesTableHeaderTypeColumn"
                    style={{
                      display:
                        windowSize.width <= 767 &&
                        windowSize.orientation === "portrait"
                          ? "none"
                          : "flex",
                    }}
                  >
                    {userData && userData.role === "Translator" ? (
                      capitalize(file.type)
                    ) : userData ? (
                      <select
                        value={file.type}
                        onChange={(e) =>
                          handleChangeFileType(file, e.target.value)
                        }
                        className="userStatusSelect"
                      >
                        <option key={"source"} value={"source"}>
                          {"Source"}
                        </option>
                        <option key={"reference"} value={"reference"}>
                          {"Reference"}
                        </option>
                        <option key={"support"} value={"support"}>
                          {"Support"}
                        </option>
                        <option key={"translation"} value={"translation"}>
                          {"Translation"}
                        </option>
                        <option key={"revision"} value={"revision"}>
                          {"Revision"}
                        </option>
                        <option key={"client"} value={"client"}>
                          {"Client"}
                        </option>
                        <option key={"coversheet"} value={"coversheet"}>
                          {"Coversheet"}
                        </option>
                      </select>
                    ) : null}
                  </div>
                </div>
              ))}
          </div>
          {((orderInfo.status === "in-progress" &&
            userData &&
            userData.role === "Translator") ||
            (userData &&
              (userData.role === "owner" || userData.role === "Support"))) && (
            <div className="singleTranslationFilesSecondary">
              <div className="singleTranslationFilesUpload">
                <p className="singleTranslationDecideTitle">Add Files</p>
                <p className="singleTranslationDetailsSecondaryText singleTranslationFilesUploadText">
                  Ensure that the file name is comprised of just the order
                  number and the document title. You must include both a .docx
                  and a .pdf of your translation.
                </p>
                <FileUploaderButton
                  text="Choose Files"
                  backend
                  files={files}
                  handleFiles={handleFiles}
                  handleDeleteFile={() => {}}
                  fontSize={
                    windowSize.width > 1024
                      ? 0.9
                      : windowSize.width > 767
                      ? windowSize.orientation === "portrait"
                        ? 1.7
                        : 1.1
                      : windowSize.orientation === "portrait"
                      ? 3.5
                      : 1
                  }
                />
              </div>
              {orderInfo.certified && (
                <div className="singleTranslationFilesCoversheet">
                  <p className="singleTranslationDecideTitle">
                    Generate Coversheet
                  </p>
                  <p className="singleTranslationDetailsSecondaryText singleTranslationFilesUploadText">
                    Generate a new coversheet with order details and document
                    name prefilled. This may take a couple of seconds.
                  </p>
                  <div
                    className="userLanguageFilterContainer"
                    style={{ display: "flex" }}
                  >
                    <input
                      type="text"
                      value={documentName}
                      onChange={(e) => setDocumentName(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          generateCoversheet();
                        }
                      }}
                      className="coversheetInput"
                      placeholder="Document Name (*)"
                    />
                  </div>
                  <AltAnimatedButton
                    text={coversheetButton}
                    onClick={() => generateCoversheet()}
                    backend
                    disabled={documentName === ""}
                    fontSize={
                      windowSize.width > 1024
                        ? 0.9
                        : windowSize.width > 767
                        ? windowSize.orientation === "portrait"
                          ? 1.7
                          : 1.1
                        : windowSize.orientation === "portrait"
                        ? 3.5
                        : 1
                    }
                  />
                </div>
              )}
            </div>
          )}
        </div>
      ) : orderInfo &&
        tab === "invoice" &&
        orderInfo.status !== "new" &&
        orderInfo.status !== "assigned" &&
        orderInfo.invoice ? (
        <div className="singleTranslationDetailsOuter">
          <div className="singleTranslationInvoiceMain">
            <div className="singleTranslationInvoiceTitleLine">
              <p className="singleTranslationDecideTitle">Invoice Details</p>
              {orderInfo.invoice &&
                ((orderInfo.invoice.status === "submitted" &&
                  userData &&
                  userData.role === "Translator") ||
                  (userData &&
                    orderInfo.invoice.status !== "created" &&
                    orderInfo.invoice.status !== "paid" &&
                    (userData.role === "Support" ||
                      userData.role === "owner"))) && (
                  <div
                    className="singleTranslationInvoiceEditButton"
                    onClick={() => toggleInvoiceEdit()}
                  >
                    {invoiceEdit ? "Cancel" : "Edit"}
                  </div>
                )}
            </div>
            <div className="singleTranslationFilesTableHeader">
              <div className="singleTranslationFilesTableHeaderColumn singleTranslationFilesTableHeaderNameColumnAlt">
                Description
              </div>
              <div className="singleTranslationFilesTableHeaderColumn singleTranslationFilesTableHeaderTypeColumn">
                Word Count
              </div>
              {orderInfo.invoice &&
                userData &&
                invoiceEdit &&
                invoiceLines.length > 0 &&
                (userData.role === "owner" ||
                  userData.role === "Support" ||
                  orderInfo.invoice.status === "created" ||
                  orderInfo.invoice.status === "submitted") && (
                  <div className="singleTranslationFilesTableHeaderColumn singleTranslationFilesTableHeaderButtonColumn"></div>
                )}
            </div>

            {orderInfo.invoice &&
            userData &&
            invoiceEdit &&
            invoiceLines.length > 0 &&
            (userData.role === "owner" ||
              userData.role === "Support" ||
              orderInfo.invoice.status === "created" ||
              orderInfo.invoice.status === "submitted") ? (
              invoiceLines.map((line) => (
                <div
                  key={line.id}
                  className="singleTranslationFilesTableHeader"
                  style={{ borderBottom: "1px solid #f5f5f5" }}
                >
                  <div className="singleTranslationFilesTableHeaderColumn singleTranslationFilesTableHeaderNameColumnAlt">
                    <select
                      value={line.filename}
                      onChange={(e) =>
                        handleChangeInvoiceLineName(e.target.value, line)
                      }
                      className="quoteDetailsSelect"
                    >
                      <option key="" value="" disabled>
                        Select a file
                      </option>
                      {orderInfo.files
                        .filter(
                          (f) =>
                            (f.type === "translation" ||
                              f.type === "revision") &&
                            (f.format ===
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                              f.format ===
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                        )
                        .map((file) => (
                          <option key={file.ref} value={file.name}>
                            {file.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="singleTranslationFilesTableHeaderColumn singleTranslationFilesTableHeaderTypeColumn">
                    <input
                      type="text"
                      value={line.wordCount}
                      onChange={(e) =>
                        handleChangeInvoiceLineCount(e.target.value, line)
                      }
                      className="coversheetInput"
                      placeholder=""
                      style={{ marginBottom: 0 }}
                    />
                  </div>
                  {((orderInfo.invoice &&
                    orderInfo.invoice.status !== "submitted") ||
                    invoiceEdit) && (
                    <div className="singleTranslationFilesTableHeaderColumn singleTranslationFilesTableHeaderButtonColumn">
                      <Trash2
                        className="fileUploaderLineIcon"
                        onClick={() => handleDeleteNewLine(line)}
                      />
                    </div>
                  )}
                </div>
              ))
            ) : orderInfo.invoice &&
              userData &&
              !invoiceEdit &&
              orderInfo.invoice.lines.length > 0 ? (
              orderInfo.invoice.lines.map((line) => (
                <div
                  key={line.id}
                  className="singleTranslationFilesTableHeader"
                  style={{ borderBottom: "1px solid #f5f5f5" }}
                >
                  <div className="singleTranslationFilesTableHeaderColumn singleTranslationFilesTableHeaderNameColumnAlt">
                    {line.filename}
                  </div>
                  <div className="singleTranslationFilesTableHeaderColumn singleTranslationFilesTableHeaderTypeColumn">
                    {line.wordCount}
                  </div>
                </div>
              ))
            ) : (orderInfo.invoice &&
                orderInfo.invoice.lines.length === 0 &&
                !invoiceEdit) ||
              (invoiceLines.length === 0 && invoiceEdit) ? (
              <div
                className="singleTranslationFilesTableHeader"
                style={{ borderBottom: "1px solid #f5f5f5" }}
              >
                <p className="singleTranslationNoLines">
                  No items, add a line to begin.
                </p>
              </div>
            ) : null}
            <div className="singleTranslationInvoiceTotals">
              {invoiceEdit ? (
                <div
                  className="singleTranslationInvoiceAddLineContainer"
                  onClick={() => addNewInvoiceLine()}
                >
                  <PlusCircle className="singleTranslationAddLineIcon" />
                  <p className="singleTranslationInvoiceAddLineText">
                    Add Line
                  </p>
                </div>
              ) : (
                <div />
              )}
              <div className="singleTranslationInvoiceTotalsInner">
                <div className="singleTranslationInvoiceTotalsTitle">
                  <p className="singleTranslationInvoiceTotalsTextTitle">
                    Words
                  </p>
                  <p className="singleTranslationInvoiceTotalsTextTitle">
                    <strong>Invoice Total</strong>
                  </p>
                </div>
                <div className="singleTranslationInvoiceTotalsText">
                  <p className="singleTranslationInvoiceTotalsTextTitle">
                    {(orderInfo.invoice && orderInfo.invoice !== "submitted") ||
                    invoiceEdit
                      ? invoiceLines.reduce(
                          (accum, curr) =>
                            curr.wordCount !== ""
                              ? accum + parseInt(curr.wordCount, 10)
                              : accum,
                          0
                        )
                      : orderInfo.invoice.lines.reduce(
                          (accum, curr) =>
                            curr.wordCount !== ""
                              ? accum + parseInt(curr.wordCount, 10)
                              : accum,
                          0
                        )}
                  </p>
                  <p className="singleTranslationInvoiceTotalsTextTitle">
                    <strong>
                      {(orderInfo.invoice &&
                        orderInfo.invoice !== "submitted") ||
                      invoiceEdit
                        ? "$" +
                          invoiceLines
                            .reduce(
                              (accum, curr) =>
                                curr.wordCount !== ""
                                  ? accum +
                                    parseInt(curr.wordCount, 10) *
                                      orderInfo.translator.orderRate
                                  : accum,
                              0
                            )
                            .toFixed(2)
                        : "$" +
                          orderInfo.invoice.lines
                            .reduce(
                              (accum, curr) =>
                                curr.wordCount !== ""
                                  ? accum +
                                    parseInt(curr.wordCount, 10) *
                                      orderInfo.translator.orderRate
                                  : accum,
                              0
                            )
                            .toFixed(2)}
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            {(invoiceMemo !== "" ||
              invoiceEdit ||
              (orderInfo.invoice &&
                orderInfo.invoice.status === "created")) && (
              <div className="singleTranslationInvoiceMemo">
                <p className="singleTranslationInvoiceMemoTitle">Memo</p>
                <textarea
                  className="singleTranslationInvoiceMemoTextArea"
                  placeholder=""
                  readOnly={
                    (orderInfo.invoice &&
                      orderInfo.invoice.status === "submitted" &&
                      !invoiceEdit) ||
                    (userData && userData.role !== "Translator") ||
                    orderInfo.invoice.status === "review" ||
                    orderInfo.invoice.status === "approved" ||
                    orderInfo.invoice.status === "paid"
                  }
                  value={invoiceMemo}
                  onChange={(e) => setInvoiceMemo(e.target.value)}
                />
              </div>
            )}
            {orderInfo &&
              userData &&
              orderInfo.invoice &&
              (invoiceEdit || orderInfo.invoice.status === "created") &&
              (userData.role === "owner" ||
                userData.role === "Support" ||
                orderInfo.invoice.status === "submitted" ||
                orderInfo.invoice.status === "created") && (
                <div className="singleTranslationInvoiceButtons">
                  {(userData.role === "owner" ||
                    userData.role === "Support" ||
                    orderInfo.invoice.status === "created") && (
                    <AltAnimatedButton
                      text={saveInvoiceButton}
                      onClick={() => saveInvoice("none")}
                      backend
                      disabled={
                        saveInvoiceButton !== "Save" ||
                        (submitInvoiceButton !== "Save and Submit" &&
                          submitInvoiceButton !== "Resubmit") ||
                        invoiceLines.length === 0 ||
                        !invoiceLines.some(
                          (l) =>
                            l.filename !== "" &&
                            l.wordCount !== "0" &&
                            l.wordCount !== ""
                        )
                      }
                      fontSize={
                        windowSize.width > 1024
                          ? 0.9
                          : windowSize.width > 767
                          ? windowSize.orientation === "portrait"
                            ? 1.7
                            : 1.1
                          : windowSize.orientation === "portrait"
                          ? 3.5
                          : 1
                      }
                    />
                  )}
                  <div className="singleTranslationInvoiceButtonsSeparator" />
                  {userData && userData.role === "Translator" && (
                    <AltAnimatedButton
                      text={submitInvoiceButton}
                      onClick={() => saveInvoice("submit")}
                      backend
                      disabled={
                        saveInvoiceButton !== "Save" ||
                        (submitInvoiceButton !== "Save and Submit" &&
                          submitInvoiceButton !== "Resubmit") ||
                        invoiceLines.length === 0 ||
                        !invoiceLines.some(
                          (l) =>
                            l.filename !== "" &&
                            l.wordCount !== "0" &&
                            l.wordCount !== ""
                        )
                      }
                      fontSize={
                        windowSize.width > 1024
                          ? 0.9
                          : windowSize.width > 767
                          ? windowSize.orientation === "portrait"
                            ? 1.7
                            : 1.1
                          : windowSize.orientation === "portrait"
                          ? 3.5
                          : 1
                      }
                    />
                  )}
                </div>
              )}
          </div>
          <div className="singleTranslationInvoiceSecondaryOuter">
            <div className="singleTranslationInvoiceSecondary">
              <p className="singleTranslationDecideTitle">
                {"Invoice #" + orderInfo.invoice.invoiceNumber}
              </p>
              <div
                className="singleTranslationStatusContainer"
                style={{
                  backgroundColor:
                    orderInfo.invoice?.status === "created"
                      ? "#FF7C1D"
                      : orderInfo.invoice?.status === "submitted"
                      ? "#AE5FD2"
                      : orderInfo.invoice?.status === "approved"
                      ? "#1491F8"
                      : orderInfo.invoice?.status === "review"
                      ? "#e91e63"
                      : orderInfo.invoice?.status === "paid"
                      ? "#17BC5B"
                      : "transparent",
                }}
              >
                {orderInfo.invoice?.status === "created"
                  ? "CREATED"
                  : orderInfo.invoice?.status === "submitted"
                  ? "SUBMITTED"
                  : orderInfo.invoice?.status === "approved"
                  ? "APPROVED"
                  : orderInfo.invoice?.status === "review"
                  ? "REVIEW"
                  : orderInfo.invoice?.status === "paid"
                  ? "PAID"
                  : ""}
              </div>
              <p className="singleTranslationDetailsSecondaryText">
                <strong>
                  {orderInfo.expedited ? "Expedited Rate" : "Standard Rate"}
                </strong>
                <br />
                {"$" + orderInfo.translator.orderRate + "0/word"}
              </p>
              {orderInfo.invoice && orderInfo.invoice.status !== "created" && (
                <p className="singleTranslationDetailsSecondaryText">
                  <strong>{"Submitted: "}</strong>
                  {formatDate(orderInfo.invoice.submitted)}
                </p>
              )}
              {orderInfo.invoice && orderInfo.invoice.status === "paid" && (
                <p
                  className="singleTranslationDetailsSecondaryText"
                  style={{ marginTop: 0 }}
                >
                  <strong>{"Payment Sent: "}</strong>
                  {formatDate(orderInfo.invoice.paidAt)}
                </p>
              )}

              {userData &&
                (userData.role === "Support" || userData.role === "owner") &&
                orderInfo &&
                orderInfo.invoice &&
                (orderInfo.invoice.status === "submitted" ||
                  orderInfo.invoice.status === "review") && (
                  <div className="singleTranslationDetailsSecondarySeparator" />
                )}
              {userData &&
                (userData.role === "Support" || userData.role === "owner") &&
                orderInfo &&
                orderInfo.invoice &&
                (orderInfo.invoice.status === "submitted" ||
                  orderInfo.invoice.status === "review") && (
                  <AltAnimatedButton
                    space
                    color={"#17BC5B"}
                    text="Approve Invoice"
                    width={"100%"}
                    onClick={() => approveInvoice()}
                    backend
                    fontSize={
                      windowSize.width > 1024
                        ? 1
                        : windowSize.width > 767
                        ? windowSize.orientation === "portrait"
                          ? 1.9
                          : 1.2
                        : windowSize.orientation === "portrait"
                        ? 3.7
                        : 1.1
                    }
                  />
                )}
              {userData &&
                (userData.role === "Support" || userData.role === "owner") &&
                orderInfo &&
                orderInfo.invoice &&
                orderInfo.invoice.status === "submitted" && (
                  <div className="singleTranslationDetailsSecondarySeparator" />
                )}
              {userData &&
                (userData.role === "Support" || userData.role === "owner") &&
                orderInfo &&
                orderInfo.invoice &&
                orderInfo.invoice.status === "submitted" && (
                  <AltAnimatedButton
                    space
                    color={"#e91e63"}
                    text="Review Invoice"
                    width={"100%"}
                    onClick={() => reviewInvoice()}
                    backend
                    fontSize={
                      windowSize.width > 1024
                        ? 1
                        : windowSize.width > 767
                        ? windowSize.orientation === "portrait"
                          ? 1.9
                          : 1.2
                        : windowSize.orientation === "portrait"
                        ? 3.7
                        : 1.1
                    }
                  />
                )}
              {userData &&
                (userData.role === "Support" || userData.role === "owner") &&
                orderInfo &&
                orderInfo.invoice &&
                orderInfo.invoice.status === "approved" && (
                  <div className="singleTranslationDetailsSecondarySeparator" />
                )}
              {userData &&
                (userData.role === "Support" || userData.role === "owner") &&
                orderInfo &&
                orderInfo.invoice &&
                orderInfo.invoice.status === "approved" && (
                  <AltAnimatedButton
                    space
                    color={"#e91e63"}
                    text="Reopen Review"
                    width={"100%"}
                    onClick={() => reopenInvoiceReview()}
                    backend
                    fontSize={
                      windowSize.width > 1024
                        ? 1
                        : windowSize.width > 767
                        ? windowSize.orientation === "portrait"
                          ? 1.9
                          : 1.2
                        : windowSize.orientation === "portrait"
                        ? 3.7
                        : 1.1
                    }
                  />
                )}
            </div>
            {orderInfo &&
              orderInfo.invoice &&
              userData &&
              ((userData.role === "Translator" &&
                orderInfo.invoice.invoiceReview) ||
                ((userData.role === "Support" || userData.role === "owner") &&
                  (orderInfo.invoice.invoiceReview ||
                    orderInfo.invoice.status === "review"))) && (
                <div className="singleTranslationInvoiceSecondary">
                  <p className="singleTranslationInvoiceMemoTitle">
                    Invoice Review
                  </p>
                  {userData && userData.role !== "Translator" && (
                    <p className="singleTranslationDetailsSecondaryText singleTranslationInvoiceReviewText">
                      Leave a message for the translator about the review of
                      this invoice.
                    </p>
                  )}
                  <textarea
                    className="singleTranslationInvoiceMemoTextArea singleTranslationInvoiceReviewMemoArea"
                    placeholder=""
                    readOnly={
                      !userData ||
                      !orderInfo ||
                      userData.role === "Translator" ||
                      orderInfo.invoice.status !== "review"
                    }
                    value={invoiceReview}
                    onChange={(e) => setInvoiceReview(e.target.value)}
                  />
                  {userData &&
                    (userData.role === "Support" ||
                      userData.role === "owner") &&
                    orderInfo &&
                    orderInfo.invoice &&
                    orderInfo.invoice.status === "review" && (
                      <AltAnimatedButton
                        color={"#FF7C1D"}
                        text={invoiceReviewSaveButton}
                        onClick={() => saveInvoiceReview()}
                        disabled={invoiceReview === ""}
                        backend
                        fontSize={
                          windowSize.width > 1024
                            ? 0.9
                            : windowSize.width > 767
                            ? windowSize.orientation === "portrait"
                              ? 1.7
                              : 1.1
                            : windowSize.orientation === "portrait"
                            ? 3.3
                            : 1
                        }
                      />
                    )}
                </div>
              )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SingleTranslation;
