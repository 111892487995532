import React, { useState, useContext, useEffect } from 'react';
import { Editor, EditorState, RichUtils, convertToRaw } from 'draft-js';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useSpring, animated } from 'react-spring';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

import { User, Plus, Mail, FileText, Lock, Clock, Globe, List, CreditCard, Check, Bold, Underline, Italic, ArrowLeft, HelpCircle } from 'react-feather';

import { FormTextInput } from '../../Components/FormTextInput';
import { AltAnimatedButton } from '../../Components/AltAnimatedButton';

import { languages } from '../../Data/languages';
import { countries } from '../../Data/countries';

import { useScreenTransition } from '../../Hooks/useScreenTransition';

import { AuthContext } from '../../Context/AuthContext';
import { NotificationContext } from '../../Context/NotificationContext';
import { DataContext } from '../../Context/DataContext';

import './OrderPayment.css';
import { AnimatedButton } from '../../Components/AnimatedButton';
import TopBar from '../../Components/TopBar';

const OrderPayment = ({ windowSize }) => {

  const { transitionTo } = useScreenTransition();
  const { showNotification } = useContext(NotificationContext);

  const { signedIn } = useContext(AuthContext);

  const { order, setOrder, userData } = useContext(DataContext);

  const [recipient, setRecipient] = useState('');
  const [street, setStreet] = useState('');
  const [apartment, setApartment] = useState('');
  const [city, setCity] = useState('');
  const [region, setRegion] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('');

  const [reference, setReference] = useState('');
  const [discountCode, setDiscountCode] = useState('');
  const [appliedCode, setAppliedCode] = useState('');
  const [discount, setDiscount] = useState(0);

  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());

  const [saveAddress, setSaveAddress] = useState(false);
  const [saveDefault, setSaveDefault] = useState(false);

  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);

  const [applingDiscount, setApplingDiscount] = useState(false);

  const [orderSubmitButton, setOrderSubmitButton] = useState('Place Order');
  const statusOverlayProps = useSpring({ height: windowSize.height, opacity: orderSubmitButton !== 'Place Order' ? 1 : 0, pointerEvents: orderSubmitButton !== 'Place Order' ? 'all' : 'none' });
  const discountOverlayProps = useSpring({ height: windowSize.height, opacity: applingDiscount ? 1 : 0, pointerEvents: applingDiscount ? 'all' : 'none' });

  useEffect(() => {
    if (order.parsed && (order.name === '' || order.email === '') && !signedIn) {
      transitionTo('/order/contact');
    } else if (order.parsed && (order.orderID === '' || !order.languageFrom || order.languageFrom === '' || !order.languageTo || order.languageTo === '' || !order.files || order.files.length === 0)) {
      transitionTo('/order/documents');
    } else if (order.parsed && !order.optionsSet) {
      transitionTo('/order/options');
    }

    if (order && order.hardCopy) setCountry(order.hardCopyExtra.country || '');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, signedIn]);

  useEffect(() => {
    setSelectedAddressIndex(signedIn && userData && userData.shipping && userData.shipping.length > 0 ? (userData.shipping.find(address => address.default) ? (userData.shipping.find(address => address.default).country === country ? userData.shipping.findIndex(address => address.default) : -1) : 0) : 0);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedIn, userData, country])

  useEffect(() => {
    if ((windowSize.width > 767 || windowSize.orientation === 'landscape') && document.getElementById('mainPrimary') && document.getElementById('mainSecondary')) {
      if (document.getElementById('mainPrimary').clientHeight > document.getElementById('mainSecondary').clientHeight) {
        document.getElementById('mainSecondary').style.minHeight = `${document.getElementById('mainPrimary').clientHeight}px`;
      } else {
        document.getElementById('mainPrimary').style.minHeight = `${document.getElementById('mainSecondary').clientHeight}px`;
      }    
    }
  }, [windowSize]);

  useEffect(()=> {
    if(userData && userData.hasTeam){
      const firestore = firebase.firestore();

      firestore.collection("teams").doc(userData.teamId).get().then(doc => {
        if(doc.exists && doc.data().discount && doc.data().discount > 0){
          setDiscount(doc.data().discount)
        }
      })
    }
  }, [userData])

  const editor = React.useRef(null);

  function focusEditor() {
    editor.current.focus();
  }

  const handlePlaceOrder = async paypalID => {
    setOrderSubmitButton('Placing order...');

    const now = new Date();

    const firestore = firebase.firestore();

    const addressID = uuidv4();

    await firestore.collection('orders').doc(order.orderID)
      .update({
        notes: convertToRaw(editorState.getCurrentContent()),
        timestamp: now.getTime(),
        status: 'new',
        shippingInfo: order.hardCopy ? (!(signedIn && userData && userData.shipping && userData.shipping.length > 0) || selectedAddressIndex === -1 ? { id: addressID, recipient: recipient, street: street, apartment: apartment, city: city, region: region, zipCode: zipCode, country: country } : userData.shipping[selectedAddressIndex]) : null,
        paypalOrderID: paypalID,
        discountApplied: discount > 0,
        discount: discount,
        reference: reference,
        referenceSearch: reference.toLowerCase(),
      })
      .then(async() => {
        if ((!(signedIn && userData && userData.shipping && userData.shipping.length > 0) || selectedAddressIndex === -1) && saveAddress) {
          const address = { id: addressID, recipient: recipient, street: street, apartment: apartment, city: city, region: region, zipCode: zipCode, country: country, default: saveDefault };

          const mShipping = userData.shipping ? (saveDefault ? userData.shipping.map(address => ({ ...address, default: false })).concat([address]) : userData.shipping.concat([address])) : [address];

          await firestore.collection('users').doc(firebase.auth().currentUser.uid)
            .update({
              shipping: mShipping
            })
            .then(() => {
            })
            .catch(error => {
              showNotification('Error', "An error ocurred while saving the address. Unfortunately, the address couldn't be saved.", 'error');
            })
        }

        if (appliedCode !== '') {
          axios({
            method:'post',
            url: "https://us-central1-project-everbary.cloudfunctions.net/updateCouponUsage",
            data: {
              code: appliedCode,
            }
          })
          .catch(() => {});
        }

        setOrderSubmitButton('Place Order');
        localStorage.setItem('order', JSON.stringify({ orderID: '', name: '', email: '', parsed: true }));
        setOrder({ orderID: '', name: '', email: '', parsed: true });
        transitionTo('/account/order/' + order.orderID);
      })
      .catch(error => {
        //console.log(error);
        setOrderSubmitButton('Place Order');
        showNotification('Error', "An error ocurred while finishing the order. Please, try again.", 'error');
      })
  }

  const handleClickContact = () => {
    if (!signedIn) {
      transitionTo('/order/contact');
    }
  }

  const handleClickDocuments = () => {
    transitionTo('/order/documents');
  }

  const handleClickOptions = () => {
    transitionTo('/order/options');
  }

  const getLanguage = (language) => {
    if (languages.find(lang => lang.value === language)) {
      return languages.find(lang => lang.value === language).label;
    } else {
      return '';
    }
  }

  const getExpedited = () => {
    
    let total = 0;

    if (order && order.certified) {
      if (order.pagesCount !== '') {
        total = total + parseInt(order.pagesCount, 10) * 24.95;
      } else {
        total = 0;
      }
    } else if (order) {
      if (order.wordCount !== '') {
        total = total + parseInt(order.wordCount, 10) * 0.1;
      } else {
        total = 0;
      }
    }

    return total * 0.4;
  }

  const totalNumber = () => {
    let total = 0;

    if (order && order.certified) {
      if (order.pagesCount !== '') {
        total = total + parseInt(order.pagesCount, 10) * 24.95;
      } else {
        total = 0;
      }
    } else if (order) {
      if (order.wordCount !== '') {
        total = total + parseInt(order.wordCount, 10) * 0.1;
      } else {
        total = 0;
      }
    }

    if (order && order.expedited) {
      total = total + getExpedited();
    }

    if (order && order.notarization) {
      total = total + 34.90;
    }

    if (order && order.hardCopy) {
      if (['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(order.hardCopyExtra.country)) {
        if (order.hardCopyExtra.method === 'priority') {
          total = total + 19.95;
        } else {
          total = total + 37.95;
        }
      } else {
        if (order.hardCopyExtra.method === 'priority') {
          total = total + 49.95;
        } else {
          total = total + 78.95;
        }
      }
    }

    if (order && order.hardCopy && order.hardCopyExtra.requireSignature) {
      total = total + 4.99;
    }

    return total;
  }

  const formatTotal = value => {
    return '$' + value.toFixed(2);
  }

  const getTimeFrame = () => {

    let limits = [0, 750];

    for (let i = 2; i < 200; i++) {
      limits.push(limits[limits.length - 1] + 1500 + (i-2) * 250);
    }

    let count = 0;

    if (order.certified && order.pagesCount !== '') {
      count = order.pagesCount * 250;
    } else if (!order.certified && order.wordCount !== '') {
      count = order.wordCount;
    };

    const ceiling = limits.filter(limit => count <= limit).sort((a,b) => a - b)[0];
    let ceilingIndex = limits.findIndex(limit => limit === ceiling);

    if (order.expedited) {
      ceilingIndex = ceilingIndex / 2;
    };

    if (ceilingIndex > 0.5) {
      ceilingIndex = Math.ceil(ceilingIndex);
    }

    if (ceilingIndex <= 2) {
      return `${ceilingIndex * 24} hours`;
    } else {
      return `${ceilingIndex - 1}-${ceilingIndex} days`;
    }

  }

  const handleBoldClick = () => {
    setEditorState(editorState => RichUtils.toggleInlineStyle(editorState, 'BOLD'))
  }

  const handleItalicClick = () => {
    setEditorState(editorState => RichUtils.toggleInlineStyle(editorState, 'ITALIC'))
  }

  const handleUnderlineClick = () => {
    setEditorState(editorState => RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'))
  }

  const toggleUL = () => {
    setEditorState(editorState => RichUtils.toggleBlockType(editorState,'unordered-list-item'))
  }

  const handleSelectAddressOption = index => {
    if (userData.shipping[index].country === order.hardCopyExtra.country) {
      setSelectedAddressIndex(index)
    } else {
      showNotification('Error', "This address doesn't belong to the country set for delivery in your order. Please, use another address or change your order's delivery country.", 'error');
    }
  }

  const createOrder = (data, actions) => {
    return actions.order.create({
      intent: 'CAPTURE',
      purchase_units: [
        {
          description: `Your Order`,
          amount: {
            currency_code: "USD",
            value: (totalNumber() - getDiscount()).toFixed(2)
          },
          reference_id: order.orderID
        }
      ],
      application_context: {
        brand_name: 'eVerbary',
        landing_page: 'NO_PREFERENCE',
        user_action: 'PAY_NOW',
        payment_method: {
          payee_preferred: 'IMMEDIATE_PAYMENT_REQUIRED'
        }
      }
    });
  };

  const onOrderApprove = (data, actions) => {
    actions.order.capture().then(details => {
      setOrderSubmitButton('Placing order...');
      handlePlaceOrder(data.orderID);
    });
  };

  const onOrderPaymentFailure = (data, actions) => {
    showNotification('Error', "An error ocurred while placing the order. Please, try again.", 'error');
  }

  const getDiscount = () => {
    if (discount === 0) {
      return 0;
    } else {
      let total = 0;

      if (order && order.certified) {
        if (order.pagesCount !== '') {
          total = total + parseInt(order.pagesCount, 10) * 24.95;
        } else {
          total = 0;
        }
      } else if (order) {
        if (order.wordCount !== '') {
          total = total + parseInt(order.wordCount, 10) * 0.1;
        } else {
          total = 0;
        }
      }
      return total * discount / 100;
      // return totalNumber() * discount / 100;
    }
  }

  const formatDiscount = value => {
    // console.log(value.toFixed(2));
    return '-$' + value.toFixed(2);
  }

  const handleApplyCode = () => {
    setApplingDiscount(true);
    const firestore = firebase.firestore();
    try{
    firestore.collection('coupons').where('code', '==', discountCode).get()
      .then((querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          const doc = querySnapshot.docs[0];

          if (doc.data().uses > 0) {
            setDiscount(doc.data().discount);
            setAppliedCode(discountCode);
            // showNotification('Discount applied', "The discount was successfully applied", 'success');
          } else {
            showNotification('Error', "That code is no longer valid. Please, try another one.", 'error');
          }
        } else {
          showNotification('Error', "That code doesn't exist. Please, try another one.", 'error');
        }
      })}
      catch(e){
        showNotification('Error', "There was an error applying the code. Please, try again.", 'error');
      }
      finally{
        setTimeout(()=> {setApplingDiscount(false)}, 500);
      }
  }

  const phoneScreen = windowSize.orientation === 'portrait' && windowSize.width <= 767; 

  return (
    <>
      <animated.div style={statusOverlayProps} className="checkoutStatusOverlay">
        We are finishing up the order and redirecting you. This will take a second.
      </animated.div>
      <animated.div style={discountOverlayProps} className="checkoutStatusOverlay">
        Applying Discount, Please wait...
      </animated.div>

      <TopBar/>

      <div className="quoteContainer">
        <div className="quoteHeader">
          <img src={require('../../assets/logoAlt.png')} alt="Logo eVerbary" className="quoteLogo" onClick={(e) => transitionTo('/',e)} />
          <div className="quoteHeaderExtra">
            <div className="quoteHeaderExtraBlock" onClick={handleClickContact} style={{ opacity: 0.5, cursor: !signedIn ? 'pointer' : 'default' }}>
              <User className="quoteHeaderIcon" />
              <p className="quoteHeaderExtraBlockText" style={{ display: phoneScreen ? 'none' : 'flex' }}>Contact</p>
            </div>
            <div className="orderHeaderConnector" />
            <div className="quoteHeaderExtraBlock" onClick={handleClickDocuments} style={{ opacity: 0.5 }}>
              <FileText className="quoteHeaderIcon" />
              <p className="quoteHeaderExtraBlockText" style={{ display: phoneScreen ? 'none' : 'flex' }}>Documents</p>
            </div>
            <div className="orderHeaderConnector" />
            <div className="quoteHeaderExtraBlock" onClick={handleClickOptions} style={{ opacity: 0.5 }}>
              <List className="quoteHeaderIcon" />
              <p className="quoteHeaderExtraBlockText" style={{ display: phoneScreen ? 'none' : 'flex' }}>Options</p>
            </div>
            <div className="orderHeaderConnector" />
            <div className="quoteHeaderExtraBlock">
              <CreditCard className="quoteHeaderIcon" />
              <p className="quoteHeaderExtraBlockText" style={{ display: phoneScreen ? 'none' : 'flex' }}>Payment</p>
            </div>
          </div>
        </div>
        <div className="quoteMainContainer">
          <div id="mainPrimary" className="quoteMainPrimaryContainer">
            <div className="quotePrimaryInner">
              {order.hardCopy ? 
                <>
                  <h3 className="quotePrimaryTitle">Shipping Address</h3>
                  { signedIn && userData && userData.shipping && userData.shipping.length > 0
                  ? <div className="orderAddressSelector">
                      { userData.shipping.map((address, index) => 
                        <div className="quoteOptionalContainer" onClick={() => handleSelectAddressOption(index)} style={{ borderColor: index === selectedAddressIndex ? '#FF7C1D' : 'rgba(102,102,102,0.1)' }}>
                          <div className="orderAddressOptionalInner">
                            <Mail className="orderAddressBigIcon" />
                            <div className="orderAddressInfo">
                              <p className="orderAddressInfoTitle">{address.recipient}</p>
                              <p className="orderAddressInfoText">{address.street + " " + address.apartment + ', ' + address.city + ', ' + address.region + ', ' + address.zipCode + ', ' + address.country}</p>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="quoteOptionalContainer" style={{ borderColor: selectedAddressIndex === -1 ? '#FF7C1D' : 'rgba(102,102,102,0.1)' }}>
                        {selectedAddressIndex === -1
                        ? <div className="orderAddressOptionalInnerForm">
                            <div className="quoteDetailsInputsContainer">
                              <FormTextInput placeholder="Recipient (*)" value={recipient} onChange={setRecipient} color='#666' />
                            </div>
                            <div className="quoteDetailsInputsContainer">
                              <FormTextInput placeholder="Street (*)" value={street} onChange={setStreet} color='#666' />
                            </div>
                            <div className="quoteDetailsInputsContainer">
                              <FormTextInput placeholder="Apartment, Suite or Floor Number" value={apartment} onChange={setApartment} color='#666' />
                            </div>
                            <div className="quoteDetailsInputsContainer">
                              <div className="orderPaymentTripleLine">
                                <div className="orderPaymentTripleField">
                                  <FormTextInput placeholder="City (*)" value={city} onChange={setCity} color='#666' />
                                </div>
                                <div className="orderPaymentTripleField">
                                  <FormTextInput placeholder={country === 'United States' ? "State (*)" : "Region (*)"} value={region} onChange={setRegion} color='#666' />
                                </div>
                                <div className="orderPaymentTripleField">
                                  <FormTextInput placeholder="Postal Code (*)" value={zipCode} onChange={setZipCode} color='#666' />
                                </div>
                              </div>
                            </div>
                            <div className="quoteDetailsInputsContainer">
                              <select value={country} onChange={(e) => setCountry(e.target.value)} className="quoteDetailsSelect" disabled>
                                <option key="" value="" disabled>Country (*)</option>
                                {countries.map(country => 
                                  <option key={country} value={country}>{country}</option>
                                )}
                              </select>
                            </div>
                            {signedIn && <div className="hardCopyCheckboxLine" onClick={() => setSaveAddress(sa => !sa)}>
                              <div className="hardCopyCheckbox">
                                <Check className="hardCopyCheck" style={{ opacity: saveAddress ? 1 : 0 }} />
                              </div>
                              <p className="hardCopyCheckboxText">Save address</p>
                            </div>}
                            { signedIn && saveAddress &&
                              <div className="hardCopyCheckboxLine" onClick={() => setSaveDefault(ss => !ss)}>
                                <div className="hardCopyCheckbox">
                                  <Check className="hardCopyCheck" style={{ opacity: saveDefault ? 1 : 0 }} />
                                </div>
                                <p className="hardCopyCheckboxText">Set as default</p>
                              </div>
                            }
                          </div>
                        : <div className="orderAddressOptionalInner">
                            <Plus className="orderAddressOptionalNewAddressIcon" />
                            <p className="orderAddressOptionalNewAddress" onClick={() => setSelectedAddressIndex(-1)}>Use a new address</p>
                          </div>
                        }
                      </div>
                    </div>
                  : <>
                      <div className="quoteDetailsInputsContainer">
                        <FormTextInput placeholder="Recipient (*)" value={recipient} onChange={setRecipient} color='#666' />
                      </div>
                      <div className="quoteDetailsInputsContainer">
                        <FormTextInput placeholder="Street (*)" value={street} onChange={setStreet} color='#666' />
                      </div>
                      <div className="quoteDetailsInputsContainer">
                        <FormTextInput placeholder="Apartment, Suite or Floor Number" value={apartment} onChange={setApartment} color='#666' />
                      </div>
                      <div className="quoteDetailsInputsContainer">
                        <div className="orderPaymentTripleLine">
                          <div className="orderPaymentTripleField">
                            <FormTextInput placeholder="City (*)" value={city} onChange={setCity} color='#666' />
                          </div>
                          <div className="orderPaymentTripleField">
                            <FormTextInput placeholder={country === 'United States' ? "State (*)" : "Region (*)"} value={region} onChange={setRegion} color='#666' />
                          </div>
                          <div className="orderPaymentTripleField">
                            <FormTextInput placeholder="Postal Code (*)" value={zipCode} onChange={setZipCode} color='#666' />
                          </div>
                        </div>
                      </div>
                      <div className="quoteDetailsInputsContainer">
                        <select value={country} onChange={(e) => setCountry(e.target.value)} className="quoteDetailsSelect" disabled>
                          <option key="" value="" disabled>Country (*)</option>
                          {countries.map(country => 
                            <option key={country} value={country}>{country}</option>
                          )}
                        </select>
                      </div>
                      {signedIn && <div className="hardCopyCheckboxLine" onClick={() => setSaveAddress(sa => !sa)}>
                        <div className="hardCopyCheckbox">
                          <Check className="hardCopyCheck" style={{ opacity: saveAddress ? 1 : 0 }} />
                        </div>
                        <p className="hardCopyCheckboxText">Save address</p>
                      </div>}
                      { signedIn && saveAddress &&
                        <div className="hardCopyCheckboxLine" onClick={() => setSaveDefault(ss => !ss)}>
                          <div className="hardCopyCheckbox">
                            <Check className="hardCopyCheck" style={{ opacity: saveDefault ? 1 : 0 }} />
                          </div>
                          <p className="hardCopyCheckboxText">Set as default</p>
                        </div>
                      }
                    </>
                  }
                </> 
              : null}

              <h3 className="quotePrimaryTitle">Notes</h3>
              <p className="quoteSmallMessage">Please include any notes your translation team might need including the preferred spelling for all people and places.</p>
              <div className="quoteRichTextButtons">
                <Bold className="quoteRichTextButton" onClick={handleBoldClick} />
                <Italic className="quoteRichTextButton" onClick={handleItalicClick} />
                <Underline className="quoteRichTextButton" onClick={handleUnderlineClick} />
                <List className="quoteRichTextButton" onClick={toggleUL} />
              </div>
              <div className="quoteRichTextEditor" onClick={focusEditor}>
                <Editor
                  ref={editor}
                  editorState={editorState}
                  onChange={editorState => setEditorState(editorState)}
                />
              </div>

              <h3 className="quotePrimaryTitle">Your Reference</h3>
              <p className="quoteSmallMessage">If submitted, the reference field will be included in emails, in translations, and in your invoice.</p>
              <FormTextInput placeholder="Your Reference" value={reference} onChange={setReference} color='#666' />
              

              <p className="quotePrimaryTitle">Apply Discount Code</p>
              <div className="orderCountContainer" style={{ marginBottom: '3vw' }}>
                <div className="orderCountInputContainer" style={{ flex: 1, marginRight: '5%' }}>
                  <FormTextInput placeholder="Discount Code" value={discountCode} onChange={setDiscountCode} color='#666' />
                </div>
                <AltAnimatedButton text="Apply" onClick={handleApplyCode} fontSize={windowSize.width > 1024 ? 1.1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 2.4 : 1.5) : (windowSize.orientation === 'portrait' ? 5 : 1.2)} />
              </div>

              {!phoneScreen && 
                <div className="quoteSubmitButtonContainer">
                  <div className="quoteGoBackButton" onClick={(e) => transitionTo('/order/options',e)}>
                    <ArrowLeft className="quoteGoBackIcon" />
                  </div>
                {signedIn && userData && userData.hasTeam && 
                <>
                <AnimatedButton text="Pay Later" onClick={()=> {handlePlaceOrder("pay-later")}} fontSize={windowSize.width > 1024 ? 1.3 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.8 : 1.5) : (windowSize.orientation === 'portrait' ? 5 : 1.8)}/>
                <p style={{margin:"1rem"}}>OR</p>
                </>}
                  <div style={(!signedIn && !order.name) || order.languageFrom === '' || !order.optionsSet || (order.hardCopy && (!(signedIn && userData && userData.shipping && userData.shipping.length > 0) || selectedAddressIndex === -1) && ( recipient === '' || street === '' || city === '' || region === '' || zipCode === '' || country === '' )) ? { opacity: 0.4, pointerEvents: 'none' } : { opacity: 1, pointerEvents: 'all' }}>
                    <PayPalButtons
                      style={windowSize.width > 1024 ? { layout: 'vertical', height: 40 } : { layout: 'vertical', height: 35 }}
                      createOrder={createOrder}
                      onApprove={onOrderApprove}
                      onError={onOrderPaymentFailure}
                      onShippingChange={function noRef() {}}
                    />
                  </div>
                </div>
              }
            </div>
          </div>
          <div id="mainSecondary" className="quoteMainSecondaryContainer">
            <div className="orderSummary">
              <h3 className="orderSummaryTitle">SUMMARY</h3>
              <div className="orderSummaryInfo">
                <div className="orderSummaryInfoMain">
                  <p className="orderSummaryInfoType">{order.certified ? "Certified Translation" : "Standard Translation"}</p>
                  <p className="orderSummaryInfoCount">{order.certified ? (order.pagesCount !== '' ? order.pagesCount + " pages (" + (parseInt(order.pagesCount, 10) * 250) + " words max)" : "0 pages (0 words max)") : (order.wordCount !== '' ? order.wordCount + " words" : "0 words")}</p>
                </div>
                <p className="orderSummaryInfoType">{order.certified ? (order.pagesCount !== "" ? '$' + (parseInt(order.pagesCount, 10) * 24.95).toFixed(2) : "$0.00") : ((order.wordCount !== '' ? '$' + (parseInt(order.wordCount, 10) * 0.1).toFixed(2) : '$0.00'))}</p>
              </div>
              {order.notarization ? 
              <div className="orderSummaryInfo">
                <div className="orderSummaryInfoMain">
                  <p className="orderSummaryInfoType">Notarization</p>
                  <p className="orderSummaryInfoCount">Valid in all 50 states</p>
                </div>
                <p className="orderSummaryInfoType">$34.90</p>
              </div> : null}
              {order.expedited ? 
              <div className="orderSummaryInfo">
                <div className="orderSummaryInfoMain">
                  <p className="orderSummaryInfoType">Expedited Turnaround</p>
                  <p className="orderSummaryInfoCount">Turnaround reduced by 50%</p>
                </div>
                <p className="orderSummaryInfoType">{formatTotal(getExpedited())}</p>
              </div> : null}
              {order.hardCopy && order.hardCopyExtra.country !== '' && order.hardCopyExtra.method !== '' ? 
              <div className="orderSummaryInfo">
                <div className="orderSummaryInfoMain">
                  <p className="orderSummaryInfoType">{`USPS ${order.hardCopyExtra.method.charAt(0).toUpperCase() + order.hardCopyExtra.method.slice(1)} Mail ${!['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(order.hardCopyExtra.country) ? 'International' : ''}`}</p>
                  <p className="orderSummaryInfoCount">{`${['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(order.hardCopyExtra.country) ? (order.hardCopyExtra.method === 'priority' ? '2-3' : '1-2') : (order.hardCopyExtra.method === 'priority' ? '6-10' : '3-5')} days in transit`}</p>
                </div>
                <p className="orderSummaryInfoType">{`$${['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(order.hardCopyExtra.country) ? (order.hardCopyExtra.method === 'priority' ? '19.95' : '37.95') : (order.hardCopyExtra.method === 'priority' ? '49.95' : '78.95')}`}</p>
              </div> : null}
              {order.hardCopy && order.hardCopyExtra.requireSignature ? 
              <div className="orderSummaryInfo">
                <div className="orderSummaryInfoMain">
                  <p className="orderSummaryInfoType">Signature Required for Delivery</p>
                  <p className="orderSummaryInfoCount">Adult signature required</p>
                </div>
                <p className="orderSummaryInfoType">$4.99</p>
              </div> : null}
              {discount > 0 ? 
              <div className="singleQuoteOrderSummaryInfo">
                <div className="singleQuoteOrderSummaryInfoMain">
                  <p className="singleQuoteOrderSummaryInfoType">{`Discount -${discount}%`}</p>
                  <p className="orderSummaryInfoCount">{appliedCode}</p>
                </div>
                <p className="singleQuoteOrderSummaryInfoType">{formatDiscount(getDiscount())}</p>
              </div> : null}
              <div className="orderSummaryTotal">
                <p>Total</p>
                <p>{formatTotal(totalNumber() - getDiscount())}</p>
              </div>
            </div>
            <div className="quoteSecondaryExtraInfo">
              {((order.certified && order.pagesCount !== '') || (!order.certified && order.wordCount !== '')) &&
                <div className="orderSecondaryTitle">
                  <Clock className="orderSecondaryIcon" />
                  {`${getTimeFrame()} est. turnaround`}
                </div>}
              {((order.certified && order.pagesCount !== '') || (!order.certified && order.wordCount !== '')) &&
                <div className="quoteSecondarySub">This is an estimate to deliver the digital copy based on the current order information you've provided.</div>
              }
              {order.languageFrom !== '' && order.languageTo !== '' &&
                <div className="orderSecondaryTitle">
                  <Globe className="orderSecondaryIcon" />
                  {`${getLanguage(order.languageFrom)} to ${getLanguage(order.languageTo)} Translation`}
                </div>
              }
              {order.languageFrom !== '' && order.languageTo !== '' &&
                <div className="quoteSecondarySub">We'll pair you with a professional translator and a project manager from our support team.</div>
              }
              <div className="orderSecondaryTitle">
                <Lock className="orderSecondaryIcon" />
                Secure and Private
              </div>
              <div className="quoteSecondarySub">All data processed by our site, including uploaded files, is encrypted. Only essential staff with signed NDAs can access your information.</div>
            </div>
            <img src={require('../../assets/encryptionIcons.png')} alt="Encryption Certificates" className="quoteSecondaryEncryptionImg" />
            <div className="orderSecondaryTitle">
            <HelpCircle className="orderSecondaryIcon" />
              Need help?
            </div>
            <div className="quoteSecondarySub"><a href="https://everbary.com/contact">Contact us</a> and we will create the order on your behalf.</div>
            {phoneScreen && 
            <>
              <div className="quoteSubmitButtonContainer">
                <div className="quoteGoBackButton" onClick={(e) => transitionTo('/order/options',e)}>
                  <ArrowLeft className="quoteGoBackIcon" />
                </div>
                <div style={(!signedIn && !order.name) || order.languageFrom === '' || !order.optionsSet || (order.hardCopy && (!(signedIn && userData && userData.shipping && userData.shipping.length > 0) || selectedAddressIndex === -1) && ( recipient === '' || street === '' || city === '' || region === '' || zipCode === '' || country === '' )) ? { opacity: 0.4, pointerEvents: 'none' } : { opacity: 1, pointerEvents: 'all' }}>
                  <PayPalButtons
                    style={{ layout: 'vertical', height: 40 }}
                    createOrder={createOrder}
                    onApprove={onOrderApprove}
                    onError={onOrderPaymentFailure}
                    onShippingChange={function noRef() {}}
                  />
                </div>
              </div>
              {signedIn && userData && userData.hasTeam && <AnimatedButton text="Pay Later" onClick={()=> {handlePlaceOrder("pay-later")}} fontSize={windowSize.width > 1024 ? 1.3 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.8 : 1.5) : (windowSize.orientation === 'portrait' ? 5 : 1.8)}/>}
              </>
            }
          </div>
        </div>
        <div className="quoteFooter">
          <div className="quoteFooterLinksContainer">
            <div className="quoteFooterLink">Contact</div>
            <div className="quoteFooterLinkSeparator" />
            <div className="quoteFooterLink">Terms</div>
            <div className="quoteFooterLinkSeparator" />
            <div className="quoteFooterLink">Refunds</div>
            <div className="quoteFooterLinkSeparator" />
            <div className="quoteFooterLink">Privacy</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderPayment;