import React from 'react';

const getSingleUseCase = (path, transitionTo) => {
  switch (path) {
    case "immigration":
      return {
        title: "Certified Translation for Immigration - USCIS",
        sub: "We specialize in providing certified translations that meet the guidelines set forth by USCIS. We offer a USCIS acceptance guarantee to give you total peace of mind that our translation will be accepted.",
        type: "certified",
        image: "torchUse",
        blockTitle: "Translation for USCIS",
        block: 
          <p className="servicesDisplaySubheading">
            US Citizenship and Immigration Services (USCIS) requires a <span className="hoveringTextLink" onClick={(e) => transitionTo('/certified',e)}>certified translation</span> for any non-English documents to accept them as part of the application. The most common documents submitted to USCIS include vital certificates (ex: birth, marriage, divorce certificates), affidavits, transcripts, passports, etc.
            <br/><br/>
            The translation must be complete – meaning, the translated document must include all text from the source document, and it must include a signed Certificate of Translation Accuracy. eVerbary guarantees acceptance of our certified translations by USCIS, our certificate has been approved, and we have a 100% acceptance rate.
            <br/><br/>
            USCIS will accept certified translations printed from an electronic PDF file, which we include with all orders, and they do not require the certified translation to be notarized. See our <span /*className="hoveringTextLink" onClick={(e) => transitionTo('/guide/uscis',e)}*/>USCIS translation guide</span> for additional information.
          </p>
      }
    case "government":
      return {
        title: "Certified Translation for Offical Government Use",
        sub: "When submitting documents to the governments, they will typically require a certified translation to ensure any translation submitted is true, accurate, and a word-for-word translation of the source.",
        type: "certified",
        image: "governmentUse",
        blockTitle: "Translations for Governmental Uses",
        block: 
          <p className="servicesDisplaySubheading">
            If you are submitting foreign-language documents to a government agency (in the US or abroad), they will likely need to be accompanied by a <span className="hoveringTextLink" onClick={(e) => transitionTo('/certified',e)}>certified translation</span>. In most cases, the receiving party will define a certified translation as a “complete, true and accurate, word-for-word translation of a source document, that was completed by a professional translator and inclusive of a signed certificate of translation accuracy.”
            <br/><br/>
            Please be sure to check the specific requirements of the end-user as they can vary. If you have questions about acceptance, please <span className="hoveringTextLink" onClick={(e) => transitionTo('/contact',e)}>contact us</span> with a list of the requirements of the receiving party to and we will review the requirements to help determine if our translation will be accepted.
          </p>
      }
    case "academic":
      return {
        title: "Certified Translation for Academic Admissions",
        sub: "When applying to a US-based school, the admissions office will require an evaluation of your foreign credentials. The credential evaluator will need a certified translation of your credentials, which we provide.",
        type: "certified",
        image: "hatUse",
        blockTitle: "Translation for Admissions Offices",
        block: 
          <p className="servicesDisplaySubheading">
            Applications for admission to colleges and universities will require a certified translation for any foreign language documents, completed in a line-by-line, word-for-word format. The most common documents submitted to colleges and universities include birth certificates, transcripts, passports, etc.
            <br/><br/>
            It is important to note that evaluation and/or conversion of grades is not a part of a translation and will need to be completed by a specialist (ex: <span className="hoveringTextLink" onClick={() => window.open('https://wes.org', '_blank')}>wes.org</span>) after the translation is complete. View our <span /*className="hoveringTextLink" onClick={(e) => transitionTo('/guide/foreign',e)}*/>credential evaluation guide</span> for more details on how credential evaluation and translation are related.
            <br/><br/>
            Please be sure to check the specific requirements of the receiving party as they can vary. If you have questions about acceptance, please <span className="hoveringTextLink" onClick={(e) => transitionTo('/contact',e)}>contact us</span> with a list of the requirements of the receiving party to and we will review the requirements to help determine if our translation will be accepted.
            <br/><br/>
            eVerbary can deliver completed translations directly to the admissions office, upon request.
          </p>
      }
    case "financial":
      return {
        title: "Certified Translation for Obtaining a Mortgage",
        sub: "When applying for a mortgage or other loan, the lender will require many supporting documents, financial and otherwise. If your documents are in a foreign language, they will likely need a certified translation.",
        type: "certified",
        image: "keyUse",
        blockTitle: "Translation for Mortgage Processing",
        block: 
          <p className="servicesDisplaySubheading">
            When applying for a mortgage or other loan, it is a common requirement that all foreign language documents need to be accompanied by a <span className="hoveringTextLink" onClick={(e) => transitionTo('/certified',e)}>certified translation</span>. The most common documents submitted when applying for a mortgage or loan include bank statements, passports, pay stubs, employment verification, etc.
            <br/><br/>
            It is important to note that currency conversion is not a part of a translation. We do offer currency conversion as a separate service that can be added to a certified translation for an additional fee. We ask that you provide the desired rate of exchange for the currency conversion; this rate will be listed on the certificate of translation accuracy.
            <br/><br/>
            Please be sure to check the specific requirements of the receiving party as they can vary. If you have questions about acceptance, please <span className="hoveringTextLink" onClick={(e) => transitionTo('/contact',e)}>contact us</span> with a list of the requirements of the receiving party to and we will review the requirements to help determine if our translation will be accepted.
          </p>
      }
    case "legal":
      return {
        title: "Certified Translation for Legal Use in Court",
        sub: "When submitting documents to the court, they will typically require a certified translation to ensure any translation submitted is true, accurate, and a word-for-word translation of the source.",
        type: "certified",
        image: "gavelUse",
        blockTitle: "Translations for Court",
        block: 
          <p className="servicesDisplaySubheading">
            Foreign language documents submitted to the court will almost always require a <span className="hoveringTextLink" onClick={(e) => transitionTo('/certified',e)}>certified translation</span>. Common documents submitted to the court include depositions, prior judgments, evidence, etc. In most cases, courts will define a certified translation as a “complete, true and accurate, word-for-word translation of a source document, that was completed by a professional translator and inclusive of a signed certificate of translation accuracy.”
            <br/><br/>
            Please be sure to check the specific requirements of the receiving party as they can vary. If you have questions about acceptance, please <span className="hoveringTextLink" onClick={(e) => transitionTo('/contact',e)}>contact us</span> with a list of the requirements of the receiving party to and we will review the requirements to help determine if our translation will be accepted.
          </p>
      }
    case "medical":
      return {
        title: "Certified Translation of Medical History",
        sub: "When traveling abroad, immigrating to another country, attending school in another country, etc. you'll likely be required to provide your medical history. If that history is in a foreign language, you'll typically need to submit a certified translation of it.",
        type: "certified",
        image: "medicalUse",
        blockTitle: "Translation of Medical History",
        block: 
          <p className="servicesDisplaySubheading">
            Medical records (ex: immunizations, medical history, etc.) in a foreign language may require a <span className="hoveringTextLink" onClick={(e) => transitionTo('/certified',e)}>certified translation</span> if submitted as proof of prior health history.
            <br/><br/>
            Please note, we are only able to supply translations of non-technical medical records (i.e., documents that are intended for consumption by the general public). Documents that are primarily medical jargon (ex: written by a doctor for another doctor, not a layperson) should be translated by a specialist with sufficient experience in the medical profession.
            <br/><br/>
            If you have questions about the translation of your medical records, please <span className="hoveringTextLink" onClick={(e) => transitionTo('/contact',e)}>contact us</span> and let us know the specifics of your documents.
          </p>
      }
    case "legalStandard":
      return {
        title: "Translation of Legal Documents",
        sub: "Have your legal documents translated for your personal or business use. Perfect for contracts, agreements, offers, and any number of other legal documents for business or personal use.",
        type: "standard",
        image: "legalUse",
        blockTitle: "Standard Translation of Legal Documents",
        block: 
          <p className="servicesDisplaySubheading">
            Any legal document that is not being submitted to a court or an official receiving party can be completed as a <span className="hoveringTextLink" onClick={(e) => transitionTo('/standard',e)}>standard translation</span>. This will allow for the most natural reading in the target language as the translator is not bound by strict word-for-word guidelines that can sometimes lead to awkward phrasing in a <span className="hoveringTextLink" onClick={(e) => transitionTo('/certified',e)}>certified translation</span>.
            <br/><br/>
            As is the case with all translations, we take confidentiality very seriously and require all employees to sign an NDA as a condition of employment. If you would like us to provide our NDA (or sign yours), just let us know.
          </p>
      }
    case "digital":
      return {
        title: "Translation of Digital Content",
        sub: "Expand your reach into new markets by having your blog posts, product listings, audio transcripts, etc. translated.",
        type: "standard",
        image: "digitalUse",
        blockTitle: "Standard Translation of Digital Content",
        block: 
          <p className="servicesDisplaySubheading">
            We help individuals and businesses expand their reach into new markets by translating their digital content (ex: blog posts, product listings, audio transcripts, etc.) into various target languages via our <span className="hoveringTextLink" onClick={(e) => transitionTo('/standard',e)}>standard translation</span> service. If you have a high volume of content to translate, <span className="hoveringTextLink" onClick={(e) => transitionTo('/contact',e)}>contact us</span> and we'll be able to assist you.
            <br/><br/>
            Any content that is not being submitted to a court or an official receiving party should be completed as a standard translation. This will allow for the most natural reading in the target language as the translator is not bound by strict word-for-word guidelines that can sometimes lead to awkward phrasing in a <span className="hoveringTextLink" onClick={(e) => transitionTo('/certified',e)}>certified translation</span>.
            <br/><br/>
            As is the case with all translations, we take confidentiality very seriously and require all employees to sign an NDA as a condition of employment. If you would like us to provide our NDA (or sign yours), just let us know.
            <br/><br/>
            If you have a high volume of content to translate, contact us at <span className="hoveringTextLink" onClick={() => window.open('mailto:support@everbary.com', '_blank')}>support@everbary.com</span> and we can likely offer you a discount on our translation services.
          </p>
      }
    case "communications":
      return {
        title: "Translation of Business Communications",
        sub: "We often translate business communications to facilitate conversations as well as to create searchable records for businesses.",
        type: "standard",
        image: "mailUse",
        blockTitle: "Standard Translation of Business Communications",
        block: 
          <p className="servicesDisplaySubheading">
            Critical documents, such as internal and B2B correspondence (emails, text messages, etc.) between parties that speak different languages, can be translated to facilitate communication.
            <br/><br/>
            Any document that is not being submitted to a court or an official receiving party can be completed as a <span className="hoveringTextLink" onClick={(e) => transitionTo('/standard',e)}>standard translation</span>. This will allow for the most natural reading in the target language as the translator is not bound by strict word-for-word guidelines that can sometimes lead to awkward phrasing in a <span className="hoveringTextLink" onClick={(e) => transitionTo('/certified',e)}>certified translation</span>.
            <br/><br/>
            As is the case with all translations, we take confidentiality very seriously and require all employees to sign an NDA as a condition of employment. If you would like us to provide our NDA (or sign yours), just let us know.
          </p>
      }
    case "documents":
      return {
        title: "Translation of Business Documents",
        sub: "We help businesses communicate effectively with their employees by translating critical internal documents such as handbooks, manuals, memos, etc. quickly and accurately.",
        type: "standard",
        image: "penUse",
        blockTitle: "Standard Translation of Business Documents",
        block: 
          <p className="servicesDisplaySubheading">
            Internal business documents such as handbooks, manuals, memos, etc., are often translated so they can be provided to employees that are unable to read the original language of the document. It is not uncommon for a business to have us translate these documents into multiple target languages.
            <br/><br/>
            Any document that is not being submitted to a court or an official receiving party should be completed as a <span className="hoveringTextLink" onClick={(e) => transitionTo('/standard',e)}>standard translation</span>. This will allow for the most natural reading in the target language as the translator is not bound by strict word-for-word guidelines that can sometimes lead to awkward phrasing in a <span className="hoveringTextLink" onClick={(e) => transitionTo('/certified',e)}>certified translation</span>.
            <br/><br/>
            As is the case with all translations, we take confidentiality very seriously and require all employees to sign an NDA as a condition of employment. If you would like us to provide our NDA (or sign yours), just let us know.
          </p>
      }
    default:
      break;
  }
}

export { getSingleUseCase }