import React, { useState } from "react";
import "./TopBar.css";

const TopBar = () => {
  const [hideTopBar, setHideTopBar] = useState(true);
  const closeTopBar = () => {
    setHideTopBar(true);
  };

  return (
    <>
      {!hideTopBar && (
        <div className="topBar">
          <img src={require("../assets/notification.png")} alt="" />
          <p>
            We'll be unavailable from Monday 8 to Monday 15 - we're sorry for
            the inconveniece! For urgent matters, please write at +1 505 916
            8399 (Only WhatsApp).
          </p>
          <div className="closeBtn" onClick={closeTopBar}>
            <img src={require("../assets/close.png")} alt="" />
          </div>
        </div>
      )}
    </>
  );
};

export default TopBar;
