import React, { useContext, useEffect, useState } from "react";
import { useSpring, animated, config } from "react-spring";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { Navbar } from "../../Components/Navbar";
import { AccountFooter } from "../../Components/AccountFooter";

import { useScreenTransition } from "../../Hooks/useScreenTransition";

import "react-datepicker/dist/react-datepicker.css";

import "../Account/Quotes.css";
import "./Payments.css";
import "./MakePayment.css";
import { DataContext } from "../../Context/DataContext";
import { NotificationContext } from "../../Context/NotificationContext";
import { TransitionContext } from "../../Context/TransitionContext";
import TopBar from "../../Components/TopBar";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { FileUploaderButton } from "../../Components/FileUploaderButton";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { AuthContext } from "../../Context/AuthContext";
import PaymentUpdatePopUp from "./PaymentUpdatePopUp";
import { AltAnimatedButton } from "../../Components/AltAnimatedButton";
import axios from "axios";

import SignInForm from '../SignIn/SignInForm';
import CreateAccount from '../CreateAccount/CreateAccount';

const PAYMENTS = ["Paypal", "Bank Transfer", "Card", "Check"];

const BANKDETAILS = {
  beneficiaryName: "eVerbary LLC",
  routingNumber: "084106768",
  accountNumber: "9800934503",
  typeOfAccount: "Checking",
  beneficiaryAddress: "530-B Harkle Road, Santa Fe, NM 87505",
  bank: "Evolve Bank & Trust",
  bankAddress: "6000 Poplar Ave, Suite 300, Memphis, TN 38119",
};

const TeamMakePayment = ({ windowSize }) => {
  const { transitionTo } = useScreenTransition();
  const { userData } = useContext(DataContext);
  const { showNotification } = useContext(NotificationContext);
  const { signedIn } = useContext(AuthContext);
  const { setColor } = useContext(TransitionContext);

  const [selectedPayment, setSelectedPayment] = useState(PAYMENTS[0]);
  const [files, setFiles] = useState([]);
  const [teamInvoice, setTeamInvoice] = useState();
  const [showPaymentMessage, setShowPaymentMessage] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [paymentUpdatePopUp, setPaymentUpdatePopUp] = useState(false);
  const [confirmPaymentText, setConfirmPaymentText] =
    useState("Confirm Payment");

  const phoneScreen =
    windowSize.width > 767 || windowSize.orientation === "landscape"
      ? false
      : true;

  const [menuOpen, setMenuOpen] = useState(!phoneScreen);

  const accountMenuProps = useSpring({
    maxHeight: menuOpen || !phoneScreen ? "calc(50vh)" : "calc(2.5vh)",
    config: config.slow,
  });

  useEffect(() => {
    setColor(true);
  }, [setColor]);

  const { teamInvoiceID } = useParams();

  useEffect(
    (e) => {
      if (teamInvoiceID) {
        
        getInvoiceData(e, teamInvoiceID);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teamInvoiceID, userData, signedIn]
  );


  useEffect(
    (e) => {
      if (
        teamInvoice &&
        (teamInvoice.total === 0 || teamInvoice.orders.length === 0)
      ) {
        transitionTo("/team/payments", e);
      } else if (teamInvoice && teamInvoice.status !== "new") {
        setShowPaymentMessage(true);
        if (teamInvoice.status === "processing") {
          setIsProcessing(true);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teamInvoice]
  );

  const getInvoiceData = (e,invoiceID) => {
    console.log(signedIn)
    if (!signedIn) {
      transitionTo("/sign-in", e);
    } else {
      if (firebase.auth().currentUser && userData) {
        const firestore = firebase.firestore();

        let query = firestore.collection("teamInvoices").doc(invoiceID);

        query
          .get()
          .then(async(invocieDoc) => {
            if (invocieDoc.exists) {
              const invoiceData = invocieDoc.data();
              let total = 0;
              let awaitingOrders = [];
              if (invoiceData.status === "new" && invoiceData.orders && invoiceData.orders.length > 0) {
               const allPromises = invoiceData.orders.map(async(order, i) =>
                  await firestore.collection("orders").doc(order.id).get()
                    .then((orderDoc) => {
                      if (
                        orderDoc.data().paypalOrderID &&
                        orderDoc.data().paypalOrderID === "pay-later" && orderDoc.data().status !== "cancelled"
                      ) {
                        total += orderDoc.data().totalPrice - getDiscount(orderDoc.data());
                        awaitingOrders.push(order);
                      } else {
                        setPaymentUpdatePopUp(true);
                      }
                    })
                );

                await Promise.all(allPromises);

                if (
                  total === 0
                ) {
                  transitionTo("/team/payments", e);
                }else if(total > 0){
                  setTeamInvoice({
                    id: invocieDoc.id,
                    ...invocieDoc.data(),
                    total: Number(formatTotal(total)),
                    orders: awaitingOrders,
                  });
                  setLoading(false);
                }

              } else {
                setTeamInvoice({
                  id: invocieDoc.id,
                  ...invocieDoc.data(),
                });
                setLoading(false);
              }
            } else {
              transitionTo("/team/payments", e);
            }
          })
          .catch((error) => {
            console.log(error);
            showNotification(
              "Error",
              "An error ocurred while loading the orders. Please, try again. If it persists, contact Support.",
              "error"
            );
          });
      }
    }
  };

  // const getTotal = (order) => {
  //   let total = 0;
  //   const orderDate = order ? new Date(order.timestamp) : new Date();
  //   const priceUpdateDate = new Date("Sun, 30 Oct 2022 20:02:56 GMT");

  //   if (order && order.certified) {
  //     if (order.pagesCount !== "") {
  //       total = total + parseInt(order.pagesCount, 10) * 24.95;
  //     } else {
  //       total = 0;
  //     }
  //   } else if (order) {
  //     if (order.wordCount !== "") {
  //       total = total + parseInt(order.wordCount, 10) * 0.1;
  //     } else {
  //       total = 0;
  //     }
  //   }

  //   if (order && order.expedited) {
  //     if (orderDate > priceUpdateDate) {
  //       total = total + total * 0.4;
  //     } else {
  //       total = total + total * 0.6;
  //     }
  //   }

  //   if (order && order.notarization) {
  //     total = total + 34.9;
  //   }

  //   if (order && order.hardCopy) {
  //     if (orderDate >= priceUpdateDate) {
  //       if (
  //         ["United States", "Puerto Rico", "U.S. Virgin Islands"].includes(
  //           order.hardCopyExtra.country
  //         )
  //       ) {
  //         if (order.hardCopyExtra.method === "priority") {
  //           total = total + 19.95;
  //         } else {
  //           total = total + 37.95;
  //         }
  //       } else {
  //         if (order.hardCopyExtra.method === "priority") {
  //           total = total + 49.95;
  //         } else {
  //           total = total + 78.95;
  //         }
  //       }
  //     } else {
  //       if (
  //         ["United States", "Puerto Rico", "U.S. Virgin Islands"].includes(
  //           order.hardCopyExtra.country
  //         )
  //       ) {
  //         if (order.hardCopyExtra.method === "priority") {
  //           total = total + 12.95;
  //         } else {
  //           total = total + 29.95;
  //         }
  //       } else {
  //         if (order.hardCopyExtra.method === "priority") {
  //           total = total + 39.95;
  //         } else {
  //           total = total + 67.95;
  //         }
  //       }
  //     }
  //   }

  //   if (order && order.hardCopy && order.hardCopyExtra.requireSignature) {
  //     if (orderDate >= priceUpdateDate) {
  //       total = total + 4.99;
  //     } else {
  //       total = total + 2.54;
  //     }
  //   }

  //   return total;
  // };

  const getDiscount = (order) => {
    if (!order || !order.discount || order.discount === 0) {
      return 0;
    } else {
      const orderDate = order ? new Date(order.timestamp) : new Date();
      const discountUpdateDate = new Date("Tue, 28 Mar 2023 20:02:56 GMT");
      if (orderDate >= discountUpdateDate) {
        let total = 0;

        if (order && order.certified) {
          if (order.pagesCount !== "") {
            total = total + parseInt(order.pagesCount, 10) * 24.95;
          } else {
            total = 0;
          }
        } else if (order) {
          if (order.wordCount !== "") {
            total = total + parseInt(order.wordCount, 10) * 0.1;
          } else {
            total = 0;
          }
        }
        return (total * order.discount) / 100;
      }
      return (order.totalPrice * order.discount) / 100;
    }
  };

  const formatTotal = (value) => {
    return value.toFixed(2);
  };

  const handleFiles = (e) => {
    const mFiles = e.target.files;

    for (let i = 0; i < mFiles.length; i++) {
      // if (!files.some((file) => file.name === mFiles[i].name)) {
        const fileID = uuidv4();
        setFiles((f) =>
          f.concat([
            {
              id: fileID,
              name: mFiles[i].name,
              file: mFiles[i],
              progress: 0,
              status: "uploading",
            },
          ])
        );
      // } else {
      //   showNotification(
      //     "File already uploaded.",
      //     "You've already uploaded a file with this name. Try uploading with another name.",
      //     "error"
      //   );
      // }
    }

    const storageRef = firebase.storage().ref();

    for (let i = 0; i < mFiles.length; i++) {
      // if (!files.some((file) => file.name === mFiles[i].name)) {
        const subFolder = uuidv4();
        const fileRef = storageRef.child(
          teamInvoice.date + '/' + subFolder + "/" + mFiles[i].name
        );

        const downloadToken = uuidv4();

        const fileName = mFiles[i].name.replace('#', '%23').replace('+', "%2b");

        const url = `https://firebasestorage.googleapis.com/v0/b/project-everbary.appspot.com/o/${teamInvoice.date}%2F${subFolder}%2F${fileName}?alt=media&token=${downloadToken}`;

        var metadata = {
          firebaseStorageDownloadTokens: downloadToken,
          customMetadata: {
            ordersDate: teamInvoice.date,
          },
        };

        const uploadTask = fileRef.put(mFiles[i], metadata);

        setFiles((f) =>
          f.map((file) =>
            file.name === mFiles[i].name && file.ref === mFiles[i].ref ? { ...file, task: uploadTask } : file
          )
        );

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setFiles((f) =>
              f.map((file) =>
                file.name === mFiles[i].name && file.ref === mFiles[i].ref
                  ? { ...file, progress: progress.toFixed(2) }
                  : file
              )
            );
          },
          (error) => {
            setFiles((f) =>
              f.map((file) =>
                file.name === mFiles[i].name && file.ref === mFiles[i].ref
                  ? { ...file, status: "failed" }
                  : file
              )
            );
            showNotification(
              "Error uploading.",
              "There was an error uploading the file " +
                mFiles[i].name +
                ". Please, try again.",
              "error"
            );
            // eslint-disable-next-line no-loop-func
          },
          () => {
            setFiles((f) =>
              f.map((file) =>
                file.name === mFiles[i].name && file.ref === mFiles[i].ref
                  ? {
                      ...file,
                      status: "complete",
                      ref: teamInvoice.date + "/" + subFolder + "/" + mFiles[i].name,
                      url: url,
                    }
                  : file
              )
            );
          }
        );
      // }
    }
  };

  const handleDeleteFile = (file) => {
    const storageRef = firebase.storage().ref();

    if (file.status === "complete" || file.timestamp) {
      const fileRef = storageRef.child(file.ref);
      setFiles((f) =>
        f.map((mFile) =>
          mFile.name === file.name && mFile.ref === file.ref ? { ...mFile, status: "deleting" } : mFile
        )
      );

      fileRef
        .delete()
        .then(function () {
          setFiles((f) => f.filter((mFile) => mFile.ref !== file.ref));
        })
        .then(() => {})
        .catch(function (error) {
          showNotification(
            "Error deleting the file.",
            "There was an error deleting the file " +
              file.name +
              ". Please, try again.",
            "error"
          );
        });
    } else if (file.status === "failed") {
      setFiles((f) => f.filter((mFile) => mFile.ref !== file.ref));
    } else {
      file.task.cancel();
      setFiles((f) => f.filter((mFile) => mFile.ref !== file.ref));
    }
  };

  const createOrder = (data, actions) => {
    return actions.order.create({
      intent: "CAPTURE",
      purchase_units: [
        {
          description: `Your Awaititng Order`,
          amount: {
            currency_code: "USD",
            value: teamInvoice.total,
          },
          reference_id: teamInvoice.date,
        },
      ],
      application_context: {
        brand_name: "eVerbary",
        landing_page: "NO_PREFERENCE",
        user_action: "PAY_NOW",
        payment_method: {
          payee_preferred: "IMMEDIATE_PAYMENT_REQUIRED",
        },
      },
    });
  };

  const onOrderApprove = (data, actions) => {
    actions.order.capture().then((details) => {
      addProcessingPaymentinDB("paid",data.orderID);
    });
  };

  const onOrderPaymentFailure = (data, actions) => {
    showNotification(
      "Error",
      "An error ocurred while placing the order. Please, try again.",
      "error"
    );
  };

  const confirmBankPayment = (e) => {
    setConfirmPaymentText("Confirming...");
    if (files.some((file) => file.status !== "complete")) {
      setConfirmPaymentText("Confirm Payment");
      return showNotification(
        "Uploading Documents",
        "Uploading Documents, Please wait...",
        "error"
      );
    }
    if (files.length > 0) {
      setIsProcessing(true);
      addProcessingPaymentinDB("processing","");
    } else {
      setConfirmPaymentText("Confirm Payment");
      showNotification(
        "Upload Documents",
        "Please attach the payment reciept or photos of the front and back of your check, as applicable.",
        "error"
      );
    }
  };

  const addProcessingPaymentinDB = async(status, paypalPaymentID = "") => {
    // setTimeout(() => {}, 1000);
    const firestore = firebase.firestore();
    const docRef = firestore.collection("teamInvoices").doc(teamInvoice.id);
    const localFiles = files.map((f) => f);

    let invoiceNumber = 0;

    await axios({
      method:'post',
      url: "https://us-central1-project-everbary.cloudfunctions.net/getCounter",
      data: {
        type: 'teamInvoice'
      }
    })
      .then((response) => {
        invoiceNumber = parseInt(response.data.number, 10);
      })
      .catch((error) => {
        //console.log(error);
      });

    if (invoiceNumber === 0) {
      setLoading(false);
      showNotification('Error', "An error ocurred while placing the order. Please, try again. If it persists, contact support.", 'error');
      return false;
    }

    docRef
      .update({
        ...teamInvoice,
        status: status,
        paymentMode: selectedPayment,
        teamId: userData.teamId,
        reason: "",
        paypalPaymentID: paypalPaymentID,
        invoiceNumber: invoiceNumber,
        timestamp: new Date().getTime(),
        files: localFiles.map((f) => ({
          name: f.name,
          ref: f.ref,
          url: f.url,
          type: "client",
          size: f.file.size,
          format: f.file.type,
          timestamp: new Date().getTime(),
        })),
      })
      .then(() => {
        setShowPaymentMessage(true);
        setConfirmPaymentText("Confirm Payment");
      });
  };

  const [isModalOpen, setIsModalOpen]=useState(false);
  const [isCreateAccountOpen, setIsCreateAccountOpen]=useState(false);


  const handleLoginOpen=(newState)=>{
    setIsModalOpen(newState);
  }

  const handleCreateAccountOpen=(newState)=>{
    setIsCreateAccountOpen(newState);
  }

  return (
    <>
      <SignInForm open={isModalOpen} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/>
      <CreateAccount open={isCreateAccountOpen} isCreateAccountOpen={handleCreateAccountOpen} isLoginFormOpen={handleLoginOpen}/>
      <TopBar />
      <div className="homeContainer">
      <Navbar windowSize={windowSize} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/>
        <div className="accountContainer">
          <animated.div style={accountMenuProps} className="accountSidebar">
            <p
              className="accountSidebarTitle"
              onClick={phoneScreen ? () => setMenuOpen((i) => !i) : null}
            >
              {windowSize.width > 767 || windowSize.orientation === "landscape"
                ? "Team"
                : "Team Menu"}
            </p>
            <p
              className="accountSidebarLink"
              onClick={(e) => transitionTo("/team/members", e)}
            >
              Members
            </p>
            <p
              className="accountSidebarLink"
              onClick={(e) => transitionTo("/team/shipping", e)}
            >
              Shipping
            </p>
            <p
              className="accountSidebarLink"
              onClick={(e) => transitionTo("/team/quotes", e)}
            >
              Quotes
            </p>
            <p
              className="accountSidebarLink"
              onClick={(e) => transitionTo("/team/orders", e)}
            >
              Orders
            </p>
            <p
              className="accountSidebarLink"
              onClick={(e) => transitionTo("/team/options", e)}
            >
              Options
            </p>
            <p
              className="accountSidebarLink"
              onClick={(e) => transitionTo("/team/payments", e)}
            >
              Payments
            </p>
          </animated.div>
          <div
            className="accountMainContainer"
            style={{ width: !phoneScreen ? "73%" : "86%" }}
          >
            {showPaymentMessage ? (
              <div className="accountMainTitleRow">
                <h1 className="accountMainTitle">Payment Successfull</h1>
              </div>
            ) : (
              <div className="accountMainTitleRow">
                <h1 className="accountMainTitle">Make a Payment</h1>
              </div>
            )}

            {!showPaymentMessage &&
              teamInvoice &&
              teamInvoice.total > 0 &&
              teamInvoice.orders.length > 0 && (
                <div className="dataWrapper">
                  <div className="orderDetails">
                    <div className="orderDate">{teamInvoice.date}</div>
                    <div className="orderTotal total">${teamInvoice.total}</div>
                  </div>
                  <div className="paymentTitle">
                    Which payment method do you prefer?
                  </div>
                  <div className="paymentSelectorWrap">
                    <div
                      className={`paymentSelector frequencySelector ${
                        selectedPayment === PAYMENTS[0] && "selectedFrequency"
                      }`}
                      onClick={() => setSelectedPayment(PAYMENTS[0])}
                    >
                      {PAYMENTS[0]}
                    </div>
                    <div
                      className={`paymentSelector frequencySelector ${
                        selectedPayment === PAYMENTS[1] && "selectedFrequency"
                      }`}
                      onClick={() => setSelectedPayment(PAYMENTS[1])}
                    >
                      {PAYMENTS[1]}
                    </div>
                  </div>
                  <div className="paymentSelectorWrap">
                    <div
                      className={`paymentSelector frequencySelector ${
                        selectedPayment === PAYMENTS[2] && "selectedFrequency"
                      }`}
                      onClick={() => setSelectedPayment(PAYMENTS[2])}
                    >
                      {PAYMENTS[2]}
                    </div>
                    <div
                      className={`paymentSelector frequencySelector ${
                        selectedPayment === PAYMENTS[3] && "selectedFrequency"
                      }`}
                      onClick={() => setSelectedPayment(PAYMENTS[3])}
                    >
                      {PAYMENTS[3]}
                    </div>
                  </div>

                  {(selectedPayment === PAYMENTS[0] ||
                    selectedPayment === PAYMENTS[2]) && (
                    <div className="paypalBtnWrap">
                      <PayPalButtons
                        style={{ layout: "horizontal", height: 40 }}
                        createOrder={createOrder}
                        onApprove={onOrderApprove}
                        onError={onOrderPaymentFailure}
                        onShippingChange={function noRef() {}}
                      />
                    </div>
                  )}
                  {(selectedPayment === PAYMENTS[1] ||
                    selectedPayment === PAYMENTS[3]) && (
                    <div className="bankDetails">
                      <div className="text">
                        <b>Beneficiary Name:</b> {BANKDETAILS.beneficiaryName}
                      </div>
                      <div className="text">
                        <b>Routing number:</b> {BANKDETAILS.routingNumber}
                      </div>
                      <div className="text">
                        <b>Account number:</b> {BANKDETAILS.accountNumber}
                      </div>
                      <div className="text">
                        <b>Type of Account:</b> {BANKDETAILS.typeOfAccount}
                      </div>
                      <div className="text">
                        <b>Beneficiary Address:</b>{" "}
                        {BANKDETAILS.beneficiaryAddress}
                      </div>
                      <div className="text">
                        <b>Bank:</b> {BANKDETAILS.bank}
                      </div>
                      <div className="text">
                        <b>Bank Address:</b> {BANKDETAILS.bankAddress}
                      </div>
                      <div className="text">
                        <b>Check:</b>
                      </div>
                      <div className="text">
                        We accept check payments through online deposit. Here's
                        how you can pay by check:
                      </div>
                      <div className="text">
                        1. Take a digital photo of the front and back of your
                        check. Ensure the photo clearly shows all four corners
                        of the check.
                      </div>
                      <div className="text">
                        2. On the back of the check, please write "Alejo Fiasche
                        Cormick. For mobile deposit at Mercury only". This is
                        essential for the check to be processed correctly.
                      </div>
                      <div className="text">
                        3. Upload the photo(s) of your check in the next step.
                      </div>
                      <br />
                      <div>
                        <div className="headingText">Attachments</div>
                        <div className="text">
                          Please attach the payment reciept or photos of the
                          front and back of your check, as applicable.
                        </div>
                        <div className="uploadBoxWrap">
                          <FileUploaderButton
                            display
                            backend
                            text="Add Files"
                            files={files}
                            handleFiles={handleFiles}
                            handleDeleteFile={handleDeleteFile}
                            fontSize={
                              windowSize.width > 1024
                                ? 1
                                : windowSize.width > 767
                                ? windowSize.orientation === "portrait"
                                  ? 1.4
                                  : 1
                                : windowSize.orientation === "portrait"
                                ? 3.2
                                : 1.3
                            }
                          />
                          <AltAnimatedButton
                            text={confirmPaymentText}
                            color="rgb(23, 188, 91)"
                            backend
                            disabled={confirmPaymentText === "Confirming..."}
                            onClick={(e) => {
                              confirmPaymentText !== "Confirming..." &&
                                confirmBankPayment(e);
                            }}
                            fontSize={
                              windowSize.width > 1024
                                ? 0.9
                                : windowSize.width > 767
                                ? windowSize.orientation === "portrait"
                                  ? 1.7
                                  : 1.1
                                : windowSize.orientation === "portrait"
                                ? 3.5
                                : 1
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

            {showPaymentMessage &&
              teamInvoice &&
              (!isProcessing ? (
                teamInvoice.status === "failed" ? (
                  <div className="thankUWrapper">
                    <div className="text">
                      Oops! It looks like there was a hiccup with processing
                      your payment, and it didn't go through as expected.
                    </div>
                    <div className="text">
                      <b>Reasons: {teamInvoice.reason}</b>
                    </div>
                    <div className="text">
                      If you have any questions or concerns, please feel free to
                      <span
                        className="hoveringTextLink"
                        onClick={(e) => transitionTo("/contact", e)}
                      >
                        {" "}
                        contact us
                      </span>
                      .
                    </div>
                  </div>
                ) : (
                  <div className="thankUWrapper">
                    <div className="headingText">
                      Thank you for your payment!
                    </div>
                    <div className="text">
                      Your payment of ${teamInvoice.total} for{" "}
                      {teamInvoice.date} has successfully cleared!.
                    </div>
                  </div>
                )
              ) : (
                <div className="thankUWrapper">
                  <div className="headingText">
                    Your payment is being processed!
                  </div>
                  <div className="text">
                    Bank transfers take 1-3 days and checks 5-7 days to clear. A
                    confirmation email will be on its way soon.
                  </div>
                  <div className="text">
                    If you have any questions or concerns, please feel free to
                    <span
                      className="hoveringTextLink"
                      onClick={(e) => transitionTo("/contact", e)}
                    >
                      {" "}
                      contact us
                    </span>
                    .
                  </div>
                </div>
              ))}

            {!teamInvoice && loading && (
              <div className="loadingQuotes">Loading...</div>
            )}
          </div>
        </div>
        <PaymentUpdatePopUp
          windowSize={windowSize}
          openConfirmation={paymentUpdatePopUp}
          setOpenConfirmation={setPaymentUpdatePopUp}
        />
        <AccountFooter windowSize={windowSize} />
      </div>
    </>
  );
};

export default TeamMakePayment;
