import React, { useContext, useEffect, useRef, useState } from 'react'
import { AccountFooter } from '../../Components/AccountFooter'
import { Navbar } from '../../Components/Navbar'

import { animated, useSpring, config } from 'react-spring';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

import '../Account/Profile.css';
import '../Account/Orders.css';
import './Members.css';
import { useScreenTransition } from '../../Hooks/useScreenTransition';
import { Trash2 } from 'react-feather';
import { NotificationContext } from '../../Context/NotificationContext';
import { DataContext } from '../../Context/DataContext';
import axios from 'axios';
import AddIcon from '../../assets/plus-icon.png';
import { useEnterKey } from '../../Hooks/useEnterKey';
import { FormTextInput } from '../../Components/FormTextInput';
import { TransitionContext } from '../../Context/TransitionContext';
import PaymentsPopUp from './PaymentsPopUp';
import { AltAnimatedButton } from '../../Components/AltAnimatedButton';
import TopBar from '../../Components/TopBar';

const pageLimit = 50;

const Members = ({windowSize}) => {


    const { showNotification } = useContext(NotificationContext);

    const { userData } = useContext(DataContext);
    const { setColor } = useContext(TransitionContext);


    const [teamName, setTeamName] = useState('');
    const [newUserEmail, setNewUserEmail] = useState('');
    const [newUserName, setNewUserName] = useState('');
    const [newUserPass] = useState('tempPassword');
    const [showAddUser, setShowAddUser] = useState(false);
    
    const [createBtn, setCreateBtn] = useState('Create');
    const { transitionTo } = useScreenTransition();

    const phoneScreen = windowSize.width > 767 || windowSize.orientation === 'landscape' ? false : true;

    const [menuOpen, setMenuOpen] = useState(!phoneScreen);

    const accountMenuProps = useSpring({ maxHeight: menuOpen || !phoneScreen ? 'calc(50vh)' : 'calc(2.5vh)', config: config.slow });

    const [users, setUsers] = useState([]);
    const [loadedUsers, setLoadedUsers] = useState(false); 

    const [openConfirmation, setOpenConfirmation] = useState(false);


    let firstLoad = useRef();
    if (!firstLoad.current) firstLoad.current = true;

    let nextPointer = useRef();
    if (!nextPointer.current) nextPointer.current = null;

    useEffect(()=> {
      if(userData && userData.teamId === "" && !userData.hasTeam){
        transitionTo('/');
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData])

    useEffect(()=> {
        if(userData && userData.teamId){

            const firestore = firebase.firestore();
            firestore.collection('teams').doc(userData.teamId).get().then(doc => {
                if(doc.exists){
                    setTeamName(doc.data().name);
                }
        })

        }
    }, [userData])

    useEffect(() => {
        if(userData && userData.teamId){
        const firestore = firebase.firestore();
    
        let query = firestore.collection('users').where('hasTeam', '==', true);
    
        const unsubscribe = 
          query.where('teamId', '==', userData.teamId).orderBy('timestamp', 'asc')
          .limit(pageLimit)
          .onSnapshot(querySnapshot => {
            if (firstLoad.current) {
              nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];
              firstLoad.current = false;
              
              setUsers(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
              setLoadedUsers(true);
            } else {
              let newUsers = [];
  
              querySnapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                  newUsers.push({ id: change.doc.id, ...change.doc.data() });
                }
              });
              
              setUsers(u => newUsers.concat(u));
              setLoadedUsers(true);
            }
            }, error => {
              console.log(error);
              showNotification('Error', "There was an error loading the users. Please, reload the page.", 'error');
              setLoadedUsers(true);
            });
            
            return () => unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData]);
    
    
      const loadMoreUsers = () => {
        if (nextPointer.current) {
          const firestore = firebase.firestore();
    
          let query = firestore.collection('users').where('hasTeam', '==', true)
    
          query.where('teamId', '==', userData.teamId).orderBy('timestamp', 'asc')
          .startAfter(nextPointer.current)
          .limit(pageLimit)
          .get().then((querySnapshot) => {
          nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];

          let oldUsers = [];

          querySnapshot.forEach((doc) => {
            oldUsers.push({ id: doc.id, ...doc.data() });
          });
          
          setUsers(u => u.concat(oldUsers));
        })
        .catch((error) => {
          showNotification('Error', "There was an error loading the users. Please, reload the page.", 'error');
          //console.log("Error getting documents: ", error);
        });
        }
      }

      const handleDeleteUser = async userID => {
        setUsers(u => u.map(user => user.id === userID ? { ...user, status: 'deleting' } : user));
    
        const firestore = firebase.firestore();
    
        // axios({
        //   method:'post',
        //   url: "https://us-central1-project-everbary.cloudfunctions.net/deleteUser",
        //   data: {
        //     userID: userID
        //   }
        // })
        await firestore.collection("orders").where("userID", "==", userID).get().then(ordersSnap => {
          if(!ordersSnap.empty){
            ordersSnap.forEach(order => {
                firestore.collection("orders").doc(order.id).update( {
                  teamId: "",
                  ccEmails: firebase.firestore.FieldValue.delete(),
                })
            })
          }
        });
        await firestore.collection("quotes").where("userID", "==", userID).get().then(quotesSnap => {
          if(!quotesSnap.empty){
            quotesSnap.forEach(quote => {
              firestore.collection("quotes").doc(quote.id).update( {
                teamId: "",
              })
            })
          }
        })
        if(userData && userData.teamId){
          await firestore.collection("orders").where("teamId", "==", userData.teamId).get().then(ordersSnap => {
            if(!ordersSnap.empty){
              ordersSnap.forEach(orderDoc => {
                if(orderDoc.data().ccEmails){
                  const ccEmails = orderDoc.Data().ccEmails;
                  const selectedCCEmails = ccEmails.selectedCCEmails && ccEmails.selectedCCEmails.filter(email => email !== userData.email)
                  firestore.collection("orders").doc(orderDoc.id).update( {
                    ccEmails: {
                      customCCEmails: ccEmails.customCCEmails,
                      selectAllCCEmails: ccEmails.selectAllCCEmails,
                      selectAllTeamEmails: ccEmails.selectAllTeamEmails,
                      selectedCCEmails: selectedCCEmails,
                      selectedCustomCCEmails: ccEmails.selectedCustomCCEmails
                    }
                  })
                }
              })
            }
          });
        }
        await firestore.collection("users").doc(userID).update({
            teamId: "",
            hasTeam: false
          }).then(()=>{
              showNotification("User Removed", "The user has been removed from team successfully.", "success");     
            })
          .catch(error => {
            console.log(error)
            showNotification("Error", "An error ocurred removing the user. Please, try again. If it persists, contact technical support.", "error");
          })
      }
    

      const createNewUser = () => {
        setCreateBtn('Creating...');
        axios({
          method:'post',
          url: "https://us-central1-project-everbary.cloudfunctions.net/createUserByTeamMember",
          data: {
              role: 'client',
              name: newUserName,
              email: newUserEmail.toLowerCase(),
              password: newUserPass,
              teamId: userData.teamId,
              hasTeam: true,
          }
        })
          .then(async(response) => {
            if (response.data.code === 'auth/email-already-exists') {
              showNotification("Error", "There's already a user with this email. Please, try again.", "error");
            } else if (response.data.code === 'auth/invalid-email') {
              showNotification('Error', "The email address is invalid. Please, try again.", 'error');
            } else if (response.data.code === 'auth/invalid-password') {
              showNotification('Error', "Password is too weak. Please, try again with another one.", 'error');
            } else {
              const firestore = firebase.firestore();
              await firestore.collection("orders").where("teamId", "==",userData.teamId).get().then(ordersSnap => {
                if(!ordersSnap.empty){
                  ordersSnap.forEach(orderDoc => {
                    if(orderDoc.data().ccEmails){
                      const ccEmails = orderDoc.data().ccEmails;
                      let selectedCCEmails = [];

                        const customCCEmails = ccEmails.customCCEmails.includes(newUserEmail) ? ccEmails.customCCEmails.filter(customEmail => customEmail !== newUserEmail): ccEmails.customCCEmails;
                        const selectedCustomCCEmails = ccEmails.selectedCustomCCEmails.includes(newUserEmail)? ccEmails.selectedCustomCCEmails.filter(customEmail => customEmail !== newUserEmail): ccEmails.selectedCustomCCEmails;
                        if(ccEmails.selectAllCCEmails || ccEmails.selectAllTeamEmails || ccEmails.selectedCustomCCEmails.includes(newUserEmail)){
                          selectedCCEmails = ccEmails.selectedCCEmails.includes(newUserEmail)? ccEmails.selectedCCEmails : ccEmails.selectedCCEmails.push(newUserEmail);
                        }else{
                          selectedCCEmails = ccEmails.selectedCCEmails;
                        }
                        firestore.collection("orders").doc(orderDoc.id).update( {
                          ccEmails: {
                            customCCEmails: customCCEmails,
                            selectAllCCEmails: ccEmails.selectAllCCEmails,
                            selectAllTeamEmails: ccEmails.selectAllTeamEmails,
                            selectedCCEmails: selectedCCEmails,
                            selectedCustomCCEmails: selectedCustomCCEmails
                          }
                        })
                    }
                  })
                }
              });
              showNotification("Email Sent","Confirmation email send to the user email", "success");     
              setShowAddUser(false);
              setNewUserEmail('');
              setNewUserName('');
            }
            setCreateBtn("Create");
          })
          .catch((error) => {
            setCreateBtn("Create");
            console.log(error.code);
            showNotification("Error", "An error ocurred creating the user. Please, try again. If it persists, contact technical support.", "error");
          });
        
      }

      const triggerCreateUser = () => {
        if (showAddUser && newUserEmail !== "" && newUserName !== ""){
          createNewUser();
          
        };
      }
    
      useEnterKey(() => triggerCreateUser());

      useEffect(() => {
        setColor(true);
      }, [setColor]);


  return (
    <>
    <TopBar/>
    <div className="homeContainer">
    <Navbar windowSize={windowSize} />
    <div className="accountContainer">
      <animated.div style={accountMenuProps} className="accountSidebar">
        <p className="accountSidebarTitle" onClick={phoneScreen ? () => setMenuOpen(i => !i) : null}>{windowSize.width > 767 || windowSize.orientation === 'landscape' ? 'Team' : 'Team Menu'}</p>
        <p className="accountSidebarLink" style={{ color: '#666' }}>Members</p>
        <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/shipping',e)}>Shipping</p>
        <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/quotes',e)}>Quotes</p>
        <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/orders',e)}>Orders</p>
        <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/options',e)}>Options</p>
        <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/payments',e)}>Payments</p>
      </animated.div>
      <div className="accountMainContainer">
        <div className="accountMainTitleRow">
          <h1 className="accountMainTitle">Team Details</h1>
        </div>
        <div className="accountProfileFormLine teamNameWrapper">
          <p className='teamNameText'>Team Name: </p>
          <p className='teamNameText'>{teamName}</p>
          </div>
          <br/>
        <div className="quoteHeaderLine">
            <p className="quoteDetailsHeaderColumn quoteTotalHeaderColumn"></p>
            <p className="quoteDetailsHeaderColumn">Name</p>
            <p className="quoteDetailsHeaderColumn">Email</p>
            <p className="quoteDetailsHeaderColumn quoteTotalHeaderColumn">
            </p>
            <p className="quoteDetailsHeaderColumn quoteTotalHeaderColumn">
            </p>
          </div>
          { loadedUsers && userData && users.length > 0 ? users.map((user,index) => (
            <div key={user.id} className="quoteLine">
                <p className="quoteDetailsLineColumn quoteTotalHeaderColumn">{index + 1}.</p>
                <p className="quoteDetailsLineColumn">{user.name}</p>
                <p className="quoteDetailsLineColumn quoteDetailsTitle">{user.email}</p>
                <div className="quoteDetailsLineColumn quoteTotalHeaderColumn"></div>
                <div className="quoteDetailsLineColumn quoteTotalHeaderColumn">
                {user.status !== "deleting" ? <Trash2 className='usersDeleteButton' onClick={() => handleDeleteUser(user.id)}/>:<p className="userStatusText">Removing...</p>}
                </div>
            </div>
          ))
          : loadedUsers ? <div className="loadingTranslations">No users.</div> : <div className="loadingTranslations">Loading users...</div>
      }
      { loadedUsers && users.length > 0 && 
      <div className="addUserContainer">
      <div className="addNewUserBtn" onClick={()=> setShowAddUser(showUser => !showUser)}><img src={AddIcon} alt="add user"/></div>
        {showAddUser &&
      <div className="userInputsWrapper">
      <FormTextInput placeholder="Name(*)" value={newUserName} onChange={setNewUserName} color='#666' />
      <FormTextInput placeholder="Email(*)" value={newUserEmail} onChange={setNewUserEmail} color='#666' />
      {/* <FormSimpleTextInput placeholder="Password(*)" value={newUserPass} onChange={setNewUserPass} color='#666' /> */}
      {/* <div className="addNewUserBtn" onClick={()=> createNewUser()}><img src={AddIcon} alt="add user"/></div> */}
      
      <AltAnimatedButton disabled={createBtn === "Creating..."} text={createBtn} onClick={()=> createNewUser()} fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.7 : 1.1) : (windowSize.orientation === 'portrait' ? 3.5 : 1)} />
      </div>
      }
      </div>
      }
      { loadedUsers && users.length !== 0 && users.length >= 50 && nextPointer.current &&
        <div className="loadMoreUsersButton" onClick={() => loadMoreUsers()}>
          Load more users...
        </div>
      }
           
        
      </div>
    </div>
    <AccountFooter windowSize={windowSize} />
    <PaymentsPopUp windowSize={windowSize} openConfirmation={openConfirmation} setOpenConfirmation={setOpenConfirmation}/>
  </div>
  </>
  )
}

export default Members