import React, { useContext } from "react";
import { useEffect, useState } from "react";
import "./ResetPassword.css";
import { ReactComponent as Close } from "../../assets/icons/x.svg";
import { ReactComponent as EyeOff } from "../../assets/icons/eye-closed.svg";
import { Eye, Loader } from "react-feather";
import {ReactComponent as EyeBlack} from '../../assets/icons/eye-closed-black.svg'
import axios from "axios";
import { NotificationContext } from "../../Context/NotificationContext";

export default function ResetPassword({ isLoginOpen ,backToSignIn,handlePasswordChanged,email}) {
  const [newPassword, setNewPassword] = useState("");
  const [validPassword, setValidPassword] = useState(true);
  const [focusEmail, setFocusEmail]=useState(false);
  const [showPassword, setShowPassword]=useState("password");
  const { showNotification } = useContext(NotificationContext);
  const [isLoading, setIsLoading]=useState(false);

  const [btnText, setBtnText] = useState("Reset Password");


  useEffect(() => {
    if (newPassword !== "") {
      if (newPassword.length < 6) {
        setValidPassword(false);
      } else {
        setValidPassword(true);
      }
    }
  }, [newPassword]);

  let inputStyle = validPassword
    ? "resetPasswordFormInput"
    : "resetPasswordFormInput errorInput";

  let spanStyle = validPassword
    ? "resetPasswordFormValidation successValidation"
    : "resetPasswordFormValidation";

    let isAbleToChangePassword=validPassword && newPassword!==""?false:true;

    const toggleShowPassword=()=>{
        setShowPassword((prevShowPassword) =>
            prevShowPassword === "password" ? "text" : "password"
        );
    }

    const updatePassword = async () => {
      setIsLoading(true);
      setBtnText("Reseting");
    
      try {
        const response = await axios.post(
          "https://us-central1-project-everbary.cloudfunctions.net/resetUserPassword",
          {
            email: email.toLowerCase(),
            password: newPassword,
          }
        );
    
        if (response.status === 200 && response.data === "success") {
          handlePasswordChanged();
        } else {
          showNotification(
            "Error",
            "Error occurred while resetting password. Please try again or contact support!",
            "error"
          );
        }
      } catch (error) {
        console.log(error);
        showNotification(
          "Error",
          "An error occurred while resetting the password. Please try again later.",
          "error"
        );
      } finally {
        setIsLoading(false);
        setBtnText("Reset Password");
      }
    };    

  return (
    <div className="resetPasswordForm">
      <div className="resetPasswordFormHeader">
        <h2 className="resetPasswordFormTitle">Reset password</h2>
        <Close className="resetPasswordFormIcon" onClick={()=>isLoginOpen()}/>
      </div>

      <div className="resetPasswordInputContainer">
        <input
          type={showPassword}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          onFocus={()=>setFocusEmail(true)}
          onKeyDown={(e) => e.key === "Enter" && updatePassword()}
          onBlur={()=>setFocusEmail(false)}
          className={!focusEmail && newPassword!==""?(validPassword?"resetPasswordFormInputNotEmpty":"resetPasswordFormInputNotEmpty errorInput"):inputStyle}
        />
        <span className={!focusEmail && newPassword!==""?"resetPlaceholderNotInFocus":"resetPasswordInputPlaceholder"}>New password</span>

        {showPassword === "text" ? (
                <Eye className={newPassword===""?"resetshowPasswordEye ":"resetshowPasswordEye focusedPasswordEye"} onClick={toggleShowPassword} />
              ) : null}
             
             
              {showPassword === "password" && newPassword!==""? (
                <EyeOff
                  className={"resetshowPasswordEye "}
                  onClick={toggleShowPassword}
                />
              ) : null}

              {newPassword==="" && showPassword === "password"? (
                <EyeOff
                  className={"resetshowPasswordEye "}
                  onClick={toggleShowPassword}
                />
              ) : null}

              {showPassword === "password" && newPassword!==""? (
                <EyeBlack
                  className={"resetshowPasswordEye"}
                  onClick={toggleShowPassword}
                />
              ) : null}
      </div>

      {newPassword !== "" ? (
        <span className={spanStyle}>6 characters</span>
      ) : null}
      <button className="resetPasswordFormButton" disabled={isAbleToChangePassword || isLoading} onClick={()=>updatePassword()}>{btnText}
      {isLoading && <Loader className="btnLoader"/>}</button>
      <span className="resetPasswordFormLogIn" onClick={()=>backToSignIn()}>
        Back to Sign In
      </span>
    </div>
  );
}
