import React from "react";

import "./FormTextInput.css";

function FormTextInput({
  placeholder,
  value,
  onChange,
  color,
  correct,
  incorrect,
  password = false,
  disabled = false,
  focus = false,
  onKeyDown = () => {},
}) {
  return (
    <div className="formInputSimpleContainer">
      <input
        autoFocus={focus}
        onKeyDown={onKeyDown}
        required
        disabled={disabled}
        type={password ? "password" : "text"}
        className={`formInputSimple ${value !== "" && "validInput"}`}
        style={{
          color: incorrect ? "#f93636" : correct ? "#07a70b" : color || "#666",
          borderColor: incorrect
            ? "#f93636"
            : correct
            ? "#07a70b"
            : color || "rgba(102,102,102,0.7)",
        }}
        placeholder=""
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <p
        className={`formInputSimpleLabel ${value !== "" && "validInputLabel"}`}
        style={{
          color: incorrect ? "#f93636" : correct ? "#07a70b" : color || "#666",
        }}
      >
        {placeholder}
      </p>
    </div>
  );
}

export { FormTextInput };
