import React, { useState, useContext, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { useHistory } from 'react-router-dom';
import { Editor, EditorState, RichUtils, convertToRaw } from 'draft-js';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import { Check, Bold, Underline, Italic, List, User, AtSign, X } from 'react-feather';

import { languages } from '../../Data/languages';
import { countries } from '../../Data/countries';

import { AltAnimatedButton } from '../../Components/AltAnimatedButton';
import { FileUploaderButton } from '../../Components/FileUploaderButton';
import { FormSimpleTextInput } from '../../Components/FormSimpleTextInput';

import { NotificationContext } from '../../Context/NotificationContext'; 
import { DataContext } from '../../Context/DataContext';

import { useEnterKey } from '../../Hooks/useEnterKey';

import './ManualOrder.css';

const ManualOrder = ({ windowSize, mode }) => {

  const history = useHistory();

  const { showNotification } = useContext(NotificationContext);

  const { userData } = useContext(DataContext);

  const [name, setName] = useState('');
  const [name2, setName2] = useState('');
  const [email, setEmail] = useState('');
  const [email2, setEmail2] = useState('');

  const [certified, setCertified] = useState(true);

  const certifiedProps = useSpring({ borderColor: certified ? '#FF7C1D' : 'rgba(102,102,102,0.1)' });
  const standardProps = useSpring({ borderColor: !certified ? '#FF7C1D' : 'rgba(102,102,102,0.1)' });

  const [languageFrom, setLanguageFrom] = useState('');
  const [languageTo, setLanguageTo] = useState('');

  const [translatorCertify, setTranslatorCertify] = useState(false);

  const [notarization, setNotarization] = useState(false);
  const [expedited, setExpedited] = useState(false);
  const [hardCopy, setHardCopy] = useState(false);

  const [hardCopyCountry, setHardCopyCountry] = useState('');
  const [hardCopyMethod, setHardCopyMethod] = useState('');
  const [hardCopyRequireSignature, setHardCopyRequireSignature] = useState(false);

  const [pagesCount, setPagesCount] = useState('');
  const [wordCount, setWordCount] = useState('');

  const [files, setFiles] = useState([]);

  const [discount, setDiscount] = useState(0);

  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());

  const [orderSubmitButton, setOrderSubmitButton] = useState(mode === 'order' ? 'Create Order' : 'Create Quote');

  const orderID = useRef(uuidv4());

  const [recipient, setRecipient] = useState('');
  const [street, setStreet] = useState('');
  const [apartment, setApartment] = useState('');
  const [city, setCity] = useState('');
  const [region, setRegion] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('');

  const [nameSearches, setNameSearches] = useState([]);
  const [name2Searches, setName2Searches] = useState([]);
  const [emailSearches, setEmailSearches] = useState([]);
  const [email2Searches, setEmail2Searches] = useState([]);

  useEffect(() => {
    if (!userData || (userData.role !== 'owner' && userData.role !== 'Support')) {
      showNotification('Error', "You don't have sufficient permissions to see this page. If you think you have the correct permissions, please contact our support team.", 'error');
      history.push(mode === 'order' ? '/office/translations' : '/office/quotes');
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editor = React.useRef(null);

  function focusEditor() {
    editor.current.focus();
  }

  const handleFiles = (e) => {
    const mFiles = e.target.files;

    for (let i = 0; i < mFiles.length; i++) {
      // if (!files.some(file => file.name === mFiles[i].name)) {
        const fileID = uuidv4();
        setFiles(f => f.concat([{ id: fileID, name: mFiles[i].name, file: mFiles[i], progress: 0, status: 'uploading' }]));
      // } else {
      //   showNotification("File already uploaded.", "You've already uploaded a file with this name. Try uploading with another name.", "error");
      // }
    }

    const storageRef = firebase.storage().ref();
    
    for (let i = 0; i < mFiles.length; i++) {
      // if (!files.some(file => file.name === mFiles[i].name)) {
        const subFolder = uuidv4();
        const fileRef = storageRef.child(orderID.current + '/' + subFolder + '/'  + mFiles[i].name);

        const downloadToken = uuidv4();
;
        const fileName = mFiles[i].name.replace('#', '%23').replace('+', "%2b");

        const url = `https://firebasestorage.googleapis.com/v0/b/project-everbary.appspot.com/o/${orderID.current}%2F${subFolder}%2F${fileName}?alt=media&token=${downloadToken}`;

        var metadata = {
          firebaseStorageDownloadTokens: downloadToken,
          customMetadata: {
            'orderID': orderID.current
          }
        }

        const uploadTask = fileRef.put(mFiles[i], metadata);

        setFiles(f => f.map(file => file.name === mFiles[i].name && file.ref === mFiles[i].ref ? { ...file, task: uploadTask } : file));

        uploadTask.on('state_changed', (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setFiles(f => f.map(file => file.name === mFiles[i].name && file.ref === mFiles[i].ref ? { ...file, progress: progress.toFixed(2) } : file));
        }, (error) => {
          //console.log(error);
          setFiles(f => f.map(file => file.name === mFiles[i].name && file.ref === mFiles[i].ref ? { ...file, status: 'failed' } : file));
          showNotification("Error uploading.", "There was an error uploading the file " + mFiles[i].name + ". Please, try again.", "error");
        }, () => {
          setFiles(f => f.map(file => file.name === mFiles[i].name && file.ref === mFiles[i].ref ? { ...file, status: 'complete', ref: orderID.current + '/' + subFolder + '/' + mFiles[i].name, url: url } : file));
        });
      // }
    }
  }

  const handleDeleteFile = file => {
    const storageRef = firebase.storage().ref();

    if (file.status === 'complete') {
      const fileRef = storageRef.child(file.ref);
      setFiles(f => f.map(mFile => mFile.name === file.name && mFile.ref === file.ref  ? { ...mFile, status: 'deleting' } : mFile));

      fileRef.delete()
        .then(function() {
          setFiles(f => f.filter(mFile => mFile.ref !== file.ref));
        }).catch(function(error) {
          showNotification("Error deleting the file.", "There was an error deleting the file " + file.name + ". Please, try again.", "error");
        });
    } else if (file.status === 'failed') {
      setFiles(f => f.filter(mFile => mFile.ref !== file.ref));
    } else {
      file.task.cancel();
      setFiles(f => f.filter(mFile => mFile.ref !== file.ref));
    }
  }

  const onChangePagesCount = (count) => {
    var reg = /^[0-9]+\.?[0-9]{0,3}$/;

    if (count === '' || reg.test(count)) {
      setPagesCount(count);
    }
  }

  const onChangeWordCount = (count) => {
    var reg = /^[0-9]+\.?[0-9]{0,3}$/;

    if (count === '' || reg.test(count)) {
      setWordCount(count);
    }
  }

  const onChangeDiscount = (discount) => {
    var reg = /^[0-9]+\.?[0-9]{0,3}$/;

    if (discount === '' || reg.test(discount)) {
      setDiscount(discount);
    }
  }

  const handleBoldClick = () => {
    setEditorState(editorState => RichUtils.toggleInlineStyle(editorState, 'BOLD'))
  }

  const handleItalicClick = () => {
    setEditorState(editorState => RichUtils.toggleInlineStyle(editorState, 'ITALIC'))
  }

  const handleUnderlineClick = () => {
    setEditorState(editorState => RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'))
  }

  const toggleUL = () => {
    setEditorState(editorState => RichUtils.toggleBlockType(editorState,'unordered-list-item'))
  }

  const getExpedited = () => {
    let total = 0;

    if (certified) {
      if (pagesCount !== '') {
        total = total + parseInt(pagesCount, 10) * 24.95;
      } else {
        total = 0;
      }
    } else {
      if (wordCount !== '') {
        total = total + parseInt(wordCount, 10) * 0.1;
      } else {
        total = 0;
      }
    }

    return total * 0.4;
  }

  const getTotal = () => {
    let total = 0;

    if (certified) {
      if (pagesCount && pagesCount !== '' && pagesCount !== 0) {
        total = total + parseInt(pagesCount, 10) * 24.95;
      } else {
        total = 0;
      }
    } else {
      if (wordCount && wordCount !== '' && wordCount !== 0) {
        total = total + parseInt(wordCount, 10) * 0.1;
      } else {
        total = 0;
      }
    }

    if (expedited) {
      total = total + getExpedited();
    }

    if (notarization) {
      total = total + 34.90;
    }

    if (hardCopy) {
      if (['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(hardCopyCountry)) {
        if (hardCopyMethod === 'priority') {
          total = total + 19.95;
        } else {
          total = total + 37.95;
        }
      } else {
        if (hardCopyMethod === 'priority') {
          total = total + 49.95;
        } else {
          total = total + 78.95;
        }
      }
    }

    if (hardCopy && hardCopyRequireSignature) {
      total = total + 4.99;
    }

    return total;
  }

  const getDiscount = () => {
    if (discount === 0 || !discount || discount === '') {
      return 0;
    } else {
      let total = 0;

    if (certified) {
      if (pagesCount && pagesCount !== '' && pagesCount !== 0) {
        total = total + parseInt(pagesCount, 10) * 24.95;
      } else {
        total = 0;
      }
    } else {
      if (wordCount && wordCount !== '' && wordCount !== 0) {
        total = total + parseInt(wordCount, 10) * 0.1;
      } else {
        total = 0;
      }
    }
      return total * discount / 100;
    }
  }

  const formatTotal = value => {
    return '$' + value.toFixed(2);
  }

  const formatDiscount = value => {
    return '-$' + value.toFixed(2);
  }

  const handleSubmitOrder = async () => {
    //eslint-disable-next-line
    var reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (!reg.test(email)) {
      showNotification('Error', "The email address is invalid. Please, try again with a valid email address.", 'error');
      return false;
    }

    setOrderSubmitButton(mode === 'order' ? 'Creating Order...' : 'Creating Quote...');

    const now = new Date();

    const firestore = firebase.firestore();

    let orderNumber = 0;
    
    await axios({
      method:'post',
      url: "https://us-central1-project-everbary.cloudfunctions.net/getCounter",
      data: {
        type: mode
      }
    })
      .then((response) => {
        orderNumber = parseInt(response.data.number, 10);
      })
      .catch((error) => {
        //console.log(error);
      });

    if (orderNumber === 0) {
      showNotification('Error', `An error ocurred while creating the ${mode}. Please, try again. If it persists, contact support.`, 'error');
      return false;
    }

    const addressID = uuidv4();

    let userID = null;
    let teamID = null;

    await firestore.collection('users').where('email', '==', email).get()
      .then(querySnapshot => {
        if (querySnapshot.docs.length > 0) {
          userID = querySnapshot.docs[0].id;
          teamID = querySnapshot.docs[0].data().teamId;
        }
      })
      .catch(error => {
        //console.log(error);
      })

    const fields = mode === 'order' ? { orderNumber: orderNumber } : { quoteNumber: orderNumber };

    firestore.collection(mode === 'order' ? 'orders' : 'quotes').doc(orderID.current)
      .set({
        ...fields,
        userID: userID ? userID : '',
        teamId: teamID ? teamID : '',
        email: email.toLowerCase(),
        name: name,
        email2: email2 ? email2.toLowerCase() : email2,
        name2: name2,
        certified: certified,
        languageFrom: languageFrom,
        languageTo: languageTo,
        translatorCertification: translatorCertify,
        notarization: notarization,
        notarizationStatus: notarization ? 'pending' : null,
        expedited: expedited,
        hardCopy: hardCopy,
        hardCopyStatus: hardCopy ? 'pending': null,
        hardCopyExtra: hardCopy ? { country: hardCopyCountry, method: hardCopyMethod, requireSignature: hardCopyRequireSignature } : null,
        pagesCount: certified ? pagesCount : 0,
        wordCount: !certified ? wordCount : 0,
        notes: convertToRaw(editorState.getCurrentContent()),
        files: files.map(file => ({ name: file.name, ref: file.ref, url: file.url, type: 'source', size: file.file.size, format: file.file.type, timestamp: now.getTime() })),
        timestamp: now.getTime(),
        status: mode === 'order' ? 'new' : 'replied',
        shippingInfo: hardCopy && mode === 'order' ? { id: addressID, recipient: recipient, street: street, apartment: apartment, city: city, region: region, zipCode: zipCode, country: country } : null,
        discount: discount !== 0 && discount !== '' && discount !== '0' ? Number(discount) : 0,
        discountApplied: discount !== 0 && discount !== '' && discount !== '0' ? true : false,
        paypalOrderID: "pay-later",
        totalPrice: getTotal(),
      })
      .then(() => {
        setOrderSubmitButton(mode === 'order' ? 'Order Created' : 'Quote Created');
        history.push(mode === 'order' ? '/office/translations' : '/office/quotes');
      })
      .catch(error => {
        //console.log(error);
        setOrderSubmitButton(mode === 'order' ? 'Create Order' : 'Create Quote');
        showNotification('Error', `An error ocurred while creating the ${mode}. Please, try again.`, 'error');
      })
  }

  const triggerSubmitOrder = () => {
    if (!(name === '' || email === '' || languageFrom === '' || languageTo === '' || files.length === 0 || files.some(file => file.status !== 'complete') || (certified && hardCopy && (hardCopyCountry === '' || hardCopyMethod === '')) || (certified && (pagesCount === '' || parseInt(pagesCount,10) === 0)) || (!certified && (wordCount === '' || parseInt(wordCount,10) === 0)))) {
      handleSubmitOrder();
    }
  }

  useEnterKey(() => triggerSubmitOrder());

 const getFilteredNamesList = (capitalSearch, data) => {

  const filtered = data.filter(d => {
    if(d.name.includes(" ")){
    const name =  d.name.split(" ");
    const first = name[0] ? name[0].slice(0, capitalSearch.length) : "";
    const middle= name[1] ? name[1].slice(0, capitalSearch.length) : "";
    const last= name[2] ? name[2].slice(0, capitalSearch.length) : "";


    if(capitalSearch === first || capitalSearch.toLowerCase() === first ||
    capitalSearch === middle || capitalSearch.toLowerCase() === middle ||
    capitalSearch === last || capitalSearch.toLowerCase() === last){
      return true;
    }else{
      return false;
    }
  }else{
    if(capitalSearch === d.name.slice(0, capitalSearch.length) ||
    capitalSearch.toLowerCase() === d.name.slice(0, capitalSearch.length)){
      return true;
    }else{
      return false
    }
  }
  });


  filtered.sort(function(a, b){

    const firstName = a.name.toLowerCase();
    const secondName = b.name.toLowerCase();
    if(firstName < secondName) { return -1; }
    if(firstName > secondName) { return 1; }
    return 0;
  });

return filtered;

 }

  const getNames = (name, nameSearches) => {
    if(name === ""){
      nameSearches([]);
      return;
    }
    const firestore = firebase.firestore();
    const capitalName = capitalize(name);

    firestore.collection("users").where("name", ">=", capitalName).orderBy("name", "asc").get().then(querySnap => {
      if(!querySnap.empty){
        const data = querySnap.docs.map(doc => {return {"id": doc.id, ...doc.data()}});
        const filtered = getFilteredNamesList(capitalName, [...data]);
          nameSearches(filtered);

      }
    })
    
  }

  const getEmails = (email, emailSearches) => {
    if(email === ""){
      emailSearches([]);
      return;
    }

    const firestore = firebase.firestore();

    firestore.collection("users").where("email", ">=", email.toLowerCase()).orderBy("email", "asc").get().then(querySnap => {
      if(!querySnap.empty){
        const data = querySnap.docs.map(doc => {return {"id": doc.id, ...doc.data()}});
        const filteredData = data.filter(value => value.email.includes(email.toLowerCase()));
        emailSearches(filteredData);
      }
    })
  }

  const capitalize = value => {
    if(value.length > 1){
    const array = value.split(" ");
    const capitalizeWordArray = array.map(data => {
      if(data.length > 1){
        return data.slice(0,1).toUpperCase() + data.slice(1,-1) + data.slice(-1)
      }
      return data.toUpperCase();
    });
    return capitalizeWordArray.join(" ");
    }else{
      return value.toUpperCase();
    }
  }


  return (
    <div className="singleTranslationContainer">
      <div className="singleTranslationHeader">
        <p className="singleTranslationOrderNumber">{mode === 'order' ? 'New Order' : 'New Quote'}</p>
      </div>
      <div className="singleTranslationDetailsOuter">
        <div className="singleTranslationDetailsMain">
          <div className="singleTranslationDetailsDates">
            <div className="manualOrderDecideMainContainer">
              <div className="preferencesDecideInfoContainer">
                <p className="quotePrimaryTitle" style={{ marginTop: 0 }}>Client Info</p>
                <div className="quotePrimaryForm">
                  <div className="quotePrimaryFormElement element">
                    <FormSimpleTextInput placeholder="Full Name(*)" value={name} onChange={(value) => {
                      setName(value); 
                      setName2Searches([]);
                      getNames(value, setNameSearches);
                      }} color='#666' onKeyDown={(e)=> e.key === "Enter" && getNames(e.target.value, setNameSearches)} />
                    {nameSearches.length > 0 && <div  className="searchBoxWrap">
                      <X className="editBtn closeIcon" onClick={() => setNameSearches([]) }/>
                      {nameSearches.map(user => <div key={user.id} className='searchTextWrap'>
                          <User className='editBtn'/>
                          <div className="quotePrimaryOptionText" onClick={()=> {
                             setEmail(user.email);
                             setName(user.name);
                             setNameSearches([]);
                             setEmailSearches([]);
                             }}>{user.name}</div> 
                         </div>)}
                         
                    </div>}
                  </div>
                  <div className="quotePrimaryFormElement element">
                    <FormSimpleTextInput placeholder="Email(*)" value={email} onChange={(value) => {
                      setEmail(value); 
                      setEmail2Searches([]);
                      getEmails(value, setEmailSearches);
                      }} color='#666' onKeyDown={(e)=> e.key === "Enter" && getEmails(e.target.value, setEmailSearches)} />
                    { emailSearches.length > 0 && <div  className="searchBoxWrap">
                      <X className="editBtn closeIcon" onClick={() => setEmailSearches([]) }/>
                      {emailSearches.map(user => <div key={user.id} className='searchTextWrap'>
                          <AtSign className='editBtn'/>
                          <div className="quotePrimaryOptionText" onClick={()=> {
                             setEmail(user.email);
                             setName(user.name);
                             setEmailSearches([]);
                             setNameSearches([]);
                             }}>{user.email}</div> 
                         </div>)}
                    </div>}
                  </div>
                </div>

                <div className="quotePrimaryForm">

                  <div className="quotePrimaryFormElement element">
                    <FormSimpleTextInput placeholder="Second Name(*)" value={name2} onChange={(value) => {
                      setName2(value); 
                      setNameSearches([])
                      getNames(value, setName2Searches);
                      }} color='#666' onKeyDown={(e)=> e.key === "Enter" && getNames(e.target.value, setName2Searches)} />
                    {name2Searches.length > 0 && <div  className="searchBoxWrap">
                      <X className="editBtn closeIcon" onClick={() => setName2Searches([]) }/>
                      {name2Searches.map(user => <div key={user.id} className='searchTextWrap'>
                          <User className='editBtn'/>
                          <div className="quotePrimaryOptionText" onClick={()=> {
                             setEmail2(user.email);
                             setName2(user.name);
                             setName2Searches([]);
                             setEmail2Searches([]);
                             }}>{user.name}</div> 
                         </div>)}
                         
                    </div>}
                  </div>

                  <div className="quotePrimaryFormElement element">
                    <FormSimpleTextInput placeholder="Second Email(*)" value={email2} onChange={(value) => {
                      setEmail2(value);
                      setEmailSearches([]); 
                      getEmails(value, setEmail2Searches);
                      }} color='#666' onKeyDown={(e)=> e.key === "Enter" && getEmails(e.target.value, setEmail2Searches)} />
                    { email2Searches.length > 0 && <div  className="searchBoxWrap">
                      <X className="editBtn closeIcon" onClick={() => setEmail2Searches([]) }/>
                      {email2Searches.map(user => <div key={user.id} className='searchTextWrap'>
                          <AtSign className='editBtn'/>
                          <div className="quotePrimaryOptionText" onClick={()=> {
                             setEmail2(user.email);
                             setName2(user.name);
                             setEmail2Searches([]);
                             setName2Searches([]);
                             }}>{user.email}</div> 
                         </div>)}
                    </div>}
                  </div>
                </div>

                <p className="quotePrimaryTitle" style={{ marginTop: 'calc(2vh)' }}>Type of translation</p>
                <div className="quotePrimaryOptions">
                  <animated.div style={certifiedProps} className="quotePrimaryOption" onClick={() => setCertified(true)}>
                    <p className="quotePrimaryOptionTitle">Certified Translation</p>
                    <p className="quotePrimaryOptionText">Word-for-word human translation of documents with certification for official use.</p>
                  </animated.div>
                  <animated.div style={standardProps} className="quotePrimaryOption" onClick={() => setCertified(false)}>
                    <p className="quotePrimaryOptionTitle">Standard Translation</p>
                    <p className="quotePrimaryOptionText">Human translation of documents and text-based content for business or personal use.</p>
                  </animated.div>
                </div>
                <p className="quotePrimaryTitle">Language Pair</p>
                <p className="quoteSmallMessage">Either the target or destination language must be English.</p>
                <div className="quoteDetailsInputsContainer">
                  <div className="quoteDetailsSelectContainer">
                    <select value={languageFrom} onChange={(e) => setLanguageFrom(e.target.value)} className="quoteDetailsSelect">
                      <option key="" value="" disabled>Translate From (*)</option>
                      {languages.filter(language => languageTo === 'english' || languageTo === '' || language.value === 'english').map(language => 
                        <option key={language.value} value={language.value}>{language.label}</option>
                      )}
                    </select>
                  </div>
                  <div className="quoteDetailsSelectContainer">
                    <select placeholder="Translate To (*)" value={languageTo} onChange={(e) => setLanguageTo(e.target.value)} className="quoteDetailsSelect">
                      <option key="" value="" disabled>Translate To (*)</option>
                      {languages.filter(language => languageFrom === 'english' || languageFrom === '' || language.value === 'english').map(language => 
                        <option key={language.value} value={language.value}>{language.label}</option>
                      )}
                    </select>
                  </div>
                </div>

                <p className="quotePrimaryTitle">{certified ? "Page Count" : "Word Count"}</p>
                {wordCount !== '' && parseInt(wordCount, 10) < 200 && 
                  <div className="orderCountWarning">200 word minimum per order</div>
                }
                <div className="orderCountContainer">
                  <div className="orderCountInputContainer">
                    <FormSimpleTextInput placeholder={certified ? "Page Count (*)" : "Word Count (*)"} value={certified ? pagesCount : wordCount} onChange={certified ? onChangePagesCount : onChangeWordCount} color='#666' />
                  </div>
                </div>

                <p className="quotePrimaryTitle">Files</p>
                <p className="quoteSmallMessage">Add all files required for the translation.</p>
                <div className="quoteDetailsFileUploaderContainer">
                  <FileUploaderButton display text="Choose Files" backend files={files} handleFiles={handleFiles} handleDeleteFile={handleDeleteFile} fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.7 : 1.1) : (windowSize.orientation === 'portrait' ? 3.5 : 1)} />
                </div>

                <p className="quotePrimaryTitle">Optional Services</p>
                {certified ? <div className="quoteOptionalContainer" style={{ borderColor: notarization ? '#FF7C1D' : 'rgba(102,102,102,0.1)' }}>
                  <div className="quoteOptionalTitleRow">
                    <p className="quotePrimaryOptionTitle">Notarization</p>
                    <div className="quoteOptionalTitleStatus" onClick={() => setNotarization(n => !n)} style={{ backgroundColor: !notarization ? '#FF7C1D' : 'rgba(102,102,102,0.1)', color: !notarization ? '#fff' : '#666' }}>{notarization ? "REMOVE" : "ADD"}</div>
                  </div>
                  <p className="quotePrimaryOptionText">Stamp and signature authenticating the signer of the translation certification. Valid in all 50 states.</p>
                </div> : null}
                <div className="quoteOptionalContainer" style={{ borderColor: expedited ? '#FF7C1D' : 'rgba(102,102,102,0.1)' }}>
                  <div className="quoteOptionalTitleRow">
                    <p className="quotePrimaryOptionTitle">Expedited Turnaround</p>
                    <div className="quoteOptionalTitleStatus" onClick={() => setExpedited(n => !n)} style={{ backgroundColor: !expedited ? '#FF7C1D' : 'rgba(102,102,102,0.1)', color: !expedited ? '#fff' : '#666' }}>{expedited ? "REMOVE" : "ADD"}</div>
                  </div>
                  <p className="quotePrimaryOptionText">Order will be prioritized and turnaround time for digital delivery reduced by 50%.</p>
                </div>
                {certified ? 
                <div className="quoteOptionalContainer" style={{ borderColor: hardCopy ? '#FF7C1D' : 'rgba(102,102,102,0.1)' }}>
                  <div className="quoteOptionalTitleRow">
                    <p className="quotePrimaryOptionTitle">Mail Hard Copy</p>
                    <div className="quoteOptionalTitleStatus" onClick={() => setHardCopy(n => !n)} style={{ backgroundColor: !hardCopy ? '#FF7C1D' : 'rgba(102,102,102,0.1)', color: !hardCopy ? '#fff' : '#666' }}>{hardCopy ? "REMOVE" : "ADD"}</div>
                  </div>
                  <p className="quotePrimaryOptionText">Original translation with wet ink signature(s) shipped via USPS Priority or Express mail.</p>
                  {hardCopy ? 
                  <>
                    <div className="hardCopyLineSeparator" />
                    <div className="quoteDetailsInputsContainer">
                      <div className="quoteDetailsSelectContainer">
                        <select value={hardCopyCountry} onChange={(e) => setHardCopyCountry(e.target.value)} className="quoteDetailsSelect">
                          <option key="" value="" disabled>Country (*)</option>
                          {countries.map(country => 
                            <option key={country} value={country}>{country}</option>
                          )}
                        </select>
                      </div>
                      <div className="quoteDetailsSelectContainer">
                        <select placeholder="USPS Service (*)" value={hardCopyMethod} onChange={(e) => setHardCopyMethod(e.target.value)} className="quoteDetailsSelect">
                          <option key="" value="" disabled>USPS Service (*)</option>
                          <option key="priority" value="priority">{['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(hardCopyCountry) ? "Priority: 2-3 days in transit" : "Priority: 6-10 days in transit"}</option>
                          <option key="express" value="express">{['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(hardCopyCountry) ? "Express: 1-2 days in transit" : "Express: 3-5 days in transit" }</option>
                        </select>
                      </div>
                    </div>
                    <div className="hardCopyCheckboxLine" onClick={() => setHardCopyRequireSignature(hc => !hc)}>
                      <div className="hardCopyCheckbox">
                        <Check className="hardCopyCheck" style={{ opacity: hardCopyRequireSignature ? 1 : 0 }} />
                      </div>
                      <p className="hardCopyCheckboxText">Require signature for delivery</p>
                    </div>
                  </> : null}
                </div> : null}

                {hardCopy && mode === 'order' && <div className="quoteOptionalContainer" style={{ borderColor: '#FF7C1D' }}>
                  <div className="orderAddressOptionalInnerForm">
                    <div className="quoteDetailsInputsContainer">
                      <FormSimpleTextInput placeholder="Recipient (*)" value={recipient} onChange={setRecipient} color='#666' />
                    </div>
                    <div className="quoteDetailsInputsContainer">
                      <FormSimpleTextInput placeholder="Street (*)" value={street} onChange={setStreet} color='#666' />
                    </div>
                    <div className="quoteDetailsInputsContainer">
                      <FormSimpleTextInput placeholder="Apartment, Suite or Floor Number" value={apartment} onChange={setApartment} color='#666' />
                    </div>
                    <div className="quoteDetailsInputsContainer">
                      <div className="orderPaymentTripleLine">
                        <div className="orderPaymentTripleField">
                          <FormSimpleTextInput placeholder="City (*)" value={city} onChange={setCity} color='#666' />
                        </div>
                        <div className="orderPaymentTripleField">
                          <FormSimpleTextInput placeholder={country === 'United States' ? "State (*)" : "Region (*)"} value={region} onChange={setRegion} color='#666' />
                        </div>
                        <div className="orderPaymentTripleField">
                          <FormSimpleTextInput placeholder="Postal Code (*)" value={zipCode} onChange={setZipCode} color='#666' />
                        </div>
                      </div>
                    </div>
                    <div className="quoteDetailsInputsContainer">
                      <select value={hardCopyCountry} onChange={(e) => setCountry(e.target.value)} className="quoteDetailsSelect" disabled>
                        <option key="" value="" disabled>Country (*)</option>
                        {countries.map(country => 
                          <option key={country} value={country}>{country}</option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>}

                {
                  <div className="quoteOptionalContainer" style={{ borderColor: translatorCertify ? '#FF7C1D' : 'rgba(102,102,102,0.1)' }}>
                  <div className="quoteOptionalTitleRow">
                    <p className="quotePrimaryOptionTitle">Individual Translator's Certification</p>
                    <div className="quoteOptionalTitleStatus" onClick={() => setTranslatorCertify(n => !n)} style={{ backgroundColor: !translatorCertify ? '#FF7C1D' : 'rgba(102,102,102,0.1)', color: !translatorCertify ? '#fff' : '#666' }}>{translatorCertify ? "REMOVE" : "ADD"}</div>
                  </div>
                </div> 
                }

                <p className="quotePrimaryTitle">Notes</p>
                <p className="quoteSmallMessage">Please include any notes needed for the translation team to work with.</p>
                <div className="quoteRichTextButtons">
                  <Bold className="quoteRichTextButton" onClick={handleBoldClick} />
                  <Italic className="quoteRichTextButton" onClick={handleItalicClick} />
                  <Underline className="quoteRichTextButton" onClick={handleUnderlineClick} />
                  <List className="quoteRichTextButton" onClick={toggleUL} />
                </div>
                <div className="quoteRichTextEditor" onClick={focusEditor}>
                  <Editor
                    ref={editor}
                    editorState={editorState}
                    onChange={editorState => setEditorState(editorState)}
                  />
                </div>
                <div className="quoteSubmitButtonContainer">
                  <AltAnimatedButton text={orderSubmitButton} backend disabled={name === '' || email === '' || languageFrom === '' || languageTo === '' || files.length === 0 || files.some(file => file.status !== 'complete') || (certified && hardCopy && (hardCopyCountry === '' || hardCopyMethod === '')) || (certified && (pagesCount === '' || parseInt(pagesCount,10) === 0)) || (!certified && (wordCount === '' || parseInt(wordCount,10) === 0))} onClick={() => handleSubmitOrder()} fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.7 : 1.1) : (windowSize.orientation === 'portrait' ? 3.5 : 1)} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="singleRevisionDetailsSecondary">
          <div className="singleRevisionInformation" style={{ minHeight: 0 }}>
            <p className="singleTranslationDecideTitle">{mode === 'order' ? 'Order Summary' : 'Quote Summary'}</p>
            <div className="singleQuoteSummary">
              <div className="orderSummaryInfo">
                <div className="orderSummaryInfoMain">
                  <p className="singleQuoteSummaryInfoType">{certified ? "Certified Translation" : "Standard Translation"}</p>
                  <p className="singleQuoteSummaryInfoCount">{certified ? (pagesCount !== '' ? pagesCount + " pages (" + (parseInt(pagesCount, 10) * 250) + " words max)" : "0 pages (0 words max)") : (wordCount !== '' ? wordCount + " words" : "0 words")}</p>
                </div>
                <p className="singleQuoteSummaryInfoType">{certified ? (pagesCount !== "" ? '$' + (parseInt(pagesCount, 10) * 24.95).toFixed(2) : "$0.00") : ((wordCount !== '' ? '$' + (parseInt(wordCount, 10) * 0.1).toFixed(2) : '$0.00'))}</p>
              </div>
              {notarization ? 
              <div className="orderSummaryInfo">
                <div className="orderSummaryInfoMain">
                  <p className="singleQuoteSummaryInfoType">Notarization</p>
                  <p className="singleQuoteSummaryInfoCount">Valid in all 50 states</p>
                </div>
                <p className="singleQuoteSummaryInfoType">$34.90</p>
              </div> : null}
              {expedited ? 
              <div className="orderSummaryInfo">
                <div className="orderSummaryInfoMain">
                  <p className="singleQuoteSummaryInfoType">Expedited Turnaround</p>
                  <p className="singleQuoteSummaryInfoCount">Turnaround reduced by 50%</p>
                </div>
                <p className="singleQuoteSummaryInfoType">{formatTotal(getExpedited())}</p>
              </div> : null}
              {hardCopy && hardCopyCountry !== '' && hardCopyMethod !== '' ? 
              <div className="orderSummaryInfo">
                <div className="orderSummaryInfoMain">
                  <p className="singleQuoteSummaryInfoType">{`USPS ${hardCopyMethod.charAt(0).toUpperCase() + hardCopyMethod.slice(1)} Mail ${!['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(hardCopyCountry) ? 'International' : ''}`}</p>
                  <p className="singleQuoteSummaryInfoCount">{`${['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(hardCopyCountry) ? (hardCopyMethod === 'priority' ? '2-3' : '1-2') : (hardCopyMethod === 'priority' ? '6-10' : '3-5')} days in transit`}</p>
                </div>
                <p className="singleQuoteSummaryInfoType">{`$${['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(hardCopyCountry) ? (hardCopyMethod === 'priority' ? '19.95' : '37.95') : (hardCopyMethod === 'priority' ? '49.95' : '78.95')}`}</p>
              </div> : null}
              {hardCopy && hardCopyRequireSignature ? 
              <div className="orderSummaryInfo">
                <div className="orderSummaryInfoMain">
                  <p className="singleQuoteSummaryInfoType">Signature Required for Delivery</p>
                  <p className="singleQuoteSummaryInfoCount">Adult signature required</p>
                </div>
                <p className="singleQuoteSummaryInfoType">$4.99</p>
              </div> : null}
              {discount > 0 ? 
                <div className="orderSummaryInfo">
                  <div className="orderSummaryInfoMain">
                    <p className="singleQuoteSummaryInfoType">{`Discount -${discount}%`}</p>
                  </div>
                  <p className="singleQuoteSummaryInfoType">{formatDiscount(getDiscount())}</p>
                </div> : null}
              <div className="orderSummaryTotal">
                <p>Total</p>
                <p>{formatTotal(getTotal() - getDiscount())}</p>
              </div>
            </div>
          </div>
          <div className="singleRevisionInformation" style={{ minHeight: 0 }}>
            <p className="singleTranslationDecideTitle">Apply a Discount</p>
            <p className="singleTranslationDetailsSecondaryText">
              {`Apply a discount to this order for the client to see. You will see it reflected in the ${mode === 'order' ? 'Order Summary' : 'Quote Summary'} above.`}
            </p>
            <FormSimpleTextInput placeholder={"Discount (%)"} value={discount} onChange={onChangeDiscount} color='#666' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManualOrder;