const headers = {
  "/": {
    title: 'Online Translation Services | eVerbary',
    description: 'Get your documents translated in 60+ languages by a professional translator for just $24.95USD per page with 24 hour delivery. Translation, Localization & Interpretation services.'
  },
  "/order/payment": {
    title: 'Payment | eVerbary',
    description: 'We accept credit and debit cards, wire transfers, eCheck, PayPal and TransferWise. Quick, affordable and accurate Translations, Localizations & Interpretations.'
  },
  "/order/options": {
    title: 'Options | eVerbary',
    description: 'Our optional services cover notarization, shipping of a hard copy, expedited turnaround and translation certification.'
  },
  "/order/documents": {
    title: 'Documents | eVerbary',
    description: 'We can translate nearly any legible document in almost any format. If you are unsure if your document is translatable, please contact our support team.'
  },
  "/order/contact": {
    title: 'Contact | eVerbary',
    description: 'Online, quick, affordable and accurate Translation, Localization & Interpretation services. Same-day delivery. Create an order online now.'
  },
  "/quote/details": {
    title: 'Quote Details | eVerbary',
    description: 'Request a Free Quote Online. Quick, affordable and accurate Translation, Localization & Interpretation services. Same-day delivery.'
  },
  "/quote/contact": {
    title: 'Contact | eVerbary',
    description: 'Request a Free Quote Online. Quick, affordable and accurate Translation, Localization & Interpretation services.'
  },
  "/account/shipping": {
    title: 'Shipping | eVerbary',
    description: 'USPS Priority and Express Mail services with tracking. Pricing and transit times vary by destination and are listed during checkout.'
  },
  "/account/orders": {
    title: 'Your Orders | eVerbary',
    description: 'See a complete list of your orders online. Easy-tracking.'
  },
  "/account/quotes": {
    title: 'Your Quotes | eVerbary',
    description: 'See a complete list of your quotes online. Easy-tracking.'
  },
  "/account/profile": {
    title: 'Your Profile | eVerbary',
    description: 'Create an online profile. See a detailed list of your orders and quotes online. Easy-tracking.'
  },
  // "/guides": {
  //   title: 'Translation, USCIS, Notarization, Apostille Guides | eVerbary',
  //   description: "These guides will help you navigate the translation and submittal process. It can be tricky if you aren't experienced, but we are here to help."
  // },
  // "/guide/differences": {
  //   title: 'Certified and Standard Translation Differences | eVerbary',
  //   description: "There are no quality differences between certified translations and standard translations as the same professional translators perform both translation types. ... Certified translations have specific requirements that the translator must follow that aren't required of standard translations."
  // },
  // "/guide/count": {
  //   title: 'How to Count Pages for Certified Translations',
  //   description: 'We follow the industry standard for calculating a page of certified translation as 250 words. For example, a document with 1,000 words would be priced as four pages. We divide 1,000 words by 250 words to arrive at the four-page count.'
  // },
  // "/guide/notarization": {
  //   title: 'Notarization for Certified Translations | eVerbary',
  //   description: 'A notary public is a public servant appointed to authenticate the signer of the translation certification that comes with a certified translation.'
  // },
  // "/guide/uscis": {
  //   title: 'USCIS Certified Translation Requirements | eVerbary',
  //   description: '100% USCIS translation guarantee. The translation must be a true and accurate translation of the source document. The translation must be certified by a translator competent in English and the source language...'
  // },
  // "/guide/foreign": {
  //   title: 'Foreign Credential Evaluation for Certified Translations | eVerbary',
  //   description: 'Certified translation into English allows both the credential evaluator and the final receiver to read the original foreign language document. These services are performed by different specialists who have specific professional training and experience in their field.'
  // },
  // "/guide/apostille": {
  //   title: 'How to Obtain an Apostille for a Notarized Certified Translation | eVerbary',
  //   description: 'Follow this short guide to obtain an apostille to authenticate the notarization on our Certificate of Translation Accuracy for the international use of your translation.'
  // },
  // "/guide/process": {
  //   title: 'Translation Process Overview | eVerbary',
  //   description: 'Obtain Your Word Count The total word count of your documents will determine the price for both certified translation and standard translation. Certified translation is charged on a per-page basis with a page being defined as 250 words or fewer. ... Standard translation is charged on a per word basis.'
  // },
  // "/guide/glossary": {
  //   title: 'Glossary of Translation Terms | eVerbary',
  // description: 'If you do not order translations regularly there might be some terminology you are unfamiliar with. This guide provides definitions for common terms you might run across.'
  // },
  // "/guide/turnaround": {
  //   title: 'Translation Turnaround Times | eVerbary',
  //   description: 'When we talk about turnaround times, we are referring to the time it takes from when an order is assigned to a translator until the time it is delivered back to the ... 400 words per hour...'
  // },
  "/terms": {
    title: 'Terms of Service | eVerbary',
    description: 'By visiting our website or making a purchase from us you agree to be bound by the following terms and conditions, please read them fully.'
  },
  "/refunds": {
    title: 'Refund Policy | eVerbary',
    description: 'Cancellations. You may request a cancellation anytime before your order is completed. We will prorate the refund for your primary service (translation) based on the amount of work the translator has completed on your project.'
  },
  "/privacy": {
    title: 'Privacy Policy | eVerbary',
    description: 'Personal Information We Collect. When you visit https://eVerbary.com(the "Site"), we automatically collect certain information about your device, including ...'
  },
  // "/jobs": {
  //   title: 'Online Translator Jobs | eVerbary',
  //   description: 'Join our team of professional translators! We offer bi-weekly payouts, standard and expedited rates, organized and efficient project management software, ...'
  // },
  "/about": {
    title: 'About | eVerbary',
    description: 'About eVerbary. eVerbary helps individuals and businesses with quick and affordable document translation services. ... We are excited to have you as a ...'
  },
  "/business": {
    title: 'Translation Services for Businesses | eVerbary',
    description: 'We support businesses of all sizes across dozens of industries with seamless and efficient workflows to ensure all materials are properly translated and ...'
  },
  "/contact": {
    title: 'Contact | eVerbary',
    description: 'Our support staff is available to assist you via WhatsApp and email daily from 8 a.m. - 11 p.m. PST and after-hours for urgent matters. Check the FAQ and our translation guides ...'
  },
  "/order-lookup": {
    title: 'Order Lookup | eVerbary',
    description: 'Lookup your order to check the status, download files, request revisions, write a review, etc. Your order number can be found in your order confirmation email.'
  },
  // "/sign-in": {
  //   title: 'Sign In | eVerbary',
  //   description: 'Sign in to Your Account. Welcome back! Please sign in to your account to continue. Need to create an account? Forgot your password? Reset it. Email'
  // },
  "/forgot": {
    title: 'Reset Password | eVerbary',
    description: "We'll email you a reset link to confirm you own the email address associated with this account and then you'll be able to reset your password and sign in."
  },
  // "/create-account": {
  //   title: 'Create Account | eVerbary',
  //   description: 'Create an account to access your quote and order history, saved addresses, payment methods, and site preferences anytime.'
  // },
  "/faq": {
    title: 'FAQ | eVerbary',
    description: 'Checkout our frequently asked questions to learn more about the online translation services offered by eVerbary.'
  },
  "/reviews": {
    title: 'Verified Customer Reviews | eVerbary',
    description: 'Very satisfied with eVerbary. I have used them to translate my documents that needed for USCIS. I have translated with them 3 times. Great service ...'
  },
  "/use-cases": {
    title: 'Use Cases | eVerbary',
    description: 'Certified Translation Use Cases. Immigration – USCIS Acceptance. Government – Offical Uses. Academic – University Admissions. Financial – Mortgage Processing. Legal – Court Cases. Medical – Health History.'
  },
  "/use-case": {
    title: 'Use Cases | eVerbary',
    description: 'Certified Translation Use Cases. Immigration – USCIS Acceptance. Government – Offical Uses. Academic – University Admissions. Financial – Mortgage Processing. Legal – Court Cases. Medical – Health History.'
  },
  // "/use-case/immigration": {
  //   title: 'Certified Translation for Immigration - USCIS | eVerbary',
  //   description: 'A word-for-word human translation of documents delivered on eVerbary letterhead including a signed and stamped Certificate of Translation Accuracy for official use as required by U.S. Immigration (USCIS), universities, courts, and many local, state, and federal governments.'
  // },
  // "/use-case/legalStandard": {
  //   title: 'Translation of Legal Documents | eVerbary',
  //   description: 'Have your legal documents translated for your personal or business use. Perfect for contracts, agreements, offers, and any number of other legal documents for ...'
  // },
  // "/use-case/digital": {
  //   title: 'Translation of Digital Content | eVerbary',
  //   description: 'Expand your reach into new markets by having your blog posts, product listings, audio transcripts, etc. translated.'
  // },
  // "/use-case/government": {
  //   title: 'Certified Translation for Offical Government Use | eVerbary',
  //   description: 'A word-for-word human translation of documents delivered on eVerbary letterhead including a signed and stamped Certificate of Translation Accuracy for official use as required by U.S. Immigration (USCIS), universities, courts, and many local, state, and federal governments.'
  // },
  // "/use-case/academic": {
  //   title: 'Certified Translation for Academic Admissions | eVerbary',
  //   description: 'A word-for-word human translation of documents delivered on eVerbary letterhead including a signed and stamped Certificate of Translation Accuracy for official use as required by U.S. Immigration (USCIS), universities, courts, and many local, state, and federal governments.'
  // },
  // "/use-case/financial": {
  //   title: 'Certified Translation for Obtaining a Mortgage | eVerbary',
  //   description: 'A word-for-word human translation of documents delivered on eVerbary letterhead including a signed and stamped Certificate of Translation Accuracy for official use as required by U.S. Immigration (USCIS), universities, courts, and many local, state, and federal governments.'
  // },
  // "/use-case/legal": {
  //   title: 'Certified Translation for Legal Use in Court | eVerbary',
  //   description: 'A word-for-word human translation of documents delivered on eVerbary letterhead including a signed and stamped Certificate of Translation Accuracy for official use as required by U.S. Immigration (USCIS), universities, courts, and many local, state, and federal governments.'
  // },
  // "/use-case/medical": {
  //   title: 'Certified Translation of Medical History | eVerbary',
  //   description: 'A word-for-word human translation of documents delivered on eVerbary letterhead including a signed and stamped Certificate of Translation Accuracy for official use as required by U.S. Immigration (USCIS), universities, courts, and many local, state, and federal governments.'
  // },
  // "/use-case/communications": {
  //   title: 'Translation of Business Communications | eVerbary',
  //   description: 'Critical documents, such as internal and B2B correspondence (emails, text messages, etc.) between parties that speak different languages, can be translated to facilitate communication.'
  // },
  // "/use-case/documents": {
  //   title: 'Translation of Business Documents | eVerbary',
  //   description: 'Internal business documents such as handbooks, manuals, memos, etc., are often translated so they can be provided to employees that are unable to read the original language...'
  // },
  "/languages": {
    title: 'Language Translation Services | eVerbary',
    description: '60+ Languages Translated by Native Speaking Professional Translators ... member of our translation team is fluent in English as well as their native language.'
  },
  // "/documents": {
  //   title: 'Documents We Translate | eVerbary',
  //   description: "Upload Any Document for Translation. Birth Certificate. Driver's License. Diploma. Medical Records. Bank Statement. Marriage Certificate. Apostille."
  // },
  "/standard": {
    title: 'Standard Translation Services | eVerbary',
    description: 'Get your documents or text-based content translated in 60+ languages by a professional translator for just $0.10 per word with 24 hour delivery. Legal Contracts. Business Digital Content. Business Communications.'
  },
  "/interpretations": {
    title: 'Interpretations | eVerbary',
    description: 'Consecutive interpretation, simultaneous interpretation, sight interpretation, and more.'
  },
  "/certified": {
    title: 'Certified Translation Services | eVerbary',
    description: '100% USCIS Acceptance Guarantee. eVerbary is among the largest providers of certified translations in the United States. Our services are utilized by tens of thousands businesses and ...'
  },
  // "/language/spanish": {
  //   title: 'Certified Spanish Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Spanish translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Albanian": {
  //   title: 'Certified Albanian Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Albanian translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Arabic": {
  //   title: 'Certified Arabic Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Arabic translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Armenian": {
  //   title: 'Certified Armenian Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Armenian translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Bengali": {
  //   title: 'Certified Bengali Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Bengali translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Bosnian": {
  //   title: 'Certified Bosnian Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Bosnian translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Bulgarian": {
  //   title: 'Certified Bulgarian Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Bulgarian translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Burmese": {
  //   title: 'Certified Burmese Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Burmese translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Catalan": {
  //   title: 'Certified Catalan Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Catalan translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/ChineseSimp": {
  //   title: 'Certified Chinese (Simplified) Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Chinese (Simplified) translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Chinesetrad": {
  //   title: 'Certified Chinese (Traditional) Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Chinese (Traditional) translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Creole": {
  //   title: 'Certified Creole Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Creole translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Croatian": {
  //   title: 'Certified Croatian Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Croatian translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Czech": {
  //   title: 'Certified Czech Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Czech translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Danish": {
  //   title: 'Certified Danish Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Danish translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Dutch": {
  //   title: 'Certified Dutch Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Dutch translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/English": {
  //   title: 'Certified English Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional English translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Farsi": {
  //   title: 'Certified Farsi Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Farsi translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Finnish": {
  //   title: 'Certified Finnish Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Finnish translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/French": {
  //   title: 'Certified French Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional French translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/FrenchCan": {
  //   title: 'Certified French (Canadian) Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional French (Canadian) translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Georgian": {
  //   title: 'Certified Georgian Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Georgian translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/German": {
  //   title: 'Certified German Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional German translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Greek": {
  //   title: 'Certified Greek Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Greek translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Gujrati": {
  //   title: 'Certified Gujrati Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Gujrati translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Hebrew": {
  //   title: 'Certified Hebrew Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Hebrew translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Hindi": {
  //   title: 'Certified Hindi Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Hindi translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Hungarian": {
  //   title: 'Certified Hungarian Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Hungarian translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Indonesian": {
  //   title: 'Certified Indonesian Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Indonesian translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Italian": {
  //   title: 'Certified Italian Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Italian translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Japanese": {
  //   title: 'Certified Japanese Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Japanese translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Kannada": {
  //   title: 'Certified Kannada Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Kannada translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Korean": {
  //   title: 'Certified Korean Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Korean translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Latin": {
  //   title: 'Certified Latin Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Latin translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Latvian": {
  //   title: 'Certified Latvian Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Latvian translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Lithuanian": {
  //   title: 'Certified Lithuanian Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Lithuanian translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Macedonian": {
  //   title: 'Certified Macedonian Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Macedonian translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Malay": {
  //   title: 'Certified Malay Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Malay translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Maltese": {
  //   title: 'Certified Maltese Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Maltese translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Marathi": {
  //   title: 'Certified Marathi Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Marathi translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Nepali": {
  //   title: 'Certified Nepali Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Nepali translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Norwegian": {
  //   title: 'Certified Norwegian Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Norwegian translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Persian": {
  //   title: 'Certified Persian Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Persian translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Polish": {
  //   title: 'Certified Polish Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Polish translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/PortugueseBra": {
  //   title: 'Certified Portuguese (Brazil) Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Portuguese (Brazil) translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/PortuguesePor": {
  //   title: 'Certified Portuguese (Portuguese) Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Portuguese (Portuguese) translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Punjabi": {
  //   title: 'Certified Punjabi Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Punjabi translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Romanian": {
  //   title: 'Certified Romanian Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Romanian translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Russian": {
  //   title: 'Certified Russian Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Russian translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Serbian": {
  //   title: 'Certified Serbian Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Serbian translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Slovak": {
  //   title: 'Certified Slovak Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Slovak translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Slovenian": {
  //   title: 'Certified Slovenian Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Slovenian translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Somali": {
  //   title: 'Certified Somali Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Somali translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Swedish": {
  //   title: 'Certified Swedish Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Swedish translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Tagalog": {
  //   title: 'Certified Tagalog Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Tagalog translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Tamil": {
  //   title: 'Certified Tamil Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Tamil translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Thai": {
  //   title: 'Certified Thai Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Thai translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Turkish": {
  //   title: 'Certified Turkish Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Turkish translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Ukranian": {
  //   title: 'Certified Ukranian Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Ukranian translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Urdu": {
  //   title: 'Certified Urdu Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Urdu translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Uzbek": {
  //   title: 'Certified Uzbek Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Uzbek translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/language/Vietnamese": {
  //   title: 'Certified Vietnamese Translation | eVerbary',
  //   description: 'Get your documents translated and certified by a professional Vietnamese translator for just $24.95 per page with 24 hour delivery... 100% USCIS acceptance guarantee. Notarization & Hard Copy Available.'
  // },
  // "/document/academic-transcripts": {
  //   title: 'Certified Academic Transcripts Translation | eVerbary',
  //   description: 'Get your academic transcripts translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'
  // },
  // "/document/adoption-documents": {
  //   title: 'Certified Adoption Documents Translation | eVerbary',
  //   description: 'Get your adoption docuemnts translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'
  // },
  // "/document/affidavit": {
  //   title: 'Certified Affidavit Translation | eVerbary',
  //   description: 'Get your affidavit translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'
  // },
  // "/document/apostille": {
  //  title:  'Apostille Translation Services  | eVerbary',
  //  description: 'Get your apostille translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'   
  // },
  // "/document/asylum-documents": {
  //  title:  'Asylum Documents Translation Services  | eVerbary',
  //  description: 'Get your asylum documents translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'
  // },
  // "/document/background-check": {
  //  title:  'Background Check Translation Services  | eVerbary',
  //  description: 'Get your background check documents translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'
  // },
  // "/document/bank-statement": {
  //  title:  'Bank Statement Translation Services  | eVerbary',
  //  description: 'Get your bank statements translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'
  // },
  // "/document/birth-certificate": {
  //  title:  'Birth Certificate Translation Services  | eVerbary',
  //  description: 'Get your birth certificate translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'    
  // },
  // "/document/brochure": {
  //  title:  'Brochure Translation Services  | eVerbary',
  //  description: 'Get your brochure translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'
  // },
  // "/document/business-license": {
  //  title:  'Business License Translation Services  | eVerbary',
  //  description: 'Get your business license documents translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'
  // },
  // "/document/change-of-name": {
  //  title:  'Change Of Name Translation Services  | eVerbary',
  //  description: 'Get your change-of-name documents translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'
  // },
  // "/document/course-guide": {
  //  title:  'Course Guide Translation Services | eVerbary',
  //  description: 'Get your course guides translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...' 
  // },
  // "/document/criminal-record": {
  //  title:  'Criminal Record Translation Services | eVerbary',
  //  description: 'Get your criminal records translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...' 
  // },
  // "/document/death-certificate": {
  //  title:  'Death Certificate Translation Services | eVerbary',
  //  description: 'Get your death certificate translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...' 
  // },
  // "/document/deposition": {
  //  title:  'Deposition Translation Services | eVerbary',
  //  description: 'Get your deposition documents translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'   
  // },
  // "/document/diploma": {
  //  title:  'Diploma Translation Services | eVerbary',
  //  description: 'Get your diploma translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'   
  // },
  // "/document/divorce-documents": {
  //  title:  'Divorce Documents Translation Services | eVerbary',
  //  description: 'Get your divorce documents translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'  
  // },
  // "/document/drivers-license": {
  //  title: "Driver's License Translation Services | eVerbary",
  //  description: "Get your driver's license translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization..."
  // },
  // "/document/email": {
  //  title:  'Email Translation Services | eVerbary',
  //  description: 'Get your emails translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'  
  // },
  // "/document/employee-manual": {
  //  title:  'Employee Manual Translation Services | eVerbary',
  //  description: 'Get your employee manuals translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'    
  // },
  // "/document/financial-statement": {
  //  title:  'Financial Statement Translation Services | eVerbary',
  //  description: 'Get your financial statements translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'    
  // },
  // "/document/handbook": {
  //  title:  'Handbook Translation Services | eVerbary',
  //  description: 'Get your handbooks translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'   
  // },
  // "/document/legal-contract": {
  //  title:  'Legal Contract Translation Services | eVerbary',
  //  description: 'Get your legal contracts translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'    
  // },
  // "/document/marriage-certificate": {
  //  title:  'Marriage Certificate Translation Services | eVerbary',
  //  description: 'Get your marriage certificate translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'    
  // },
  // "/document/medical-records": {
  //  title:  'Medical Records Translation Services | eVerbary',
  //  description: 'Get your medical records translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'      
  // },
  // "/document/meeting-minutes": {
  //  title:  'Meeting Minutes Translation Services | eVerbary',
  //  description: 'Get your meeting minutes translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'     
  // },
  // "/document/mortgage-application": {
  //  title:  'Mortgage Application Translation Services | eVerbary',
  //  description: 'Get your mortgage application translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'      
  // },
  // "/document/passport": {
  //  title:  'Passport Translation Services | eVerbary',
  //  description: 'Get your passport translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'     
  // },
  // "/document/patent-application": {
  //  title:  'Patent Application Translation Services | eVerbary',
  //  description: 'Get your patent application translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'      
  // },
  // "/document/press-release": {
  //  title:  'Press Release Translation Services | eVerbary',
  //  description: 'Get your press release translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'       
  // },
  // "/document/product-manual": {
  //  title:  'Product Manual Translation Services | eVerbary',
  //  description: 'Get your product manual translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'        
  // },
  // "/document/rental-agreement": {
  //  title:  'Rental Agreement Translation Services | eVerbary',
  //  description: 'Get your rental agreement translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'         
  // },
  // "/document/resume": {
  //  title:  'Resume Translation Services | eVerbary',
  //  description: 'Get your resume translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'        
  // },
  // "/document/tax-returns": {
  //  title:  'Tax Returns Translation Services | eVerbary',
  //  description: 'Get your tax returns translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'        
  // },
  // "/document/text-message": {
  //  title:  'Text Message Translation Services | eVerbary',
  //  description: 'Get your text messages translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'        
  // },
  // "/document/university-application": {
  //  title:  'University Application Translation Services | eVerbary',
  //  description: 'Get your University Application documents translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'        
  // },
  // "/document/vaccination-record": {
  //  title:  'Vaccination Record Translation Services | eVerbary',
  //  description: 'Get your vaccination records translated and certified by a professional translator for just $24.95 per page with 24 hour delivery. 100% Acceptance Guarantee. Notarization...'         
  // },
  "/verify": {
    title: 'Verify the Authenticity of Our Translation | eVerbary',
    description: 'Verify the authenticity of the document provided to you and compare your copy against the original translated by eVerbary, LLC.'
  },
  "default": {
    title: 'Online Translation Services | eVerbary',
    description: 'Get your documents translated in 60+ languages by a professional translator with 24 hour delivery. Translation, Localization & Interpretation services. 100% USCIS Acceptance Guarantee.'
  }
};

export { headers };