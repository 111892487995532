import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Editor, EditorState, convertFromRaw, convertToRaw, RichUtils } from 'draft-js';
import { v4 as uuidv4 } from 'uuid';
import { animated } from 'react-spring';
import axios from 'axios';

import { StopCircle, Trash2, Bold, Italic, Underline, List } from 'react-feather';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { getBlob } from "@firebase/storage";

import { FileUploaderButton } from '../../Components/FileUploaderButton';
import { AltAnimatedButton } from '../../Components/AltAnimatedButton';

import { languages } from '../../Data/languages';

import { NotificationContext } from '../../Context/NotificationContext';
import { DataContext } from '../../Context/DataContext';

import './SingleRevision.css';
import { useScreenTransition } from '../../Hooks/useScreenTransition';

const SingleRevision = ({ windowSize }) => {

  const { id } = useParams();

  const { transitionTo } = useScreenTransition();

  const { showNotification } = useContext(NotificationContext);

  const { userData } = useContext(DataContext);

  const [tab, setTab] = useState('details');

  const [files, setFiles] = useState([]);

  const [orderInfo, setOrderInfo] = useState(null);

  const [documentName, setDocumentName] = useState('');

  const [clientRevisionNotes, setClientRevisionNotes] = React.useState(() => EditorState.createEmpty());

  const [translatorNotes, setTranslatorNotes] = React.useState(() => EditorState.createEmpty());

  const [translatorNotesClient, setTranslatorNotesClient] = React.useState(() => EditorState.createEmpty());

  const [supportNotes, setSupportNotes] = React.useState(() => EditorState.createEmpty());

  const [coversheetButton, setCoversheetButton] = useState('Generate Coversheet');
  const [reUploadingCoversheet, setReUploadingCoversheet] = useState("");

  const [submitRevisionButton, setSubmitRevisionButton] = useState('Submit Revision');

  useEffect(() => {
    if (id) {
      const firestore = firebase.firestore();

      const unsubscribe = firestore.collection('orders').doc(id)
        .onSnapshot(doc => {
          if (doc.exists) {
            setOrderInfo({ ...doc.data(), id: doc.id });
            
            if (doc.data().clientRevisionNotes) {
              setClientRevisionNotes(EditorState.createWithContent(convertFromRaw(doc.data().clientRevisionNotes)));
            }

            if (doc.data().supportNotes) {
              setSupportNotes(EditorState.createWithContent(convertFromRaw(doc.data().supportNotes)))
            }

            if (doc.data().translatorNotes) {
              setTranslatorNotes(EditorState.createWithContent(convertFromRaw(doc.data().translatorNotes)))
            }

            if (doc.data().translatorNotesClient) {
              setTranslatorNotesClient(EditorState.createWithContent(convertFromRaw(doc.data().translatorNotesClient)))
            }

            if (!userData || (userData.role === 'Translator' && userData.id !== doc.data().translator.id) || ['created', 'new', 'assigned', 'in-progress'].includes(doc.data().status)) {
              showNotification('Error', "You don't have sufficient permissions to see this order. If you think you have the correct permissions, please contact our support team.", 'error');
              transitionTo('/office/revisions');
            }
          } else {
            showNotification('Error', "There is no order with that ID. Please, try again.", 'error');
            transitionTo('/office/revisions');
          }
        }, error => {
          showNotification('Error', "There was an error loading the order info. Please, reload the page.", 'error');
        })

      return () => unsubscribe();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orderInfo && orderInfo.status === 'revision') {
      const now = new Date();

      if (document.getElementById("progressCircle")) {
        var circle = document.getElementById("progressCircle");
        var radius = circle.r.baseVal.value;
        var circumference = radius * 2 * Math.PI;

        circle.style.strokeDasharray = `${circumference} ${circumference}`;
        circle.style.strokeDashoffset = `${circumference}`;

        const percent = ((now.getTime() - orderInfo.revisionAssigned) / (orderInfo.revisionDue - orderInfo.revisionAssigned));

        const offset = percent <= 1 ? circumference - percent * circumference : 0;
        circle.style.strokeDashoffset = `${offset}`;
      }
    }

    setFiles(localFiles => localFiles.filter(f => !orderInfo.files.some(mFile => mFile.name === f.name)));
  }, [orderInfo, tab]);

  const handleFiles = (e) => {
    const mFiles = e.target.files;

    for (let i = 0; i < mFiles.length; i++) {
      // if (!files.some(file => file.name === mFiles[i].name) && !orderInfo.files.some(file => file.name === mFiles[i].name)) {
        const fileID = uuidv4();
        setFiles(f => f.concat([{ id: fileID, name: mFiles[i].name, file: mFiles[i], progress: 0, status: 'uploading' }]));
      // } else {
      //   showNotification("File already uploaded.", "You've already uploaded a file with this name. Try uploading with another name.", "error");
      // }
    }

    const storageRef = firebase.storage().ref();
    
    for (let i = 0; i < mFiles.length; i++) {
      // if (!files.some(file => file.name === mFiles[i].name) && !orderInfo.files.some(file => file.name === mFiles[i].name)) {
        const subFolder = uuidv4();
      
        const fileRef = storageRef.child(orderInfo.id + '/' + subFolder + '/'  + mFiles[i].name);

        const downloadToken = uuidv4();

        const fileName = mFiles[i].name.replace('#', '%23').replace('+', "%2b");

        const url = `https://firebasestorage.googleapis.com/v0/b/project-everbary.appspot.com/o/${orderInfo.id}%2F${subFolder}%2F${fileName}?alt=media&token=${downloadToken}`;

        var metadata = {
          firebaseStorageDownloadTokens: downloadToken,
          customMetadata: {
            'orderID': orderInfo.id
          }
        }

        const uploadTask = fileRef.put(mFiles[i], metadata);

        setFiles(f => f.map(file => file.name === mFiles[i].name && file.ref === mFiles[i].ref ? { ...file, task: uploadTask } : file));

        uploadTask.on('state_changed', (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setFiles(f => f.map(file => file.name === mFiles[i].name && file.ref === mFiles[i].ref ? { ...file, progress: progress.toFixed(2), type: 'revision' } : file));
        }, (error) => {
          setFiles(f => f.map(file => file.name === mFiles[i].name && file.ref === mFiles[i].ref ? { ...file, status: 'failed', type: 'revision' } : file));
          showNotification("Error uploading.", "There was an error uploading the file " + mFiles[i].name + ". Please, try again.", "error");
        }, () => {
          setFiles(f => f.map(file => file.name === mFiles[i].name && file.ref === mFiles[i].ref ? { ...file, status: 'complete', ref: orderInfo.id + '/' + subFolder + '/'  + mFiles[i].name, url: url, type: 'revision' } : file));
          
          const firestore = firebase.firestore();

          firestore.collection('orders').doc(orderInfo.id)
            .update({
              files: firebase.firestore.FieldValue.arrayUnion({ name: mFiles[i].name, ref: orderInfo.id + '/' + subFolder + '/'  + mFiles[i].name, url: url, type: 'revision', size: mFiles[i].size, format: mFiles[i].type,timestamp: (new Date()).getTime() })
            })
            .then(() => {
            })
            .catch(error => {
              showNotification("Error uploading.", "There was an error uploading the file " + mFiles[i].name + ". Please, try again.", "error");
            })
        });
      // }
    }
  }

  const handleDeleteFile = file => {
    if (file.type !== 'revision' && file.type !== 'coversheet') {
      showNotification("Error deleting the file.", "You don't have the permissions to delete this file.", "error");
      return false;
    }

    const storageRef = firebase.storage().ref();

    if (file.status === 'complete') {
      const fileRef = storageRef.child(file.ref);
      setFiles(f => f.map(mFile => mFile.name === file.name && mFile.ref === file.ref ? { ...mFile, status: 'deleting' } : mFile));

      fileRef.delete()
        .then(function() {
          setFiles(f => f.filter(mFile => mFile.ref !== file.ref));
        }).catch(function(error) {
          showNotification("Error deleting the file.", "There was an error deleting the file " + file.name + ". Please, try again.", "error");
        });
    } else if (file.status === 'failed') {
      setFiles(f => f.filter(mFile => mFile.ref !== file.ref));
    } else {
      file.task.cancel();
      setFiles(f => f.filter(mFile => mFile.ref !== file.ref));
    }
  }

  const handleDeleteUploadedFile = async file => {
    if (file.type !== 'revision' && file.type !== 'coversheet') {
      showNotification("Error deleting the file.", "You don't have the permissions to delete this file.", "error");
      return false;
    }

    const mFiles = orderInfo.files.filter(f => f.ref !== file.ref);

    const firestore = firebase.firestore();

    const storage = firebase.storage();
    const storageRef = storage.ref();
    const fileRef = storageRef.child(file.ref);

    await fileRef.delete();

    firestore.collection('orders').doc(orderInfo.id)
      .update({
        files: mFiles
      })
      .then(() => {
        showNotification("Success", "The file " + file.name + " has been successfully deleted.", "success");
      })
      .catch(error => {
        showNotification("Error deleting the file.", "There was an error deleting the file " + file.name + ". Please, try again.", "error");
      });
  }

  const formatSize = size => {
    if (!size) {
      return '';
    }

    if (size > 1000000) {
      return (size / 1000000).toFixed(2) + "MB"
    } else {
      return (size / 1000).toFixed(2) + "KB"
    }
  }

  const formatDate = timestamp => {
    if (!timestamp) {
      return '';
    }

    const date = new Date(timestamp);

    const splitted = date.toDateString().split(' ');

    return splitted[1] + ' ' + splitted[2] + ', ' + splitted[3] + ' @ ' + date.toTimeString().substr(0, 5);
  }

  const capitalize = text => {
    if (!text) {
      return '';
    }

    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  const downloadFile = url => {
      window.open(url)
  }

  const reUploadSheet = (path) => {
    const storage = firebase.storage();
    let oldRef = storage.ref().child(path);
    
    getBlob(oldRef).then(blob => {
      oldRef.delete();
      oldRef.put(blob).then(() => {
            setReUploadingCoversheet("");
            setCoversheetButton("Generate Coversheet");
            showNotification(
              "Success",
              "The coversheet for your file has been successfully created.",
              "success"
            );
      })
    })
    }

  const generateCoversheet = () => {
    // setCoversheetButton('Generating...'); 
    const mDocName = `${documentName}-${capitalize(orderInfo.languageFrom)} to ${capitalize(orderInfo.languageTo)}-${orderInfo.orderNumber}`;
    setDocumentName('');

    // if (!orderInfo.files.some(file => file.name === mDocName || file.name === mDocName + '.docx')) {
      axios({
        method:'post',
        url: "https://us-central1-project-everbary.cloudfunctions.net/generateCoversheet",
        data: {
          docName: mDocName,
          orderID: orderInfo.id,
          languageFrom: orderInfo.languageFrom,
          languageTo: orderInfo.languageTo,
          translatorCertification: orderInfo.translatorCertification ? orderInfo.translatorCertification : false,
        }
      })
        .then((res) => {
          reUploadSheet(res.data);
          setReUploadingCoversheet(res.data);
        })
        .catch((error) => {
          setCoversheetButton('Generate Coversheet');
          showNotification("Error", "An error ocurred generating the coversheet. Please, try again. If it persists, contact technical support.", "error");
        });
    // } else {
    //   setCoversheetButton('Generate Coversheet');
    //   showNotification("Error", "There's already a file with that name. Please, try again.", "error");
    // }  
  }

  const submitRevision = () => {
    if (userData && userData.role === 'Translator' && orderInfo.files.some(f => f.type === 'revision') && (!orderInfo.certified || (orderInfo.files.some(f => f.type === 'revision' && f.format === 'application/pdf') && orderInfo.files.some(f => f.type === 'revision' && f.format === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')))) {
      setSubmitRevisionButton('Submitting...');
      
      const firestore = firebase.firestore();

      const now = new Date();

      firestore.collection('orders').doc(orderInfo.id)
        .update({
          status: orderInfo.supportApproved ? 'user-review' : 'support-review',
          revisionSubmitted: now.getTime()
        })
        .then(() => {
          showNotification("Success", "Revision has been submitted.", "success");
          setSubmitRevisionButton('Submit Revision');
        })
        .catch(error => {
          setSubmitRevisionButton('Submit Revision');
          showNotification("Error", "An error ocurred submitting the revision. Please, try again. If it persists, contact technical support.", "error");
        })
    }
  }

  const reopenRevision = () => {
    if (window.confirm('Are you sure you want to reopen the revision process? This may affect any support user that is already reviewing this order.')) {

      const firestore = firebase.firestore();

      const now = new Date();

      firestore.collection('orders').doc(orderInfo.id)
        .update({
          status: 'revision',
        })
        .then(() => {
          showNotification("Success", "Revision has been reopened.", "success");

          firestore.collection('notifications').add({
            timestamp: now.getTime(),
            type: 'revisionReopen',
            data: {
              userID: userData.id,
              name: userData.name,
              orderID: orderInfo.id,
              orderNumber: orderInfo.orderNumber
            },
            target: ['owner', 'Support'],
            read: []
          });
        })
        .catch(error => {
          showNotification("Error", "An error ocurred reopening the revision process. Please, try again. If it persists, contact technical support.", "error");
        })
    } else {
      showNotification("Error", "An error ocurred reopening the revision process. Please, try again. If it persists, contact technical support.", "error");
    } 
  }

  const saveTranslatorNotes = () => {
    const firestore = firebase.firestore();

    firestore.collection('orders').doc(orderInfo.id)
      .update({
        translatorNotes: convertToRaw(translatorNotes.getCurrentContent())
      })
      .then(() => {
        showNotification("Success", "Notes saved succesfully!", "success");
      })
      .catch(error => {
        showNotification("Error", "An error ocurred saving the notes. Please, try again. If it persists, contact technical support.", "error");
      })
  }

  const saveTranslatorNotesClient = () => {
    const firestore = firebase.firestore();

    firestore.collection('orders').doc(orderInfo.id)
      .update({
        translatorNotesClient: convertToRaw(translatorNotesClient.getCurrentContent()),
        translatorRevisionNotesFlag: orderInfo.translatorRevisionNotesFlag ? false : true
      })
      .then(() => {
        showNotification("Success", "Notes saved succesfully!", "success");
      })
      .catch(error => {
        showNotification("Error", "An error ocurred saving the notes. Please, try again. If it persists, contact technical support.", "error");
      })
  }

  const handleBoldClick = target => {
    if (target === 'client') {
      setTranslatorNotesClient(editorState => RichUtils.toggleInlineStyle(editorState, 'BOLD'))
    } else {
      setTranslatorNotes(editorState => RichUtils.toggleInlineStyle(editorState, 'BOLD'))
    }
  }

  const handleItalicClick = target => {
    if (target === 'client') {
      setTranslatorNotesClient(editorState => RichUtils.toggleInlineStyle(editorState, 'ITALIC'))
    } else {
      setTranslatorNotes(editorState => RichUtils.toggleInlineStyle(editorState, 'ITALIC'))
    }
  }

  const handleUnderlineClick = target => {
    if (target === 'client') {
      setTranslatorNotesClient(editorState => RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'))
    } else {
      setTranslatorNotes(editorState => RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'))
    }
  }

  const toggleUL = target => {
    if (target === 'client') {
      setTranslatorNotesClient(editorState => RichUtils.toggleBlockType(editorState,'unordered-list-item'))
    } else {
      setTranslatorNotes(editorState => RichUtils.toggleBlockType(editorState,'unordered-list-item'))
    }
  }

  return (
    <div className="singleTranslationContainer">
      <div className="singleTranslationHeader">
        <p className="singleTranslationOrderNumber">{orderInfo && "#" + orderInfo.orderNumber}</p>
        {orderInfo && <div className="singleTranslationTabsControl">
          <p className="singleTranslationTabButton" onClick={() => setTab('details')} style={{ color: tab === 'details' ? '#FF7C1D' : '#666', borderColor: tab === 'details' ? 'rgba(255, 124, 29, 1)' : 'rgba(255, 124, 29, 0)' }}>Details</p>
          <p className="singleTranslationTabButton" onClick={() => setTab('files')} style={{ color: tab === 'files' ? '#FF7C1D' : '#666', borderColor: tab === 'files' ? 'rgba(255, 124, 29, 1)' : 'rgba(255, 124, 29, 0)' }}>Files</p>
        </div>}
      </div>
      {orderInfo && tab === 'details'
      ? <div className="singleTranslationDetailsOuter">
          <div className="singleTranslationDetailsMain">
            <div className="singleTranslationDetailsDates">
              <p className="singleTranslationDecideTitle">Revision Dates</p>
              <div className="singleTranslationDecideMainContainer">
                <div className="singleTranslationDatesProgressContainer">
                  <p style={{ color: (orderInfo.status === 'revision' || orderInfo.status === 'support-review' || orderInfo.status === 'user-review') ? '#333' : orderInfo.status === 'cancelled' ? '#E1504A' : '#17BC5B' }} className="singleTranslationDatesProgressText">
                    { orderInfo.status === 'revision'
                    ? (orderInfo.revisionDue - (new Date().getTime()) > 172800000
                      ? `${Math.floor((orderInfo.revisionDue - (new Date().getTime())) / (1000 * 60 * 60 * 24))} days from now` 
                      : orderInfo.revisionDue - (new Date()).getTime() > 86400000
                        ? `1 day from now`
                        : orderInfo.revisionDue - (new Date()).getTime() > 0
                          ? `${Math.floor((orderInfo.revisionDue - (new Date().getTime())) / (1000 * 60 * 60))} hours from now`
                          : "Past due date")
                    : (orderInfo.status === 'support-review' || orderInfo.status === 'user-review') ? 'In Review' : orderInfo.status === 'cancelled' ? 'Cancelled' : 'Completed'
                    }
                  </p>
                  <svg
                    className="progress-ring"
                    width="100%"
                    height="100%">
                    <circle
                      className="progress-ring__circle"
                      stroke={orderInfo.status === 'revision' ? '#1e7e81' : orderInfo.status === 'cancelled' ? '#E1504A' : '#17BC5B'}
                      strokeWidth="12"
                      fill="transparent"
                      r="90"
                      cx="50%"
                      cy="50%"/>
                    <circle
                      id="progressCircle"
                      className="progress-ring__circle topCircle"
                      stroke={orderInfo.status === 'revision' ? '#6FAEAD' : orderInfo.status === 'cancelled' ? '#E1504A' : '#17BC5B'}
                      strokeWidth="12"
                      fill="transparent"
                      r="90"
                      cx="50%"
                      cy="50%"/>
                  </svg>
                </div>
                <div className="singleTranslationDecideInfoContainer">
                  <div className="singleTranslationDecideInfoLine">
                    <p className="singleTranslationDecideInfoTitle">Assigned</p>
                    <p className="singleTranslationDecideInfoText">{formatDate(orderInfo.revisionAssigned)}</p>
                  </div>
                  <div className="singleTranslationDecideInfoSeparator" />
                  <div className="singleTranslationDecideInfoLine">
                    <p className="singleTranslationDecideInfoTitle">Due</p>
                    <p className="singleTranslationDecideInfoText">{formatDate(orderInfo.revisionDue)}</p>
                  </div>
                  {(orderInfo.status === 'support-review' || orderInfo.status === 'user-review') &&
                    <>
                      <div className="singleTranslationDecideInfoSeparator" />
                      <div className="singleTranslationDecideInfoLine">
                        <p className="singleTranslationDecideInfoTitle">Submitted</p>
                        <p className="singleTranslationDecideInfoText">{formatDate(orderInfo.revisionSubmitted)}</p>
                      </div>
                    </>
                  }
                  {orderInfo.status === 'completed' &&
                    <>
                      <div className="singleTranslationDecideInfoSeparator" />
                      <div className="singleTranslationDecideInfoLine">
                        <p className="singleTranslationDecideInfoTitle">Completed</p>
                        <p className="singleTranslationDecideInfoText">{formatDate(orderInfo.completed)}</p>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
            {orderInfo.status === 'revision' && <div className="singleTranslationDetailsComments">
              <p className="singleTranslationDecideTitle">Revision Request</p>
              { clientRevisionNotes.getCurrentContent().getPlainText() === '' && supportNotes.getCurrentContent().getPlainText() === ''
              ? <p className="singleTranslationDetailsSecondaryText">No comments posted.</p>
              : <>
                  {supportNotes.getCurrentContent().getPlainText() !== '' && !orderInfo.supportApproved && <div className="singleTranslationNotesContainer">
                    <div className="singleTranslationNotesTitle" style={{ backgroundColor: '#FF7C1D' }}>
                      <strong>Support's Revision Request</strong>
                    </div>
                    <div className="singleTranslationRichTextEditor">
                      <Editor
                        editorState={supportNotes}
                        onChange={supportNotes => setSupportNotes(supportNotes)}
                        readOnly
                      />
                    </div>
                  </div>}
                  {clientRevisionNotes.getCurrentContent().getPlainText() !== '' && orderInfo.supportApproved && <div className="singleTranslationNotesContainer">
                    <div className="singleTranslationNotesTitle"><strong>Client's Revision Request</strong></div>
                    <div className="singleTranslationRichTextEditor">
                      <Editor
                        editorState={clientRevisionNotes}
                        onChange={editorState => setClientRevisionNotes(editorState)}
                        readOnly
                      />
                    </div>
                  </div>}
                </>
              }
            </div>}
          </div>
          <div className="singleRevisionDetailsSecondary">
            <div className="singleRevisionInformation">
              <p className="singleTranslationDecideTitle">Revision Information</p>
              {userData && userData.role === 'Translator' 
              ? <div className="singleTranslationStatusContainer" style={{ backgroundColor: orderInfo.status === 'support-review' ? '#30d5c8' : orderInfo.status === 'user-review' ? '#30d5c8' : orderInfo.status === 'revision' ? '#333' : orderInfo.status === 'cancelled' ? '#E1504A' : '#17BC5B' }}>
                  { orderInfo.status === 'support-review' ? 'IN REVIEW' : orderInfo.status === 'user-review' ? 'IN REVIEW' : orderInfo.status === 'revision' ? 'REVISION' : orderInfo.status === 'cancelled' ? 'CANCELLED' : 'COMPLETED' }
                </div>
              : null
              }
              <p className="singleTranslationDetailsSecondaryText">
                <strong>{languages.find(lang => lang.value === orderInfo.languageFrom).label}</strong>
                {" to "}
                <strong>{languages.find(lang => lang.value === orderInfo.languageTo).label}</strong>
                <br/>
                {orderInfo.certified ? (orderInfo.pagesCount - 1) * 250 + " - " + orderInfo.pagesCount * 250 + " est. words" : orderInfo.wordCount + ' est. words'}
              </p>
              <p className="singleTranslationDetailsSecondaryText">
                <strong>{orderInfo.certified ? 'Certified Translation' : 'Standard Translation'}</strong>
                <br/>
                {orderInfo.certified ? 'This is a word-for-word translation delivered as both a .docx and .pdf with a coversheet. Basic formatting to match the source document is required.' : 'This is a standard translation delivered as a .docx only with no coversheet. Basic formatting is required.'}
              </p>
              <div className="singleTranslationDetailsSecondarySeparator" />
              {orderInfo.status === 'revision' && 
                <AltAnimatedButton space color={'#17BC5B'} text={submitRevisionButton} disabled={!(userData && userData.role === 'Translator' && orderInfo.files.some(f => f.type === 'revision') && (!orderInfo.certified || (orderInfo.files.some(f => f.type === 'revision' && f.format === 'application/pdf') && orderInfo.files.some(f => f.type === 'revision' && f.format === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'))))} width={'100%'} onClick={() => submitRevision()} backend fontSize={windowSize.width > 1024 ? 1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.9 : 1.2) : (windowSize.orientation === 'portrait' ? 3.7 : 1.1)} />
              }
              {(orderInfo.status === 'support-review' || orderInfo.status === 'user-review') && (new Date()).getTime() - orderInfo.revisionSubmitted < 172800000 &&
                <AltAnimatedButton space color={'#333'} text={"Reopen Revision"} width={'100%'} onClick={() => reopenRevision()} backend fontSize={windowSize.width > 1024 ? 1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.9 : 1.2) : (windowSize.orientation === 'portrait' ? 3.7 : 1.1)} />
              }
            </div>
            {(orderInfo.status === 'revision' || orderInfo.status === 'support-review' || orderInfo.status === 'user-review' || orderInfo.status === 'completed') && <div className="singleRevisionInformation">
              <p className="singleTranslationDecideTitle">{!orderInfo.supportApproved ? "Translator Notes - Support Review" : "Translator Notes - Client Review"}</p>
              <p className="singleTranslationDetailsSecondaryText">{`Enter any notes you need to pass to ${!orderInfo.supportApproved ? "our staff" : "the client"} regarding this revision request.`}</p>
              {!orderInfo.supportApproved
              ? <div className="singleTranslationNotesContainer">
                  <div className="singleTranslationNotesTitle" style={{ backgroundColor: '#333' }}>
                    <strong>Notes</strong>
                    <div className="singleOrderTextButtons" style={{ display: orderInfo.status === 'revision' ? 'flex' : 'none' }}>
                      <Bold className="singleOrderRichTextButton" onClick={() => handleBoldClick('support')} />
                      <Italic className="singleOrderRichTextButton" onClick={() => handleItalicClick('support')} />
                      <Underline className="singleOrderRichTextButton" onClick={() => handleUnderlineClick('support')} />
                      <List className="singleOrderRichTextButton" onClick={() => toggleUL('support')} />
                      <div className="singleTranslationNotesAuxButton" onClick={() => saveTranslatorNotes()}>
                        Save
                      </div>
                    </div>
                  </div>
                  <div className="singleTranslationRichTextEditor">
                    <Editor
                      editorState={translatorNotes}
                      onChange={translatorNotes => setTranslatorNotes(translatorNotes)}
                      readOnly={orderInfo.status !== 'revision'}
                    />
                  </div>
                </div>
              : <div className="singleTranslationNotesContainer">
                  <div className="singleTranslationNotesTitle" style={{ backgroundColor: '#333' }}>
                    <strong>Notes</strong>
                    <div className="singleOrderTextButtons" style={{ display: orderInfo.status === 'revision' ? 'flex' : 'none' }}>
                      <Bold className="singleOrderRichTextButton" onClick={() => handleBoldClick('client')} />
                      <Italic className="singleOrderRichTextButton" onClick={() => handleItalicClick('client')} />
                      <Underline className="singleOrderRichTextButton" onClick={() => handleUnderlineClick('client')} />
                      <List className="singleOrderRichTextButton" onClick={() => toggleUL('client')} />
                      <div className="singleTranslationNotesAuxButton" onClick={() => saveTranslatorNotesClient()}>
                        Save
                      </div>
                    </div>
                  </div>
                  <div className="singleTranslationRichTextEditor">
                    <Editor
                      editorState={translatorNotesClient}
                      onChange={translatorNotesClient => setTranslatorNotesClient(translatorNotesClient)}
                      readOnly={orderInfo.status !== 'revision'}
                    />
                  </div>
                </div>
              }
            </div>}
          </div>
        </div>
      : orderInfo && tab === 'files'
      ? <div className="singleTranslationFilesOuter">
          <div className="singleTranslationFilesMain">
            <p className="singleTranslationDecideTitle">Revision Files</p>
            <div className="singleTranslationFilesTableHeader">
              <div className="singleTranslationFilesTableHeaderColumn singleTranslationFilesTableHeaderNameColumn">Name</div>
              <div className="singleTranslationFilesTableHeaderColumn singleTranslationFilesTableHeaderTypeColumn" style={{ display: windowSize.width > 767 || windowSize.orientation === 'landscape' ? 'flex' : 'none' }}>Type</div>
            </div>
            { orderInfo.files.map(file => 
              file.type === "coversheet" && reUploadingCoversheet === file.ref ? null :
              <div key={file.ref} className="singleTranslationFilesTableLine">
                <div className="singleTranslationFilesTableLineTextColumn singleTranslationFilesTableHeaderNameColumn">
                  <p className="singleTranslationFilesTableLineText" style={{ cursor: orderInfo.status === 'revision' ? 'pointer' : 'default' }} onClick={orderInfo.status === 'revision' ? () => downloadFile(file.url) : null}>
                    <strong>{file.name}</strong>
                    {file.size && file.timestamp && <br/>}
                    <span style={{ opacity: 0.7 }}>{file.size && file.timestamp && `Uploaded ${formatDate(file.timestamp)} - ${formatSize(file.size)}`}</span>
                  </p>
                  {(file.type === 'revision' || file.type === 'coversheet') && orderInfo.status === 'revision' && <Trash2 className="fileUploaderLineIcon" onClick={() => handleDeleteUploadedFile(file)} />}
                </div>
                <div className="singleTranslationFilesTableLineColumn singleTranslationFilesTableHeaderTypeColumn" style={{ display: windowSize.width > 767 || windowSize.orientation === 'landscape' ? 'flex' : 'none' }}>{capitalize(file.type)}</div>
              </div>
            )}
            { files.filter(f => !orderInfo.files.some(mFile => mFile.name === f.name && mFile.ref === f.ref)).map(file => 
              <div key={file.ref} className="singleTranslationFilesTableLine">
                <div className="singleTranslationFilesTableLineColumn singleTranslationFilesTableHeaderNameColumn">
                  <div className="fileUploaderLine">
                    <div className="fileUploaderLineInfo">
                      <p className="fileUploaderLineName">{file.name}</p>
                      <p className="fileUploaderLineSize">{formatSize(file.file.size)}</p>
                    </div>
                    {file.status === 'uploading' ? <StopCircle className="fileUploaderLineIcon" onClick={() => handleDeleteFile(file)} /> : <Trash2 className="fileUploaderLineIcon" onClick={() => handleDeleteFile(file)} />}
                  </div>
                  <div className="fileUploaderLineProgressContainer">
                    <animated.div style={{ width: `${file.progress}%`, borderColor: file.status === 'complete' ? '#4CAF50' : '#FF7C1D' }} className="fileUploaderLineProgress" />
                    <p className="fileUploaderLineProgressText">{file.status === 'complete' ? 'Completed' : file.status === 'failed' ? "Upload Failed." : file.status === 'deleting' ? "Deleting..." : "Uploading (" + file.progress + "%)"}</p>
                  </div>
                </div>
                <div className="singleTranslationFilesTableLineColumn singleTranslationFilesTableHeaderTypeColumn" style={{ display: windowSize.width > 767 || windowSize.orientation === 'landscape' ? 'flex' : 'none' }}>{capitalize(file.type)}</div>
              </div>
            )}
          </div>
          {orderInfo.status === 'revision' && <div className="singleTranslationFilesSecondary">
            <div className="singleTranslationFilesUpload">
              <p className="singleTranslationDecideTitle">Add Files</p>
              <p className="singleTranslationDetailsSecondaryText singleTranslationFilesUploadText">Ensure that the file name is comprised of just the order number and the document title. You must include both a .docx and a .pdf of your translation.</p>
              <FileUploaderButton text="Choose Files" backend files={files} handleFiles={handleFiles} handleDeleteFile={() => {}} fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.7 : 1.1) : (windowSize.orientation === 'portrait' ? 3.5 : 1)} />
            </div>
            {orderInfo.certified && <div className="singleTranslationFilesCoversheet">
              <p className="singleTranslationDecideTitle">Generate Coversheet</p>
              <p className="singleTranslationDetailsSecondaryText singleTranslationFilesUploadText">Generate a new coversheet with order details and document name prefilled. This may take a couple of seconds.</p>
              <div className="userLanguageFilterContainer">
                <input type="text" value={documentName} onChange={(e) => setDocumentName(e.target.value)} onKeyDown={(e)=> { if(e.key === "Enter"){ generateCoversheet() }}} className="coversheetInput" placeholder="Document Name (*)" />
              </div>
              <AltAnimatedButton text={coversheetButton} onClick={()=> generateCoversheet()} backend disabled={documentName === ''} fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.7 : 1.1) : (windowSize.orientation === 'portrait' ? 3.5 : 1)} />
            </div>}
          </div>}
        </div>
      : null
      }
    </div>
  );
};

export default SingleRevision;