import React, { useState, useContext, useEffect } from 'react';
import { useParams} from 'react-router-dom';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { NotificationContext } from '../../Context/NotificationContext';
import { DataContext } from '../../Context/DataContext';

import './SingleClient.css';
import { useScreenTransition } from '../../Hooks/useScreenTransition';

const months = {
  'Jan': 'January',
  'Feb': 'February',
  'Mar': 'March',
  'Apr': 'April',
  'May': 'May',
  'Jun': 'June',
  'Jul': 'July',
  'Aug': 'August',
  'Sep': 'September',
  'Oct': 'October',
  'Nov': 'November',
  'Dec': 'December'
};

const SingleClient = ({ windowSize }) => {

  const { clientID } = useParams();

  const { transitionTo } = useScreenTransition();

  const { showNotification } = useContext(NotificationContext);

  const { userData } = useContext(DataContext);

  const [clientInfo, setClientInfo] = useState(null);

  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    if (clientID) {
      const firestore = firebase.firestore();

      const unsubscribe = firestore.collection('users').doc(clientID)
        .onSnapshot(doc => {
          if (doc.exists) {
            setClientInfo({ ...doc.data(), id: doc.id });

            if (!userData || (userData.role !== 'owner' && userData.role !== 'Support')) {
              showNotification('Error', "You don't have sufficient permissions to see this page. If you think you have the correct permissions, please contact our support team.", 'error');
              transitionTo('/office/translations');
            }
          } else {
            showNotification('Error', "There is no client with that ID. Please, try again.", 'error');
            transitionTo('/office/clients');
          }
        }, error => {
          showNotification('Error', "There was an error loading the client info. Please, reload the page.", 'error');
        })

      return () => unsubscribe();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientID]);

  useEffect(() => {
    if (clientInfo) {
      const firestore = firebase.firestore();

      firestore.collection('reviews').where('email', '==', clientInfo.email).orderBy('date', 'desc').get()
        .then(querySnapshot => {
          setReviews(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        })
        .catch(error => {
          //console.log(error);
          showNotification('Error', "There was an error loading the reviews. Please, reload the page.", 'error');
        })

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientInfo]);

  const formatDate = timestamp => {
    if (!timestamp) return '';

    const date = new Date(timestamp);

    const splitted = date.toDateString().split(' ');

    return months[splitted[1]] + ' ' + splitted[2] + ', ' + splitted[3];
  }

  return (
    <div className="singleTranslationContainer">
      <div className="singleTranslationHeader">
        <p className="singleTranslationOrderNumber">Client Information</p>
      </div>
      {clientInfo
      ? <div className="singleTranslationDetailsOuter">
          <div className="singleTranslationDetailsMain" style={{ width: '100%' }}>
            <div className="singleTranslationDetailsDates">
              <div className="singlePaymentDecideTitleContainer">
                <p className="singlePaymentDecideTitle">Details</p>
              </div>
              <div className="singleTranslationDecideMainContainer">
                <div className="singleTranslationDecideInfoContainer">
                  <div className="singleTranslationDecideInfoLine">
                    <p className="singleTranslationDecideInfoTitle">Name</p>
                    <p className="singleTranslationDecideInfoText">
                      {clientInfo.name}
                    </p>
                  </div>
                  <div className="singleTranslationDecideInfoSeparator" />
                  <div className="singleTranslationDecideInfoLine">
                    <p className="singleTranslationDecideInfoTitle">Email</p>
                    <p className="singleTranslationDecideInfoText">
                      {clientInfo.email}
                    </p>
                  </div>
                  <div className="singleTranslationDecideInfoSeparator" />
                  <div className="singleTranslationDecideInfoLine">
                    <p className="singleTranslationDecideInfoTitle">Phone</p>
                    <p className="singleTranslationDecideInfoText">
                      {clientInfo.phone}
                    </p>
                  </div>
                  <div className="singleTranslationDecideInfoSeparator" />
                  <div className="singleTranslationDecideInfoLine">
                    <p className="singleTranslationDecideInfoTitle">Company</p>
                    <p className="singleTranslationDecideInfoText">
                      {clientInfo.company}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="singleTranslationDetailsComments">
              <p className="singleTranslationDecideTitle">Review History</p>
              { reviews.length === 0
              ? <div className="singleClientReviewsMessage">No reviews yet.</div>
              : reviews.map(review => 
                  <div key={review.id} className="singleClientReviewLine">
                    <p className="singleClientReviewText">
                      <strong>{review.name}</strong>
                      <br/>{`${review.languageFrom} to ${review.languageTo}`}
                      <br/><strong>Rating: {review.score}</strong>
                      <br/><br/><i>{review.text}</i>
                      <br/><br/>Posted <strong>{formatDate(review.date)}</strong>
                    </p>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      : null
      }
    </div>
  );
};

export default SingleClient;