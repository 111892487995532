import React, { useState, useContext, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSpring, animated } from 'react-spring';
import axios from 'axios';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { MinusCircle, FileText, Upload, StopCircle } from 'react-feather';

import { AltAnimatedButton } from '../../Components/AltAnimatedButton';

import { NotificationContext } from '../../Context/NotificationContext';
import { DataContext } from '../../Context/DataContext';

import { useEnterKey } from '../../Hooks/useEnterKey';

import './Payments.css';
import { useScreenTransition } from '../../Hooks/useScreenTransition';

const pageLimit = 50;

const Payments = ({ windowSize }) => {

  const { transitionTo } = useScreenTransition()


  const { showNotification } = useContext(NotificationContext);

  const { userData } = useContext(DataContext);

  const [payments, setPayments] = useState([]);
  const [loadedPayments, setLoadedPayments] = useState(false);

  const [users, setUsers] = useState([]);
  const [loadedUsers, setLoadedUsers] = useState(false);

  const paymentSearchRef = useRef();
  const [paymentSearch, setPaymentSearch] = useState('');

  const [translatorSearch, setTranslatorSearch] = useState('');

  const [statusFilter, setStatusFilter] = useState('');

  const [triggerFilter, setTriggerFilter] = useState(true);

  const [newInvoiceTranslator, setNewInvoiceTranslator] = useState('');

  const [modalOpen, setModalOpen] = useState(false);
  const modalProps = useSpring({ opacity: modalOpen ? 1 : 0, pointerEvents: modalOpen ? 'all' : 'none' });

  const [addPaymentButton, setAddPaymentButton] = useState('Create Payment');

  const [fileUploading, setFileUploading] = useState({ id: null, task: null });

  let firstLoad = useRef();
  if (!firstLoad.current) firstLoad.current = true;

  let nextPointer = useRef();
  if (!nextPointer.current) nextPointer.current = null;

  useEffect(() => {
    const applyOrder = e => {
      if (e.keyCode === 13) {
        setTriggerFilter(tf => !tf);
      }
    }

    const paymentSearchRefVar = paymentSearchRef.current;

    paymentSearchRef.current.addEventListener("keyup", applyOrder);

    return () => {
      paymentSearchRefVar.removeEventListener("keyup", applyOrder);
    }
  }, []);

  useEffect(() => {
    const firestore = firebase.firestore();

    const unsubscribe = firestore.collection('users')
      .where('role', '==', 'Translator')
      .onSnapshot(querySnapshot => {
        setUsers(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        setLoadedUsers(true);
      }, error => {
        //console.log(error);
        showNotification('Error', "There was an error loading the users. Please, reload the page.", 'error');
        setLoadedUsers(true);
      });

    return () => unsubscribe();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    firstLoad.current = true;

    const firestore = firebase.firestore();

    let query = firestore.collection('payments');

    if (paymentSearch !== '') {
      query = query.where('paymentNumber', '==', parseInt(paymentSearch,10));
    };

    if (translatorSearch !== '') {
      query = query.where('translator.id', '==', translatorSearch);
    };

    if (statusFilter !== '') {
      query = query.where('status', '==', statusFilter);
    };

    const unsubscribe = 
      query
        .orderBy('timestamp', 'desc')
        .limit(pageLimit)
        .onSnapshot(querySnapshot => {
          if (firstLoad.current) {
            nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];
            firstLoad.current = false;
            
            setPayments(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
            setLoadedPayments(true);
          } else {
            let newPayments = [];

            querySnapshot.docChanges().forEach((change) => {
              if (change.type === "added") {
                newPayments.push({ id: change.doc.id, ...change.doc.data() });
              }
            });
            
            setPayments(u => newPayments.concat(u));
            setLoadedPayments(true);
          }
        }, error => {
          //console.log(error);
          showNotification('Error', "There was an error loading the payments. Please, reload the page.", 'error');
          setLoadedPayments(true);
        });

    return () => unsubscribe();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerFilter, translatorSearch, statusFilter]);

  const loadMorePayments = () => {
    if (nextPointer.current) {
      const firestore = firebase.firestore();

      let query = firestore.collection('payments');

      if (paymentSearch !== '') {
        query = query.where('paymentNumber', '==', parseInt(paymentSearch,10));
      };
  
      if (translatorSearch !== '') {
        query = query.where('translator.id', '==', translatorSearch);
      };
  
      if (statusFilter !== '') {
        query = query.where('status', '==', statusFilter);
      };

      query
        .orderBy('timestamp', 'desc')
        .startAfter(nextPointer.current)
        .limit(pageLimit)
        .get().then((querySnapshot) => {
          nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];

          let oldPayments = [];

          querySnapshot.forEach((doc) => {
            oldPayments.push({ id: doc.id, ...doc.data() });
          });
          
          setPayments(u => u.concat(oldPayments));
        })
        .catch((error) => {
          showNotification('Error', "There was an error loading the payments. Please, reload the page.", 'error');
          //console.log("Error getting documents: ", error);
        });
    }
  }

  const formatDate = timestamp => {
    if (!timestamp) {
      return '';
    }

    const date = new Date(timestamp);

    const splitted = date.toDateString().split(' ');

    return splitted[1] + ' ' + splitted[2] + ', ' + splitted[3];
  }

  const formatWordCount = payment => {
    if (payment.invoices.length === 0) {
      return '0';
    }

    return payment.invoices.reduce((acc, cur) => acc + parseInt(cur.wordCount, 10), 0);
  }

  const formatPaymentTotal = payment => {
    if (payment.invoices.length === 0) {
      return '$0.00';
    }

    return "$" + payment.invoices.reduce((acc, cur) => acc + parseInt(cur.wordCount, 10) * cur.rate, 0).toFixed(2);
  }

  const addNewPayment = async () => {
    if (!userData) {
      showNotification('Error', "You don't have permissions to create a payment. If you think this is wrong, please contact technical support.", 'error');
      return false;
    }

    const firestore = firebase.firestore();

    const now = new Date();

    let paymentNumber = 0;

    await axios({
      method:'post',
      url: "https://us-central1-project-everbary.cloudfunctions.net/getCounter",
      data: {
        type: 'payment'
      }
    })
      .then((response) => {
        paymentNumber = parseInt(response.data.number, 10);
      })
      .catch((error) => {
        //console.log(error);
      });

    const translator = users.find(u => u.id === newInvoiceTranslator);

    if (paymentNumber === 0 || !translator) {
      showNotification('Error', "There was an error creating the payment. Please, try again. If it persists, contact technical support.", 'error');
      return false;
    }

    setAddPaymentButton('Creating...');

    firestore.collection('payments').doc(uuidv4())
      .set({
        paymentNumber: paymentNumber,
        translator: {
          id: translator.id,
          name: translator.name,
          method: translator.method
        },
        invoices: [],
        invoiceNumbers: [],
        status: 'inactive',
        timestamp: now.getTime()
      })
      .then(async () => {


        setModalOpen(false);
        setAddPaymentButton('Create Payment');
        setNewInvoiceTranslator('');
        showNotification('Success', "Payment successfully created", 'success');
      })
      .catch(error => {
        setAddPaymentButton('Create Payment');
        setNewInvoiceTranslator('');
        showNotification('Error', "There was an error creating the payment. Please, try again. If it persists, contact technical support.", 'error');
      })
  }

  const deletePayment = paymentID => {
    if (userData && window.confirm('Are you sure you want to delete this payment? This action cannot be undone.')) {
      const firestore = firebase.firestore();

      firestore.collection('payments').doc(paymentID).delete()
        .then(() => {
          showNotification('Success', "Payment successfully deleted", 'success');
        })
        .catch(error => {
          showNotification('Error', "There was an error deleting the payment. Please, try again. If it persists, contact technical support.", 'error');
        })
    }
  }

  const handleChangeStatus = (payment, newStatus) => {
    if (userData) {
      if (newStatus === 'inactive' || newStatus === 'awaiting') {
        const firestore = firebase.firestore();

        firestore.collection('payments').doc(payment.id)
          .update({
            status: newStatus,
          })
          .then(() => {
            showNotification('Success', "Payment's status has been successfully changed", 'success');
          })
          .catch(error => {
            showNotification('Error', "There was an error changing the status. Please, try again. If it persists, contact technical support.", 'error');
          })
      } else if (window.confirm('Are your sure you want to set this payment as Paid? This action cannot be undone.')) {
        if (!payment.invoices.some(invoice => invoice.status !== 'approved')) {
          const firestore = firebase.firestore();

          const now = new Date();
  
          firestore.collection('payments').doc(payment.id)
            .update({
              status: newStatus,
              paidAt: now.getTime(),
              invoices: payment.invoices.map(invoice => ({ ...invoice, status: 'paid' }))
            })
            .then(() => {
              showNotification('Success', "Payment's status has been successfully changed", 'success');
            })
            .catch(error => {
              showNotification('Error', "There was an error changing the status. Please, try again. If it persists, contact technical support.", 'error');
            })
        } else {
          showNotification('Error', "All Invoices must be approved before settling this Payment.", 'error');
        }
      }
    } else {
      showNotification('Error', "You don't have the correct permissions for this action. If you think this is a mistake, please contact technical support.", 'error');
    }
  }

  const handleClickAddFile = paymentID => {
    if (document.getElementById(`fileInput_${paymentID}`)) {
      document.getElementById(`fileInput_${paymentID}`).click();
    }
  }

  const handleFile = (e, paymentID) => {
    const mFiles = e.target.files;
    
    const fileID = uuidv4();

    const storageRef = firebase.storage().ref();
    
    const fileRef = storageRef.child('vouchers/' + paymentID + '/' + fileID);

    const downloadToken = uuidv4();
    const url = `https://firebasestorage.googleapis.com/v0/b/project-everbary.appspot.com/o/vouchers%2F${paymentID}%2F${fileID}?alt=media&token=${downloadToken}`;

    var metadata = {
      firebaseStorageDownloadTokens: downloadToken,
      customMetadata: {
        'paymentID': paymentID
      }
    }

    const uploadTask = fileRef.put(mFiles[0], metadata);

    setFileUploading({ id: paymentID, task: uploadTask });

    uploadTask.on('state_changed', (snapshot) => {
    }, (error) => {
      setFileUploading({ id: null, task: null });
      showNotification('Error', "There was an error uploading the voucher or the upload was cancelled. Please, try again.", 'error');
    }, () => {
      setFileUploading({ id: null, task: null });  
      const firestore = firebase.firestore();

      firestore.collection('payments').doc(paymentID)
        .update({
          voucher: {
            ref: 'vouchers' + paymentID + '/' + fileID,
            url: url
          }
        })
        .then(() => {
          showNotification('Success', "Voucher has been successfully uploaded", 'success');
        })
        .catch(error => {
          showNotification('Error', "There was an error uploading the voucher. Please, try again. If it persists, contact technical support.", 'error');
        })
    });
  }

  const handleStopUpload = () => {
    if (fileUploading.task) {
      fileUploading.task.cancel();
    }
  }

  const triggerAddPayment = () => {
    if (modalOpen && newInvoiceTranslator !== '') {
      addNewPayment();
    }
  }

  useEnterKey(() => triggerAddPayment());

  return (
    <div className="translationsContainer">
      {userData && modalOpen && <animated.div style={modalProps} className="userModalUnderlay" onClick={() => setModalOpen(false)}/>}
      {userData && modalOpen && <animated.div style={modalProps} className="userModal">
        <div className="singleQuoteMainPrimaryFilesTitle" style={{ marginTop: 0, marginBottom: 'calc(3vh)' }}>
          Select Translator
          <div className="singleOrderRevisionButtons">
            <AltAnimatedButton color={'#E1504A'} text={'Close'} onClick={() => setModalOpen(false)} backend fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.9 : 1.2) : (windowSize.orientation === 'portrait' ? 3.7 : 1.1)} />
          </div>
        </div>
        <div className="userModalLine">
          <select value={newInvoiceTranslator} onChange={(e) => setNewInvoiceTranslator(e.target.value)} className="quoteDetailsSelect">
            <option key="" value="" disabled>Translator (*)</option>
            {users.map(user => 
              <option key={user.id} value={user.id}>{user.name}</option>
            )}
          </select>
        </div>
        <div className="userModalButtonContainer">
          <AltAnimatedButton text={addPaymentButton} backend disabled={newInvoiceTranslator === ''} onClick={() => addNewPayment()} fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.7 : 1.1) : (windowSize.orientation === 'portrait' ? 3.5 : 1)} />
        </div>
      </animated.div>}
      <div className="translationsInnerContainer">
        <div className="translationsTitleRow">
          <p className="translationsTitle">Payments</p>
          <div className="usersFunctionsContainer">
            <div className="userLanguageFilterContainer">
              <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} className="quoteDetailsSelect">
                <option key="" value="">All</option>
                <option key="inactive" value="inactive">Inactive</option>
                <option key="awaiting" value="awaiting">Awaiting</option>
                <option key="paid" value="paid">Paid</option>
              </select>
            </div>
            <div className="userLanguageFilterContainer" style={{ display: windowSize.width > 1024 || windowSize.orientation === 'landscape' ? 'flex' : 'none' }}>
              <input type="text" value={paymentSearch} ref={paymentSearchRef} onChange={(e) => setPaymentSearch(e.target.value)} className="userNameFilter" placeholder="Search by payment number" />
            </div>
            <div className="userLanguageFilterContainer" style={{ marginRight: 'calc(3vw)' }}>
              <select value={translatorSearch} onChange={(e) => setTranslatorSearch(e.target.value)} className="quoteDetailsSelect">
                <option key="" value="">No translator selected</option>
                {users.map(user => 
                  <option key={user.id} value={user.id}>{user.name}</option>
                )}
              </select>
            </div>
            {userData && userData !== 'Translator' && <AltAnimatedButton text={"Add Payment"} onClick={() => setModalOpen(true)} backend disabled={!loadedUsers || users.length === 0} fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.7 : 1.1) : (windowSize.orientation === 'portrait' ? 3.5 : 1)} />}
          </div>
        </div>
        <div className="translationsTableHeader">
          <div className="usersHeaderColumn paymentsNumberColumn">Number</div>
          <div className="usersHeaderColumn paymentsInvoicesColumn">Invoices</div>
          <div className="usersHeaderColumn paymentsWordsColumn">Words</div>
          <div className="usersHeaderColumn paymentsMethodColumn">Method</div>
          <div className="usersHeaderColumn paymentsPaidColumn">Paid At</div>
          <div className="usersHeaderColumn paymentsTranslatorColumn">Translator</div>
          <div className="usersHeaderColumn paymentsStatusColumn">Status</div>
          <div className="usersHeaderColumn paymentsTotalColumn">Total</div>
          <div className="usersHeaderColumn singlePaymentIconColumn" style={{ display: windowSize.width > 767 || windowSize.orientation === 'landscape' ? 'flex' : 'none' }}></div>
          <div className="usersHeaderColumn singlePaymentIconColumn" style={{ display: windowSize.width > 767 || windowSize.orientation === 'landscape' ? 'flex' : 'none' }}></div>
          <div className="usersHeaderColumn singlePaymentIconColumn" style={{ display: windowSize.width > 767 || windowSize.orientation === 'landscape' ? 'flex' : 'none' }}></div>
        </div>
        { loadedPayments && payments.length > 0
        ? payments.map(payment => 
          <div key={payment.id} className="translationsTableLine">
            <div className="usersLineColumn paymentsNumberColumn" style={{ cursor: 'pointer', color: '#609ddb' }} onClick={(e) => transitionTo('/office/payment/' + payment.id,e)}>{payment.paymentNumber}</div>
            <div className="usersLineColumn paymentsInvoicesColumn">{payment.invoices.length}</div>
            <div className="usersLineColumn paymentsWordsColumn">{formatWordCount(payment)}</div>
            <div className="usersLineColumn paymentsMethodColumn">{payment.translator.method}</div>
            <div className="usersLineColumn paymentsPaidColumn">{formatDate(payment.paidAt)}</div>
            <div className="usersLineColumn paymentsTranslatorColumn">{payment.translator.name}</div>
            <div className="usersLineColumn paymentsStatusColumn">
              { payment.status === 'paid'
              ? <div className="translationsStatusContainer" style={{ backgroundColor: '#17BC5B' }}>PAID</div>
              : <select value={payment.status} onChange={(e) => handleChangeStatus(payment, e.target.value)} className="userStatusSelect">
                  <option key="inactive" value="inactive">Inactive</option>
                  <option key="awaiting" value="awaiting">Awaiting</option>
                  <option key="paid" value="paid">Paid</option>
                </select>
              }
            </div>
            <div className="usersLineColumn paymentsTotalColumn">{formatPaymentTotal(payment)}</div>
            <div className="usersLineColumn singlePaymentIconColumn" style={{ display: windowSize.width > 767 || windowSize.orientation === 'landscape' ? 'flex' : 'none' }}>
              <MinusCircle className="translationsColumnIcon singlePaymentClickIcon" onClick={() => deletePayment(payment.id)} />
            </div>
            <div className="usersLineColumn singlePaymentIconColumn" style={{ display: windowSize.width > 767 || windowSize.orientation === 'landscape' ? 'flex' : 'none' }}>
              {payment.voucher && <FileText className="translationsColumnIcon singlePaymentClickIcon" onClick={() => window.open(payment.voucher.url, '_blank')} />}
            </div>
            <div className="usersLineColumn singlePaymentIconColumn" style={{ display: windowSize.width > 767 || windowSize.orientation === 'landscape' ? 'flex' : 'none' }}>
              { fileUploading.id === payment.id
              ? <StopCircle className="translationsColumnIcon singlePaymentClickIcon" onClick={() => handleStopUpload(payment.id)} />
              : <Upload className="translationsColumnIcon singlePaymentClickIcon" onClick={!fileUploading.id ? () => handleClickAddFile(payment.id) : null} />
              }
              <input 
                type="file" 
                name="file" 
                id={`fileInput_${payment.id}`}
                className="fileUploaderInput"
                onChange={(e) => handleFile(e, payment.id)}
                accept=".pdf, image/png, image/jpeg"
              />
            </div>
          </div>)
        : loadedPayments ? <div className="loadingTranslations">No payments.</div> : <div className="loadingTranslations">Loading payments...</div>
        }
        { loadedPayments && payments.length >= 50 && nextPointer.current &&
          <div className="loadMoreUsersButton" onClick={() => loadMorePayments()}>
            Load more payments...
          </div>
        }
      </div>
    </div>
  );
};

export default Payments;