import React from 'react';
import { ChevronRight } from 'react-feather'; 

import { useScreenTransition } from '../Hooks/useScreenTransition';

import './UseCasesDisplay.css';

const UseCasesDisplay = () => {

  const { transitionTo } = useScreenTransition();

  return (
    <div className="useContainer">
      <div className="useCaseBlock">
        <div className="useCaseImgContainer" /*onClick={(e) => transitionTo('/use-case/immigration',e)}*/>
          <img src={require('../assets/torch.png')} alt="Statue of Liberty Torch" className="useCaseImg" />
        </div>
        <h3 className="useCaseBlockText">Immigration<br/>USCIS Acceptance</h3>
      </div>
      <div className="useCaseBlock">
        <div className="useCaseImgContainer" /*onClick={(e) => transitionTo('/use-case/academic',e)}*/>
          <img src={require('../assets/hat.png')} alt="Graduation Hat" className="useCaseImg" />
        </div>
        <h3 className="useCaseBlockText">Academic<br/>University Admissions</h3>
      </div>
      <div className="useCaseBlock nonMobileBlock">
        <div className="useCaseImgContainer" /*onClick={(e) => transitionTo('/use-case/medical',e)}*/>
          <img src={require('../assets/estet.png')} alt="Medical Record" className="useCaseImg" />
        </div>
        <h3 className="useCaseBlockText">Medical<br/>Records and Reports</h3>
      </div>
      <div className="useCaseInfoContainer">
        <p className="useCaseInfoTitle">Use Cases</p>
        <p className="useCaseInfoSub">Certified translations are typically required for official uses such as submission to USCIS, universities, courts and governments.</p>
        {/* <div className="useCaseInfoButtonContainer" onClick={(e) => transitionTo('/use-cases',e)}>
          <p className="useCaseInfoButtonText">More use cases</p>
          <ChevronRight color='#18b7bb' size={20} />
        </div> */}
      </div>
    </div>
  );
}

export { UseCasesDisplay };