import React, { useState, useContext, useEffect, useRef } from "react";
import { useSpring, animated } from "react-spring";
import { v4 as uuidv4 } from "uuid";
import { Editor, EditorState, RichUtils, convertToRaw } from "draft-js";
import axios from "axios";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";

import {
  User,
  FileText,
  Check,
  Bold,
  Underline,
  Italic,
  List,
  ArrowLeft,
} from "react-feather";

import { FormTextInput } from "../../Components/FormTextInput";
import { AltAnimatedButton } from "../../Components/AltAnimatedButton";
import { FileUploaderButton } from "../../Components/FileUploaderButton";

import { languages } from "../../Data/languages";
import { countries } from "../../Data/countries";

import { useScreenTransition } from "../../Hooks/useScreenTransition";
import { useEnterKey } from "../../Hooks/useEnterKey";

import { AuthContext } from "../../Context/AuthContext";
import { NotificationContext } from "../../Context/NotificationContext";
import { DataContext } from "../../Context/DataContext";

import "./QuoteDetails.css";
import TopBar from "../../Components/TopBar";

// function sleep(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

const QuoteDetails = ({ windowSize }) => {
  const { transitionTo } = useScreenTransition();
  const { showNotification } = useContext(NotificationContext);

  const { signedIn } = useContext(AuthContext);

  const { quote, setQuote, userData } = useContext(DataContext);

  const quoteID = useRef();

  useEffect(() => {
    if (quote.name === "" && !signedIn) {
      transitionTo("/quote/contact");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote, signedIn]);

  useEffect(() => {
    quoteID.current = uuidv4();

    if (
      (windowSize.width > 767 || windowSize.orientation === "landscape") &&
      document.getElementById("mainPrimary") &&
      document.getElementById("mainSecondary")
    ) {
      document.getElementById("mainSecondary").style.height = `${
        document.getElementById("mainPrimary").clientHeight
      }px`;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [certified, setCertified] = useState(true);

  const [languageFrom, setLanguageFrom] = useState("");
  const [languageTo, setLanguageTo] = useState("");

  const certifiedProps = useSpring({
    borderColor: certified ? "#FF7C1D" : "rgba(102,102,102,0.1)",
  });
  const standardProps = useSpring({
    borderColor: !certified ? "#FF7C1D" : "rgba(102,102,102,0.1)",
  });

  const [notarization, setNotarization] = useState(false);
  const [expedited, setExpedited] = useState(false);
  const [hardCopy, setHardCopy] = useState(false);

  const [hardCopyCountry, setHardCopyCountry] = useState("");
  const [hardCopyMethod, setHardCopyMethod] = useState("");
  const [hardCopyRequireSignature, setHardCopyRequireSignature] =
    useState(false);

  const [discountCode, setDiscountCode] = useState("");
  const [appliedCode, setAppliedCode] = useState("");
  const [discount, setDiscount] = useState(0);

  const [files, setFiles] = useState([]);

  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );

  const [quoteSubmitButton, setQuoteSubmitButton] = useState("Finish Quote");

  const editor = React.useRef(null);

  function focusEditor() {
    editor.current.focus();
  }

  const handleFiles = (e) => {
    const mFiles = e.target.files;

    for (let i = 0; i < mFiles.length; i++) {
      if (!files.some((file) => file.name === mFiles[i].name)) {
        const fileID = uuidv4();
        setFiles((f) =>
          f.concat([
            {
              id: fileID,
              name: mFiles[i].name,
              file: mFiles[i],
              progress: 0,
              status: "uploading",
            },
          ])
        );
      } else {
        showNotification(
          "File already uploaded.",
          "You've already uploaded a file with this name. Try uploading with another name.",
          "error"
        );
      }
    }

    const storageRef = firebase.storage().ref();

    for (let i = 0; i < mFiles.length; i++) {
      if (!files.some((file) => file.name === mFiles[i].name)) {
        const fileRef = storageRef.child(
          quoteID.current + "/" + mFiles[i].name
        );

        const subFolder = uuidv4();
        const downloadToken = uuidv4();

        const fileName = mFiles[i].name.replace("#", "%23").replace("+", "%2b");

        const url = `https://firebasestorage.googleapis.com/v0/b/project-everbary.appspot.com/o/${quoteID.current}%2F${subFolder}%2F${fileName}?alt=media&token=${downloadToken}`;

        var metadata = {
          firebaseStorageDownloadTokens: downloadToken,
          customMetadata: {
            quoteID: quoteID.current,
          },
        };

        const uploadTask = fileRef.put(mFiles[i], metadata);

        setFiles((f) =>
          f.map((file) =>
            file.name === mFiles[i].name && file.ref === mFiles[i].ref ? { ...file, task: uploadTask } : file
          )
        );

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setFiles((f) =>
              f.map((file) =>
                file.name === mFiles[i].name && file.ref === mFiles[i].ref
                  ? { ...file, progress: progress.toFixed(2) }
                  : file
              )
            );
          },
          (error) => {
            //console.log(error);
            setFiles((f) =>
              f.map((file) =>
                file.name === mFiles[i].name && file.ref === mFiles[i].ref
                  ? { ...file, status: "failed" }
                  : file
              )
            );
            showNotification(
              "Error uploading.",
              "There was an error uploading the file " +
                mFiles[i].name +
                ". Please, try again.",
              "error"
            );
          },
          () => {
            setFiles((f) =>
              f.map((file) =>
                file.name === mFiles[i].name && file.ref === mFiles[i].ref
                  ? {
                      ...file,
                      status: "complete",
                      ref: quoteID.current + "/" + mFiles[i].name,
                      url: url,
                    }
                  : file
              )
            );
          }
        );
      }
    }
  };

  const handleDeleteFile = (file) => {
    const storageRef = firebase.storage().ref();

    if (file.status === "complete") {
      const fileRef = storageRef.child(file.ref);
      setFiles((f) =>
        f.map((mFile) =>
          mFile.name === file.name && mFile.ref === file.ref ? { ...mFile, status: "deleting" } : mFile
        )
      );

      fileRef
        .delete()
        .then(function () {
          setFiles((f) => f.filter((mFile) =>  mFile.ref !== file.ref));
        })
        .catch(function (error) {
          showNotification(
            "Error deleting the file.",
            "There was an error deleting the file " +
              file.name +
              ". Please, try again.",
            "error"
          );
        });
    } else if (file.status === "failed") {
      setFiles((f) => f.filter((mFile) =>  mFile.ref !== file.ref));
    } else {
      file.task.cancel();
      setFiles((f) => f.filter((mFile) =>  mFile.ref !== file.ref));
    }
  };

  const handleBoldClick = () => {
    setEditorState((editorState) =>
      RichUtils.toggleInlineStyle(editorState, "BOLD")
    );
  };

  const handleItalicClick = () => {
    setEditorState((editorState) =>
      RichUtils.toggleInlineStyle(editorState, "ITALIC")
    );
  };

  const handleUnderlineClick = () => {
    setEditorState((editorState) =>
      RichUtils.toggleInlineStyle(editorState, "UNDERLINE")
    );
  };

  const toggleUL = () => {
    setEditorState((editorState) =>
      RichUtils.toggleBlockType(editorState, "unordered-list-item")
    );
  };

  const handleSubmitQuote = async () => {
    setQuoteSubmitButton("Submitting...");

    const now = new Date();

    const firestore = firebase.firestore();

    let quoteNumber = 0;

    await axios({
      method: "post",
      url: "https://us-central1-project-everbary.cloudfunctions.net/getCounter",
      data: {
        type: "quote",
      },
    })
      .then((response) => {
        quoteNumber = parseInt(response.data.number, 10);
      })
      .catch((error) => {
        //console.log(error);
      });

    if (quoteNumber === 0) {
      showNotification(
        "Error",
        "An error ocurred while submitting the quote. Please, try again. If it persists, contact support.",
        "error"
      );
      return false;
    }

    firestore
      .collection("quotes")
      .doc(quoteID.current)
      .set({
        quoteNumber: quoteNumber,
        userID: signedIn ? firebase.auth().currentUser.uid : "",
        teamId: signedIn ? (userData.teamId ? userData.teamId : "") : "",
        email: signedIn
          ? userData
            ? userData.email
            : firebase.auth().currentUser.email
          : quote.email,
        name: signedIn ? (userData ? userData.name : "") : quote.name,
        certified: certified,
        languageFrom: languageFrom,
        languageTo: languageTo,
        notarization: notarization,
        expedited: expedited,
        hardCopy: hardCopy,
        hardCopyExtra: hardCopy
          ? {
              country: hardCopyCountry,
              method: hardCopyMethod,
              requireSignature: hardCopyRequireSignature,
            }
          : null,
        notes: convertToRaw(editorState.getCurrentContent()),
        files: files.map((file) => ({
          name: file.name,
          ref: file.ref,
          url: file.url,
          type: "source",
          size: file.file.size,
          format: file.file.type,
          timestamp: now.getTime(),
        })),
        timestamp: now.getTime(),
        status: "submitted",
        discountApplied: discount > 0,
        discount: discount,
        userNotes: "",
      })
      .then(() => {
        localStorage.setItem(
          "quote",
          JSON.stringify({ name: "", email: "", parsed: true })
        );
        setQuote({ name: "", email: "", parsed: true });

        if (appliedCode !== "") {
          axios({
            method: "post",
            url: "https://us-central1-project-everbary.cloudfunctions.net/updateCouponUsage",
            data: {
              code: appliedCode,
            },
          }).catch(() => {});
        }

        if (signedIn) {
          transitionTo("/account/quotes");
        } else {
          transitionTo("/");
        }
      })
      .catch((error) => {
        //console.log(error);
        setQuoteSubmitButton("Finish Quote");
        showNotification(
          "Error",
          "An error ocurred while submitting the quote. Please, try again.",
          "error"
        );
      });
  };

  const handleClickContact = () => {
    if (!signedIn) {
      transitionTo("/quote/contact");
    }
  };

  const handleApplyCode = () => {
    const firestore = firebase.firestore();

    firestore
      .collection("coupons")
      .where("code", "==", discountCode)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          const doc = querySnapshot.docs[0];

          if (doc.data().uses > 0) {
            setDiscount(parseInt(doc.data().discount, 10));
            setAppliedCode(discountCode);
            showNotification(
              "Discount applied",
              "The discount was successfully applied",
              "success"
            );
          } else {
            showNotification(
              "Error",
              "That code is no longer valid. Please, try another one.",
              "error"
            );
          }
        } else {
          showNotification(
            "Error",
            "That code doesn't exist. Please, try another one.",
            "error"
          );
        }
      })
      .catch(() => {
        showNotification(
          "Error",
          "There was an error applying the code. Please, try again.",
          "error"
        );
      });
  };

  const triggerContinue = () => {
    if (
      !(
        (!signedIn && !quote.name) ||
        languageFrom === "" ||
        languageTo === "" ||
        files.length === 0 ||
        files.some((file) => file.status !== "complete") ||
        (certified &&
          hardCopy &&
          (hardCopyCountry === "" || hardCopyMethod === ""))
      )
    ) {
      handleSubmitQuote();
    }
  };

  useEnterKey(() => triggerContinue());

  return (
    <>
      <TopBar />
      <div className="quoteContainer">
        <div className="quoteHeader">
          <img
            src={require("../../assets/logoAlt.png")}
            alt="Logo eVerbary"
            className="quoteLogo"
            onClick={(e) => transitionTo("/", e)}
          />
          <div className="quoteHeaderExtra">
            <div
              className="quoteHeaderExtraBlock"
              onClick={handleClickContact}
              style={{
                opacity: 0.5,
                cursor: !signedIn ? "pointer" : "default",
              }}
            >
              <User className="quoteHeaderIcon" />
              <p className="quoteHeaderExtraBlockText">Contact</p>
            </div>
            <div className="quoteHeaderConnector" />
            <div className="quoteHeaderExtraBlock">
              <FileText className="quoteHeaderIcon" />
              <p className="quoteHeaderExtraBlockText">Details</p>
            </div>
          </div>
        </div>
        <div className="quoteMainContainer">
          <div id="mainPrimary" className="quoteMainPrimaryContainer">
            <div className="quotePrimaryInner">
              <h3 className="quotePrimaryTitle">
                What type of translation do you need?
              </h3>
              <div className="quotePrimaryOptions">
                <animated.div
                  style={certifiedProps}
                  className="quotePrimaryOption"
                  onClick={() => setCertified(true)}
                >
                  <p className="quotePrimaryOptionTitle">
                    Certified Translation
                  </p>
                  <p className="quotePrimaryOptionText">
                    Word-for-word human translation of documents with
                    certification for official use.
                  </p>
                </animated.div>
                <animated.div
                  style={standardProps}
                  className="quotePrimaryOption"
                  onClick={() => setCertified(false)}
                >
                  <p className="quotePrimaryOptionTitle">
                    Standard Translation
                  </p>
                  <p className="quotePrimaryOptionText">
                    Human translation of documents and text-based content for
                    business or personal use.
                  </p>
                </animated.div>
              </div>
              <h3 className="quotePrimaryTitle">
                What language pair do you need translated?
              </h3>
              <p className="quoteSmallMessage">
                Either the source or target language must include English.
                Please contact us if you need a language pair that doesn't
                include English.
              </p>
              <div className="quoteDetailsInputsContainer">
                <div className="quoteDetailsSelectContainer">
                  <select
                    value={languageFrom}
                    onChange={(e) => setLanguageFrom(e.target.value)}
                    className="quoteDetailsSelect"
                  >
                    <option key="" value="" disabled>
                      Translate From (*)
                    </option>
                    {languages
                      .filter(
                        (language) =>
                          languageTo === "english" ||
                          languageTo === "" ||
                          language.value === "english"
                      )
                      .map((language) => (
                        <option key={language.value} value={language.value}>
                          {language.label}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="quoteDetailsSelectContainer">
                  <select
                    placeholder="Translate To (*)"
                    value={languageTo}
                    onChange={(e) => setLanguageTo(e.target.value)}
                    className="quoteDetailsSelect"
                  >
                    <option key="" value="" disabled>
                      Translate To (*)
                    </option>
                    {languages
                      .filter(
                        (language) =>
                          languageFrom === "english" ||
                          languageFrom === "" ||
                          language.value === "english"
                      )
                      .map((language) => (
                        <option key={language.value} value={language.value}>
                          {language.label}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <h3 className="quotePrimaryTitle">
                Upload the files you need translated
              </h3>
              <div className="quoteDetailsFileUploaderContainer">
                <FileUploaderButton
                  display
                  text="Choose Files"
                  files={files}
                  handleFiles={handleFiles}
                  handleDeleteFile={handleDeleteFile}
                  fontSize={
                    windowSize.width > 1024
                      ? 1.1
                      : windowSize.width > 767
                      ? windowSize.orientation === "portrait"
                        ? 1.6
                        : 1.1
                      : windowSize.orientation === "portrait"
                      ? 5
                      : 1.5
                  }
                />
              </div>

              <h3 className="quotePrimaryTitle">
                Which optional services do you need?
              </h3>
              {certified ? (
                <div
                  className="quoteOptionalContainer"
                  style={{
                    borderColor: notarization
                      ? "#FF7C1D"
                      : "rgba(102,102,102,0.1)",
                  }}
                >
                  <div className="quoteOptionalTitleRow">
                    <p className="quotePrimaryOptionTitle">Notarization</p>
                    <div
                      className="quoteOptionalTitleStatus"
                      onClick={() => setNotarization((n) => !n)}
                      style={{
                        backgroundColor: !notarization
                          ? "#FF7C1D"
                          : "rgba(102,102,102,0.1)",
                        color: !notarization ? "#fff" : "#666",
                      }}
                    >
                      {notarization ? "REMOVE" : "ADD"}
                    </div>
                  </div>
                  <p className="quotePrimaryOptionText">
                    Stamp and signature authenticating the signer of the
                    translation certification. Valid in all 50 states.
                  </p>
                </div>
              ) : null}
              <div
                className="quoteOptionalContainer"
                style={{
                  borderColor: expedited ? "#FF7C1D" : "rgba(102,102,102,0.1)",
                }}
              >
                <div className="quoteOptionalTitleRow">
                  <p className="quotePrimaryOptionTitle">
                    Expedited Turnaround
                  </p>
                  <div
                    className="quoteOptionalTitleStatus"
                    onClick={() => setExpedited((n) => !n)}
                    style={{
                      backgroundColor: !expedited
                        ? "#FF7C1D"
                        : "rgba(102,102,102,0.1)",
                      color: !expedited ? "#fff" : "#666",
                    }}
                  >
                    {expedited ? "REMOVE" : "ADD"}
                  </div>
                </div>
                <p className="quotePrimaryOptionText">
                  Your order will be prioritized and turnaround time for digital
                  delivery reduced by 50%.
                </p>
              </div>
              {certified ? (
                <div
                  className="quoteOptionalContainer"
                  style={{
                    borderColor: hardCopy ? "#FF7C1D" : "rgba(102,102,102,0.1)",
                  }}
                >
                  <div className="quoteOptionalTitleRow">
                    <p className="quotePrimaryOptionTitle">Mail Hard Copy</p>
                    <div
                      className="quoteOptionalTitleStatus"
                      onClick={() => setHardCopy((n) => !n)}
                      style={{
                        backgroundColor: !hardCopy
                          ? "#FF7C1D"
                          : "rgba(102,102,102,0.1)",
                        color: !hardCopy ? "#fff" : "#666",
                      }}
                    >
                      {hardCopy ? "REMOVE" : "ADD"}
                    </div>
                  </div>
                  <p className="quotePrimaryOptionText">
                    Original translation with wet ink signature(s) shipped via
                    USPS Priority or Express mail.
                  </p>
                  {hardCopy ? (
                    <>
                      <div className="hardCopyLineSeparator" />
                      <div className="quoteDetailsInputsContainer">
                        <div className="quoteDetailsSelectContainer">
                          <select
                            value={hardCopyCountry}
                            onChange={(e) => setHardCopyCountry(e.target.value)}
                            className="quoteDetailsSelect"
                          >
                            <option key="" value="" disabled>
                              Country (*)
                            </option>
                            {countries.map((country) => (
                              <option key={country} value={country}>
                                {country}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="quoteDetailsSelectContainer">
                          <select
                            placeholder="USPS Service (*)"
                            value={hardCopyMethod}
                            onChange={(e) => setHardCopyMethod(e.target.value)}
                            className="quoteDetailsSelect"
                          >
                            <option key="" value="" disabled>
                              USPS Service (*)
                            </option>
                            <option key="priority" value="priority">
                              {[
                                "United States",
                                "Puerto Rico",
                                "U.S. Virgin Islands",
                              ].includes(hardCopyCountry)
                                ? "Priority: 2-3 days in transit"
                                : "Priority: 6-10 days in transit"}
                            </option>
                            <option key="express" value="express">
                              {[
                                "United States",
                                "Puerto Rico",
                                "U.S. Virgin Islands",
                              ].includes(hardCopyCountry)
                                ? "Express: 1-2 days in transit"
                                : "Express: 3-5 days in transit"}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div
                        className="hardCopyCheckboxLine"
                        onClick={() => setHardCopyRequireSignature((hc) => !hc)}
                      >
                        <div className="hardCopyCheckbox">
                          <Check
                            className="hardCopyCheck"
                            style={{
                              opacity: hardCopyRequireSignature ? 1 : 0,
                            }}
                          />
                        </div>
                        <p className="hardCopyCheckboxText">
                          Require signature for delivery
                        </p>
                      </div>
                    </>
                  ) : null}
                </div>
              ) : null}

              <h3 className="quotePrimaryTitle">Notes</h3>
              <p className="quoteSmallMessage">
                Please include any notes your translation team might need
                including the preferred spelling for all people and places.
              </p>
              <div className="quoteRichTextButtons">
                <Bold
                  className="quoteRichTextButton"
                  onClick={handleBoldClick}
                />
                <Italic
                  className="quoteRichTextButton"
                  onClick={handleItalicClick}
                />
                <Underline
                  className="quoteRichTextButton"
                  onClick={handleUnderlineClick}
                />
                <List className="quoteRichTextButton" onClick={toggleUL} />
              </div>
              <div className="quoteRichTextEditor" onClick={focusEditor}>
                <Editor
                  ref={editor}
                  editorState={editorState}
                  onChange={(editorState) => setEditorState(editorState)}
                />
              </div>

              <p className="quotePrimaryTitle">Apply Discount Code</p>
              {appliedCode && (
                <p
                  className="quoteSmallMessage"
                  style={{ marginBottom: "1vw" }}
                >
                  Currently Applied:{" "}
                  <strong>{`${appliedCode} (${discount}%)`}</strong>
                </p>
              )}
              <div
                className="orderCountContainer"
                style={{ marginBottom: "3vw" }}
              >
                <div
                  className="orderCountInputContainer"
                  style={{ flex: 1, marginRight: "5%" }}
                >
                  <FormTextInput
                    placeholder="Discount Code"
                    value={discountCode}
                    onChange={setDiscountCode}
                    color="#666"
                  />
                </div>
                <AltAnimatedButton
                  text="Apply"
                  onClick={handleApplyCode}
                  fontSize={
                    windowSize.width > 1024
                      ? 1.1
                      : windowSize.width > 767
                      ? windowSize.orientation === "portrait"
                        ? 2.4
                        : 1.5
                      : windowSize.orientation === "portrait"
                      ? 5
                      : 1.2
                  }
                />
              </div>

              <div className="quoteSubmitButtonContainer">
                <div
                  className="quoteGoBackButton"
                  style={{ display: !signedIn ? "flex" : "none" }}
                  onClick={(e) => transitionTo("/quote/contact", e)}
                >
                  <ArrowLeft className="quoteGoBackIcon" />
                </div>
                <AltAnimatedButton
                  text={quoteSubmitButton}
                  disabled={
                    (!signedIn && !quote.name) ||
                    languageFrom === "" ||
                    languageTo === "" ||
                    files.length === 0 ||
                    files.some((file) => file.status !== "complete") ||
                    (certified &&
                      hardCopy &&
                      (hardCopyCountry === "" || hardCopyMethod === ""))
                  }
                  onClick={() => handleSubmitQuote()}
                  fontSize={
                    windowSize.width > 1024
                      ? 1.2
                      : windowSize.width > 767
                      ? windowSize.orientation === "portrait"
                        ? 2.2
                        : 1.5
                      : windowSize.orientation === "portrait"
                      ? 5
                      : 1.2
                  }
                />
              </div>
            </div>
          </div>
          <div id="mainSecondary" className="quoteMainSecondaryContainer">
            <h3 className="quoteSecondaryTitle">WHAT TO EXPECT</h3>
            <div className="quoteSecondarySub">
              We’ll respond quickly with a formal quote covering the cost and
              turnaround time for your translation project.
            </div>
            <div className="quoteSecondaryLine">
              <Check size={15} color="#18b7bb" />
              <div className="quoteSecondaryLineText">
                Free quote, no obligation to buy
              </div>
            </div>
            <div className="quoteSecondaryLine">
              <Check size={15} color="#18b7bb" />
              <div className="quoteSecondaryLineText">
                Final pricing with no surprises
              </div>
            </div>
            <div className="quoteSecondaryLine">
              <Check size={15} color="#18b7bb" />
              <div className="quoteSecondaryLineText">
                Estimated turnaround time
              </div>
            </div>
            <div className="quoteSecondaryLine">
              <Check size={15} color="#18b7bb" />
              <div className="quoteSecondaryLineText">
                Quick convert to order when ready
              </div>
            </div>
            <div className="quoteSecondaryLine">
              <Check size={15} color="#18b7bb" />
              <div className="quoteSecondaryLineText">
                Quote is guaranteed for 30 days
              </div>
            </div>
            <div className="quoteSecondaryExtraInfo">
              <h3 className="quoteSecondaryTitle">SECURE AND PRIVATE</h3>
              <div className="quoteSecondarySub">
                All data processed by our site, including uploaded files, is
                encrypted. Only essential staff with signed NDAs can access your
                information.
              </div>
            </div>
            <img
              src={require("../../assets/encryptionIcons.png")}
              alt="Encryption Certificates"
              className="quoteSecondaryEncryptionImg"
            />
          </div>
        </div>
        <div className="quoteFooter">
          <div className="quoteFooterLinksContainer">
            <div className="quoteFooterLink">Contact</div>
            <div className="quoteFooterLinkSeparator" />
            <div className="quoteFooterLink">Terms</div>
            <div className="quoteFooterLinkSeparator" />
            <div className="quoteFooterLink">Refunds</div>
            <div className="quoteFooterLinkSeparator" />
            <div className="quoteFooterLink">Privacy</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuoteDetails;
