import React, { useState, useContext, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

import { Maximize2, Minimize2, Bell, ChevronDown, PenTool, Type, Inbox, User, Users as Team, FileText, CreditCard, Clipboard, Menu, X, Info, Tag } from 'react-feather';

import { useScreenTransition } from '../../Hooks/useScreenTransition';

import { DataContext } from '../../Context/DataContext';
import { NotificationContext } from '../../Context/NotificationContext';
import { AuthContext } from '../../Context/AuthContext';

import Translations from './Translations';
import OwnTranslations from './OwnTranslations';
import OwnRevisions from './OwnRevisions';
import Users from './Users';
import Coupons from './Coupons';
import Quotes from './Quotes';
import Invoices from './Invoices';
import Payments from './Payments';
import Clients from './Clients';
import OwnInvoices from './OwnInvoices';
import OwnPayments from './OwnPayments';
import SingleTranslation from './SingleTranslation';
import SingleRevision from './SingleRevision';
import SinglePayment from './SinglePayment';
import SingleQuote from './SingleQuote';
import SingleClient from './SingleClient';
import SingleUser from './SingleUser';
import Preferences from './Preferences';
import ManualOrder from './ManualOrder';
import Notifications from './Notifications';
import SingleNotification from './SingleNotification';
import Guidelines from './Guidelines';

import './Office.css';
import Teams from './Teams';

const Office = ({ windowSize }) => {

  let { path, url } = useRouteMatch();

  const { transitionTo } = useScreenTransition();

  const { userData } = useContext(DataContext);

  const { showNotification } = useContext(NotificationContext);

  const { clearSignOut } = useContext(AuthContext);

  const location = useLocation();

  const [sidebarPeek, setSidebarPeek] = useState(false);
  const [openMain, setOpenMain] = useState(false);

  const [mobileOpen, setMobileOpen] = useState(false);

  const desktopScreen = windowSize.width > 1024 || windowSize.orientation === 'landscape';

  const mobileMenuProps = useSpring({ width: mobileOpen ? (windowSize.width > 767 ? 'calc(40vw)' : 'calc(70vw)') : 'calc(0vw)' });
  const mobileInnerContainerProps = useSpring({ width: 'calc(100vw)' });

  const innerContainerProps = useSpring({ width: !openMain ? (windowSize.width > 1024 ? 'calc(82vw)' : 'calc(82vw)') : 'calc(99.7vw)' });
  const sidebarProps = useSpring({ width: !openMain || sidebarPeek ? (windowSize.width > 1024 ? 'calc(18vw)' : 'calc(18vw)') : 'calc(0.3vw)' });

  const sidebarBadgesProps = useSpring({ opacity: !openMain || sidebarPeek ? 1 : 0 });

  const [orders, setOrders] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [quotes, setQuotes] = useState([]);

  const [lastNotification, setLastNotification] = useState(0);

  useEffect(() => {
    if (userData && (userData.role === 'owner' || userData.role === 'Support' || userData.role === 'Translator')) {
      const firestore = firebase.firestore();

      let query = firestore.collection('orders');
  
      if (userData.role === 'owner' || userData.role === 'Support') {
        query = query.where('status', 'in', ['new', 'assigned', 'in-progress', 'support-review', 'revision']);
      } else if (userData.role === 'Translator') {
        query = query.where('status', 'in', ['assigned', 'in-progress', 'revision']).where('translator.id', '==', userData.id);
      };
      
      const unsubscribe = 
        query
          .onSnapshot(querySnapshot => {
            setOrders(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
          }, error => {
            //console.log(error);
          });

      return () => unsubscribe();
    }
    
  }, [userData]);

  useEffect(() => {
    if (userData && (userData.role === 'owner' || userData.role === 'Support' || userData.role === 'Translator')) {
      const firestore = firebase.firestore();

      let query = firestore.collection('orders');
      
      const unsubscribe = 
        query
          .where('invoice.status', '==', 'submitted')
          .onSnapshot(querySnapshot => {
            setInvoices(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
          }, error => {
            //console.log(error);
          });

      return () => unsubscribe();
    }
    
  }, [userData]);

  useEffect(() => {
    if (userData && (userData.role === 'owner' || userData.role === 'Support')) {
      const firestore = firebase.firestore();

      let query = firestore.collection('quotes');
      
      const unsubscribe = 
        query
          .where('status', '==', 'submitted')
          .onSnapshot(querySnapshot => {
            setQuotes(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
          }, error => {
            //console.log(error);
          });

      return () => unsubscribe();
    }
    
  }, [userData]);

  useEffect(() => {
    const firestore = firebase.firestore();
    
    const unsubscribe = firestore.collection('notifications').doc('lastPosted')
      .onSnapshot(doc => {
        if (doc.exists) {
          setLastNotification(userData.role === 'owner' ? doc.data().owner : userData.role === 'Support' ? doc.data().support : doc.data().translator);
        }
      }, error => {
        //console.log(error);
      });

    return () => unsubscribe();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignOut = () => {
    firebase.auth().signOut()
      .then(() => {
        showNotification('Success', 'You have successfully signed out.', 'success');
        clearSignOut();
        transitionTo('/');
      })
      .catch(error => {
        showNotification('Error', 'There was an error signing out. Please, try again. If it persists, please contact technical support.', 'error');
      })
  }

  const toggleMenu = () => {
    setMobileOpen(i => !i);
  }

  return (
    <div className="backendOuterContainer" style={{ height: windowSize.height }}>
      <animated.div style={desktopScreen ? sidebarProps : mobileMenuProps} onMouseEnter={desktopScreen ? () => setSidebarPeek(true) : null} onMouseLeave={desktopScreen ? () => setSidebarPeek(false) : null} className="backendSidebar">
        <div className="backendLogoContainer">
          <img src={require('../../assets/logoAlt.png')} alt="Logo eVerbary" className="backendLogo" onClick={(e) => transitionTo('/',e)} />
          <X className="backendCloseSidebarIcon" onClick={() => toggleMenu()} />
        </div>
        <div className="backendSidebarLinksContainer">
          {userData && userData.role === 'Translator' && <div className="backendSidebarLinkContainer">
            <div style={{ color: location.pathname.includes('translations') ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.4)' }} onClick={(e) => {if(!location.pathname.includes('/translations') || e.ctrlKey) {transitionTo(`${url}/translations`,e); setMobileOpen(false);}}} className="backendSidebarLink">
              <Type className="backendSidebarLinkIcon" />
              Translations
              <animated.div style={sidebarBadgesProps} className="backendSidebarLinkExtraIconContainer">
                {orders.filter(order => order.status === 'assigned').length > 0 && 
                  <div className="backendSidebarExtraIcon" style={{ backgroundColor: '#61dbfb' }}>
                    {orders.filter(order => order.status === 'assigned').length}
                  </div>
                }
                {orders.filter(order => order.status === 'in-progress').length > 0 && 
                  <div className="backendSidebarExtraIcon" style={{ backgroundColor: '#1491F8' }}>
                    {orders.filter(order => order.status === 'in-progress').length}
                  </div>
                }
              </animated.div>
            </div>
          </div>}
          {userData && (userData.role === 'owner' || userData.role === 'Support') && <div className="backendSidebarLinkContainer">
            <div style={{ color: location.pathname.includes('translations') ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.4)' }} onClick={(e) =>{if(!location.pathname.includes('/translations') || e.ctrlKey) { transitionTo(`${url}/translations`,e); setMobileOpen(false); }}} className="backendSidebarLink">
              <Type className="backendSidebarLinkIcon" />
              Orders
              <animated.div style={sidebarBadgesProps} className="backendSidebarLinkExtraIconContainer">
                {orders.filter(order => order.status === 'new').length > 0 && 
                  <div className="backendSidebarExtraIcon" style={{ backgroundColor: '#FF7C1D' }}>
                    {orders.filter(order => order.status === 'new').length}
                  </div>
                }
                {orders.filter(order => order.status === 'assigned').length > 0 && 
                  <div className="backendSidebarExtraIcon" style={{ backgroundColor: '#61dbfb' }}>
                    {orders.filter(order => order.status === 'assigned').length}
                  </div>
                }
                {orders.filter(order => order.status === 'in-progress').length > 0 && 
                  <div className="backendSidebarExtraIcon" style={{ backgroundColor: '#1491F8' }}>
                    {orders.filter(order => order.status === 'in-progress').length}
                  </div>
                }
                {orders.filter(order => order.status === 'support-review').length > 0 && 
                  <div className="backendSidebarExtraIcon" style={{ backgroundColor: '#e91e63' }}>
                    {orders.filter(order => order.status === 'support-review').length}
                  </div>
                }
                {orders.filter(order => order.status === 'revision').length > 0 && 
                  <div className="backendSidebarExtraIcon" style={{ backgroundColor: '#333' }}>
                    {orders.filter(order => order.status === 'revision').length}
                  </div>
                }
              </animated.div>
            </div>
          </div>}
          {userData && userData.role === 'Translator' && <div className="backendSidebarLinkContainer">
            <div style={{ color: location.pathname.includes('revisions') ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.4)' }} onClick={(e) => {if(!location.pathname.includes('/revisions') || e.ctrlKey) {transitionTo(`${url}/revisions`,e); setMobileOpen(false); }}} className="backendSidebarLink">
              <Inbox className="backendSidebarLinkIcon" />
              Revisions
              <animated.div style={sidebarBadgesProps} className="backendSidebarLinkExtraIconContainer">
                {orders.filter(order => order.status === 'revision').length > 0 && 
                  <div className="backendSidebarExtraIcon" style={{ backgroundColor: '#333' }}>
                    {orders.filter(order => order.status === 'revision').length}
                  </div>
                }
              </animated.div>
            </div>
          </div>}
          {userData && (userData.role === 'owner' || userData.role === 'Support') && <div className="backendSidebarLinkContainer">
            <div style={{ color: location.pathname.includes('quotes') ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.4)' }} onClick={(e) => {if(!location.pathname.includes('/quotes') || e.ctrlKey) {transitionTo(`${url}/quotes`,e); setMobileOpen(false); }}} className="backendSidebarLink">
              <PenTool className="backendSidebarLinkIcon" />
              Quotes
              {userData && (userData.role === 'owner' || userData.role === 'Support') &&
                <animated.div style={sidebarBadgesProps} className="backendSidebarLinkExtraIconContainer">
                  {quotes.length > 0 && 
                    <div className="backendSidebarExtraIcon" style={{ backgroundColor: '#FF7C1D' }}>
                      {quotes.length}
                    </div>
                  }
                </animated.div>
              }
            </div>
          </div>}
          <div className="backendSidebarLinkContainer">
            <div style={{ color: location.pathname.includes('invoices') ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.4)' }} onClick={(e) =>  {if(!location.pathname.includes('/invoices') || e.ctrlKey) {transitionTo(`${url}/invoices`,e); setMobileOpen(false); }}} className="backendSidebarLink">
              <FileText className="backendSidebarLinkIcon" />
              Invoices
              {userData && (userData.role === 'owner' || userData.role === 'Support') &&
                <animated.div style={sidebarBadgesProps} className="backendSidebarLinkExtraIconContainer">
                  {invoices.length > 0 && 
                    <div className="backendSidebarExtraIcon" style={{ backgroundColor: '#AE5FD2' }}>
                      {invoices.length}
                    </div>
                  }
                </animated.div>
              }
            </div>
          </div>
          <div className="backendSidebarLinkContainer">
            <div style={{ color: location.pathname.includes('payments') ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.4)' }} onClick={(e) =>  {if(!location.pathname.includes('/payments') || e.ctrlKey) {transitionTo(`${url}/payments`,e); setMobileOpen(false); }}} className="backendSidebarLink">
              <CreditCard className="backendSidebarLinkIcon" />
              Payments
            </div>
          </div>
          {userData && (userData.role === 'owner' || userData.role === 'Support') && <div className="backendSidebarLinkContainer">
            <div style={{ color: location.pathname.includes('clients') ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.4)' }} onClick={(e) => {if(!location.pathname.includes('/clients') || e.ctrlKey){ transitionTo(`${url}/clients`,e); setMobileOpen(false); }}} className="backendSidebarLink">
              <Clipboard className="backendSidebarLinkIcon" />
              Clients
            </div>
          </div>}
          {userData && userData.role === 'owner' && <div className="backendSidebarLinkContainer">
            <div style={{ color: location.pathname.includes('users') ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.4)' }} onClick={(e) => {if(!location.pathname.includes('/users') || e.ctrlKey) {transitionTo(`${url}/users`,e); setMobileOpen(false); }}} className="backendSidebarLink">
              <User className="backendSidebarLinkIcon" />
              Users
            </div>
          </div>}
          {userData && (userData.role === 'owner' || userData.role === 'Support') && <div className="backendSidebarLinkContainer">
            <div style={{ color: location.pathname.includes('teams') ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.4)' }} onClick={(e) => {if(!location.pathname.includes('/teams') || e.ctrlKey) {transitionTo(`${url}/teams`,e); setMobileOpen(false); }}} className="backendSidebarLink">
              <Team className="backendSidebarLinkIcon" />
              Teams
            </div>
          </div>}
          {userData && (userData.role === 'owner' || userData.role === "Support") && <div className="backendSidebarLinkContainer">
            <div style={{ color: location.pathname.includes('coupons') ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.4)' }} onClick={(e) => {if(!location.pathname.includes('/coupons') || e.ctrlKey) {transitionTo(`${url}/coupons`,e); setMobileOpen(false); }}} className="backendSidebarLink">
              <Tag className="backendSidebarLinkIcon" />
              Coupons
            </div>
          </div>}
          {userData && userData.role === 'Translator' && <div className="backendSidebarLinkContainer">
            <div style={{ color: location.pathname.includes('guidelines') ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.4)' }} onClick={(e) => {if(!location.pathname.includes('/guidelines') || e.ctrlKey) {transitionTo(`${url}/guidelines`,e); setMobileOpen(false); }}} className="backendSidebarLink">
              <Info className="backendSidebarLinkIcon" />
              Guidelines
            </div>
          </div>}
        </div>
      </animated.div>
      <animated.div style={desktopScreen ? innerContainerProps : mobileInnerContainerProps} className="backendInnerContainer">
        <div className="backendHeader">
          { !desktopScreen
          ? <Menu className="backendSwitchIcon" onClick={() => toggleMenu()} /> 
          : openMain 
            ? <Minimize2 className="backendSwitchIcon" onClick={() => setOpenMain(false)} /> 
            : <Maximize2 className="backendSwitchIcon" onClick={() => setOpenMain(true)} /> 
          }
          <div className="backendHeaderOptionsContainer">
            <Bell className="backendSwitchIcon" color={userData && userData.readNotifications && userData.readNotifications < lastNotification ? '#FF7C1D' : '#666'} onClick={(e) => transitionTo('/office/notifications',e)} />
            <div className="backendHeaderOptionsSeparator" />
            <div className="backendHeaderOptionsMenuContainer">
              <p className="backendHeaderOptionsName">{userData && userData.name}</p>
              <ChevronDown className="backendHeaderOptionsIcon" />
              <div className="backendHeaderOptionsMenuInner">
                <p className="backendHeaderOptionsMenuLink" onClick={(e) => transitionTo('/office/preferences',e)}>Preferences</p>
                <p className="backendHeaderOptionsMenuLink" onClick={handleSignOut}>Logout</p>
              </div>
            </div>
          </div>
        </div>
        <div className="backendTabContainer">
          <Switch>
            {userData && (userData.role === 'owner' || userData.role === 'Support') &&
              <Route path={`${path}/payments`}>
                <Payments windowSize={windowSize} />
              </Route>
            }
            {userData && (userData.role === 'owner' || userData.role === 'Support') &&
              <Route path={`${path}/clients`}>
                <Clients windowSize={windowSize} />
              </Route>
            }
            {userData && userData.role === 'Translator' &&
              <Route path={`${path}/payments`}>
                <OwnPayments windowSize={windowSize} />
              </Route>
            }
            {userData && (userData.role === 'owner' || userData.role === 'Support') &&
              <Route path={`${path}/translations`}>
                <Translations windowSize={windowSize} />
              </Route>
            }
            {userData && (userData.role === 'owner' || userData.role === 'Support') &&
              <Route path={`${path}/quotes`}>
                <Quotes windowSize={windowSize} />
              </Route>
            }
            {userData && (userData.role === 'owner' || userData.role === 'Support') &&
              <Route path={`${path}/invoices`}>
                <Invoices windowSize={windowSize} />
              </Route>
            }
            {userData && userData.role === 'Translator' &&
              <Route path={`${path}/invoices`}>
                <OwnInvoices windowSize={windowSize} />
              </Route>
            }
            {userData && userData.role === 'Translator' &&
              <Route path={`${path}/translations`}>
                <OwnTranslations windowSize={windowSize} />
              </Route>
            }
            {userData && userData.role === 'Translator' &&
              <Route path={`${path}/revisions`}>
                <OwnRevisions windowSize={windowSize} />
              </Route>
            }
            {userData && userData.role === 'Translator' &&
              <Route path={`${path}/guidelines`}>
                <Guidelines windowSize={windowSize} />
              </Route>
            }
            {userData && (userData.role === 'owner' || userData.role === 'Support') &&
              <Route path={`${path}/client/:clientID`}>
                <SingleClient windowSize={windowSize} />
              </Route>
            }
            {userData && (userData.role === 'owner' || userData.role === 'Support') &&
              <Route path={`${path}/new-order`}>
                <ManualOrder windowSize={windowSize} mode={"order"} />
              </Route>
            }
            {userData && (userData.role === 'owner' || userData.role === 'Support') &&
              <Route path={`${path}/new-quote`}>
                <ManualOrder windowSize={windowSize} mode={"quote"} />
              </Route>
            }
            {userData && userData.role === 'owner' &&
              <Route path={`${path}/user/:userID`}>
                <SingleUser windowSize={windowSize} />
              </Route>
            }
            <Route path={`${path}/notifications`}>
              <Notifications windowSize={windowSize} />
            </Route>
            <Route path={`${path}/notification/:notificationID`}>
              <SingleNotification windowSize={windowSize} />
            </Route>
            <Route path={`${path}/payment/:paymentID`}>
              <SinglePayment windowSize={windowSize} />
            </Route>
            <Route path={`${path}/quote/:id`}>
              <SingleQuote windowSize={windowSize} />
            </Route>
            <Route path={`${path}/translation/:id/:tabParam`}>
              <SingleTranslation windowSize={windowSize} />
            </Route>
            <Route path={`${path}/preferences`}>
              <Preferences windowSize={windowSize} />
            </Route>
            {userData && userData.role === 'Translator' &&
              <Route path={`${path}/revision/:id`}>
                <SingleRevision windowSize={windowSize} />
              </Route>
            }
            {userData && userData.role === 'owner' &&
              <Route path={`${path}/users`}>
                <Users windowSize={windowSize} />
              </Route>
            }
            {userData && (userData.role === 'owner' || userData.role === 'Support') &&
              <Route path={`${path}/teams`}>
                <Teams windowSize={windowSize} />
              </Route>
            }
            {userData && (userData.role === 'owner' || userData.role === "Support") &&
              <Route path={`${path}/coupons`}>
                <Coupons windowSize={windowSize} />
              </Route>
            }
          </Switch>
        </div>
      </animated.div>
    </div>
  );
};

export default Office;