import React from 'react';
import './AccountCreated.css';
import { ReactComponent as Close } from "../../assets/icons/x.svg";
import {ReactComponent as Success} from "../../assets/icons/Seccess.svg"
import { useScreenTransition } from '../../Hooks/useScreenTransition';

export default function AccountCreated({closeAccountCreated,accoutTypeSelected}) {
  const { transitionTo } = useScreenTransition();

  return (
    <div className='accountCreatedForm'>
        <div className='accountCreatedFormHeader'>
            <h2 className='accountCreatedFormTitle'>Account Created</h2>
            <Close className='signInFormIcon' onClick={()=>closeAccountCreated()}/>
        </div>
        <Success className='accountCreatedSuceessIcon'/>
        <span className='accountCreatedFormSpan'>You have successfully created a {accoutTypeSelected} account</span>
        <button className='accountCreatedFormGotoAccount' onClick={() => transitionTo('/account/profile')}>Go to Account</button>
    </div>
  )
}
