import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';

import { PlusCircle, MinusCircle } from 'react-feather';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { AltAnimatedButton } from '../../Components/AltAnimatedButton';

import { NotificationContext } from '../../Context/NotificationContext';
import { DataContext } from '../../Context/DataContext';

import './SinglePayment.css';
import { useScreenTransition } from '../../Hooks/useScreenTransition';

const SinglePayment = ({ windowSize }) => {

  const { paymentID } = useParams();

  const { transitionTo } = useScreenTransition();

  const { showNotification } = useContext(NotificationContext);

  const { userData } = useContext(DataContext);

  const [paymentInfo, setPaymentInfo] = useState(null);

  const [elegibleInvoices, setElegibleInvoices] = useState([]);

  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);

  const invoiceModalProps = useSpring({ opacity: openInvoiceModal ? 1 : 0, pointerEvents: openInvoiceModal ? 'all' : 'none' });

  useEffect(() => {
    if (paymentID) {
      const firestore = firebase.firestore();

      const unsubscribe = firestore.collection('payments').doc(paymentID)
        .onSnapshot(doc => {
          if (doc.exists) {
            setPaymentInfo({ ...doc.data(), id: doc.id });

            if (!userData || (userData.role === 'Translator' && userData.id !== doc.data().translator.id)) {
              showNotification('Error', "You don't have sufficient permissions to see this payment. If you think you have the correct permissions, please contact our support team.", 'error');
              transitionTo('/office/payments');
            }
          } else {
            showNotification('Error', "There is no payment with that ID. Please, try again.", 'error');
            transitionTo('/office/payments');
          }
        }, error => {
          showNotification('Error', "There was an error loading the payment info. Please, reload the page.", 'error');
        })

      return () => unsubscribe();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentID]);

  useEffect(() => {
    if (paymentInfo) {
      const firestore = firebase.firestore();

      const unsubscribe = firestore.collection('orders')
        .where('translator.id', '==', paymentInfo.translator.id)
        .where('invoice.status', 'in', ['submitted', 'review', 'approved'])
        .onSnapshot(querySnapshot => {
          setElegibleInvoices(querySnapshot.docs.map(doc => ({
            orderID: doc.id,
            invoiceNumber: doc.data().invoice.invoiceNumber,
            status: doc.data().invoice.status,
            submitted: doc.data().invoice.submitted,
            rate: doc.data().translator.orderRate,
            wordCount: doc.data().invoice.lines.reduce((acc, cur) => acc + parseInt(cur.wordCount, 10), 0)
          })));
        }, error => {
          showNotification('Error', "There was an error loading the invoices for this translator. Please, reload the page.", 'error');
        })

      return () => unsubscribe();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentInfo])

  const formatDate = timestamp => {
    if (!timestamp) {
      return '';
    }

    const date = new Date(timestamp);

    const splitted = date.toDateString().split(' ');

    return splitted[1] + ' ' + splitted[2] + ', ' + splitted[3];
  }

  const formatRate = rate => {
    if (!rate) {
      return '';
    }

    return "$" + rate.toFixed(2);
  }

  const openNewInvoiceModal = () => {
    setOpenInvoiceModal(true);
  }

  const addInvoiceToPayment = invoice => {
    const firestore = firebase.firestore();

    firestore.collection('payments').doc(paymentID)
      .update({
        invoices: firebase.firestore.FieldValue.arrayUnion(invoice),
        invoiceNumbers: firebase.firestore.FieldValue.arrayUnion(invoice.invoiceNumber)
      })
      .then(() => {
        showNotification('Success', "The invoice has been successfully added", 'success');
      })
      .catch(error => {
        showNotification('Error', "There was an error adding this invoice to the payment. Please, try again.", 'error');
      })
  }

  const removeInvoiceFromPayment = invoice => {
    const firestore = firebase.firestore();

    firestore.collection('payments').doc(paymentID)
      .update({
        invoices: paymentInfo.invoices.filter(i => i.orderID !== invoice.orderID),
        invoiceNumbers: firebase.firestore.FieldValue.arrayRemove(invoice.invoiceNumber)
      })
      .then(() => {
        showNotification('Success', "The invoice has been successfully removed", 'success');
      })
      .catch(error => {
        showNotification('Error', "There was an error removing this invoice to the payment. Please, try again.", 'error');
      })
  }

  const formatInvoiceTotal = invoice => {
    return "$" + (invoice.wordCount * invoice.rate).toFixed(2);
  }

  return (
    <div className="singleTranslationContainer">
      {openInvoiceModal && <animated.div style={invoiceModalProps} className="revisionModalBg" onClick={() => setOpenInvoiceModal(false)} />}
      {openInvoiceModal && <animated.div style={invoiceModalProps} className="revisionModalOuter">
        <div className="singleQuoteMainPrimaryFilesTitle" style={{ marginTop: 0 }}>
          Add Invoices
          <div className="singleOrderRevisionButtons">
            <AltAnimatedButton color={'#E1504A'} text={"Close"} onClick={() => setOpenInvoiceModal(false)} backend fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.9 : 1.2) : (windowSize.orientation === 'portrait' ? 3.7 : 1.1)} />
          </div>
        </div>
        <div className="singlePaymentTableHeader">
          <div className="usersHeaderColumn singlePaymentNumberColumn">Number</div>
          <div className="usersHeaderColumn singlePaymentSubmittedColumn">Submitted</div>
          <div className="usersHeaderColumn singlePaymentTotalColumn">Total</div>
          { userData && userData.role !== 'Translator' &&
            <div className="usersHeaderColumn singlePaymentStatusColumn">Status</div>
          }
          <div className="usersHeaderColumn singlePaymentIconColumn"></div>
        </div>
        { paymentInfo && elegibleInvoices.filter(invoice => !paymentInfo.invoices.some(i => i.orderID === invoice.orderID)).length > 0
        ? elegibleInvoices.filter(invoice => !paymentInfo.invoices.some(i => i.orderID === invoice.orderID)).map(invoice => 
          <div key={invoice.invoiceNumber} className="singlePaymentTableLine">
            <div className="usersLineColumn singlePaymentNumberColumn" style={{ cursor: 'pointer', color: '#609ddb' }} onClick={(e) => transitionTo('/office/translation/' + invoice.orderID + "/invoice",e)}>{invoice.invoiceNumber}</div>
            <div className="usersLineColumn singlePaymentSubmittedColumn">{formatDate(invoice.submitted)}</div>
            <div className="usersLineColumn singlePaymentTotalColumn">{formatInvoiceTotal(invoice)}</div>
            { userData && userData.role !== 'Translator' &&
              <div className="usersHeaderColumn singlePaymentStatusColumn">
                <div className="singleTranslationStatusContainer" style={{ backgroundColor: invoice?.status === 'created' ? '#FF7C1D' : invoice?.status === 'submitted' ? '#AE5FD2' : invoice?.status === 'approved' ? '#1491F8' : invoice?.status === 'review' ? '#e91e63' : invoice?.status === 'paid' ? '#17BC5B' : 'transparent' }}>
                  { invoice?.status === 'created' ? "CREATED" : invoice?.status === 'submitted' ? 'SUBMITTED' : invoice?.status === 'approved' ? 'APPROVED' : invoice?.status === 'review' ? 'REVIEW' : invoice?.status === 'paid' ? 'PAID' : ''}
                </div>
              </div>
            }
            <div className="usersLineColumn singlePaymentIconColumn">
              <PlusCircle className="translationsColumnIcon singlePaymentClickIcon" onClick={() => addInvoiceToPayment(invoice)} />
            </div>
          </div>)
        : <div className="loadingTranslations">No elegible invoices.</div>
        }
      </animated.div>}

      <div className="singleTranslationHeader">
        <p className="singleTranslationOrderNumber">{paymentInfo && "#" + paymentInfo.paymentNumber}</p>
      </div>
      {paymentInfo
      ? <div className="singleTranslationDetailsOuter">
          <div className="singleTranslationDetailsMain">
            <div className="singleTranslationDetailsDates">
              <div className="singlePaymentDecideTitleContainer">
                <p className="singlePaymentDecideTitle">Paid Invoices</p>
                { userData && userData.role !== 'Translator' && paymentInfo && paymentInfo.status !== 'paid' &&
                  <div style={{ width: 'auto' }}>
                    <AltAnimatedButton text={"Add Invoice"} onClick={() => openNewInvoiceModal()} backend fontSize={windowSize.width > 1024 ? 0.85 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.5 : 0.85) : (windowSize.orientation === 'portrait' ? 3.2 : 0.85)} />
                  </div>
                }
              </div>
              <div className="singlePaymentMainContainer">
                <div className="singlePaymentTableHeader">
                  <div className="usersHeaderColumn singlePaymentNumberColumn">Number</div>
                  <div className="usersHeaderColumn singlePaymentSubmittedColumn">Submitted</div>
                  <div className="usersHeaderColumn singlePaymentWordsColumn">Words</div>
                  <div className="usersHeaderColumn singlePaymentRateColumn">Rate</div>
                  <div className="usersHeaderColumn singlePaymentTotalColumn">Total</div>
                  { userData && userData.role !== 'Translator' &&
                    <div className="usersHeaderColumn singlePaymentStatusColumn">Status</div>
                  }
                  { userData && userData.role !== 'Translator' && paymentInfo && paymentInfo.status !== 'paid' &&
                    <div className="usersHeaderColumn singlePaymentIconColumn"></div>
                  }
                </div>
                { paymentInfo && paymentInfo.invoices && paymentInfo.invoices.length > 0
                ? paymentInfo.invoices.map(invoice => 
                  <div key={invoice.invoiceNumber} className="singlePaymentTableLine">
                    <div className="usersLineColumn singlePaymentNumberColumn" style={{ cursor: 'pointer', color: '#609ddb' }} onClick={(e) => transitionTo('/office/translation/' + invoice.orderID + "/invoice",e)}>{invoice.invoiceNumber}</div>
                    <div className="usersLineColumn singlePaymentSubmittedColumn">{formatDate(invoice.submitted)}</div>
                    <div className="usersLineColumn singlePaymentWordsColumn">{invoice.wordCount}</div>
                    <div className="usersLineColumn singlePaymentRateColumn">{formatRate(invoice.rate)}</div>
                    <div className="usersLineColumn singlePaymentTotalColumn">{formatInvoiceTotal(invoice)}</div>
                    { userData && userData.role !== 'Translator' &&
                      <div className="usersHeaderColumn singlePaymentStatusColumn">
                        <div className="singleTranslationStatusContainer" style={{ marginTop: 0, minWidth: '60%', backgroundColor: invoice.status === 'created' ? '#FF7C1D' : invoice.status === 'submitted' ? '#AE5FD2' : invoice.status === 'approved' ? '#1491F8' : invoice.status === 'review' ? '#e91e63' : invoice.status === 'paid' ? '#17BC5B' : 'transparent' }}>
                          { invoice.status === 'created' ? "CREATED" : invoice.status === 'submitted' ? 'SUBMITTED' : invoice.status === 'approved' ? 'APPROVED' : invoice.status === 'review' ? 'REVIEW' : invoice.status === 'paid' ? 'PAID' : ''}
                        </div>
                      </div>
                    }
                    { userData && userData.role !== 'Translator' && paymentInfo && paymentInfo.status !== 'paid' &&
                      <div className="usersLineColumn singlePaymentIconColumn">
                        <MinusCircle className="translationsColumnIcon singlePaymentClickIcon" onClick={() => removeInvoiceFromPayment(invoice)} />
                      </div>
                    }
                  </div>)
                : <div className="loadingTranslations">No invoices.</div>
                }
              </div>
            </div>
          </div>
          <div className="singleRevisionDetailsSecondary">
            <div className="singlePaymentInformation">
              <p className="singleTranslationDecideTitle">Payment Information</p>
              {paymentInfo && <div className="singleTranslationStatusContainer" style={{ backgroundColor: paymentInfo.status === 'inactive' ? '#333' : paymentInfo.status === 'awaiting' ? '#AE5FD2' : '#17BC5B' }}>
                { paymentInfo.status === 'inactive' ? "INACTIVE" : paymentInfo.status === 'awaiting' ? 'AWAITING' : paymentInfo.status === 'paid' ? 'PAID' : ''}
              </div>}
              {paymentInfo && paymentInfo.status === 'paid' &&
                <p className="singleTranslationDetailsSecondaryText">
                  <strong>{`Payment Sent ${formatDate(paymentInfo.paidAt)}`}</strong>
                  <br/>
                  {`Sent via ${paymentInfo.translator.method}`}
                </p>
              }
            </div>
          </div>
        </div>
      : null
      }
    </div>
  );
};

export default SinglePayment;