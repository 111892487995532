import React, { useState, useContext, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import axios from 'axios';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

import { Trash2, Info } from 'react-feather';

import { AltAnimatedButton } from '../../Components/AltAnimatedButton';

import { languages } from '../../Data/languages';
import { countries } from '../../Data/countries';
import { timezones } from '../../Data/timezones';

import { NotificationContext } from '../../Context/NotificationContext';

import { useEnterKey } from '../../Hooks/useEnterKey';

import './Users.css';
import { useScreenTransition } from '../../Hooks/useScreenTransition';
import { FormSimpleTextInput } from '../../Components/FormSimpleTextInput';

const pageLimit = 50;

const Users = ({ windowSize }) => {

  const { showNotification } = useContext(NotificationContext);

  const { transitionTo } = useScreenTransition();

  const [modalOpen, setModalOpen] = useState(false);
  const modalProps = useSpring({ opacity: modalOpen ? 1 : 0, pointerEvents: modalOpen ? 'all' : 'none' });

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [language, setLanguage] = useState('');
  const [standardRate, setStandardRate] = useState('');
  const [expeditedRate, setExpeditedRate] = useState('');
  const [method, setMethod] = useState('');
  const [methodID, setMethodID] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [timezone, setTimezone] = useState('');

  const [languageFilter, setLanguageFilter] = useState('');

  const nameSearchRef = useRef();
  const [nameSearch, setNameSearch] = useState('');

  const emailSearchRef = useRef();
  const [emailSearch, setEmailSearch] = useState('');

  const [triggerFilter, setTriggerFilter] = useState(true);

  const [createUserButton, setCreateUserButton] = useState('Create User');

  const [users, setUsers] = useState([]);
  const [loadedUsers, setLoadedUsers] = useState(false);

  let firstLoad = useRef();
  if (!firstLoad.current) firstLoad.current = true;

  let nextPointer = useRef();
  if (!nextPointer.current) nextPointer.current = null;

  useEffect(() => {
    const applyEmail = e => {
      if (e.keyCode === 13) {
        setTriggerFilter(tf => !tf);
      }
    }

    const applyName = e => {
      if (e.keyCode === 13) {
        setTriggerFilter(tf => !tf);
      }
    }

    const nameSearchRefVar = nameSearchRef.current;
    const emailSearchRefVar = emailSearchRef.current;

    nameSearchRef.current.addEventListener("keyup", applyName);
    emailSearchRef.current.addEventListener("keyup", applyEmail);

    return () => {
      nameSearchRefVar.removeEventListener("keyup", applyName);
      emailSearchRefVar.removeEventListener("keyup", applyEmail);
    }
  }, []);

  useEffect(() => {
    const firestore = firebase.firestore();

    let query = firestore.collection('users');

    if (languageFilter !== '') {
      query = query.where('language', '==', languageFilter);
    };

    if (nameSearch !== '') {
      query = query.where('name', '==', nameSearch);
    };

    if (emailSearch !== '') {
      query = query.where('email', '==', emailSearch.toLowerCase());
    };

    const unsubscribe = 
      query
        .orderBy('timestamp', 'desc')
        .limit(pageLimit)
        .onSnapshot(querySnapshot => {
          if (firstLoad.current) {
            nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];
            firstLoad.current = false;
            
            setUsers(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
            setLoadedUsers(true);
          } else {
            let newUsers = [];

            querySnapshot.docChanges().forEach((change) => {
              if (change.type === "added") {
                newUsers.push({ id: change.doc.id, ...change.doc.data() });
              }
            });
            
            setUsers(u => newUsers.concat(u));
            setLoadedUsers(true);
          }
        }, error => {
          //console.log(error);
          showNotification('Error', "There was an error loading the users. Please, reload the page.", 'error');
          setLoadedUsers(true);
        });

    return () => unsubscribe();
    

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageFilter, triggerFilter]);

  const loadMoreUsers = () => {
    if (nextPointer.current) {
      const firestore = firebase.firestore();

      let query = firestore.collection('users');

      if (languageFilter !== '') {
        query = query.where('language', '==', languageFilter);
      };

      if (nameSearch !== '') {
        query = query.where('name', '==', nameSearch);
      };

      if (emailSearch !== '') {
        query = query.where('email', '==', emailSearch.toLowerCase());
      };

      query
        .orderBy("timestamp", "desc")
        .startAfter(nextPointer.current)
        .limit(pageLimit)
        .get().then((querySnapshot) => {
          nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];

          let oldUsers = [];

          querySnapshot.forEach((doc) => {
            oldUsers.push({ id: doc.id, ...doc.data() });
          });
          
          setUsers(u => u.concat(oldUsers));
        })
        .catch((error) => {
          showNotification('Error', "There was an error loading the users. Please, reload the page.", 'error');
          //console.log("Error getting documents: ", error);
        });
    }
  }

  const openNewUserModal = () => {
    setModalOpen(true);
  }

  const inputStandard = (rate) => {
    //var reg = /^\d+$/;
    var reg = /^[0-9]+\.?[0-9]{0,3}$/;

    if (rate === '' || reg.test(rate)) {
      setStandardRate(rate);
    }
  }

  const inputExpedited = (rate) => {
    //var reg = /^\d+$/;
    var reg = /^[0-9]+\.?[0-9]{0,3}$/;

    if (rate === '' || reg.test(rate)) {
      setExpeditedRate(rate);
    }
  }

  const inputPhone = phone => {
    //eslint-disable-next-line
    var reg = /^\d+$/;

    if (phone === '' || reg.test(phone)) {
      setPhone(phone);
    }
  }

  const createUser = () => {
    //eslint-disable-next-line
    var reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (password.length < 8) {
      showNotification('Error', "Password must have more than 8 characters. Please, try again.", 'error');
    } else if (!reg.test(email)) {
      showNotification('Error', "The email address is invalid. Please, try again.", 'error');
    } else {
      setCreateUserButton('Creating User...');

      axios({
        method:'post',
        url: "https://us-central1-project-everbary.cloudfunctions.net/createUser",
        data: {
          email: email.toLowerCase(),
          password: password,
          name: name,
          role: role,
          language: role === 'Translator' ? language : '',
          standardRate: role === 'Translator' ? parseFloat(standardRate) : 0,
          expeditedRate: role === 'Translator' ? parseFloat(expeditedRate) : 0,
          method: role === 'Translator' ? method : '',
          methodID: role === 'Translator' ? methodID : '',
          phone: role === 'Translator' ? phone : '',
          country: role === 'Translator' ? country : '',
          timezone: role === 'Translator' ? timezone : '',
        }
      })
        .then((response) => {
          if (response.data.code === 'auth/email-already-exists') {
            showNotification("Error", "There's already a user with this email. Please, try again.", "error");
            setCreateUserButton('Create User');
          } else if (response.data.code === 'auth/invalid-email') {
            showNotification('Error', "The email address is invalid. Please, try again.", 'error');
            setCreateUserButton('Create User');
          } else if (response.data.code === 'auth/invalid-password') {
            showNotification('Error', "Password is too weak. Please, try again with another one.", 'error');
            setCreateUserButton('Create User');
          } else {
            showNotification("User Created", "The user has been created successfully.", "success");     
            setModalOpen(false);
            setCreateUserButton('Create User');
            setEmail('');
            setName('');
            setPassword('');
            setConfirm('');
            setRole('');
            setLanguage('');
            setStandardRate('');
            setExpeditedRate('');
            setMethod('');
            setMethodID('');
            setPhone('');
            setCountry('');
            setTimezone('');
          }
        })
        .catch((error) => {
          setCreateUserButton('Create User');
          //console.log(error.code);
          showNotification("Error", "An error ocurred creating the user. Please, try again. If it persists, contact technical support.", "error");
        });
    }
  }

  const handleDeleteUser = userID => {
    setUsers(u => u.map(user => user.id === userID ? { ...user, status: 'deleting' } : user));

    axios({
      method:'post',
      url: "https://us-central1-project-everbary.cloudfunctions.net/deleteUser",
      data: {
        userID: userID
      }
    })
      .then((response) => {
        showNotification("User Deleted", "The user has been deleted successfully.", "success");     
      })
      .catch(error => {
        showNotification("Error", "An error ocurred deleting the user. Please, try again. If it persists, contact technical support.", "error");
      })
  }

  const handleChangeStatus = (userID, newStatus) => {
    const firestore = firebase.firestore();

    firestore.collection('users').doc(userID)
      .update({ status: newStatus })
      .then(() => {
        showNotification("Status Updated", "The user's has been successfully updated.", "success");     
      })
      .catch(error => {
        showNotification("Error", "An error ocurred changing the user's status. Please, try again. If it persists, contact technical support.", "error");
      })
  }

  const triggerCreateUser = () => {
    if (modalOpen && !(email === '' || password === '' || confirm === '' || password !== confirm || name === '' || role === '' || (role === 'Translator' && (standardRate === '' || standardRate.endsWith('.') || expeditedRate === '' || expeditedRate.endsWith('.') || language === '' || phone === '' || country === '' || timezone === '')))) {
      createUser();
    }
  }

  useEnterKey(() => triggerCreateUser());

  return (
    <div className="translationsContainer">
      {modalOpen && <animated.div style={modalProps} className="userModalUnderlay" onClick={() => setModalOpen(false)}/>}
      {modalOpen && <animated.div style={modalProps} className="userModal">
        <div className="singleQuoteMainPrimaryFilesTitle" style={{ marginTop: 0, marginBottom: 'calc(2vh)' }}>
          New User
          <div className="singleOrderRevisionButtons">
            <AltAnimatedButton color={'#E1504A'} text={'Close'} onClick={() => setModalOpen(false)} backend fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.9 : 1.2) : (windowSize.orientation === 'portrait' ? 3.7 : 1.1)} />
          </div>
        </div>
        <div className="userModalLine">
          <div className="userModalHalf">
            <FormSimpleTextInput placeholder="Email (*)" value={email} onChange={setEmail} color='#666' />
          </div>
          <div className="userModalHalf">
            <FormSimpleTextInput placeholder="Name (*)" value={name} onChange={setName} color='#666' />
          </div>
        </div>
        <div className="userModalLine">
          <div className="userModalHalf">
            <FormSimpleTextInput password placeholder="Password (*)" value={password} onChange={setPassword} color='#666' />
          </div>
          <div className="userModalHalf">
            <FormSimpleTextInput password placeholder="Confirm Password (*)" value={confirm} onChange={setConfirm} color='#666' />
          </div>
        </div>
        <div className="userModalLine">
          <div className="userModalHalf">
            <select value={role} onChange={(e) => setRole(e.target.value)} className="quoteDetailsSelect">
              <option key="" value="" disabled>Role (*)</option>
              <option key="Support" value="Support">Support</option>
              <option key="Translator" value="Translator">Translator</option>
            </select>
          </div>
          {role === 'Translator' && <div className="userModalHalf">
            <select value={language} onChange={(e) => setLanguage(e.target.value)} className="quoteDetailsSelect">
              <option key="" value="" disabled>Language (*)</option>
              {languages.filter(language => language !== 'english').map(language => 
                <option key={language.value} value={language.value}>{language.label}</option>
              )}
            </select>
          </div>}
        </div>
        {role === 'Translator' && <div className="userModalLine userModalLanguageLine">
          <div className="userModalHalf">
            <FormSimpleTextInput placeholder="Standard Rate (*)" value={standardRate} onChange={inputStandard} color='#666' />
          </div>
          <div className="userModalHalf">
            <FormSimpleTextInput placeholder="Expedited Rate (*)" value={expeditedRate} onChange={inputExpedited} color='#666' />
          </div>
        </div>}
        {role === 'Translator' && <div className="userModalLine userModalLanguageLine">
          <div className="userModalHalf">
            <FormSimpleTextInput placeholder="Method (*)" value={method} onChange={setMethod} color='#666' />
          </div>
          <div className="userModalHalf">
            <FormSimpleTextInput placeholder="Phone (*)" value={phone} onChange={inputPhone} color='#666' />
          </div>
        </div>}
        {role === 'Translator' && <div className="userModalLine userModalLanguageLine">
          <div className="userModalHalf">
            <FormSimpleTextInput placeholder="Method ID/Email (*)" value={methodID} onChange={setMethodID} color='#666' />
          </div>
        </div>}
        {role === 'Translator' && <div className="userModalLine userModalLanguageLine">
          <div className="userModalHalf">
            <select value={country} onChange={(e) => setCountry(e.target.value)} className="quoteDetailsSelect">
              <option key="" value="" disabled>Country (*)</option>
              {countries.map(country => 
                <option key={country} value={country}>{country}</option>
              )}
            </select>
          </div>
          <div className="userModalHalf">
            <select value={timezone} onChange={(e) => setTimezone(e.target.value)} className="quoteDetailsSelect">
              <option key="" value="" disabled>Timezone (*)</option>
              {timezones.map(timezone => 
                <option key={timezone.text} value={timezone.text}>{timezone.text}</option>
              )}
            </select>
          </div>
        </div>}
        <div className="userModalButtonContainer">
          <AltAnimatedButton text={createUserButton} backend disabled={email === '' || password === '' || confirm === '' || password !== confirm || name === '' || role === '' || (role === 'Translator' && (standardRate === '' || standardRate.endsWith('.') || expeditedRate === '' || expeditedRate.endsWith('.') || language === '' || phone === '' || country === '' || timezone === ''))} onClick={() => createUser()} fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.7 : 1.1) : (windowSize.orientation === 'portrait' ? 3.5 : 1)} />
        </div>
      </animated.div>}

      <div className="translationsInnerContainer">
        <div className="translationsTitleRow">
          <p className="translationsTitle">Users</p>
          <div className="usersFunctionsContainer">
            <div className="userLanguageFilterContainer">
              <input type="text" value={nameSearch} ref={nameSearchRef} onChange={(e) => setNameSearch(e.target.value)} className="userNameFilter" placeholder="Search by name" />
            </div>
            <div className="userLanguageFilterContainer">
              <input type="text" value={emailSearch} ref={emailSearchRef} onChange={(e) => setEmailSearch(e.target.value)} className="userNameFilter" placeholder="Search by email" />
            </div>
            <div className="userLanguageFilterContainer" style={{ display: windowSize.width > 1024 || windowSize.orientation === 'landscape' ? 'flex' : 'none' }}>
              <select value={languageFilter} onChange={(e) => setLanguageFilter(e.target.value)} className="quoteDetailsSelect">
                <option key="" value="">No language selected</option>
                {languages.filter(language => language !== 'english').map(language => 
                  <option key={language.value} value={language.value}>{language.label}</option>
                )}
              </select>
            </div>
            <AltAnimatedButton text={"Add User"} onClick={() => openNewUserModal()} backend fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.7 : 1.1) : (windowSize.orientation === 'portrait' ? 3.5 : 1)} />
          </div>
        </div>
        <div className="translationsTableHeader">
          <div className="usersHeaderColumn usersIDColumn">Email</div>
          <div className="usersHeaderColumn usersNameColumn">Name</div>
          <div className="usersHeaderColumn usersRoleColumn">Role</div>
          <div className="usersHeaderColumn usersLanguageColumn">Language</div>
          <div className="usersHeaderColumn usersRateColumn">Standard</div>
          <div className="usersHeaderColumn usersRateColumn">Expedited</div>
          <div className="usersHeaderColumn usersStatusColumn">Status</div>
          <div className="usersHeaderColumn usersDeleteColumn"></div>
          <div className="usersHeaderColumn usersExtraColumn"></div>
        </div>
        { loadedUsers && users.filter(user => user.role === 'Translator' || user.role === 'Support').length > 0
        ? users.filter(user => user.role === 'Translator' || user.role === 'Support').map(user => 
          <div key={user.id} className="translationsTableLine">
            <div className="usersLineColumn usersIDColumn" style={{ cursor: 'pointer', color: '#609ddb' }} onClick={(e) => transitionTo('/office/user/' + user.id, e)}>{user.email}</div>
            <div className="usersLineColumn usersNameColumn">{user.name}</div>
            <div className="usersLineColumn usersRoleColumn">{user.role}</div>
            <div className="usersLineColumn usersLanguageColumn">{languages.find(language => language.value === user.language) ? languages.find(language => language.value === user.language).label : ''}</div>
            <div className="usersLineColumn usersRateColumn">{user.standardRate > 0 ? "$" + user.standardRate : ''}</div>
            <div className="usersLineColumn usersRateColumn">{user.expeditedRate > 0 ? "$" + user.expeditedRate : ''}</div>
            <div className="usersLineColumn usersStatusColumn">
              {user.status === 'deleting' 
                ? <p className="userStatusText">Deleting...</p>
                : user.role === 'Translator'
                  ? <select value={user.status} onChange={(e) => handleChangeStatus(user.id, e.target.value)} className="userStatusSelect">
                      <option key={'active'} value={'active'}>{'Active'}</option>
                      <option key={'paused'} value={'paused'}>{'Paused'}</option>
                      <option key={'test'} value={'test'}>{'Test'}</option>
                    </select>
                  : <p className="userStatusText">Active</p>
              }
            </div>
            <div className="usersLineColumn usersDeleteColumn">
              {user.status !== 'deleting' ? <Trash2 className="usersDeleteButton" onClick={() => handleDeleteUser(user.id)} /> : null}
            </div>
            <div className="usersLineColumn usersExtraColumn">
              {user.role === 'Translator' && <Info className="usersDeleteButton" style={{ color: '#333' }} />}
              {user.role === 'Translator' && <div className="usersExtraOverlay">
                <p className="usersExtraText"><strong>Phone: </strong>{user.phone}</p>
                <p className="usersExtraText"><strong>Method: </strong>{user.method}</p>
                {user.methodID && <p className="usersExtraText"><strong>Method ID/Email: </strong>{user.methodID}</p>}
                <p className="usersExtraText"><strong>Timezone: </strong>{user.timezone}</p>
                <p className="usersExtraText"><strong>Country: </strong>{user.country}</p>
              </div>}
            </div>
          </div>)
        : loadedUsers ? <div className="loadingTranslations">No users.</div> : <div className="loadingTranslations">Loading users...</div>
        }
        { loadedUsers && users.length >= 50 && nextPointer.current &&
          <div className="loadMoreUsersButton" onClick={() => loadMoreUsers()}>
            Load more users...
          </div>
        }
      </div>
    </div>
  );
};

export default Users;