import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ReactComponent as Close } from "../../assets/icons/x.svg";
import { ReactComponent as EyeOff } from "../../assets/icons/eye-closed.svg";
import { Eye, Loader } from "react-feather";
import ForgotPasswordForm from "./ForgotPasswordForm";
import VerificationCode from "./VerificationCode";
import {ReactComponent as EyeBlack} from '../../assets/icons/eye-closed-black.svg'
import ResetPassword from "./ResetPassword";
import PasswordChanged from "./PasswordChanged";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useScreenTransition } from '../../Hooks/useScreenTransition';
import { AuthContext } from "../../Context/AuthContext";
import {ReactComponent as Delete} from '../../assets/icons/Сircle Cross.svg';
import { NotificationContext } from "../../Context/NotificationContext";
import "./SignInForm.css";

export default function SignInForm({ open, isLoginFormOpen, isCreateAccountOpen }) {
  const modalRoot = document.getElementById("modal-root");

  const { signedIn } = useContext(AuthContext);


  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  const [signInState, setSignInState] = useState("login");
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");


  const isLoginOpen = () => {
    isLoginFormOpen(false);
    setSignInState("login");
  };

  const isCreateAccount=()=>{
    isLoginFormOpen(false);
    isCreateAccountOpen(true);
  }

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isAbleToLogin, setIsAbleToLogin] = useState(false);
  const [showPassword, setShowPassword] = useState("password");
  const [isLoading, setIsLoading] = useState(false);
  const [btnText, setBtnText] = useState("Sign in");

  const { showNotification } = useContext(NotificationContext);


  const [focus, setFocus]=useState(false);
  const [focusPass, setFocusPass]=useState(false);

  const { transitionTo } = useScreenTransition();

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) =>
      prevShowPassword === "password" ? "text" : "password"
    );
  };

  useEffect(() => {
    if (signedIn) {
      isLoginOpen();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedIn])

  useEffect(() => {
    if (userData.email) {
      setEmailError(!/\S+@\S+\.\S+/.test(userData.email));
    } else {
      setEmailError(false);
    }
  }, [userData.email]);

  useEffect(() => {
    if(userData.password){
      setPasswordError(userData.password.length < 6);
    }else{
      setPasswordError(false);
    }
  }, [userData.password]);

  useEffect(() => {
    if(userData.email === "" || userData.password === ""){
      setIsAbleToLogin(false);
      return;
    }
    setIsAbleToLogin(!emailError && !passwordError);
  }, [emailError, passwordError, userData]);

  const handleEmailChange = (e) => {
    setFocus(true);
    setUserData({ ...userData, email: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setFocusPass(true);
    setUserData({ ...userData, password: e.target.value });
  };

  const handleForgotPassword = () => {
    setSignInState("forgot-password");
  };

  const handleVerification=()=>{
    setSignInState("verification");
  }

  const handleResetPassword=()=>{
    setSignInState("reset-password");
  }

  const handlePasswordChanged=()=>{
    setSignInState("password-changed");
  }

  const backToSignIn=()=>{
    setSignInState("login");
  }

  const signIn=()=>{
    if(userData.email === "" || !/\S+@\S+\.\S+/.test(userData.email)){
      setEmailError(true);
      setFocus(false);
      return;
    }

    if(userData.password === "" || userData.password.length < 6){
      setPasswordError(true);
      setFocusPass(false);
      return;
    }

    setBtnText("Signing");
    setIsLoading(true);
    firebase.auth().signInWithEmailAndPassword(userData.email.toLowerCase(), userData.password)
    .then(()=>{
      transitionTo('/account/profile');
      setBtnText("Sign in");
      setIsLoading(false); 
    })
    .catch(e=>{
      // console.log(e);
      setBtnText("Sign in");
      setIsLoading(false);
      showNotification(
        "Error",
        "Invalid Email or Password, Either the email is incorrect or the password. Please check and try again.",
        "error"
      );
    })
  }
  
  let stylesEmailInput=!focus && emailError && userData.email!=="" ? "signInFormInput errorInput": "signInFormInput";
  let stylesPasswordInput=!focusPass && passwordError && userData.password!=="" ? "signInFormInput errorInput": "signInFormInput";
console.log(userData)
  
  if (!open) return null;

  return ReactDOM.createPortal(
    <div className="modal">
    {/* <div className="modal-2" onClick={isLoginOpen}></div> */}
      <div className="modal-content">
        {signInState === "login" ? (
          <div className="signInForm">
            <div className="signInFormHeader">
              <h2 className="signInFormTitle">Sign in</h2>
              <Close className="signInFormIcon" onClick={isLoginOpen} />
            </div>

            <div className="signInFormContainer">
              <input
                className={!focus && userData.email!==""?(emailError && userData.email!==""?'signsignInFormInputNotEmpty errorInput':'signsignInFormInputNotEmpty'):stylesEmailInput}
                type="email"
                value={userData.email}
                onChange={handleEmailChange}
                onFocus={() => {setFocus(true)}}
                onBlur={() => {setFocus(false)}}
                onKeyDown={(e) => e.key === "Enter" && signIn()}
              />
              <span className={!focus && userData.email!==""?'placeholderNotInFocus':"placeholder"}>Email</span>
              {emailError && userData.email !== "" ? <Delete className="createAccountWrongIcon" onClick={()=>setUserData({ ...userData, email: "" })}/>:null}
            </div>

            {(!focus && emailError && userData.email!=="") ? (
              <span className="signInFormInputValidation">
                Invalid email format. Example: example@example.com
              </span>
            ) : null}

            <div className="signInFormContainer">
              <input
                className={!focusPass && userData.password!==""?(passwordError && userData.password!==""?'signsignInFormInputNotEmpty errorInput':"signsignInFormInputNotEmpty"):stylesPasswordInput}
                type={showPassword}
                value={userData.password}
                onChange={handlePasswordChange}
                onFocus={() => {setFocusPass(true)}}
                onBlur={() => {setFocusPass(false)}}
                onKeyDown={(e) => e.key === "Enter" && signIn()}
              />

              <span className={!focusPass && userData.password!==""?'placeholderNotInFocus':"placeholder"}>Password</span>

              {showPassword === "text" ? (
                <Eye className={userData.password===""?"signshowPasswordEye ":"signshowPasswordEye focusedPasswordEye"} onClick={toggleShowPassword} />
              ) : null}
             
             
              {showPassword === "password" && userData.password!==""? (
                <EyeOff
                  className={"signshowPasswordEye "}
                  onClick={toggleShowPassword}
                />
              ) : null}

              {userData.password==="" && showPassword === "password"? (
                <EyeOff
                  className={"signshowPasswordEye "}
                  onClick={toggleShowPassword}
                />
              ) : null}

              {showPassword === "password" && userData.password!==""? (
                <EyeBlack
                  className={"signshowPasswordEye"}
                  onClick={toggleShowPassword}
                />
              ) : null}
              
            </div>

            {(!focusPass && passwordError && userData.password!=="") ? (
              <span className="signInFormInputValidation">
                Password must be at least 6 characters
              </span>
            ) : null}

            <span
              className="signInFormForgotPassword"
              onClick={handleForgotPassword}
            >
              Forgot Password?
            </span>
            <button className="signInFormButton" disabled={!isAbleToLogin || isLoading} onClick={()=>signIn()}>
              {btnText}
             {isLoading && <Loader className="btnLoader"/>}
            </button>

            <div className="signInFormDontHaveAccount">
              <span className="signInFormDontHaveAccountSpan">
                Don't have an account?
              </span>
              <span className="signInFormDontHaveAccountRegister" onClick={()=>isCreateAccount()}>
                Register
              </span>
            </div>
          </div>
        ) : null}
        {signInState === "forgot-password" ? (
          <ForgotPasswordForm isLoginOpen={isLoginOpen} goToVerification={handleVerification} backToSignIn={backToSignIn} email={forgotPasswordEmail} setEmail={setForgotPasswordEmail}/>
        ) : null}

        {signInState==="verification"?(
          <VerificationCode backToSignIn={backToSignIn} isLoginOpen={isLoginOpen} goToResetPassword={handleResetPassword} email={forgotPasswordEmail}/>
        ):null}

        {signInState==="reset-password"?<ResetPassword isLoginOpen={isLoginOpen} backToSignIn={backToSignIn} handlePasswordChanged={handlePasswordChanged} email={forgotPasswordEmail}/>:null}

        {signInState==="password-changed"?<PasswordChanged backToSignIn={backToSignIn} isLoginOpen={isLoginOpen}/>:null}
      </div>
    </div>,
    modalRoot
  );
}
