import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Editor, EditorState, convertFromRaw } from 'draft-js';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { NotificationContext } from '../../Context/NotificationContext';
import { DataContext } from '../../Context/DataContext';

import './SingleNotification.css';

const SingleNotification = ({ windowSize }) => {

  const { notificationID } = useParams();

  const { showNotification } = useContext(NotificationContext);

  const { userData } = useContext(DataContext);

  const [notification, setNotification] = useState(null);

  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());

  useEffect(() => {
    const firestore = firebase.firestore();

    firestore.collection('notifications').doc(notificationID).get()
      .then(doc => {
        if (doc.exists) {
          setNotification({ ...doc.data(), id: doc.id });

          if (doc.data().data.message) {
            setEditorState(EditorState.createWithContent(convertFromRaw(doc.data().data.message)));
          }
        } else {
          showNotification('Error', "There was an error loading the announcement. Please, reload the page.", 'error');
        }
      })
      .catch(error => {
        showNotification('Error', "There was an error loading the announcement. Please, reload the page.", 'error');
      })
      
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationID]);

  useEffect(() => {
    const firestore = firebase.firestore();

    firestore.collection('notifications').doc(notificationID).update({
      read: firebase.firestore.FieldValue.arrayUnion(userData.id)
    });
      
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationID]);

  const formatDate = timestamp => {
    const date = new Date(timestamp);

    const splitted = date.toDateString().split(' ');

    return splitted[1] + ' ' + splitted[2] + ', ' + splitted[3] + ' @ ' + date.toTimeString().substr(0, 5);
  }

  return (
    <div className="singleTranslationContainer">
      <div className="singleTranslationHeader">
        <p className="singleTranslationOrderNumber">Announcement</p>
      </div>
      { notification && notification.type === 'announcement'
      ? <div className="singleTranslationDetailsOuter">
          <div className="singleTranslationDetailsMain" style={{ width: '100%' }}>
            <div className="singleTranslationDetailsDates" style={{ paddingBottom: 30 }}>
              <div className="singlePaymentDecideTitleContainer" style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <p className="singlePaymentDecideTitle">{notification.data.title}</p>
                <p className="announcementDateText">{`Posted ${formatDate(notification.timestamp)}`}</p>
              </div>
              <div className="quoteRichTextEditor">
                <Editor
                  editorState={editorState}
                  onChange={editorState => setEditorState(editorState)}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      : null
      }
    </div>
  );
};

export default SingleNotification;