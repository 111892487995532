import React from 'react';

import './FormSimpleTextInput.css';

function FormSimpleTextInput({ placeholder, value, onChange, color, correct, incorrect, password = false, onKeyDown=()=> {}, onFocus= ()=> {}, onBlur=()=> {} }) {
 
  return (
    <div className="formInputContainer">
      <p className="formInputLabel" style={{ color: incorrect ? '#f93636' : correct ? '#07a70b' : (color || '#666') }}>{placeholder}</p>
      <input onKeyDown={onKeyDown} onFocus={onFocus} onBlur={onBlur} type={password ? "password" : "text"} className="formInput" style={{ color: incorrect ? '#f93636' : correct ? '#07a70b' : (color || '#666'), borderColor: incorrect ? '#f93636' : correct ? '#07a70b' : (color || 'rgba(102,102,102,0.7)') }} placeholder="" value={value} onChange={(e) => onChange(e.target.value)} />
    </div>
  );
}

export { FormSimpleTextInput };