import React, { useContext, useEffect, useRef, useState } from "react";

import "../Account/Quotes.css";
import "./Payments.css";
import TopBar from "../../Components/TopBar";
import { Navbar } from "../../Components/Navbar";
import { useSpring, animated, config } from "react-spring";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useScreenTransition } from "../../Hooks/useScreenTransition";

import { AuthContext } from "../../Context/AuthContext";
import { TransitionContext } from "../../Context/TransitionContext";
import { DataContext } from "../../Context/DataContext";
import { AccountFooter } from "../../Components/AccountFooter";
import { NotificationContext } from "../../Context/NotificationContext";
import { Loader } from "react-feather";
import { AltAnimatedButton } from "../../Components/AltAnimatedButton";

const FREQUENCIES = ["case-by-case", "weekly", "bi-weekly", "monthly"];

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const LEAPYEAR = [
  2020, 2024, 2028, 2032, 2036, 2040, 2044, 2048, 2052, 2056, 2060, 2064, 2068,
  2072, 2076, 2080,
];

// const LEAPMONTH = ["Feb 1", "Feb 29"];

const DAYSBYMONTHS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

const LEAPDAYSBYMONTH = 29;

const TeamPayments = ({ windowSize }) => {
  const { transitionTo } = useScreenTransition();
  const { setColor } = useContext(TransitionContext);
  const { showNotification } = useContext(NotificationContext);
  const { signedIn } = useContext(AuthContext);
  const { userData } = useContext(DataContext);

  const [selectedFrequency, setSelectedFrequency] = useState(FREQUENCIES[3]);
  const [showRangeData, setShowRangeData] = useState(null);

  const [loadedOrders, setLoadedOrders] = useState(false);
  const [teamOrders, setTeamOrders] = useState([]);
  const [sortedOrders, setSortedOrders] = useState([]);
  const [selectedCases, setSelectedCases] = useState([]);
  const [minYearForData, setMinYearForData] = useState(
    new Date().getFullYear()
  );

    const [loading, setLoading] = useState(false);

  let nextPointer = useRef();
  if (!nextPointer.current) nextPointer.current = null;

  const phoneScreen =
    windowSize.width > 767 || windowSize.orientation === "landscape"
      ? false
      : true;

  const [menuOpen, setMenuOpen] = useState(!phoneScreen);

  const accountMenuProps = useSpring({
    maxHeight: menuOpen || !phoneScreen ? "calc(50vh)" : "calc(2.5vh)",
    config: config.slow,
  });

  useEffect(() => {
    setColor(true);
  }, [setColor]);

  useEffect(() => {
    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedIn, userData]);

  useEffect(() => {
    if (teamOrders.length > 0) {
      if (selectedFrequency === FREQUENCIES[3]) {
        sortOrdersByMonth(new Date(), minYearForData);
      } else if (selectedFrequency === FREQUENCIES[2]) {
        sortOrdersByWeek(new Date(), minYearForData, true);
      } else if (selectedFrequency === FREQUENCIES[1]) {
        sortOrdersByWeek(new Date(), minYearForData);
      }
    } else {
      setLoadedOrders(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamOrders, selectedFrequency, setMinYearForData]);

  const getOrders = () => {
    if (!signedIn) {
      transitionTo("/sign-in");
    } else {
      sleep(800).then(() => {
        if (firebase.auth().currentUser && userData) {
          const firestore = firebase.firestore();

          let query = firestore
            .collection("orders")
            .where(
              "timestamp",
              ">=",
              new Date(`Jan, 1 ${minYearForData} 00:00:00`).getTime()
            );

          query
            .where("teamId", "==", userData.teamId)
            .orderBy("timestamp", "desc")
            .get()
            .then((querySnapshot) => {
              nextPointer.current =
                querySnapshot.docs[querySnapshot.docs.length - 1];

              const orders = querySnapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
              }));

              setTeamOrders(orders);
            })
            .catch((error) => {
              console.log(error);
              setLoadedOrders(true);
              showNotification(
                "Error",
                "An error ocurred while loading the orders. Please, try again. If it persists, contact Support.",
                "error"
              );
            });
        }
      });
    }
  };

  const loadMoreOrders = () => {
    if (nextPointer.current && userData) {
      const firestore = firebase.firestore();

      let query = firestore
        .collection("orders")
        .where(
          "timestamp",
          ">=",
          new Date(`Jan, 1 ${minYearForData - 1} 00:00:00`).getTime()
        );

      setMinYearForData((y) => y - 1);

      query
        .where("teamId", "==", userData.teamId)
        .orderBy("timestamp", "desc")
        .startAfter(nextPointer.current)
        .get()
        .then((querySnapshot) => {
          nextPointer.current =
            querySnapshot.docs[querySnapshot.docs.length - 1];

          const orders = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          setTeamOrders((o) => o.concat(orders));
        })
        .catch((error) => {
          console.log(error);
          setLoadedOrders(true);
          showNotification(
            "Error",
            "An error ocurred while loading the orders. Please, try again. If it persists, contact Support.",
            "error"
          );
        });
    }
  };

  const sortOrdersByMonth = (date, minYear) => {
    const sortedMonthOrders = [];
    let month = date.getMonth();
    let year = date.getFullYear();
    while (month <= 11 && year > minYear - 1) {
      const date = `${MONTHS[month]} 1, ${year} - ${MONTHS[month]} ${
        month === 1 && LEAPYEAR.includes(year)
          ? LEAPDAYSBYMONTH
          : DAYSBYMONTHS[month]
      }, ${year}`;

      // eslint-disable-next-line no-loop-func
      const data = teamOrders.filter((doc) => {
        const docDate = new Date(doc.timestamp);
        if (docDate.getMonth() === month && docDate.getFullYear() === year) {
          return true;
        } else {
          return false;
        }
      });
      sortedMonthOrders.push([date, data]);

      if (month > 0) {
        month -= 1;
      } else {
        year -= 1;
        month = 11;
      }
    }

    setSortedOrders(sortedMonthOrders);
    setLoadedOrders(true);
  };

  const sortOrdersByWeek = (date, minYear, biWeekly = false) => {
    console.log(minYear);
    const sortedWeekOrders = [];
    let weekday = date.getDay();
    let startMonth = date.getMonth();
    let endMonth = date.getMonth();
    let endDate =
      weekday === 0
        ? date.getDate()
        : date.getDate() + (biWeekly ? 14 : 7) - weekday;
    let startDate = endDate - (biWeekly ? 13 : 6);
    let startYear = date.getFullYear();
    let endYear = date.getFullYear();

    if (endDate > DAYSBYMONTHS[endMonth]) {
      if (endMonth === 1 && LEAPYEAR.includes(endYear)) {
        endDate -= LEAPDAYSBYMONTH;
      } else {
        endDate -= DAYSBYMONTHS[endMonth];
      }
      endMonth += 1;
      if (endMonth > 11) {
        endMonth = 0;
        endYear += 1;
      }
    }

    const setWeekStartingDate = () => {
      if (startDate <= 0) {
        if (startMonth === 2 && LEAPYEAR.includes(startYear)) {
          startMonth -= 1;
          startDate = LEAPDAYSBYMONTH + startDate;
        } else {
          if (startMonth === 0) {
            startMonth = 11;
            startYear -= 1;
          } else {
            startMonth -= 1;
          }
          startDate = DAYSBYMONTHS[startMonth] + startDate;
        }
      }
    };
    const setWeekEndingDate = () => {
      if (endDate <= 0) {
        if (endMonth === 2 && LEAPYEAR.includes(endYear)) {
          endMonth -= 1;
          endDate = LEAPDAYSBYMONTH + endDate;
        } else {
          if (endMonth === 0) {
            endMonth = 11;
            endYear -= 1;
          } else {
            endMonth -= 1;
          }
          endDate = DAYSBYMONTHS[endMonth] + endDate;
        }
      }
    };

    const filterDataFromOrders = () => {
      return teamOrders.filter((doc) => {
        const docDate = doc.timestamp;
        const docStartDate = new Date(
          `${MONTHS[startMonth]} ${startDate} ${startYear} 00:00:00`
        ).getTime();
        const docEndDate = new Date(
          `${MONTHS[endMonth]} ${endDate}  ${endYear} 23:59:00`
        ).getTime();

        if (docDate <= docEndDate && docDate >= docStartDate) {
          return true;
        } else {
          return false;
        }
      });
    };
    setWeekStartingDate();

    while (startDate <= 31 && startMonth <= 11 && startYear > minYear - 1) {
      const date = `${MONTHS[startMonth]} ${startDate}, ${startYear} - ${MONTHS[endMonth]} ${endDate}, ${endYear}`;

      const data = filterDataFromOrders();
      sortedWeekOrders.push([date, data]);

      endDate = startDate - 1;
      endMonth = startMonth;
      endYear = startYear;
      setWeekEndingDate();
      startDate = startDate - (biWeekly ? 14 : 7);
      setWeekStartingDate();
    }

    setSortedOrders(sortedWeekOrders);
    setLoadedOrders(true);
  };

  const expandRange = (i) => {
    setShowRangeData(i);
  };

  const totalAwaitingPayment = (data) => {
    let total = 0;

    data
      .filter((order) => checkAwaitingPaymentStatus(order))
      .map((order) => {
        total += order.totalPrice - getDiscount(order);
        return false;
      });

    return formatTotal(total);
  };

  const totalPayment = (data) => {
    let total = 0;

    data.map((order) => {
      if (order.status !== "cancelled") {
        total += order.totalPrice - getDiscount(order);
      }
      return false;
    });

    return formatTotal(total);
  };

  // const getTotal = (order) => {
  //   let total = 0;
  //   const orderDate = order ? new Date(order.timestamp) : new Date();
  //   const priceUpdateDate = new Date("Sun, 30 Oct 2022 20:02:56 GMT");

  //   if (order && order.certified) {
  //     if (order.pagesCount !== "") {
  //       total = total + parseInt(order.pagesCount, 10) * 24.95;
  //     } else {
  //       total = 0;
  //     }
  //   } else if (order) {
  //     if (order.wordCount !== "") {
  //       total = total + parseInt(order.wordCount, 10) * 0.1;
  //     } else {
  //       total = 0;
  //     }
  //   }

  //   if (order && order.expedited) {
  //     if (orderDate > priceUpdateDate) {
  //       total = total + total * 0.4;
  //     } else {
  //       total = total + total * 0.6;
  //     }
  //   }

  //   if (order && order.notarization) {
  //     total = total + 34.9;
  //   }

  //   if (order && order.hardCopy) {
  //     if (orderDate >= priceUpdateDate) {
  //       if (
  //         ["United States", "Puerto Rico", "U.S. Virgin Islands"].includes(
  //           order.hardCopyExtra.country
  //         )
  //       ) {
  //         if (order.hardCopyExtra.method === "priority") {
  //           total = total + 19.95;
  //         } else {
  //           total = total + 37.95;
  //         }
  //       } else {
  //         if (order.hardCopyExtra.method === "priority") {
  //           total = total + 49.95;
  //         } else {
  //           total = total + 78.95;
  //         }
  //       }
  //     } else {
  //       if (
  //         ["United States", "Puerto Rico", "U.S. Virgin Islands"].includes(
  //           order.hardCopyExtra.country
  //         )
  //       ) {
  //         if (order.hardCopyExtra.method === "priority") {
  //           total = total + 12.95;
  //         } else {
  //           total = total + 29.95;
  //         }
  //       } else {
  //         if (order.hardCopyExtra.method === "priority") {
  //           total = total + 39.95;
  //         } else {
  //           total = total + 67.95;
  //         }
  //       }
  //     }
  //   }

  //   if (order && order.hardCopy && order.hardCopyExtra.requireSignature) {
  //     if (orderDate >= priceUpdateDate) {
  //       total = total + 4.99;
  //     } else {
  //       total = total + 2.54;
  //     }
  //   }

  //   return total;
  // };

  const getDiscount = (order) => {
    if (!order || !order.discount || order.discount === 0) {
      return 0;
    } else {
      const orderDate = order ? new Date(order.timestamp) : new Date();
      const discountUpdateDate = new Date("Tue, 28 Mar 2023 20:02:56 GMT");
      if (orderDate >= discountUpdateDate) {
        let total = 0;

        if (order && order.certified) {
          if (order.pagesCount !== "") {
            total = total + parseInt(order.pagesCount, 10) * 24.95;
          } else {
            total = 0;
          }
        } else if (order) {
          if (order.wordCount !== "") {
            total = total + parseInt(order.wordCount, 10) * 0.1;
          } else {
            total = 0;
          }
        }
        return (total * order.discount) / 100;
      }
      return (order.totalPrice * order.discount) / 100;
    }
  };

  const formatTotal = (value) => {
    return value.toFixed(2);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const splitted = date.toDateString().split(" ");

    return splitted[1] + " " + splitted[2] + ", " + splitted[3];
  };

  const checkAwaitingPaymentStatus = (order) => {
    if (
      (order.paypalOrderID && order.paypalOrderID === "pay-later") &&
      order.status !== "cancelled"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const updateCheckListCases = (checkbox, order) => {
    if (checkbox) {
      setSelectedCases((cases) => [...cases, order]);
    } else {
      setSelectedCases((cases) =>
        cases.filter((caseOrder) => caseOrder.orderNumber !== order.orderNumber)
      );
    }
  };

  const checkInput = (order) => {
    return (
      selectedCases.filter(
        (caseOrder) => caseOrder.orderNumber === order.orderNumber
      ).length > 0
    );
  };

  const addTeamInvoice = async(e,data) => {
    setTimeout(() => {}, 1000);
    const firestore = firebase.firestore();
    const docRef = firestore.collection("teamInvoices");
    

    docRef
      .add({
        orders: data.orders.map(order => ({id: order.id, orderNumber: order.orderNumber})),
        total: Number(data.total),
        date: data.date,
        status: "new",
        teamId: userData.teamId,
        reason: "",
        timestamp: new Date().getTime(),
      })
      .then((docRef) => {
        // TODO: copy link.
        setLoading(false);
        transitionTo(`/team/make-payment/${docRef.id}`, e);
      });
  };

  const makePayment = (e, orders, totalFn, date) => {
    setLoading(true);
    const total = totalFn(orders);
    addTeamInvoice(e, {orders, total, date});
  };

  return (
    <>
      <TopBar />
      <div className="homeContainer">
        <Navbar windowSize={windowSize} />
        <div className="accountContainer">
          <animated.div
            style={accountMenuProps}
            className="accountSidebar sideBar"
          >
            <p
              className="accountSidebarTitle"
              onClick={phoneScreen ? () => setMenuOpen((i) => !i) : null}
            >
              {windowSize.width > 767 || windowSize.orientation === "landscape"
                ? "Team"
                : "Team Menu"}
            </p>
            <p
              className="accountSidebarLink"
              onClick={(e) => transitionTo("/team/members", e)}
            >
              Members
            </p>
            <p
              className="accountSidebarLink"
              onClick={(e) => transitionTo("/team/shipping", e)}
            >
              Shipping
            </p>
            <p
              className="accountSidebarLink"
              onClick={(e) => transitionTo("/team/quotes", e)}
            >
              Quotes
            </p>
            <p
              className="accountSidebarLink"
              onClick={(e) => transitionTo("/team/orders", e)}
            >
              Orders
            </p>
            <p
              className="accountSidebarLink"
              onClick={(e) => transitionTo("/team/options", e)}
            >
              Options
            </p>
            <p className="accountSidebarLink" style={{ color: "#666" }}>
              Payments
            </p>
          </animated.div>
          <div className="accountMainContainer">
            <div className="accountMainTitleRow">
              <h1 className="accountMainTitle">Payments</h1>
            </div>
            <div className="titleWrapper">
              <h3 className="paymentTitle">
                What's your preferred payment frequency?
              </h3>
            </div>

            <div className="freqencyWrapper">
              <button
                className={`frequencySelector ${
                  selectedFrequency === FREQUENCIES[0] && "selectedFrequency"
                }`}
                onClick={() => setSelectedFrequency(FREQUENCIES[0])}
              >
                Case-by-Case
              </button>
              <button
                className={`frequencySelector ${
                  selectedFrequency === FREQUENCIES[1] && "selectedFrequency"
                }`}
                onClick={() => setSelectedFrequency(FREQUENCIES[1])}
              >
                Weekly
              </button>
              <button
                className={`frequencySelector ${
                  selectedFrequency === FREQUENCIES[2] && "selectedFrequency"
                }`}
                onClick={() => setSelectedFrequency(FREQUENCIES[2])}
              >
                Bi-weekly
              </button>
              <button
                className={`frequencySelector ${
                  selectedFrequency === FREQUENCIES[3] && "selectedFrequency"
                }`}
                onClick={() => setSelectedFrequency(FREQUENCIES[3])}
              >
                Monthly
              </button>
            </div>

            {/* Monthly/Weekly/Bi-Weekly Range View */}

            {(selectedFrequency === FREQUENCIES[3] ||
              selectedFrequency === FREQUENCIES[2] ||
              selectedFrequency === FREQUENCIES[1]) &&
              (sortedOrders.length > 0 ? (
                sortedOrders.map((orders, i) => (
                  <div className="frequencyData" key={orders[0] + i}>
                    <div className="dataRangeWrapper">
                      <div className="dataRangeWrapper">
                        <div
                          className="dataRange"
                          onClick={() =>
                            orders[1].length > 0 &&
                            (showRangeData === i
                              ? expandRange(-1)
                              : expandRange(i))
                          }
                        >
                          <div className="date">{orders[0]}</div>
                          <div className="price">
                            {"$"}
                            {totalAwaitingPayment(orders[1]) > 0
                              ? totalAwaitingPayment(orders[1])
                              : totalPayment(orders[1])}
                          </div>
                        </div>
                        <div
                          className="rangeData"
                          style={{
                            display:
                              showRangeData === null &&
                              totalAwaitingPayment(orders[1]) > 0
                                ? "block"
                                : showRangeData === i
                                ? "block"
                                : "none",
                          }}
                        >
                          {orders.length > 0 &&
                            orders[1].map((order) => (
                              <div
                                className="orderData"
                                key={order.orderNumber}
                              >
                                <div className="date">
                                  {formatDate(order.timestamp)}
                                  {" - "}
                                  {order.orderNumber}
                                </div>
                                <div className="price">
                                  {"$"}
                                  {formatTotal(
                                    order.totalPrice - getDiscount(order)
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`paymentStatus ${
                        totalAwaitingPayment(orders[1]) > 0
                          ? "awaiting"
                          : totalPayment(orders[1]) > 0
                          ? "paid"
                          : "no-order"
                      }`}
                      onClick={(e) =>
                        totalAwaitingPayment(orders[1]) > 0 &&
                        makePayment(
                          e,
                          orders[1].filter((order) =>
                            checkAwaitingPaymentStatus(order)
                          ),
                          totalAwaitingPayment,
                          orders[0]
                        )
                      }
                    >
                      {totalAwaitingPayment(orders[1]) > 0
                        ? "Awaiting Payment"
                        : totalPayment(orders[1]) > 0
                        ? "Paid"
                        : "No Orders Received"}
                    </div>
                  </div>
                ))
              ) : signedIn && userData && loadedOrders ? (
                <div className="loadingQuotes">You have no orders.</div>
              ) : (
                <div className="loadingQuotes">Loading orders...</div>
              ))}

            {/* Case by case Range view */}

            {selectedFrequency === FREQUENCIES[0] &&
              selectedCases.length > 0 && (
                <div className="makePaymentWrapper">
                  <div className="text">
                    {"$"}
                    {totalPayment(selectedCases)}
                  </div>
                  <AltAnimatedButton
                            text={"Make Payment"}
                            color="rgb(23, 188, 91)"
                            backend
                            onClick={(e) =>
                              makePayment(
                                e,
                                selectedCases,
                                totalPayment,
                                `${formatDate(
                                  selectedCases[0].timestamp
                                )} - ${formatDate(
                                  selectedCases[selectedCases.length - 1].timestamp
                                )}`
                              )
                            }
                            fontSize={
                              windowSize.width > 1024
                                ? 0.9
                                : windowSize.width > 767
                                ? windowSize.orientation === "portrait"
                                  ? 1.7
                                  : 1.1
                                : windowSize.orientation === "portrait"
                                ? 3.5
                                : 1
                            }
                          />
                  {/* <div className="subText">or copy payment link</div> */}
                </div>
              )}

            {selectedFrequency === FREQUENCIES[0] &&
              (teamOrders.length > 0 ? (
                teamOrders.map((order, i) => (
                  <div className="frequencyData" key={order.id}>
                    <div className="dataRangeWrapper">
                      <div className="dataRangeWrapper">
                        <div
                          className="dataRange"
                          style={{ cursor: "default" }}
                        >
                          <div className="date">
                            <div className="checkbox">
                              {checkAwaitingPaymentStatus(order) && (
                                <input
                                  type="checkbox"
                                  checked={checkInput(order)}
                                  onChange={(e) => {
                                    updateCheckListCases(
                                      e.target.checked,
                                      order
                                    );
                                  }}
                                />
                              )}
                            </div>
                            {order.orderNumber}
                            {" - "}
                            {formatDate(order.timestamp)}
                          </div>
                          <div className="price">
                            {"$"}
                            {formatTotal(order.totalPrice - getDiscount(order))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`paymentStatus ${
                        checkAwaitingPaymentStatus(order)
                          ? "awaiting"
                          : order.status !== "cancelled"
                          ? "paid"
                          : "no-order"
                      }`}
                      onClick={(e) =>
                        checkAwaitingPaymentStatus(order) &&
                        makePayment(
                          e,
                          [order],
                          totalPayment,
                          `${order.orderNumber} - ${formatDate(
                            order.timestamp
                          )}`
                        )
                      }
                    >
                      {checkAwaitingPaymentStatus(order)
                        ? "Awaiting Payment"
                        : order.status !== "cancelled"
                        ? order.paypalOrderID !== "processing"
                          ? "paid"
                          : "processing"
                        : "Cancelled"}
                    </div>
                  </div>
                ))
              ) : signedIn && userData && loadedOrders ? (
                <div className="loadingQuotes">You have no orders.</div>
              ) : (
                <div className="loadingQuotes">Loading orders...</div>
              ))}
            {nextPointer.current ? (
              <p
                onClick={() => loadMoreOrders()}
                className="loadMoreQuotesButton"
              >
                Load more orders...
              </p>
            ) : null}
          </div>
        </div>
        <AccountFooter windowSize={windowSize} />
      </div>

      {loading && <div className="loadingWrapper">
        <Loader className="icon"></Loader>
      </div>}
    </>
  );
};

export default TeamPayments;
