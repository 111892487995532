import React from "react";
import { useSpring, animated } from "react-spring";

import Notification from "../../assets/notification-blue.png";
import { AltAnimatedButton } from "../../Components/AltAnimatedButton";

const PaymentUpdatePopUp = ({
  windowSize,
  openConfirmation,
  setOpenConfirmation,
}) => {
  const confirmationModalProps = useSpring({
    opacity: openConfirmation ? 1 : 0,
    pointerEvents: openConfirmation ? "all" : "none",
  });

  return (
    <>
      {openConfirmation && (
        <animated.div
          style={confirmationModalProps}
          className="revisionModalBg"
          onClick={() => setOpenConfirmation(false)}
        />
      )}
      {openConfirmation && (
        <animated.div
          style={confirmationModalProps}
          className="confirmationModalOuter"
        >
          <div className="singleTranslationNotesContainer">
            <div className="paymentIconWrap">
              <img className="confirmationModalCheck" src={Notification} alt="" />
            </div>
            <p className="confirmationModalTitle">Quick Update on Your Invoice!</p>
            <p className="documentsSubheading">
              We noticed one of your orders was paid for separately, so
              we've adjusted your invoice to reflect that. All set now!
            </p>

            <div className="closeBtnWrap">
              <AltAnimatedButton
                text={"Close"}
                backend
                onClick={() => setOpenConfirmation(false)}
                fontSize={
                  windowSize.width > 1024
                    ? 0.9
                    : windowSize.width > 767
                    ? windowSize.orientation === "portrait"
                      ? 1.7
                      : 1.1
                    : windowSize.orientation === "portrait"
                    ? 3.5
                    : 1
                }
              />
            </div>
          </div>
        </animated.div>
      )}
    </>
  );
};

export default PaymentUpdatePopUp;
