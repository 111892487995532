import React from 'react';
import { ChevronRight, Check } from 'react-feather';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import './LanguagesDisplay.css';

const LanguagesDisplay = () => {
  
  const { transitionTo } = useScreenTransition();

  return (
    <div className="languagesDisplayContainer">
      <div className="languagesDisplayInfo">
        <h2 className="documentsDisplayInfoTitle">Popular Languages</h2>
        <h3 className="documentsDisplayInfoText">Our translators are full-time professionals thoroughly vetted and reviewed on a regular basis to guarantee that our translations maintain a consistent level of quality across languages.</h3>
        <div className="documentsDisplayInfoButtonContainer" onClick={(e) => transitionTo('/languages',e)}>
          <p className="documentsDisplayInfoButtonText">See all languages</p>
          <ChevronRight color='#fff' size={20} />
        </div>
      </div>
      <div className="documentsDisplayListContainer languagesDisplayListContainer">
        {/* <h3 className="documentsDisplayListTitle">Popular Languages</h3> */}
        <div className="documentsDisplayListInner">
          <div className="documentsDisplayListColumn mobileTableColumnDocumentsDisplay">
            <div className="documentsDisplayListItem" /*onClick={(e) => transitionTo('/language/spanish',e)}*/>
              <Check color='#18b7bb' size={20} />
              <p className="documentsDisplayListItemText">Spanish</p>
            </div>
            <div className="documentsDisplayListItem" /*onClick={(e) => transitionTo('/language/portugueseBra',e)}*/>
              <Check color='#18b7bb' size={20} />
              <p className="documentsDisplayListItemText">Portuguese</p>
            </div>
            <div className="documentsDisplayListItem" /*onClick={(e) => transitionTo('/language/french',e)}*/>
              <Check color='#18b7bb' size={20} />
              <p className="documentsDisplayListItemText">French</p>
            </div>
            <div className="documentsDisplayListItem" /*onClick={(e) => transitionTo('/language/german',e)}*/>
              <Check color='#18b7bb' size={20} />
              <p className="documentsDisplayListItemText">German</p>
            </div>
            <div className="documentsDisplayListItem" /*onClick={(e) => transitionTo('/language/russian',e)}*/>
              <Check color='#18b7bb' size={20} />
              <p className="documentsDisplayListItemText">Russian</p>
            </div>
          </div>
          <div className="documentsDisplayListColumn">
            <div className="documentsDisplayListItem" /*onClick={(e) => transitionTo('/language/chineseSimp',e)}*/>
              <Check color='#18b7bb' size={20} />
              <p className="documentsDisplayListItemText">Chinese</p>
            </div>
            <div className="documentsDisplayListItem" /*onClick={(e) => transitionTo('/language/arabic',e)}*/>
              <Check color='#18b7bb' size={20} />
              <p className="documentsDisplayListItemText">Arabic</p>
            </div>
            <div className="documentsDisplayListItem" /*onClick={(e) => transitionTo('/language/italian',e)}*/>
              <Check color='#18b7bb' size={20} />
              <p className="documentsDisplayListItemText">Italian</p>
            </div>
            <div className="documentsDisplayListItem" /*onClick={(e) => transitionTo('/language/japanese',e)}*/>
              <Check color='#18b7bb' size={20} />
              <p className="documentsDisplayListItemText">Japanese</p>
            </div>
            <div className="documentsDisplayListItem" /*onClick={(e) => transitionTo('/language/korean',e)}*/>
              <Check color='#18b7bb' size={20} />
              <p className="documentsDisplayListItemText">Korean</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { LanguagesDisplay };