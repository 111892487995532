import React, { useState, useContext, useEffect, useRef } from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { Clock, Check } from 'react-feather';

import { languages } from '../../Data/languages';

import { NotificationContext } from '../../Context/NotificationContext';
import { DataContext } from '../../Context/DataContext';
import { useScreenTransition } from '../../Hooks/useScreenTransition';

const pageLimit = 50;

const OwnTranslations = ({ windowSize }) => {

  const { transitionTo } = useScreenTransition()


  const { showNotification } = useContext(NotificationContext);

  const { userData } = useContext(DataContext);

  const [orders, setOrders] = useState([]);
  const [loadedOrders, setLoadedOrders] = useState(false);

  const orderSearchRef = useRef();
  const [orderSearch, setOrderSearch] = useState('');

  const [dueSort, setDueSort] = useState(false);

  const [statusFilter, setStatusFilter] = useState('');

  const [triggerFilter, setTriggerFilter] = useState(true);

  let firstLoad = useRef();
  if (!firstLoad.current) firstLoad.current = true;

  let nextPointer = useRef();
  if (!nextPointer.current) nextPointer.current = null;

  useEffect(() => {
    const applyOrder = e => {
      if (e.keyCode === 13) {
        setTriggerFilter(tf => !tf);
      }
    }

    const orderSearchRefVar = orderSearchRef.current;

    orderSearchRef.current.addEventListener("keyup", applyOrder);

    return () => {
      orderSearchRefVar.removeEventListener("keyup", applyOrder);
    }
  }, []);

  useEffect(() => {
    if (userData) {
      firstLoad.current = true;

      const firestore = firebase.firestore();

      let query = firestore.collection('orders');

      if (orderSearch !== '') {
        query = query.where('orderNumber', '==', parseInt(orderSearch,10));
      };

      if (statusFilter !== '') {
        query = query.where('status', '==', statusFilter);
      };

      if (dueSort) {
        query = query.orderBy('due', 'asc')
      } else {
        query = query.orderBy('timestamp', 'desc');
      }

      const unsubscribe = 
        query
          .where('translator.id', '==', userData.id)
          .limit(pageLimit)
          .onSnapshot(querySnapshot => {
            if (firstLoad.current) {
              nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];
              firstLoad.current = false;
              
              setOrders(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
              setLoadedOrders(true);
            } else {
              let newOrders = [];

              querySnapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                  newOrders.push({ id: change.doc.id, ...change.doc.data() });
                }
              });
              
              setOrders(u => newOrders.concat(u));
              setLoadedOrders(true);
            }
          }, error => {
            //console.log(error);
            showNotification('Error', "There was an error loading the orders. Please, reload the page.", 'error');
            setLoadedOrders(true);
          });

      return () => unsubscribe();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, triggerFilter, dueSort, statusFilter]);

  const loadMoreOrders = () => {
    if (nextPointer.current && userData) {
      const firestore = firebase.firestore();

      let query = firestore.collection('orders');

      if (orderSearch !== '') {
        query = query.where('orderNumber', '==', orderSearch);
      };

      if (statusFilter !== '') {
        query = query.where('status', '==', statusFilter);
      };

      if (dueSort) {
        query = query.orderBy('due', 'desc')
      } else {
        query = query.orderBy('timestamp', 'desc');
      }

      query
        .where('translator.id', '==', userData.id)
        .startAfter(nextPointer.current)
        .limit(pageLimit)
        .get().then((querySnapshot) => {
          nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];

          let oldOrders = [];

          querySnapshot.forEach((doc) => {
            oldOrders.push({ id: doc.id, ...doc.data() });
          });
          
          setOrders(u => u.concat(oldOrders));
        })
        .catch((error) => {
          showNotification('Error', "There was an error loading the orders. Please, reload the page.", 'error');
          //console.log("Error getting documents: ", error);
        });
    }
  }

  const formatDue = timestamp => {
    const date = new Date(timestamp);

    const splitted = date.toDateString().split(' ');

    return splitted[1] + ' ' + splitted[2] + ', ' + splitted[3] + ' @ ' + date.toTimeString().substr(0, 5);
  }

  return (
    <div className="translationsContainer">
      <div className="translationsInnerContainer">
        <div className="translationsTitleRow">
          <p className="translationsTitle">Translations</p>
          <div className="usersFunctionsContainer">
            <div className="userLanguageFilterContainer">
              <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} className="quoteDetailsSelect">
                <option key="" value="">All</option>
                <option key="assigned" value="assigned">Assigned</option>
                <option key="in-progress" value="in-progress">In Progress</option>
                <option key="completed" value="completed">Completed</option>
                <option key="cancelled" value="cancelled">Cancelled</option>
              </select>
            </div>
            <div onClick={() => setDueSort(d => !d)} className="userFunctionsCheckboxContainer">
              <div className="userFunctionsCheckbox">
                <Check className="userFunctionsCheck" style={{ opacity: dueSort ? 1 : 0 }} />
              </div>
              Sort by due date
            </div>
            <div className="userLanguageFilterContainer" style={{ marginRight: 0 }}>
              <input type="text" value={orderSearch} ref={orderSearchRef} onChange={(e) => setOrderSearch(e.target.value)} className="userNameFilter" placeholder="Search by order number" />
            </div>
          </div>
        </div>
        <div className="translationsTableHeader">
          <div className="usersHeaderColumn translationsTimerColumn">
            <Clock className="translationsColumnIcon" />
          </div>
          <div className="usersHeaderColumn translationsOrderColumn">Order</div>
          <div className="usersHeaderColumn translationsInvoiceColumn">Invoice</div>
          <div className="usersHeaderColumn translationsLanguageColumn">From</div>
          <div className="usersHeaderColumn translationsLanguageColumn">To</div>
          <div className="usersHeaderColumn translationsDueColumn">Due</div>
          <div className="usersHeaderColumn translationsStatusColumn">Status</div>
        </div>
        { loadedOrders && orders.length > 0
        ? orders.map(order => 
          <div key={order.id} className="translationsTableLine">
            <div className="usersLineColumn translationsTimerColumn">{order.expedited ? <Clock className="translationsColumnIcon translationsLineColumnIcon" /> : null}</div>
            <div className="usersLineColumn translationsOrderColumn" style={{ cursor: 'pointer', color: '#609ddb' }} onClick={(e) => transitionTo('/office/translation/' + order.id + "/details",e)}>{order.orderNumber}</div>
            <div className="usersLineColumn translationsInvoiceColumn" style={{ cursor: 'pointer', color: '#609ddb' }} onClick={(e) => transitionTo('/office/translation/' + order.id + "/invoice",e)}>{order.status !== 'new' && order.status !== 'assigned' && order.invoice &&  order.invoice.invoiceNumber}</div>
            <div className="usersLineColumn translationsLanguageColumn">{languages.find(language => language.value === order.languageFrom) ? languages.find(language => language.value === order.languageFrom).label : null}</div>
            <div className="usersLineColumn translationsLanguageColumn">{languages.find(language => language.value === order.languageTo) ? languages.find(language => language.value === order.languageTo).label : null}</div>
            <div className="usersLineColumn translationsDueColumn">{order.due ? formatDue(order.due) : ''}</div>
            <div className="usersLineColumn translationsStatusColumn">
              <div className="translationsStatusContainer" style={{ backgroundColor: order.status === 'new' ? '#FF7C1D' : order.status === 'assigned' ? '#61dbfb' : order.status === 'in-progress' ? '#1491F8' : order.status === 'cancelled' ? '#E1504A' : '#17BC5B' }}>
                { order.status === 'new' ? "NEW ORDER" : order.status === 'assigned' ? 'ASSIGNED' : order.status === 'in-progress' ? 'IN PROGRESS' : order.status === 'cancelled' ? 'CANCELLED' : 'COMPLETED'}
              </div>
            </div>
          </div>)
        : loadedOrders ? <div className="loadingTranslations">No orders.</div> : <div className="loadingTranslations">Loading orders...</div>
        }
        { loadedOrders && orders.length >= 50 && nextPointer.current &&
          <div className="loadMoreUsersButton" onClick={() => loadMoreOrders()}>
            Load more orders...
          </div>
        }
      </div>
    </div>
  );
};

export default OwnTranslations;