import React, { useEffect, useState } from "react";
import Logo from "../assets/print-logo.png";
import "./InvoicePrint.css";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export const InvoiceToPrint = React.forwardRef((props, ref) => {

    const {orderInfo, expedited, discount, subTotal, total} = props;
    const [teamName, setTeamName] = useState("");

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
    
        const splitted = date.toDateString().split(' ');
    
        return splitted[1] + ' ' + splitted[2] + ', ' + splitted[3] + ' @ ' + date.toTimeString().substr(0, 5);
      }


      useEffect(()=> {
          
          if(orderInfo && orderInfo.teamId){
            const firestore = firebase.firestore();
            firestore.collection("teams").doc(orderInfo.teamId).get().then(doc => {
                if(doc.exists){
                    const name = doc.data().name
                    setTeamName(name);
                }
            })
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [orderInfo])

      
      if(!orderInfo){
        return <div ref={ref}></div>
      }

  return (
    <div ref={ref} className="box">
      <div className="titleWrap">
        <div className="title">Invoice</div>
        <img src={Logo} alt="" />
      </div>

        <div className="section">
          <div className="heading">Order #{orderInfo.orderNumber}</div>
          <p className="text">Date placed: {formatDate(orderInfo.timestamp)}</p>
        </div>
        <div className="section">
          <div className="heading">
            Your Reference:
            <span className="text">{" "}{orderInfo.reference}</span>
          </div>
        </div>

      <div className="row">
        <div className="section">
          <h2 className="heading">Everbary</h2>
          <p className="text">
            530-B Harkle Road St.
            <br />
            Santa Fe, NM 87505
            <br />
            support@everbary.com
          </p>
        </div>

        <div className="section">
          <h2 className="heading">Bill to</h2>
          <p className="text">{teamName ? teamName : orderInfo.name}</p>
          <p className="text">{orderInfo.email}</p>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>Description</th>
            <th>Quantity</th>
            <th>Unit Price</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
            {
            orderInfo.certified &&
          <tr>
            <td className="bg">Certified Translation</td>
            <td className="bg">{orderInfo.certified ? (orderInfo.pagesCount !== '' ? orderInfo.pagesCount + " pages (" + (parseInt(orderInfo.pagesCount, 10) * 250) + " words max)" : "0 pages (0 words max)") : (orderInfo.wordCount !== '' ? orderInfo.wordCount + " words" : "0 words")}</td>
            <td className="bg">$24.95</td>
            <td className="bg">{orderInfo.certified ? (orderInfo.pagesCount !== "" ? '$' + (parseInt(orderInfo.pagesCount, 10) * 24.95).toFixed(2) : "$0.00") : ((orderInfo.wordCount !== '' ? '$' + (parseInt(orderInfo.wordCount, 10) * 0.1).toFixed(2) : '$0.00'))}</td>
          </tr>
            }
            {orderInfo.notarization &&
          <tr>
            <td className="bg">Notarization</td>
            <td className="bg">1</td>
            <td className="bg">$34.90</td>
            <td className="bg">$34.90</td>
          </tr>
            }
          {orderInfo.expedited && <tr>
            <td className="bg">Expedited Turnaround</td>
            <td className="bg">---</td>
            <td className="bg">---</td>
            <td className="bg">{expedited}</td>
          </tr>}
          {orderInfo.hardCopy && orderInfo.hardCopyExtra.country !== '' && orderInfo.hardCopyExtra.method !== '' && <tr>
            <td className="bg">{`USPS ${orderInfo.hardCopyExtra.method.charAt(0).toUpperCase() + orderInfo.hardCopyExtra.method.slice(1)} Mail ${!['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(orderInfo.hardCopyExtra.country) ? 'International' : ''}`}</td>
            <td className="bg">---</td>
            <td className="bg">---</td>
            <td className="bg">{`$${(new Date(orderInfo.timestamp) >= new Date('Sun, 30 Oct 2022 20:02:56 GMT')) ? ['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(orderInfo.hardCopyExtra.country) ? (orderInfo.hardCopyExtra.method === 'priority' ? '19.95' : '37.95') : (orderInfo.hardCopyExtra.method === 'priority' ? '49.95' : '78.95') : ['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(orderInfo.hardCopyExtra.country) ? (orderInfo.hardCopyExtra.method === 'priority' ? '12.95' : '29.95') : (orderInfo.hardCopyExtra.method === 'priority' ? '39.95' : '67.95')}`}</td>
          </tr>}
          {orderInfo.hardCopy && orderInfo.hardCopyExtra.requireSignature && <tr>
            <td className="bg">Signature Required for Delivery</td>
            <td className="bg">---</td>
            <td className="bg">{`$${(new Date(orderInfo.timestamp) >= new Date('Sun, 30 Oct 2022 20:02:56 GMT')) ? 4.99 : 2.54}`}</td>
            <td className="bg">{`$${(new Date(orderInfo.timestamp) >= new Date('Sun, 30 Oct 2022 20:02:56 GMT')) ? 4.99 : 2.54}`}</td>
          </tr>}
          <tr>
            <td></td>
            <td className="bg">Subtotal</td>
            <td className="bg"></td>
            <td className="bg">{subTotal}</td>
          </tr>
          <tr>
            <td></td>
            <td className="bg">Discount {orderInfo.discount && orderInfo.discount > 0 && `(${orderInfo.discount}%)`}</td>
            <td className="bg"></td>
            <td className="bg">{discount}</td>
          </tr>
          <tr>
            <td></td>
            <td className="bg color">Total</td>
            <td className="bg color"></td>
            <td className="bg color">{total}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});
