import { useContext, useCallback } from 'react';
import { useHistory } from "react-router-dom";

import { TransitionContext } from '../Context/TransitionContext';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
}


const colorPages = ["/", "/certified", "/standard", "/interpretations", "/documents", "/languages", "/use-cases", "/business", "/about", "/jobs"];

const useScreenTransition = () => {

  const { setShowTransitionOverlay, setColor } = useContext(TransitionContext);

  const history = useHistory();

  const transitionTo = useCallback((path, e) => {
    if(e && e.ctrlKey){
      openInNewTab(path)
      return;
    }
    
    setShowTransitionOverlay(true);
    sleep(100).then(() => {
      if (window.innerWidth > 1024 && (colorPages.some(p => p === path) || path.startsWith("/document/") || path.startsWith("/language/") || path.startsWith("/use-case/"))) {
        setColor(false);
      } else {
        setColor(true);
      }
      
      history.push(path);
      window.document.documentElement.scrollTop = 0;
      setShowTransitionOverlay(false);
    })
  }, [setShowTransitionOverlay, history, setColor]);

  return { transitionTo };
}

export { useScreenTransition };