import React from 'react';
import { useSpring, animated } from 'react-spring';

import './TransitionOverlay.css';

const TransitionOverlay = ({ show }) => {

  const overlayProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: show ? 1 : 0 },
    immediate: false,
    config: { mass: 1, tension: 170, friction: 26, clamp: true, restSpeedThreshold: 5, restDisplacementThreshold: 0.05 },
  })
  
  return (
    <animated.div style={overlayProps} className="transitionOverlay" />
  );
}

export { TransitionOverlay };