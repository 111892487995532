import React, { useState, useContext, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import { PayPalButtons } from "@paypal/react-paypal-js";
import axios from 'axios';

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { Info, Clock, Check} from 'react-feather';

import { Navbar } from '../../Components/Navbar';
import { AccountFooter } from '../../Components/AccountFooter';
import { AltAnimatedButton } from '../../Components/AltAnimatedButton';
import { FormTextInput } from '../../Components/FormTextInput';

import { useScreenTransition } from '../../Hooks/useScreenTransition';

import { TransitionContext } from '../../Context/TransitionContext';
import { NotificationContext } from '../../Context/NotificationContext';
import { DataContext } from '../../Context/DataContext';
import { AuthContext } from '../../Context/AuthContext';

import { languages } from '../../Data/languages';
import { countries } from '../../Data/countries';

import './SingleQuote.css';
import TopBar from '../../Components/TopBar';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const SingleQuote = ({ windowSize }) => {

  const { transitionTo } = useScreenTransition();

  const { setColor } = useContext(TransitionContext);

  const { showNotification } = useContext(NotificationContext);

  const { userData } = useContext(DataContext);

  const { signedIn } = useContext(AuthContext);

  const phoneScreen = windowSize.width <= 767 && windowSize.orientation === 'portrait';

  useEffect(() => {
    setColor(true);
  }, [setColor]);

  const { quoteID } = useParams();

  const [quoteInfo, setQuoteInfo] = useState(null);
  const [loadedQuote, setLoadedQuote] = useState(false);

  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());
  const [supportNotes, setSupportNotes] = React.useState(() => EditorState.createEmpty());

  const [recipient, setRecipient] = useState('');
  const [street, setStreet] = useState('');
  const [apartment, setApartment] = useState('');
  const [city, setCity] = useState('');
  const [region, setRegion] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('');

  const [discountCode, setDiscountCode] = useState('');
  const [appliedCode, setAppliedCode] = useState('');
  const [discount, setDiscount] = useState(0);

  const [saveAddress, setSaveAddress] = useState(false);
  const [saveDefault, setSaveDefault] = useState(false);

  const [orderSubmitButton, setOrderSubmitButton] = useState('Place Order');
  const statusOverlayProps = useSpring({ height: windowSize.height, opacity: orderSubmitButton !== 'Place Order' ? 1 : 0, pointerEvents: orderSubmitButton !== 'Place Order' ? 'all' : 'none' });

  const [openShipping, setOpenShipping] = useState(false);
  const shippingModalProps = useSpring({ opacity: openShipping ? 1 : 0, pointerEvents: openShipping ? 'all' : 'none' });

  useEffect(() => {
    if (quoteInfo && quoteInfo.hardCopy) setCountry(quoteInfo.hardCopyExtra.country || '');
  }, [quoteInfo]);

  useEffect(() => {
    const firestore = firebase.firestore();

    firestore.collection('quotes').doc(quoteID)
      .get()
      .then(doc => {
        if (doc.exists) {
          setQuoteInfo({ ...doc.data(), id: doc.id });

          if (doc.data().notes) {
            setEditorState(EditorState.createWithContent(convertFromRaw(doc.data().notes)));
          }

          if (doc.data().supportNotes && doc.data().status !== 'submitted') {
            setSupportNotes(EditorState.createWithContent(convertFromRaw(doc.data().supportNotes)));
          }
          
          setLoadedQuote(true);

          sleep(500).then(() => {
            if ((windowSize.width > 767 || windowSize.orientation === 'landscape') && document.getElementById('mainPrimary') && document.getElementById('mainSecondary')) {
              if (document.getElementById('mainPrimary').clientHeight > document.getElementById('mainSecondary').clientHeight) {
                document.getElementById('mainSecondary').style.height = `${document.getElementById('mainPrimary').clientHeight}px`;
              } else {
                document.getElementById('mainPrimary').style.height = `${document.getElementById('mainSecondary').clientHeight}px`;
              }
            }
          })
          
        } else {
          showNotification('Error', 'An error ocurred loading the quote info. Try again. If it persists, please contact Support.', 'error');
        }
      })
      .catch(error => {
        //console.log(error);
        showNotification('Error', 'An error ocurred loading the quote info. Try again. If it persists, please contact Support.', 'error');
      });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteID]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const splitted = date.toDateString().split(' ');

    return splitted[1] + ' ' + splitted[2] + ', ' + splitted[3] + ' @ ' + date.toTimeString().substr(0, 5);
  }

  const getLanguage = (language) => {
    if (languages.find(lang => lang.value === language)) {
      return languages.find(lang => lang.value === language).label;
    } else {
      return '';
    }
  }

  const downloadFile = url => {
      window.open(url, '_blank')
  }

  const getExpedited = () => {
    const quoteDate = new Date(quoteInfo.timestamp);
    const priceUpdateDate = new Date('Sun, 30 Oct 2022 20:02:56 GMT');
    let total = 0;

    if (quoteInfo && quoteInfo.certified) {
      if (quoteInfo.pagesCount !== '') {
        total = total + parseInt(quoteInfo.pagesCount, 10) * 24.95;
      } else {
        total = 0;
      }
    } else if (quoteInfo) {
      if (quoteInfo.wordCount !== '') {
        total = total + parseInt(quoteInfo.wordCount, 10) * 0.1;
      } else {
        total = 0;
      }
    }

    if(quoteDate >= priceUpdateDate){
      return total * 0.4;
    }

    return total * 0.6;
  }

  const getTotal = () => {
    let total = 0;
    const quoteDate = quoteInfo ? new Date(quoteInfo.timestamp): new Date();
    const priceUpdateDate = new Date('Sun, 30 Oct 2022 20:02:56 GMT');

    if (quoteInfo && quoteInfo.certified) {
      if (quoteInfo.pagesCount !== '') {
        total = total + parseInt(quoteInfo.pagesCount, 10) * 24.95;
      } else {
        total = 0;
      }
    } else if (quoteInfo) {
      if (quoteInfo.wordCount !== '') {
        total = total + parseInt(quoteInfo.wordCount, 10) * 0.1;
      } else {
        total = 0;
      }
    }

    if (quoteInfo && quoteInfo.expedited) {
      total = total + getExpedited();
    }

    if (quoteInfo && quoteInfo.notarization) {
      total = total + 34.90;
    }

    if (quoteInfo && quoteInfo.hardCopy) {
      if(quoteDate >= priceUpdateDate){
        if (['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(quoteInfo.hardCopyExtra.country)) {
          if (quoteInfo.hardCopyExtra.method === 'priority') {
              total = total + 19.95;
          } else {
            total = total + 37.95;
          }
        } else {
          if (quoteInfo.hardCopyExtra.method === 'priority') {
            total = total + 49.95;
          } else {
            total = total + 78.95;
          }
        }
      }else{
        if (['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(quoteInfo.hardCopyExtra.country)) {
          if (quoteInfo.hardCopyExtra.method === 'priority') {
              total = total + 12.95;
          } else {
            total = total + 29.95;
          }
        } else {
          if (quoteInfo.hardCopyExtra.method === 'priority') {
            total = total + 39.95;
          } else {
            total = total + 67.95;
          }
        }
      }
     
    }

    if (quoteInfo && quoteInfo.hardCopy && quoteInfo.hardCopyExtra.requireSignature) {
      if(quoteDate >= priceUpdateDate){
        total = total + 4.99;
      }else{
        total = total + 2.54;
      }
    }

    return total;
  }

  const getDiscount = () => {
    if (quoteInfo && quoteInfo.discount && quoteInfo.discount > 0) {
      return getTotal() * quoteInfo.discount / 100;
    }

    if (discount > 0) {
      return getTotal() * discount / 100;
    }

    return 0;
  }

  const formatTotal = value => {
    return '$' + value.toFixed(2);
  }

  const formatDiscount = value => {
    return '-$' + value.toFixed(2);
  }

  const getTimeFrame = () => {

    let limits = [0, 750];

    for (let i = 2; i < 200; i++) {
      limits.push(limits[limits.length - 1] + 1500 + (i-2) * 250);
    }

    let count = 0;

    if (quoteInfo && quoteInfo.certified && quoteInfo.pagesCount !== '') {
      count = quoteInfo.pagesCount * 250;
    } else if (quoteInfo && !quoteInfo.certified && quoteInfo.wordCount !== '') {
      count = quoteInfo.wordCount;
    };

    const ceiling = limits.filter(limit => count <= limit).sort((a,b) => a - b)[0];
    let ceilingIndex = limits.findIndex(limit => limit === ceiling);

    if (quoteInfo.expedited) {
      ceilingIndex = ceilingIndex / 2;
    };

    if (ceilingIndex > 0.5) {
      ceilingIndex = Math.ceil(ceilingIndex);
    }

    if (ceilingIndex <= 2) {
      return `${ceilingIndex * 24} hours`;
    } else {
      return `${ceilingIndex - 1}-${ceilingIndex} days`;
    }
  }

  const handlePlaceOrder = async paypalID => {
    setOrderSubmitButton('Placing order...');

    const now = new Date();

    const firestore = firebase.firestore();

    let orderNumber = 0;

    await axios({
      method:'post',
      url: "https://us-central1-project-everbary.cloudfunctions.net/getCounter",
      data: {
        type: 'order'
      }
    })
      .then((response) => {
        orderNumber = parseInt(response.data.number, 10);
      })
      .catch((error) => {
        //console.log(error);
      });

    if (orderNumber === 0) {
      showNotification('Error', "An error ocurred while placing the order. Please, try again. If it persists, contact support.", 'error');
      setOrderSubmitButton('Place Order');
      return false;
    }

    const addressID = uuidv4();
    const orderID = uuidv4();

    firestore.collection('orders').doc(orderID)
      .set({
        orderNumber: orderNumber,
        userID: quoteInfo.userID,
        email: quoteInfo.email,
        name: quoteInfo.name,
        certified: quoteInfo.certified,
        languageFrom: quoteInfo.languageFrom,
        languageTo: quoteInfo.languageTo,
        notarization: quoteInfo.notarization,
        notarizationStatus: quoteInfo.notarization ? 'pending' : null,
        expedited: quoteInfo.expedited,
        hardCopy: quoteInfo.hardCopy,
        hardCopyStatus: quoteInfo.hardCopy ? 'pending' : null,
        hardCopyExtra: quoteInfo.hardCopy && quoteInfo.hardCopyExtra ? quoteInfo.hardCopyExtra : null,
        pagesCount: quoteInfo.certified ? quoteInfo.pagesCount : 0,
        wordCount: !quoteInfo.certified ? quoteInfo.wordCount : 0,
        notes: quoteInfo.notes,
        files: quoteInfo.files,
        timestamp: now.getTime(),
        status: 'new',
        shippingInfo: quoteInfo.hardCopy ? { id: addressID, recipient: recipient, street: street, apartment: apartment, city: city, region: region, zipCode: zipCode, country: country } : null,
        paypalOrderID: paypalID,
        discountApplied: quoteInfo.discount || appliedCode,
        discount: quoteInfo.discount && quoteInfo.discount > 0 ? quoteInfo.discount : discount,
        totalPrice: getTotal(),
      })
      .then(async () => {
        await firestore.collection('quotes').doc(quoteInfo.id).update({ 
          status: 'ordered',
          orderNumber: orderNumber,
          orderID: orderID
        });

        if (appliedCode !== '') {
          axios({
            method:'post',
            url: "https://us-central1-project-everbary.cloudfunctions.net/updateCouponUsage",
            data: {
              code: appliedCode,
            }
          })
          .catch(() => {});
        }

        if (signedIn && saveAddress) {
          const address = { id: addressID, recipient: recipient, street: street, apartment: apartment, city: city, region: region, zipCode: zipCode, country: country, default: saveDefault };

          const mShipping = userData && userData.shipping ? (saveDefault ? userData.shipping.map(address => ({ ...address, default: false })).concat([address]) : userData.shipping.concat([address])) : [address];

          firestore.collection('users').doc(firebase.auth().currentUser.uid)
            .update({
              shipping: mShipping
            })
            .then(() => {
            })
            .catch(error => {
              showNotification('Error', "An error ocurred while saving the address. Unfortunately, the address couldn't be saved.", 'error');
            })
        }

        setOrderSubmitButton('Place Order');
        transitionTo('/account/order/' + orderID);
      })
      .catch(error => {
        //console.log(error);
        setOrderSubmitButton('Place Order');
        showNotification('Error', "An error ocurred while placing the order. Please, try again.", 'error');
      })
  }

  const createOrder = (data, actions) => {
    return actions.order.create({
      intent: 'CAPTURE',
      purchase_units: [
        {
          description: `Your Order`,
          amount: {
            currency_code: "USD",
            value: (getTotal() - getDiscount()).toFixed(2)
          },
          reference_id: quoteInfo.id
        }
      ],
      application_context: {
        brand_name: 'eVerbary',
        landing_page: 'NO_PREFERENCE',
        user_action: 'PAY_NOW',
        payment_method: {
          payee_preferred: 'IMMEDIATE_PAYMENT_REQUIRED'
        }
      }
    });
  };

  const onOrderApprove = (data, actions) => {
    actions.order.capture().then(details => {
      setOpenShipping(false);
      handlePlaceOrder(data.orderID);
    });
  };

  const onOrderPaymentFailure = (data, actions) => {
    showNotification('Error', "An error ocurred while placing the order. Please, try again.", 'error');
  }

  const handleApplyCode = () => {
    const firestore = firebase.firestore();

    firestore.collection('coupons').where('code', '==', discountCode).get()
      .then((querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          const doc = querySnapshot.docs[0];

          if (doc.data().uses > 0) {
            setDiscount(parseInt(doc.data().discount, 10));
            setAppliedCode(discountCode);
            showNotification('Discount applied', "The discount was successfully applied", 'success');
          } else {
            showNotification('Error', "That code is no longer valid. Please, try another one.", 'error');
          }
        } else {
          showNotification('Error', "That code doesn't exist. Please, try another one.", 'error');
        }
      })
      .catch(() => {
        showNotification('Error', "There was an error applying the code. Please, try again.", 'error');
      })
  }

  const disabledPayment = recipient === '' || street === '' || region === '' || city === '' || country === '' || zipCode === '';

  return (
    <>
    <TopBar/>
    <div className="homeContainer">
      <animated.div style={statusOverlayProps} className="checkoutStatusOverlay">
        We are finishing up the order and redirecting you. This will take a second.
      </animated.div>

      {openShipping && <animated.div style={shippingModalProps} className="revisionModalBg" onClick={() => setOpenShipping(false)} />}
      {openShipping && <animated.div style={shippingModalProps} className="revisionModalOuter">
        <div className="singleQuoteMainPrimaryFilesTitle" style={{ marginTop: 0 }}>
          Shipping Information
          <div className="singleOrderRevisionButtons">
            <AltAnimatedButton color={'#E1504A'} text={"Cancel"} onClick={() => setOpenShipping(false)} backend fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.9 : 1.2) : (windowSize.orientation === 'portrait' ? 3.7 : 1.1)} />
          </div>
        </div>
        <div className="singleQuoteShippingFormSeparator" />
        <div className="orderAddressOptionalInnerForm">
          <div className="singleQuoteInputsContainer">
            <FormTextInput placeholder="Recipient (*)" value={recipient} onChange={setRecipient} color='#666' />
          </div>
          <div className="singleQuoteInputsContainer">
            <FormTextInput placeholder="Street (*)" value={street} onChange={setStreet} color='#666' />
          </div>
          <div className="singleQuoteInputsContainer">
            <FormTextInput placeholder="Apartment, Suite or Floor Number" value={apartment} onChange={setApartment} color='#666' />
          </div>
          <div className="singleQuoteInputsContainer">
            <div className="orderPaymentTripleLine">
              <div className="orderPaymentTripleField">
                <FormTextInput placeholder="City (*)" value={city} onChange={setCity} color='#666' />
              </div>
              <div className="orderPaymentTripleField">
                <FormTextInput placeholder={country === 'United States' ? "State (*)" : "Region (*)"} value={region} onChange={setRegion} color='#666' />
              </div>
              <div className="orderPaymentTripleField">
                <FormTextInput placeholder="Postal Code (*)" value={zipCode} onChange={setZipCode} color='#666' />
              </div>
            </div>
          </div>
          <div className="singleQuoteInputsContainer">
            <select value={country} onChange={(e) => setCountry(e.target.value)} className="quoteDetailsSelect" disabled>
              <option key="" value="" disabled>Country (*)</option>
              {countries.map(country => 
                <option key={country} value={country}>{country}</option>
              )}
            </select>
          </div>
          {signedIn && <div className="hardCopyCheckboxLine" onClick={() => setSaveAddress(sa => !sa)}>
            <div className="hardCopyCheckbox">
              <Check className="hardCopyCheck" style={{ opacity: saveAddress ? 1 : 0 }} />
            </div>
            <p className="hardCopyCheckboxText">Save address</p>
          </div>}
          { signedIn && saveAddress &&
            <div className="hardCopyCheckboxLine" onClick={() => setSaveDefault(ss => !ss)}>
              <div className="hardCopyCheckbox">
                <Check className="hardCopyCheck" style={{ opacity: saveDefault ? 1 : 0 }} />
              </div>
              <p className="hardCopyCheckboxText">Set as default</p>
            </div>
          }
          <div className="singleQuoteShippingFormSeparator" />
          <div className="accountProfileFormButton" style={{ opacity: disabledPayment ? 0.4 : 1, pointerEvents: disabledPayment ? 'none' : 'all', cursor: disabledPayment ? 'default' : 'pointer' }}>
            <PayPalButtons
              style={windowSize.width > 1024 ? { layout: 'vertical', height: 40 } : { layout: 'vertical', height: 35 }}
              createOrder={createOrder}
              onApprove={onOrderApprove}
              onError={onOrderPaymentFailure}
              onShippingChange={function noRef() {}}
            />
          </div>
        </div>
      </animated.div>}

      <Navbar windowSize={windowSize} />
        <animated.div style={{ opacity: loadedQuote ? 1 : 0 }} className="quoteMainContainer singleQuoteOuter">
          <div id="mainPrimary" className="singleQuoteMainPrimaryContainer">
            {quoteInfo && quoteInfo.status === 'replied' && <div className="singleQuoteCheckoutContainer">
              <img src={require('../../assets/standard.png')} alt="Checkout" className="singleQuoteCheckoutImg" />
              <div className="singleQuoteCheckoutInfo">
                <p className="singleQuoteCheckoutInfoText">Ready to place your order? Continue to the checkout, select your payment method, and confirm your order.</p>
                <div className="accountProfileFormButton">
                  { quoteInfo.hardCopy
                  ? <AltAnimatedButton text="Checkout Now" onClick={() => setOpenShipping(true)} fontSize={windowSize.width > 1024 ? 1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.3 : 1) : (windowSize.orientation === 'portrait' ? 3 : 1.3)} />
                  : <PayPalButtons
                      style={{ layout: 'vertical', height: 40 }}
                      createOrder={createOrder}
                      onApprove={onOrderApprove}
                      onError={onOrderPaymentFailure}
                      onShippingChange={function noRef() {}}
                    />
                  }
                </div>
               {userData && userData.hasTeam && <div className="payLaterWrap">
               <p className="orText">OR</p>
                  <AltAnimatedButton text="Pay Later" onClick={() => handlePlaceOrder("pay-later")} fontSize={windowSize.width > 1024 ? 1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.3 : 1) : (windowSize.orientation === 'portrait' ? 3 : 1.3)} />
                </div>}
              </div>
            </div>}
            <div className="singleQuoteMainPrimaryTitleRow">
              <p className="singleQuoteMainPrimaryTitle">{`Quote #${quoteInfo && quoteInfo.quoteNumber}`}</p>
              {quoteInfo && <div className="quoteStatusContainer" style={{ minWidth: 'auto', backgroundColor: quoteInfo.status === 'submitted' ? "#FF7C1D" : quoteInfo.status === 'replied' ? '#30d5c8' : '#17BC5B' }}>
              { quoteInfo.status === 'submitted' ? 'IN REVIEW' : quoteInfo.status === 'replied' ? 'READY' : 'ORDERED'}
              </div>}            </div>
            <div className="singleQuoteMainPrimaryInfo">
              {quoteInfo && <p className="singleQuoteMainPrimaryInfoTitle"><strong>{getLanguage(quoteInfo.languageFrom)}</strong> to <strong>{getLanguage(quoteInfo.languageTo)}</strong></p>}
              {quoteInfo && <p className="singleQuoteMainPrimaryInfoSub">{formatDate(quoteInfo.timestamp)}</p>}
            </div>
            <div className="singleQuoteMainPrimaryTitleRow" style={{ flexDirection: phoneScreen ? 'column' : 'row' }}>
              <div className="singleQuoteCardContainer">
                <div className="singleQuoteCardTitle">CONTACT</div>
                {quoteInfo && <p className="singleQuoteCardName">{quoteInfo.name}</p>}
                {quoteInfo && <p className="singleQuoteCardText">{quoteInfo.email}</p>}
              </div>
              <div className="singleQuoteCardContainer">
                <div className="singleQuoteCardTitle">DELIVERY</div>
                {quoteInfo && !quoteInfo.hardCopy
                ? <p className="singleQuoteCardText">Digital delivery only</p>
                : quoteInfo && 
                  <>
                    <p className="singleQuoteCardName">{`USPS ${quoteInfo.hardCopyExtra.method.charAt(0).toUpperCase() + quoteInfo.hardCopyExtra.method.slice(1)} Mail ${!['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(quoteInfo.hardCopyExtra.country) ? 'International' : ''}`}</p>
                    {quoteInfo.hardCopyExtra.requireSignature ? <p className="singleQuoteCardText">'Delivery requires adult signature'</p> : ''}
                  </>
                }
              </div>
            </div>
            <div className="singleQuoteMainPrimaryFilesContainer">
              <div className="singleQuoteMainPrimaryFilesTitle">Source Files</div>
              { quoteInfo && quoteInfo.files.map((file, index) => 
                <div key={file.name} className="singleQuoteFileRow">
                  <p className="singleQuoteFileTitle" onClick={() => downloadFile(file.url)}>{file.name}</p>
                  <p className="singleQuoteFileSub">{"Source Uploaded " + formatDate(quoteInfo.timestamp)}</p>
                </div>
              )}
            </div>
            <div className="singleQuoteMainPrimaryFilesContainer">
              <div className="singleQuoteMainPrimaryFilesTitle">Notes</div>
              {editorState.getCurrentContent().getPlainText() !== '' && <div className="singleTranslationNotesContainer">
                <div className="singleTranslationNotesTitle" style={{ backgroundColor: '#18b7bb' }}><strong>Client's Notes</strong></div>
                <div className="singleTranslationRichTextEditor">
                  <Editor
                    editorState={editorState}
                    onChange={editorState => setEditorState(editorState)}
                    readOnly
                  />
                </div>
              </div>}
              {quoteInfo && quoteInfo.status !== 'submitted' && supportNotes.getCurrentContent().getPlainText() !== '' && 
              <div className="singleTranslationNotesContainer">
                <div className="singleTranslationNotesTitle" style={{ backgroundColor: '#e91e63' }}><strong>Support Notes</strong></div>
                <div className="singleTranslationRichTextEditor">
                  <Editor
                    editorState={supportNotes}
                    onChange={supportNotes => setSupportNotes(supportNotes)}
                    readOnly
                  />
                </div>
              </div>}
            </div>
            {quoteInfo && (!quoteInfo.discountApplied || quoteInfo.discount === 0) && (
              <div className="singleQuoteMainPrimaryFilesContainer">
                <div className="singleQuoteMainPrimaryFilesTitle">Apply Discount Code</div>
                {appliedCode && (
                  <p className="quoteSmallMessage" style={{ marginBottom: '1vw', marginTop: '1vw' }}>
                    Currently Applied: <strong>{`${appliedCode} (${discount}%)`}</strong>
                  </p>
                )}
                <div className="orderCountContainer" style={{ marginBottom: '3vw' }}>
                  <div className="orderCountInputContainer" style={{ flex: 1, marginRight: '5%' }}>
                    <FormTextInput placeholder="Discount Code" value={discountCode} onChange={setDiscountCode} color='#666' />
                  </div>
                  <AltAnimatedButton text="Apply" onClick={handleApplyCode} fontSize={windowSize.width > 1024 ? 1.1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 2.4 : 1.5) : (windowSize.orientation === 'portrait' ? 5 : 1.2)} />
                </div>
              </div>
            )}
          </div>
          <div id="mainSecondary" className="singleQuoteMainSecondaryContainer">
            {quoteInfo && (quoteInfo.status === 'replied' || quoteInfo.status === 'ordered') 
            ? <div className="singleQuoteOrderSummary">
                <div className="singleQuoteOrderSummaryTitle">SUMMARY</div>
                <div className="singleQuoteOrderSummaryInfo">
                  <div className="singleQuoteOrderSummaryInfoMain">
                    {quoteInfo && <p className="singleQuoteOrderSummaryInfoType">{quoteInfo.certified ? "Certified Translation" : "Standard Translation"}</p>}
                    {quoteInfo && <p className="singleQuoteOrderSummaryInfoCount">{quoteInfo.certified ? (quoteInfo.pagesCount !== '' ? quoteInfo.pagesCount + " pages (" + (parseInt(quoteInfo.pagesCount, 10) * 250) + " words max)" : "0 pages (0 words max)") : (quoteInfo.wordCount !== '' ? quoteInfo.wordCount + " words" : "0 words")}</p>}
                  </div>
                  {quoteInfo && <p className="singleQuoteOrderSummaryInfoType">{quoteInfo.certified ? (quoteInfo.pagesCount !== "" ? '$' + (parseInt(quoteInfo.pagesCount, 10) * 24.95).toFixed(2) : "$0.00") : ((quoteInfo.wordCount !== '' ? '$' + (parseInt(quoteInfo.wordCount, 10) * 0.1).toFixed(2) : '$0.00'))}</p>}
                </div>
                {quoteInfo && quoteInfo.notarization ? 
                <div className="singleQuoteOrderSummaryInfo">
                  <div className="singleQuoteOrderSummaryInfoMain">
                    <p className="singleQuoteOrderSummaryInfoType">Notarization</p>
                    <p className="singleQuoteOrderSummaryInfoCount">Valid in all 50 states</p>
                  </div>
                  <p className="singleQuoteOrderSummaryInfoType">$34.90</p>
                </div> : null}
                {quoteInfo && quoteInfo.expedited ? 
                <div className="singleQuoteOrderSummaryInfo">
                  <div className="singleQuoteOrderSummaryInfoMain">
                    <p className="singleQuoteOrderSummaryInfoType">Expedited Turnaround</p>
                    <p className="singleQuoteOrderSummaryInfoCount">Turnaround reduced by 50%</p>
                  </div>
                  <p className="singleQuoteOrderSummaryInfoType">{formatTotal(getExpedited())}</p>
                </div> : null}
                {quoteInfo && quoteInfo.hardCopy && quoteInfo.hardCopyExtra.country !== '' && quoteInfo.hardCopyExtra.method !== '' ? 
                <div className="singleQuoteOrderSummaryInfo">
                  <div className="singleQuoteOrderSummaryInfoMain">
                    <p className="singleQuoteOrderSummaryInfoType">{`USPS ${quoteInfo.hardCopyExtra.method.charAt(0).toUpperCase() + quoteInfo.hardCopyExtra.method.slice(1)} Mail ${!['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(quoteInfo.hardCopyExtra.country) ? 'International' : ''}`}</p>
                    <p className="singleQuoteOrderSummaryInfoCount">{`${['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(quoteInfo.hardCopyExtra.country) ? (quoteInfo.hardCopyExtra.method === 'priority' ? '2-3' : '1-2') : (quoteInfo.hardCopyExtra.method === 'priority' ? '6-10' : '3-5')} days in transit`}</p>
                  </div>
                  <p className="singleQuoteOrderSummaryInfoType">{`$${(new Date(quoteInfo.timestamp) >= new Date('Sun, 30 Oct 2022 20:02:56 GMT')) ? ['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(quoteInfo.hardCopyExtra.country) ? (quoteInfo.hardCopyExtra.method === 'priority' ? '19.95' : '37.95') : (quoteInfo.hardCopyExtra.method === 'priority' ? '49.95' : '78.95') : ['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(quoteInfo.hardCopyExtra.country) ? (quoteInfo.hardCopyExtra.method === 'priority' ? '12.95' : '29.95') : (quoteInfo.hardCopyExtra.method === 'priority' ? '39.95' : '67.95')}`}</p>
                </div> : null}
                {quoteInfo && quoteInfo.hardCopy && quoteInfo.hardCopyExtra.requireSignature ? 
                <div className="singleQuoteOrderSummaryInfo">
                  <div className="singleQuoteOrderSummaryInfoMain">
                    <p className="singleQuoteOrderSummaryInfoType">Signature Required for Delivery</p>
                    <p className="singleQuoteOrderSummaryInfoCount">Adult signature required</p>
                  </div>
                  <p className="singleQuoteOrderSummaryInfoType">{`$${(new Date(quoteInfo.timestamp) >= new Date('Sun, 30 Oct 2022 20:02:56 GMT')) ? 4.99 : 2.54}`}</p>
                </div> : null}
                {quoteInfo && quoteInfo.discount && quoteInfo.discount > 0 ? 
                <div className="singleQuoteOrderSummaryInfo">
                  <div className="singleQuoteOrderSummaryInfoMain">
                    <p className="singleQuoteOrderSummaryInfoType">{`Discount -${quoteInfo.discount}%`}</p>
                  </div>
                  <p className="singleQuoteOrderSummaryInfoType">{formatDiscount(getDiscount())}</p>
                </div> : null}
                {quoteInfo && !quoteInfo.discount && discount > 0 ? 
                <div className="singleQuoteOrderSummaryInfo">
                  <div className="singleQuoteOrderSummaryInfoMain">
                    <p className="singleQuoteOrderSummaryInfoType">{`Discount -${discount}%`}</p>
                    <p className="orderSummaryInfoCount">{appliedCode}</p>
                  </div>
                  <p className="singleQuoteOrderSummaryInfoType">{formatDiscount(getDiscount())}</p>
                </div> : null}
                <div className="singleQuoteOrderSummaryTotal">
                  <p>Total</p>
                  <p>{formatTotal(getTotal() - getDiscount())}</p>
                </div>
              </div>
            : <div className="singleQuoteOrderSummary">
                <div className="singleQuoteOrderSummaryTitle">SUMMARY</div>
                <div className="quoteSecondarySub">Not available yet.</div>
              </div>
            }
            {quoteInfo && <div className="quoteSecondaryExtraInfo" style={{ width: phoneScreen ? '88%' : 'calc(100% - 3vw)' }}>
              {quoteInfo && ((quoteInfo.certified && quoteInfo.pagesCount !== '' && quoteInfo.pagesCount !== undefined) || (!quoteInfo.certified && quoteInfo.wordCount !== '' && quoteInfo.wordCount !== undefined)) &&
              <div className="orderSecondaryTitle">
                <Clock className="orderSecondaryIcon" />
                {`${getTimeFrame()} est. turnaround`}
              </div>}
              {quoteInfo && ((quoteInfo.certified && quoteInfo.pagesCount !== '' && quoteInfo.pagesCount !== undefined) || (!quoteInfo.certified && quoteInfo.wordCount !== '' && quoteInfo.wordCount !== undefined)) &&
                <div className="quoteSecondarySub">This is an estimate to deliver the digital copy based on the current order information you've provided.</div>
              }
              <div className="orderSecondaryTitle">
                <Info className="orderSecondaryIcon" />
                {`Status: ${quoteInfo.status === 'submitted' ? 'Submitted' : quoteInfo.status === 'replied' ? 'Ready' : quoteInfo.status === 'ordered' ? 'Ordered' : ''}`}
              </div>
              <div className="quoteSecondarySub">
                {quoteInfo.status === 'submitted' 
                  ? <span>Your quote has been received! Our staff will prepare this quote with the total cost and turnaround time for your project. We'll email you once your quote is ready for review.</span> 
                  : quoteInfo.status === 'replied'
                    ? <span>Your quote is ready! You can place your order from the <span style={{ color: '#444', fontWeight: 700 }}>PayPal</span> or <span style={{ color: '#444', fontWeight: 700 }}>Checkout Now</span> button at the top of the page to convert this quote into an order without re-entering your details.</span> 
                    : quoteInfo.status === 'ordered' 
                      ? <span>This quote has already been converted into an order. You can view its status <span style={{ cursor: 'pointer', color: '#333', textDecoration: 'underline' }} onClick={quoteInfo.orderID ? (e) => transitionTo(`/account/order/${quoteInfo.orderID}`,e) : null}>here.</span></span> 
                      : ''
                }
              </div>
            </div>}
          </div>
        </animated.div>
      <AccountFooter windowSize={windowSize} />
    </div>
    </>
  )
};

export default SingleQuote;