import React from 'react';

import './FormTextArea.css';

function FormTextArea(props) {

  return (
    <div className="formInputSimpleContainer">
      <textarea required className={`formTextArea ${props.value !== "" && "validInput"}`} placeholder="" value={props.value} onChange={(e) => props.onChange(e.target.value)} />
      <p className={`formInputSimpleLabel ${props.value !== "" && "validInputLabel"}`}>{props.placeholder}</p>
    </div>
  );
}

export { FormTextArea };