import React from 'react';
import { Route } from "react-router-dom";

import { Helmet } from "react-helmet";
import { headers } from "../Data/headers";

function HeadedRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={() => (
        <>
          <Helmet>
            <title>{headers[rest.path]?.title ?? headers['default'].title}</title>
            <meta
              name="description"
              content={headers[rest.path]?.description ?? headers['default'].description}
            />
            <meta name="robots" content="index,follow"/>
            <meta name="resource-type" content="document" />
            <meta name="distribution" content="global" />
            <meta name="revisit-after" content="1 days" />
            <meta name="rating" content="general" />
          </Helmet>
          {children}
        </>
      )}
    />
  );
}

export { HeadedRoute };