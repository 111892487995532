import React, { useContext, useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

import "./CreateAccount.css";
import { ReactComponent as Personal } from "../../assets/icons/personal.svg";
import { ReactComponent as Business } from "../../assets/icons/business.svg";
import { ReactComponent as Close } from "../../assets/icons/x.svg";
import { ReactComponent as Check } from "../../assets/icons/check.svg";
import {ReactComponent as EyeBlack} from '../../assets/icons/eye-closed-black.svg';
import { ReactComponent as EyeOff } from "../../assets/icons/eye-closed.svg";
import { Eye, Loader } from "react-feather";
import ReactDOM from "react-dom";
import AccountCreated from "./AccountCreated";
import {ReactComponent as Delete} from '../../assets/icons/Сircle Cross.svg';
import { NotificationContext } from "../../Context/NotificationContext";

export default function CreateAccount({ open, isCreateAccountOpen, isLoginFormOpen }) {
  const modalRoot = document.getElementById("modal-root");
  const [userInf, setUserInf] = useState({
    firstName: "",
    lastName: "",
    companyName:"",
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword]=useState('password');
  const [validation, setValidation]=useState({
    email:false,
    password:false
  });
  const [accoutTypeSelected, setAccountTypeSelected]=useState('personal');
  const [accountStatus, setAccountStatus]=useState('not-created');
  const [isLoading, setIsLoading]=useState(false);
  const { showNotification } = useContext(NotificationContext);
  const [submitButton, setSubmitButton] = useState("Create an Account");


  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  useEffect(() => {
    setValidation(prevValidation => ({
      ...prevValidation,
      email: validateEmail(userInf.email)
    }));
  }, [userInf.email]);

  useEffect(()=>{
    setValidation({...validation, password:userInf.password.length>=6})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userInf.password])

  const [focus, setFocus] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
  });

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) =>
      prevShowPassword === "password" ? "text" : "password"
    );
  };

  const openLoginForm=()=>{
    isCreateAccountOpen(false);
    isLoginFormOpen(true);
  }

  const closeAccountCreated=()=>{
    setAccountStatus('not-created');
    isCreateAccountOpen(false);
  }

  const createPersonalAccount=async()=>{
    setSubmitButton("Creating");
    setIsLoading(true);
    try{
    await firebase.auth().createUserWithEmailAndPassword(userInf.email.toLowerCase(), userInf.password)
      .then(credential => {
        const firestore = firebase.firestore();

        const docRef = firestore.collection('users').doc(credential.user.uid);

        docRef.set({
          role: 'client',
          name: userInf.firstName + " " + userInf.lastName,
          email: userInf.email.toLowerCase(),
          firstName: userInf.firstName,
          lastName: userInf.lastName,

        }, { merge: true });
        setAccountStatus('created');
        showNotification("Success", "Your account has been created!", "success");
      })}
      catch(error) {
        //console.log(error);
        var errorCode = error.code;

        switch(errorCode) {
          case "auth/email-already-in-use":
            showNotification("Email Already in Use", "The email is already in use. Please, try another one.", "error");
            break;
          case "auth/invalid-email":
            showNotification("Invalid Email", "The email address is invalid. Please, try again.", "error");
            break;
          case "auth/weak-password":
            showNotification("Weak Password", "The password is too weak. Please, try again.", "error");
            break;
          default:
            showNotification("Error", "An unexpected error ocurred. Please, try again.", "error");
        }
      }finally{
        setSubmitButton("Create an Account");
      }
  }

  const createBusinessAccount=()=>{
    setAccountStatus('created');
    setIsLoading(false);
  }

  const isBtnDisabled=accoutTypeSelected==='personal'?(validation.email && validation.password && userInf.firstName && userInf.password?false:true):(validation.email && validation.password && userInf.companyName && userInf.firstName && userInf.password?false:true);

  if (!open) return null;

  return ReactDOM.createPortal(
    <div className="modal">
      <div className="modal-content"></div>
      {accountStatus==='not-created'?(
      <div className="createAccountForm">
        <div className="createAccountFormHeader">
          <h2 className="createAccountFormTitle">Create Account</h2>
          <Close className="createAccountCloseIcon" onClick={()=>isCreateAccountOpen(false)}/>
        </div>

        <h3 className="createAccountFormSpan">Select Account Type</h3>

        <div className="createAccountFormContainerAdd">
          <div className="createAccountFormAccountType">
            <div className={accoutTypeSelected==="personal"?"createAccountFormTypeOption chosenAccountBorder":"createAccountFormTypeOption"} onClick={()=>setAccountTypeSelected("personal")}>
              <div className="createAccountFormTypeOptionHead">
                <Personal className="personalIcon" />
                <h3 className="createAccountFormTypeOptionHeadTitle">
                  Personal
                </h3>
              </div>
              <span className="createAccountFormTypeOptionSpan">
                Ideal for individuals who make one or more orders
              </span>
            </div>

            {/* <div className={accoutTypeSelected==="business"?"createAccountFormTypeOption chosenAccountBorder":"createAccountFormTypeOption"} onClick={()=>setAccountTypeSelected('business')}>
              <div className="createAccountFormTypeOptionHead">
                <Business className="personalIcon" />
                <h3 className="createAccountFormTypeOptionHeadTitle">
                  Business
                </h3>
              </div>
              <span className="createAccountFormTypeOptionSpan">
                Ideal for long-term customers and businesses
              </span>

              <div
                className="createAccountFormTypeOptionListItem"
                style={{ marginTop: 10 }}
              >
                <Check className="createAccountFormTypeOptionListItemIcon" />
                <span className="createAccountFormTypeOptionListItemText">
                  Add personal clients to the order
                </span>
              </div>

              <div className="createAccountFormTypeOptionListItem">
                <Check className="createAccountFormTypeOptionListItemIcon" />
                <span className="createAccountFormTypeOptionListItemText">
                  Create a team
                </span>
              </div>

              <div className="createAccountFormTypeOptionListItem">
                <Check className="createAccountFormTypeOptionListItemIcon" />
                <span className="createAccountFormTypeOptionListItemText">
                  Pay for orders at a time convenient for you
                </span>
              </div>
            </div> */}
          </div>

          <div className="createAccountFormContainerAddingForm">
            <div className="createAccountFormInputContainer">
              <input
                className={
                  userInf.firstName !== "" && !focus.firstName
                    ? "createAccountEmptyInput"
                    : "createAccountFormInput"
                }
                onFocus={() => setFocus({ ...focus, firstName: true })}
                onBlur={() => setFocus({ ...focus, firstName: false })}
                value={userInf.firstName}
                onChange={(e) =>
                  setUserInf({ ...userInf, firstName: e.target.value })
                }
              />
              <span
                className={
                  !focus.firstName && userInf.firstName !== ""
                    ? "createAccountEmptyPlaceholder"
                    : "createAccountFormPlaceholder"
                }
              >
                First name
              </span>
            </div>

            <div className="createAccountFormInputContainer">
              <input
                className={
                  userInf.lastName !== "" && !focus.lastName
                    ? "createAccountEmptyInput"
                    : "createAccountFormInput"
                }
                onFocus={() => setFocus({ ...focus, lastName: true })}
                onBlur={() => setFocus({ ...focus, lastName: false })}
                value={userInf.lastName}
                onChange={(e) =>
                  setUserInf({ ...userInf, lastName: e.target.value })
                }
              />
              <span
                className={
                  !focus.lastName && userInf.lastName !== ""
                    ? "createAccountEmptyPlaceholder"
                    : "createAccountFormPlaceholder"
                }
              >
                Last name
              </span>
            </div>

            {accoutTypeSelected==="business"?<div className="createAccountFormInputContainer">
              <input
                className={
                  userInf.companyName !== "" && !focus.companyName
                    ? "createAccountEmptyInput"
                    : "createAccountFormInput"
                }
                onFocus={() => setFocus({ ...focus, companyName: true })}
                onBlur={() => setFocus({ ...focus, companyName: false })}
                value={userInf.companyName}
                onChange={(e) =>
                  setUserInf({ ...userInf, companyName: e.target.value })
                }
              />
              <span
                className={
                  !focus.companyName && userInf.companyName !== ""
                    ? "createAccountEmptyPlaceholder"
                    : "createAccountFormPlaceholder"
                }
              >
                Company name
              </span>
            </div>:null}

            <div className="createAccountFormInputContainer">
              <input
                className={
                  userInf.email !== "" && !focus.email
                    ? (!validation.email && userInf.email!==""?"createAccountEmptyInput createAccountErrorInput":"createAccountEmptyInput")
                    : (!focus.email && !validation.email && userInf.email!==""?"createAccountFormInput createAccountErrorInput":"createAccountFormInput")
                }
                onFocus={() => setFocus({ ...focus, email: true })}
                onBlur={() => setFocus({ ...focus, email: false })}
                value={userInf.email}
                onKeyDown={(e) => e.key === "Enter" && !isBtnDisabled ? accoutTypeSelected === "personal" ? createPersonalAccount() : createBusinessAccount(): null}
                onChange={(e) =>
                  setUserInf({ ...userInf, email: e.target.value })
                }
              />
              <span
                className={
                  !focus.email && userInf.email !== ""
                    ? "createAccountEmptyPlaceholder"
                    : "createAccountFormPlaceholder"
                }
              >
                Email
              </span>

              {!validation.email && userInf.email!==""?<Delete className="createAccountWrongIcon" onClick={()=>setUserInf({...userInf, email:""})}/>:null}
            </div>

            {!focus.email && !validation.email && userInf.email!==""?<div className="createAccountFormValidation">Invalid email format. Example: example@example.com</div>:null}

            <div className="createAccountFormInputContainer">
              <input
                className={
                  userInf.password !== "" && !focus.password
                    ? (!validation.password && userInf.password!==""?"createAccountEmptyInput createAccountErrorInput":"createAccountEmptyInput")
                    : (!focus.password && !validation.password && userInf.password!==""?"createAccountFormInput createAccountErrorInput":"createAccountFormInput")
                }
                onFocus={() => setFocus({ ...focus, password: true })}
                onBlur={() => setFocus({ ...focus, password: false })}
                value={userInf.password}
                onKeyDown={(e) => e.key === "Enter" && !isBtnDisabled ? accoutTypeSelected === "personal" ? createPersonalAccount() : createBusinessAccount(): null}
                onChange={(e) =>
                  setUserInf({ ...userInf, password: e.target.value })
                }
                type={showPassword}
              />
              <span
                className={
                  !focus.password && userInf.password !== ""
                    ? "createAccountEmptyPlaceholder"
                    : "createAccountFormPlaceholder"
                }
              >
                Password
              </span>
              {showPassword === "text" ? (
                <Eye className={userInf.password===""?"showPasswordEye ":"showPasswordEye focusedPasswordEye"} onClick={toggleShowPassword} />
              ) : null}
             
             
              {showPassword === "password" && userInf.password!==""? (
                <EyeOff
                  className={"showPasswordEye "}
                  onClick={toggleShowPassword}
                />
              ) : null}

              {userInf.password==="" && showPassword === "password"? (
                <EyeOff
                  className={"showPasswordEye "}
                  onClick={toggleShowPassword}
                />
              ) : null}

              {showPassword === "password" && userInf.password!==""? (
                <EyeBlack
                  className={"showPasswordEye"}
                  onClick={toggleShowPassword}
                />
              ) : null}

              {(validation.password || !focus.password) && userInf.password?<div className={!validation.password?"createAccountFormValidationPassword":"createAccountFormValidationPassword passwordFits"}>At least 6 characters</div>:null}

            <button className="createAccountFormButton" disabled={isBtnDisabled || isLoading} onClick={()=>accoutTypeSelected === "personal" ? createPersonalAccount() : createBusinessAccount()}>{submitButton}
             {isLoading && <Loader className="btnLoader"/>}
            </button>

            <div className="createFormDontHaveAccount">
                <span className="createFormDontHaveAccountSpan">If you have an account</span>
                <span className="createFormDontHaveAccountRegister" onClick={()=>openLoginForm()}>Sign in</span>
            </div>

            </div>
          </div>
        </div>
      </div>):null}

      {accountStatus==='created'?(
        <AccountCreated closeAccountCreated={closeAccountCreated} accoutTypeSelected={accoutTypeSelected}/>
      ):null}
    </div>,
    modalRoot
  );
}
