import React, { useContext, useEffect } from 'react';

import { Info } from 'react-feather';

import { Navbar } from '../Components/Navbar';
import { Footer } from '../Components/Footer';

import { TransitionContext } from '../Context/TransitionContext';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import './Terms.css';
import TopBar from '../Components/TopBar';

const Refunds = ({ windowSize }) => {

  const { setColor } = useContext(TransitionContext);

  const { transitionTo } = useScreenTransition();

  useEffect(() => {
    setColor(true);
  }, [setColor]);

  return (
    <>
    <TopBar/>
    <div className="homeContainer">
      <Navbar windowSize={windowSize} />
      <div className="faqHeader termsHeader" style={{ alignItems: 'flex-start' }}>
        <h1 className="heading">Refund Policy</h1>
        <h2 className="faqSubheading termsSubheading">By making a purchase from us you agree to be bound by the following Refund Policy, please read it thoroughly before placing an order.</h2>
        <div className="termsUpdateContainer">
          <Info className="termsIcon" />
          <p>Last updated November 14, 2020</p>
        </div>
        <div className="termsHeaderSeparator" />
      </div>
      <div className="termsContainer">
        <h3 className="termsHeading">Overview</h3>
        <p className="termsText">
          Below you'll find the most common reasons for refund requests. Please review this policy to ensure you understand and agree with it before placing your order with us. If you believe your order is eligible for a refund or you have questions about our policy, please <span className="hoveringTextLink" onClick={(e) => transitionTo('/contact',e)}>contact us</span> with all pertinent details so we can review your request.
        </p>
        <div className="termsSeparator" />
        <h3 className="termsHeading">Order Changes</h3>
        <p className="termsText">
          You can contact us to request changes to the services you've ordered before the service has been provided. This includes both adding and removing services or the quantity of service. Once the service has begun, we typically cannot make changes, but we will accommodate your request if possible.
        </p>
        <div className="termsSeparator" />
        <h3 className="termsHeading">Cancellations</h3>
        <div className="termsText">
          You may request a cancellation anytime before your order is completed. We will prorate the refund for your primary service (translation) based on the amount of work the translator has completed on your project. For example:
          <br/><br/>
          - You have placed an order for three pages of certified translation totaling $74.85 and request to cancel before the project being assigned to a translator. You will receive a full refund of $74.85.<br/><br/>
          - You have placed an order for five pages of certified translation totaling $124.75 and request to cancel while the order is in progress. We contact the translator to stop work and get a current word count. The translator has translated three pages so your prorated refund will be for two pages totaling $49.95.<br/><br/>
          - You have placed an order for 335 words of standard translation totaling $33.50 and request to cancel after the order is complete. You will not receive a refund.<br/><br/>
          Secondary services (notarization, shipping, etc.) not provided will be refunded in full. Secondary services that have been provided are not eligible for a refund.
        </div>
        <div className="termsSeparator" />
        <h3 className="termsHeading">Turnaround Times</h3>
        <p className="termsText">
          We provide a turnaround estimate during checkout based on historical order data. This is an estimate of the time it will take from when we assign your project to a translator until they return it translated and you are notified. We only work with human translators so we cannot guarantee a specific turnaround time, only that we'll put best efforts towards meeting or beating the estimate provided.
          <br/><br/>The turnaround time starts once the project is assigned to the translator as there can be delays during the initial processing of the order. For example, if you don't upload a legible source file, select the wrong language pair or choose the wrong number of words/pages you can expect a delay before the estimated turnaround time begins as we'll have to resolve the underlying issue before assigning the project to a translator. If the wrong number of words/pages are selected at checkout, you can also expect the turnaround estimate to change once the count is corrected.
        </p>
        <div className="termsSeparator" />
        <h3 className="termsHeading">Quality Concerns</h3>
        <p className="termsText">
          Judging the quality of a translation is subjective as there are often multiple ways to phrase words and word choices will vary based on experience and understanding of the context of the source material. We will make every attempt to work with you to ensure you are satisfied with the final translation, but the translator ultimately has the final determination of word choice.
          <br/><br/>In the rare case that a translator has failed to deliver a translation up to our quality standards and revisions won't be adequate to bring it up to our standards, we may, at our sole discretion, issue a refund or offer a re-translation free of charge.
        </p>
        <div className="termsSeparator" />
        <h3 className="termsHeading">Revision Requests</h3>
        <p className="termsText">
          You may request a revision through your order status page within 30 days of the translation has been made available to you. Revisions are free of charge as long the source material is not being edited. Source material changes will be handled on a case-by-case basis. We might be able to edit the existing translation and charge for the additional translated words, but if the changes are substantial a full retranslation might be required.
          <br/><br/>The most common revision requests we see are for the names of people and places. When placing your order, we request this information, and we urge you to provide it as names can be particularly challenging to translate. This will significantly reduce the need for revisions, which ensure you have your finalized translation as quickly as possible.
        </p>
        <div className="termsSeparator" />
        <h3 className="termsHeading">Translation Acceptance</h3>
        <p className="termsText">
          Except for USCIS, we do not offer refunds for translation acceptance issues. It is your sole responsibility to ensure we meet the requirements of your receiving party. We strongly suggest you contact us with a list of their requirements before placing your order to ensure we meet them if you have any doubt.
          <br/><br/>We do offer a money-back guarantee for certified translations declined by USCIS for additional peace of mind, though we've never had a translation declined after thousands of submissions as we exceed their translation requirements.
        </p>
        <div className="termsSeparator" />
        <h3 className="termsHeading">Shipping Issues</h3>
        <p className="termsText">
          We exclusively ship with the <span className="hoveringTextLink" onClick={() => window.open('https://usps.com', '_blank')}>United States Postal Service (USPS)</span>. If you become aware of an issue with your shipment, please <span className="hoveringTextLink" onClick={(e) => transitionTo('/contact',e)}>contact us</span> immediately so we may assist you as possible to resolve. USPS is a third-party service provider, and we do not offer refunds for issues related to their handling of your shipment.
        </p>
      </div>
      <Footer windowSize={windowSize} />
    </div>
    </>
  );
}

export default Refunds;