const languages = [
  {
    value: 'albanian',
    label: 'Albanian'
  },
  {
    value: 'arabic',
    label: 'Arabic'
  },
  {
    value: 'armenian',
    label: 'Armenian'
  },
  {
    value: 'bengali',
    label: 'Bengali'
  },
  {
    value: 'bosnian',
    label: 'Bosnian'
  },
  {
    value: 'bulgarian',
    label: 'Bulgarian'
  },
  {
    value: 'burmese',
    label: 'Burmese'
  },
  {
    value: 'catalan',
    label: 'Catalan'
  },
  {
    value: 'chineseSimp',
    label: 'Chinese (Simplified)'
  },
  {
    value: 'chineseTrad',
    label: 'Chinese (Traditional)'
  },
  {
    value: 'creole',
    label: 'Creole'
  },
  {
    value: 'croatian',
    label: 'Croatian'
  },
  {
    value: 'czech',
    label: 'Czech'
  },
  {
    value: 'danish',
    label: 'Danish'
  },
  {
    value: 'dari',
    label: 'Dari'
  },
  {
    value: 'dutch',
    label: 'Dutch'
  },
  {
    value: 'english',
    label: 'English'
  },
  {
    value: 'farsi',
    label: 'Farsi'
  },
  {
    value: 'finnish',
    label: 'Finnish'
  },
  {
    value: 'french',
    label: 'French'
  },
  {
    value: 'frenchCan',
    label: 'French (Canadian)'
  },
  {
    value: 'georgian',
    label: 'Georgian'
  },
  {
    value: 'german',
    label: 'German'
  },
  {
    value: 'greek',
    label: 'Greek'
  },
  {
    value: 'gujrati',
    label: 'Gujrati'
  },
  {
    value: 'hebrew',
    label: 'Hebrew'
  },
  {
    value: 'hindi',
    label: 'Hindi'
  },
  {
    value: 'hungarian',
    label: 'Hungarian'
  },
  {
    value: 'indonesian',
    label: 'Indonesian'
  },
  {
    value: 'italian',
    label: 'Italian'
  },
  {
    value: 'japanese',
    label: 'Japanese'
  },
  {
    value: 'kannada',
    label: 'Kannada'
  },
  {
    value: 'korean',
    label: 'Korean'
  },
  {
    value: 'latin',
    label: 'Latin'
  },
  {
    value: 'latvian',
    label: 'Latvian'
  },
  {
    value: 'lithuanian',
    label: 'Lithuanian'
  },
  {
    value: 'macedonian',
    label: 'Macedonian'
  },
  {
    value: 'malay',
    label: 'Malay'
  },
  {
    value: 'maltese',
    label: 'Maltese'
  },
  {
    value: 'marathi',
    label: 'Marathi'
  },
  {
    value: 'nepali',
    label: 'Nepali'
  },
  {
    value: 'norwegian',
    label: 'Norwegian'
  },
  {
    value: 'pashto',
    label: 'Pashto'
  },
  {
    value: 'persian',
    label: 'Persian'
  },
  {
    value: 'polish',
    label: 'Polish'
  },
  {
    value: 'portugueseBrazil',
    label: 'Portuguese (Brazil)'
  },
  {
    value: 'portuguesePortugal',
    label: 'Portuguese (Portugal)'
  },
  {
    value: 'punjabi',
    label: 'Punjabi'
  },
  {
    value: 'romanian',
    label: 'Romanian'
  },
  {
    value: 'russian',
    label: 'Russian'
  },
  {
    value: 'serbian',
    label: 'Serbian'
  },
  {
    value: 'slovak',
    label: 'Slovak'
  },
  {
    value: 'slovenian',
    label: 'Slovenian'
  },
  {
    value: 'somali',
    label: 'Somali'
  },
  {
    value: 'spanish',
    label: 'Spanish'
  },
  {
    value: 'swedish',
    label: 'Swedish'
  },
  {
    value: 'tagalog',
    label: 'Tagalog'
  },
  {
    value: 'tamil',
    label: 'Tamil'
  },
  {
    value: 'thai',
    label: 'Thai'
  },
  {
    value: 'turkish',
    label: 'Turkish'
  },
  {
    value: 'ukrainian',
    label: 'Ukrainian'
  },
  {
    value: 'urdu',
    label: 'Urdu'
  },
  {
    value: 'uzbek',
    label: 'Uzbek'
  },
  {
    value: 'vietnamese',
    label: 'Vietnamese'
  },
];

export { languages };