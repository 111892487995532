import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as Close } from "../../assets/icons/x.svg";
import axios from "axios";
import { NotificationContext } from "../../Context/NotificationContext";
import { Loader } from "react-feather";
import "./ForgotPasswordForm.css";

export default function ForgotPasswordForm({
  isLoginOpen,
  goToVerification,
  backToSignIn,
  email,
  setEmail,
}) {
  const [emailError, setEmailError] = useState(false);
  const [isAbleToSend, setIsAbleToSend] = useState(false);

  const [focus, setFocus] = useState(false);
  const { showNotification } = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [btnText, setBtnText] = useState("Send Code");

  useEffect(() => {
    if (emailError) {
      setIsAbleToSend(false);
    } else {
      setIsAbleToSend(true);
    }
    if (email === "") {
      setIsAbleToSend(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailError]);

  useEffect(() => {
    if (email) {
      setEmailError(!/\S+@\S+\.\S+/.test(email));
    } else {
      setEmailError(false);
    }
  }, [email]);

  const closeForm = () => {
    isLoginOpen();
  };

  const verificationStart =async () => {
    if(email === "" || !/\S+@\S+\.\S+/.test(email)){
      setEmailError(true);
      setFocus(false);
      return;
    }
    setBtnText("Sending");
    setIsLoading(true);
    try {
      const response = await axios.post("https://us-central1-project-everbary.cloudfunctions.net/generateForgotPassCode", {
        email: email.toLowerCase(),
      });
      if (response.status === 200 && response.data === "Code created!") {
        goToVerification();
        showNotification(
          "Success",
          "Verification code sent, Please check your email for verification code!",
          "success"
        );
      } else if (response.status === 200 && response.data === "Email Not Found!") {
        showNotification(
          "Error",
          "Invalid Email,The Email you've entered doesn't match. Please try another Email!",
          "error"
        );
      }
    } catch (error) {
      // console.error(error);
      setIsLoading(false);
      setBtnText("Send Code");
      showNotification(
        "Error",
        "Error occurred while sending verification code. Please try again or contact support!",
        "error"
      );
      
    } finally {
      setIsLoading(false);
      setBtnText("Send Code");
    }
  };
  

  let inputStyle = !focus && emailError && email!==""
    ? "forgotPasswordFormInput errorInput"
    : "forgotPasswordFormInput";

  
  return (
    <div className="forgotPasswordForm">
      <div className="forgotPasswordFormHeader">
        <h2 className="forgotPasswordFormHeaderTitle">Forgot Password</h2>
        <Close className="signInFormIcon" onClick={closeForm} />
      </div>

      <span className="forgotPasswordFormText">
        Please enter the email associated with your account
      </span>

      <div className="forgotPassworFormContainer">
        <input
          type="email"
          className={
            !focus && email !== ""
              ? !emailError
                ? "forgotPasswordFormInputNotEmpty"
                : "forgotPasswordFormInputNotEmpty errorInput"
              : inputStyle
          }
          value={email}
          onChange={(e) => {setEmail(e.target.value); setFocus(true)}}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onKeyDown={(e) => e.key === "Enter" && verificationStart()}
        />
        <span
          className={
            !focus && email !== ""
              ? "forgotPasswordFormPlaceholderNotEmpty"
              : "forgotPassworFormPlaceholder"
          }
        >
          Email
        </span>
      </div>

      {!focus && emailError && email!=="" ? (
        <span className="forgotPasswordFormError">
          Invalid email format. Example: example@example.com
        </span>
      ) : null}
      <button
        className="forgotPasswordFormButton"
        disabled={!isAbleToSend || isLoading}
        onClick={() => verificationStart()}
      >
        {btnText}
        {isLoading && <Loader className="btnLoader"/>}
      </button>

      <span
        className="forgotPasswordFormBackToSignIn"
        onClick={() => backToSignIn()}
      >
        Back to Sign In
      </span>
    </div>
  );
}
