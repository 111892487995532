import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';

import { User, FileText, Lock, Clock, Globe, List, CreditCard, Check, ArrowLeft, HelpCircle } from 'react-feather';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

import { AltAnimatedButton } from '../../Components/AltAnimatedButton';

import { languages } from '../../Data/languages';
import { countries } from '../../Data/countries';

import { useScreenTransition } from '../../Hooks/useScreenTransition';
import { useEnterKey } from '../../Hooks/useEnterKey';

import { AuthContext } from '../../Context/AuthContext';
import { NotificationContext } from '../../Context/NotificationContext';
import { DataContext } from '../../Context/DataContext';

import './OrderOptions.css';
import TopBar from '../../Components/TopBar';

const OrderOptions = ({ windowSize }) => {

  const { transitionTo } = useScreenTransition();
  const { showNotification } = useContext(NotificationContext);

  const { signedIn } = useContext(AuthContext);

  const { order, setOrder, userData } = useContext(DataContext);

  useEffect(() => {
    if (order.parsed && (order.name === '' || order.email === '') && !signedIn) {
      transitionTo('/order/contact');
    } else if (order.parsed && (order.orderID === '' || !order.languageFrom || order.languageFrom === '' || !order.languageTo || order.languageTo === '' || !order.files || order.files.length === 0)) {
      transitionTo('/order/documents');
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, signedIn]);

  const [continueButton, setContinueButton] = useState("Continue to Payment");

  const [notarization, setNotarization] = useState(false);
  const [expedited, setExpedited] = useState(false);
  const [hardCopy, setHardCopy] = useState(false);

  const [hardCopyCountry, setHardCopyCountry] = useState('');
  const [hardCopyMethod, setHardCopyMethod] = useState('');
  const [hardCopyRequireSignature, setHardCopyRequireSignature] = useState(false);

  useEffect(() => {
    setNotarization(order.notarization || false);
    setExpedited(order.expedited || false);
    setHardCopy(order.hardCopy || false);
    setHardCopyCountry(order.hardCopyExtra ? (order.hardCopyExtra.country || '' ) : '');
    setHardCopyMethod(order.hardCopyExtra ? (order.hardCopyExtra.method || '' ) : '');
    setHardCopyRequireSignature(order.hardCopyExtra ? (order.hardCopyExtra.requireSignature || false ) : false);
  }, [order]);

  const handleContinueToPayment = async () => {

    setContinueButton('Continuing...');

    const now = new Date();

    const firestore = firebase.firestore();

    let orderNumber = 0;

    if (!order.orderNumber) {
      await axios({
        method:'post',
        url: "https://us-central1-project-everbary.cloudfunctions.net/getCounter",
        data: {
          type: 'order'
        }
      })
        .then((response) => {
          orderNumber = parseInt(response.data.number, 10);
        })
        .catch((error) => {
          //console.log(error);
        });
  
      if (orderNumber === 0) {
        showNotification('Error', "An error ocurred while processing the request. Please, try again. If it persists, contact support.", 'error');
        setContinueButton('Continue to Payment');
        return false;
      }
    }

    //console.log(order.files);

    const data = {
      orderNumber: order.orderNumber ? order.orderNumber : orderNumber,
      userID: signedIn ? firebase.auth().currentUser.uid : '',
      teamId: signedIn ? (userData.teamId ? userData.teamId : '') : '',
      email: signedIn ? (userData ? userData.email : firebase.auth().currentUser.email) : order.email,
      name: signedIn ? (userData ? userData.name : '') : order.name,
      certified: order.certified,
      languageFrom: order.languageFrom,
      languageTo: order.languageTo,
      notarization: notarization,
      notarizationStatus: notarization ? 'pending' : null,
      expedited: expedited,
      hardCopy: hardCopy,
      hardCopyStatus: hardCopy ? 'pending' : null,
      hardCopyExtra: hardCopy ? { country: hardCopyCountry, method: hardCopyMethod, requireSignature: hardCopyRequireSignature } : null,
      pagesCount: order.certified ? order.pagesCount : 0,
      wordCount: !order.certified ? order.wordCount : 0,
      files: order.files.map(file => ({ name: file.name, ref: file.ref, url: file.url, type: file.type, size: file.size, format: file.format, timestamp: now.getTime() })),
      timestamp: now.getTime(),
      status: 'created',
      notified: false,
      userNotes:'',
      totalPrice: getTotal(),
    }
    
    localStorage.setItem('order', JSON.stringify({ ...order, files: JSON.stringify(order.files.filter(f => f.ref && f.url && f.status === 'complete').map(file => ({ name: file.name, ref: file.ref, url: file.url, type: 'source', size: file.file ? file.file.size : file.size, format: file.file ? file.file.type : file.format, status: 'complete', progress: 100 }))), orderNumber: order.orderNumber ? order.orderNumber : orderNumber, optionsSet: true, notarization: notarization, expedited: expedited, hardCopy: hardCopy, hardCopyExtra: hardCopy ? { country: hardCopyCountry, method: hardCopyMethod, requireSignature: hardCopyRequireSignature } : null }));
    setOrder(o => ({ ...o, orderNumber: order.orderNumber ? order.orderNumber : orderNumber, optionsSet: true, notarization: notarization, expedited: expedited, hardCopy: hardCopy, hardCopyExtra: hardCopy ? { country: hardCopyCountry, method: hardCopyMethod, requireSignature: hardCopyRequireSignature } : null }));
    
    firestore.collection('orders').doc(order.orderID)
      .set(data)
      .then(() => {
        setContinueButton('Continue to Payment');
        transitionTo('/order/payment');
      })
      .catch(error => {
        //console.log(error);
        setContinueButton('Continue to Payment');
        showNotification('Error', "An error ocurred while placing the order. Please, try again.", 'error');
      })
  }

  const handleClickContact = () => {
    if (!signedIn) {
      transitionTo('/order/contact');
    }
  }

  const handleClickDocuments = () => {
    transitionTo('/order/documents');
  }

  const handleClickPayment = () => {
    if ((signedIn || order.name !== '') && order.languageFrom && order.languageFrom !== '' && order.optionsSet) {
      transitionTo('/order/payment');
    }
  }

  const getLanguage = (language) => {
    if (languages.find(lang => lang.value === language)) {
      return languages.find(lang => lang.value === language).label;
    } else {
      return '';
    }
  }

  const getExpedited = () => {
    let total = 0;

    if (order && order.certified) {
      if (order.pagesCount !== '') {
        total = total + parseInt(order.pagesCount, 10) * 24.95;
      } else {
        total = 0;
      }
    } else if (order) {
      if (order.wordCount !== '') {
        total = total + parseInt(order.wordCount, 10) * 0.1;
      } else {
        total = 0;
      }
    }

    return total * 0.4;
  }

  const getTotal = () => {
    let total = 0;

    if (order && order.certified) {
      if (order.pagesCount !== '') {
        total = total + parseInt(order.pagesCount, 10) * 24.95;
      } else {
        total = 0;
      }
    } else if (order) {
      if (order.wordCount !== '') {
        total = total + parseInt(order.wordCount, 10) * 0.1;
      } else {
        total = 0;
      }
    }

    if (expedited) {
      total = total + getExpedited();
    }

    if (notarization) {
      total = total + 34.90;
    }

    if (hardCopy) {
      if (['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(hardCopyCountry)) {
        if (hardCopyMethod === 'priority') {
          total = total + 19.95;
        } else {
          total = total + 37.95;
        }
      } else {
        if (hardCopyMethod === 'priority') {
          total = total + 49.95;
        } else {
          total = total + 78.95;
        }
      }
    }

    if (hardCopy && hardCopyRequireSignature) {
      total = total + 4.99;
    }

    return total;
  }

  const formatTotal = value => {
    return '$' + value.toFixed(2);
  }

  const getTimeFrame = () => {

    let limits = [0, 750];

    for (let i = 2; i < 200; i++) {
      limits.push(limits[limits.length - 1] + 1500 + (i-2) * 250);
    }

    let count = 0;

    if (order.certified && order.pagesCount !== '') {
      count = order.pagesCount * 250;
    } else if (!order.certified && order.wordCount !== '') {
      count = order.wordCount;
    };

    const ceiling = limits.filter(limit => count <= limit).sort((a,b) => a - b)[0];
    let ceilingIndex = limits.findIndex(limit => limit === ceiling);

    if (expedited) {
      ceilingIndex = ceilingIndex / 2;
    };

    if (ceilingIndex > 0.5) {
      ceilingIndex = Math.ceil(ceilingIndex);
    }

    if (ceilingIndex <= 2) {
      return `${ceilingIndex * 24} hours`;
    } else {
      return `${ceilingIndex - 1}-${ceilingIndex} days`;
    }

  }

  const triggerContinue = () => {
    if (!((!signedIn && !order.name) || order.languageFrom === '')) {
      handleContinueToPayment();
    }
  }

  useEnterKey(() => triggerContinue());

  const phoneScreen = windowSize.orientation === 'portrait' && windowSize.width <= 767; 

  return (
    <>
    <TopBar/>
    <div className="quoteContainer">
      <div className="quoteHeader">
        <img src={require('../../assets/logoAlt.png')} alt="Logo eVerbary" className="quoteLogo" onClick={(e) => transitionTo('/',e)} />
        <div className="quoteHeaderExtra">
          <div className="quoteHeaderExtraBlock" onClick={handleClickContact} style={{ opacity: 0.5, cursor: !signedIn ? 'pointer' : 'default' }}>
            <User className="quoteHeaderIcon" />
            <p className="quoteHeaderExtraBlockText" style={{ display: phoneScreen ? 'none' : 'flex' }}>Contact</p>
          </div>
          <div className="orderHeaderConnector" />
          <div className="quoteHeaderExtraBlock" onClick={handleClickDocuments} style={{ opacity: 0.5 }}>
            <FileText className="quoteHeaderIcon" />
            <p className="quoteHeaderExtraBlockText" style={{ display: phoneScreen ? 'none' : 'flex' }}>Documents</p>
          </div>
          <div className="orderHeaderConnector" />
          <div className="quoteHeaderExtraBlock">
            <List className="quoteHeaderIcon" />
            <p className="quoteHeaderExtraBlockText" style={{ display: phoneScreen ? 'none' : 'flex' }}>Options</p>
          </div>
          <div className="orderHeaderConnector" />
          <div className="quoteHeaderExtraBlock" onClick={handleClickPayment} style={{ opacity: 0.5, cursor: (signedIn || order.name !== '') && order.languageFrom && order.languageFrom !== '' && order.optionsSet ? 'pointer' : 'default' }}>
            <CreditCard className="quoteHeaderIcon" />
            <p className="quoteHeaderExtraBlockText" style={{ display: phoneScreen ? 'none' : 'flex' }}>Payment</p>
          </div>
        </div>
      </div>
      <div className="quoteMainContainer">
        <div id="mainPrimary" className="quoteMainPrimaryContainer">
          <div className="quotePrimaryInner">

            <h3 className="quotePrimaryTitle">Which optional services do you need?</h3>
            {order.certified ? <div className="quoteOptionalContainer" style={{ borderColor: notarization ? '#FF7C1D' : 'rgba(102,102,102,0.1)' }}>
              <div className="quoteOptionalTitleRow">
                <p className="quotePrimaryOptionTitle">Notarization</p>
                <div className="orderOptionalExtraContainer">
                  <p className="orderOptionalPrice">$34.90</p>
                  <div className="quoteOptionalTitleStatus" onClick={() => setNotarization(n => !n)} style={{ backgroundColor: !notarization ? '#FF7C1D' : 'rgba(102,102,102,0.1)', color: !notarization ? '#fff' : '#666' }}>{notarization ? "REMOVE" : "ADD"}</div>
                </div>
              </div>
              <p className="quotePrimaryOptionText" style={{ width: '70%' }}>Stamp and signature authenticating the signer of the translation certification. Valid in all 50 states.</p>
            </div> : null}
            <div className="quoteOptionalContainer" style={{ borderColor: expedited ? '#FF7C1D' : 'rgba(102,102,102,0.1)' }}>
              <div className="quoteOptionalTitleRow">
                <p className="quotePrimaryOptionTitle">Expedited Turnaround</p>
                <div className="orderOptionalExtraContainer">
                  <p className="orderOptionalPrice">{formatTotal(getExpedited())}</p>
                  <div className="quoteOptionalTitleStatus" onClick={() => setExpedited(n => !n)} style={{ backgroundColor: !expedited ? '#FF7C1D' : 'rgba(102,102,102,0.1)', color: !expedited ? '#fff' : '#666' }}>{expedited ? "REMOVE" : "ADD"}</div>
                </div>
              </div>
              <p className="quotePrimaryOptionText" style={{ width: '70%' }}>Your order will be prioritized and turnaround time for digital delivery reduced by 50%.</p>
            </div>
            {order.certified ? 
            <div className="quoteOptionalContainer" style={{ borderColor: hardCopy ? '#FF7C1D' : 'rgba(102,102,102,0.1)' }}>
              <div className="quoteOptionalTitleRow">
                <p className="quotePrimaryOptionTitle">Mail Hard Copy</p>
                <div className="quoteOptionalTitleStatus" onClick={() => setHardCopy(n => !n)} style={{ backgroundColor: !hardCopy ? '#FF7C1D' : 'rgba(102,102,102,0.1)', color: !hardCopy ? '#fff' : '#666' }}>{hardCopy ? "REMOVE" : "ADD"}</div>
              </div>
              <p className="quotePrimaryOptionText" style={{ width: '70%' }}>Original translation with wet ink signature(s) shipped via USPS Priority or Express mail.</p>
              {hardCopy ? 
              <>
                <div className="hardCopyLineSeparator" />
                <div className="quoteDetailsInputsContainer">
                  <div className="quoteDetailsSelectContainer">
                    <select value={hardCopyCountry} onChange={(e) => setHardCopyCountry(e.target.value)} className="quoteDetailsSelect">
                      <option key="" value="" disabled>Country (*)</option>
                      {countries.map(country => 
                        <option key={country} value={country}>{country}</option>
                      )}
                    </select>
                  </div>
                  <div className="quoteDetailsSelectContainer">
                    <select placeholder="USPS Service (*)" value={hardCopyMethod} onChange={(e) => setHardCopyMethod(e.target.value)} className="quoteDetailsSelect">
                      <option key="" value="" disabled>USPS Service (*)</option>
                      <option key="priority" value="priority">{['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(hardCopyCountry) ? "Priority: 2-3 days in transit" : "Priority: 6-10 days in transit"}</option>
                      <option key="express" value="express">{['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(hardCopyCountry) ? "Express: 1-2 days in transit" : "Express: 3-5 days in transit" }</option>
                    </select>
                  </div>
                </div>
                <div className="hardCopyCheckboxLine" onClick={() => setHardCopyRequireSignature(hc => !hc)}>
                  <div className="hardCopyCheckbox">
                    <Check className="hardCopyCheck" style={{ opacity: hardCopyRequireSignature ? 1 : 0 }} />
                  </div>
                  <p className="hardCopyCheckboxText">Require signature for delivery $4.99</p>
                </div>
              </> : null}
            </div> : null}
            
            {!phoneScreen && <div className="quoteSubmitButtonContainer">
              <div className="quoteGoBackButton" onClick={(e) => transitionTo('/order/documents',e)}>
                <ArrowLeft className="quoteGoBackIcon" />
              </div>
              <AltAnimatedButton text={continueButton} disabled={(!signedIn && !order.name) || order.languageFrom === '' || (hardCopy && (hardCopyCountry === '' || hardCopyMethod === ''))} onClick={() => handleContinueToPayment()} fontSize={windowSize.width > 1024 ? 1.2 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 2.2 : 1.5) : (windowSize.orientation === 'portrait' ? 5 : 1.2)} />
            </div>}
          </div>
        </div>
        <div id="mainSecondary" className="quoteMainSecondaryContainer">
          <div className="orderSummary">
            <h3 className="orderSummaryTitle">SUMMARY</h3>
            <div className="orderSummaryInfo">
              <div className="orderSummaryInfoMain">
                <p className="orderSummaryInfoType">{order.certified ? "Certified Translation" : "Standard Translation"}</p>
                <p className="orderSummaryInfoCount">{order.certified ? (order.pagesCount !== '' ? order.pagesCount + " pages (" + (parseInt(order.pagesCount, 10) * 250) + " words max)" : "0 pages (0 words max)") : (order.wordCount !== '' ? order.wordCount + " words" : "0 words")}</p>
              </div>
              <p className="orderSummaryInfoType">{order.certified ? (order.pagesCount !== "" ? '$' + (parseInt(order.pagesCount, 10) * 24.95).toFixed(2) : "$0.00") : ((order.wordCount !== '' ? '$' + (parseInt(order.wordCount, 10) * 0.1).toFixed(2) : '$0.00'))}</p>
            </div>
            {notarization ? 
            <div className="orderSummaryInfo">
              <div className="orderSummaryInfoMain">
                <p className="orderSummaryInfoType">Notarization</p>
                <p className="orderSummaryInfoCount">Valid in all 50 states</p>
              </div>
              <p className="orderSummaryInfoType">$34.90</p>
            </div> : null}
            {expedited ? 
            <div className="orderSummaryInfo">
              <div className="orderSummaryInfoMain">
                <p className="orderSummaryInfoType">Expedited Turnaround</p>
                <p className="orderSummaryInfoCount">Turnaround reduced by 50%</p>
              </div>
              <p className="orderSummaryInfoType">{formatTotal(getExpedited())}</p>
            </div> : null}
            {hardCopy && hardCopyCountry !== '' && hardCopyMethod !== '' ? 
            <div className="orderSummaryInfo">
              <div className="orderSummaryInfoMain">
                <p className="orderSummaryInfoType">{`USPS ${hardCopyMethod.charAt(0).toUpperCase() + hardCopyMethod.slice(1)} Mail ${!['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(hardCopyCountry) ? 'International' : ''}`}</p>
                <p className="orderSummaryInfoCount">{`${['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(hardCopyCountry) ? (hardCopyMethod === 'priority' ? '2-3' : '1-2') : (hardCopyMethod === 'priority' ? '6-10' : '3-5')} days in transit`}</p>
              </div>
              <p className="orderSummaryInfoType">{`$${['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(hardCopyCountry) ? (hardCopyMethod === 'priority' ? '19.95' : '37.95') : (hardCopyMethod === 'priority' ? '49.95' : '78.95')}`}</p>
            </div> : null}
            {hardCopyRequireSignature ? 
            <div className="orderSummaryInfo">
              <div className="orderSummaryInfoMain">
                <p className="orderSummaryInfoType">Signature Required for Delivery</p>
                <p className="orderSummaryInfoCount">Adult signature required</p>
              </div>
              <p className="orderSummaryInfoType">$4.99</p>
            </div> : null}
            <div className="orderSummaryTotal">
              <p>Total</p>
              <p>{formatTotal(getTotal())}</p>
            </div>
          </div>
          <div className="quoteSecondaryExtraInfo">
            {((order.certified && order.pagesCount !== '') || (!order.certified && order.wordCount !== '')) &&
              <div className="orderSecondaryTitle">
                <Clock className="orderSecondaryIcon" />
                {`${getTimeFrame()} est. turnaround`}
              </div>}
            {((order.certified && order.pagesCount !== '') || (!order.certified && order.wordCount !== '')) &&
              <div className="quoteSecondarySub">This is an estimate to deliver the digital copy based on the current order information you've provided.</div>
            }
            {order.languageFrom !== '' && order.languageTo !== '' &&
              <div className="orderSecondaryTitle">
                <Globe className="orderSecondaryIcon" />
                {`${getLanguage(order.languageFrom)} to ${getLanguage(order.languageTo)} Translation`}
              </div>
            }
            {order.languageFrom !== '' && order.languageTo !== '' &&
              <div className="quoteSecondarySub">We'll pair you with a professional translator and a project manager from our support team.</div>
            }
            <div className="orderSecondaryTitle">
              <Lock className="orderSecondaryIcon" />
              Secure and Private
            </div>
            <div className="quoteSecondarySub">All data processed by our site, including uploaded files, is encrypted. Only essential staff with signed NDAs can access your information.</div>
          </div>
          <img src={require('../../assets/encryptionIcons.png')} alt="Encryption Certificates" className="quoteSecondaryEncryptionImg" />
          <div className="orderSecondaryTitle">
            <HelpCircle className="orderSecondaryIcon" />
            Need help?
          </div>
          <div className="quoteSecondarySub"><a href="https://everbary.com/contact">Contact us</a> and we will create the order on your behalf.</div>
          {phoneScreen && <div className="quoteSubmitButtonContainer">
            <div className="quoteGoBackButton" onClick={(e) => transitionTo('/order/documents',e)}>
              <ArrowLeft className="quoteGoBackIcon" />
            </div>
            <AltAnimatedButton text={continueButton} disabled={(!signedIn && !order.name) || order.languageFrom === ''} onClick={() => handleContinueToPayment()} fontSize={windowSize.width > 1024 ? 1.2 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 2.2 : 1.5) : (windowSize.orientation === 'portrait' ? 5 : 1.2)} />
          </div>}
        </div>
      </div>
      <div className="quoteFooter">
        <div className="quoteFooterLinksContainer">
          <div className="quoteFooterLink">Contact</div>
          <div className="quoteFooterLinkSeparator" />
          <div className="quoteFooterLink">Terms</div>
          <div className="quoteFooterLinkSeparator" />
          <div className="quoteFooterLink">Refunds</div>
          <div className="quoteFooterLinkSeparator" />
          <div className="quoteFooterLink">Privacy</div>
        </div>
      </div>
    </div>
    </>
  );
}

export default OrderOptions;