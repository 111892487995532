import React, { useState, useContext, useEffect, useRef } from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { NotificationContext } from '../../Context/NotificationContext';
import { DataContext } from '../../Context/DataContext';

import './Payments.css';
import { useScreenTransition } from '../../Hooks/useScreenTransition';


const pageLimit = 50;

const OwnPayments = ({ windowSize }) => {

  const { transitionTo } = useScreenTransition()


  const { showNotification } = useContext(NotificationContext);

  const { userData } = useContext(DataContext);

  const [payments, setPayments] = useState([]);
  const [loadedPayments, setLoadedPayments] = useState(false);

  const paymentSearchRef = useRef();
  const [paymentSearch, setPaymentSearch] = useState('');

  const [statusFilter, setStatusFilter] = useState('');

  const [triggerFilter, setTriggerFilter] = useState(true);

  let firstLoad = useRef();
  if (!firstLoad.current) firstLoad.current = true;

  let nextPointer = useRef();
  if (!nextPointer.current) nextPointer.current = null;

  useEffect(() => {
    const applyOrder = e => {
      if (e.keyCode === 13) {
        setTriggerFilter(tf => !tf);
      }
    }

    const paymentSearchRefVar = paymentSearchRef.current;

    paymentSearchRef.current.addEventListener("keyup", applyOrder);

    return () => {
      paymentSearchRefVar.removeEventListener("keyup", applyOrder);
    }
  }, []);

  useEffect(() => {
    if (userData) {
      firstLoad.current = true;

      const firestore = firebase.firestore();

      let query = firestore.collection('payments');

      if (paymentSearch !== '') {
        query = query.where('paymentNumber', '==', parseInt(paymentSearch,10));
      };

      if (statusFilter !== '') {
        query = query.where('status', '==', statusFilter);
      };

      const unsubscribe = 
        query
          .where('status', 'in', ['awaiting', 'paid'])
          .where('translator.id', '==', userData.id)
          .orderBy('timestamp', 'desc')
          .limit(pageLimit)
          .onSnapshot(querySnapshot => {
            if (firstLoad.current) {
              nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];
              firstLoad.current = false;
              
              setPayments(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
              setLoadedPayments(true);
            } else {
              let newPayments = [];

              querySnapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                  newPayments.push({ id: change.doc.id, ...change.doc.data() });
                }
              });
              
              setPayments(u => newPayments.concat(u));
              setLoadedPayments(true);
            }
          }, error => {
            //console.log(error);
            showNotification('Error', "There was an error loading the payments. Please, reload the page.", 'error');
            setLoadedPayments(true);
          });

      return () => unsubscribe();

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, triggerFilter, statusFilter]);

  const loadMorePayments = () => {
    if (nextPointer.current && userData) {
      const firestore = firebase.firestore();

      let query = firestore.collection('payments');

      if (paymentSearch !== '') {
        query = query.where('paymentNumber', '==', parseInt(paymentSearch,10));
      };
  
      if (statusFilter !== '') {
        query = query.where('status', '==', statusFilter);
      };

      query
        .where('translator.id', '==', userData.id)
        .orderBy('timestamp', 'desc')
        .startAfter(nextPointer.current)
        .limit(pageLimit)
        .get().then((querySnapshot) => {
          nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];

          let oldPayments = [];

          querySnapshot.forEach((doc) => {
            oldPayments.push({ id: doc.id, ...doc.data() });
          });
          
          setPayments(u => u.concat(oldPayments));
        })
        .catch((error) => {
          showNotification('Error', "There was an error loading the payments. Please, reload the page.", 'error');
          //console.log("Error getting documents: ", error);
        });
    }
  }

  const formatDate = timestamp => {
    if (!timestamp) {
      return '';
    }

    const date = new Date(timestamp);

    const splitted = date.toDateString().split(' ');

    return splitted[1] + ' ' + splitted[2] + ', ' + splitted[3];
  }

  const formatWordCount = payment => {
    if (payment.invoices.length === 0) {
      return '';
    }

    return payment.invoices.reduce((acc, cur) => acc + parseInt(cur.wordCount, 10), 0);
  }

  const formatPaymentTotal = payment => {
    if (payment.invoices.length === 0) {
      return '';
    }

    return "$" + payment.invoices.reduce((acc, cur) => acc + parseInt(cur.wordCount, 10) * cur.rate, 0).toFixed(2);
  }

  return (
    <div className="translationsContainer">
      <div className="translationsInnerContainer">
        <div className="translationsTitleRow">
          <p className="translationsTitle">Payments</p>
          <div className="usersFunctionsContainer">
            <div className="userLanguageFilterContainer">
              <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} className="quoteDetailsSelect">
                <option key="" value="">All</option>
                <option key="awaiting" value="awaiting">Awaiting</option>
                <option key="paid" value="paid">Paid</option>
              </select>
            </div>
            <div className="userLanguageFilterContainer">
              <input type="text" value={paymentSearch} ref={paymentSearchRef} onChange={(e) => setPaymentSearch(e.target.value)} className="userNameFilter" placeholder="Search by payment number" />
            </div>
          </div>
        </div>
        <div className="translationsTableHeader">
          <div className="usersHeaderColumn paymentsNumberColumn">Number</div>
          <div className="usersHeaderColumn paymentsInvoicesColumn">Invoices</div>
          <div className="usersHeaderColumn paymentsWordsColumn">Words</div>
          <div className="usersHeaderColumn paymentsMethodColumn">Method</div>
          <div className="usersHeaderColumn paymentsPaidColumn">Paid At</div>
          <div className="usersHeaderColumn paymentsStatusColumn">Status</div>
          <div className="usersHeaderColumn paymentsTotalColumn">Total</div>
        </div>
        { loadedPayments && payments.length > 0
        ? payments.map(payment => 
          <div key={payment.id} className="translationsTableLine">
            <div className="usersLineColumn paymentsNumberColumn" style={{ cursor: 'pointer', color: '#609ddb' }} onClick={(e) => transitionTo('/office/payment/' + payment.id.e)}>{payment.paymentNumber}</div>
            <div className="usersLineColumn paymentsInvoicesColumn">{payment.invoices.length}</div>
            <div className="usersLineColumn paymentsWordsColumn">{formatWordCount(payment)}</div>
            <div className="usersLineColumn paymentsMethodColumn">{payment.translator.method}</div>
            <div className="usersLineColumn paymentsPaidColumn">{formatDate(payment.paidAt)}</div>
            <div className="usersLineColumn paymentsStatusColumn">
              <div className="translationsStatusContainer" style={{ backgroundColor: payment.status === 'inactive' ? '#333' : payment.status === 'awaiting' ? '#AE5FD2' : payment.status === 'paid' ? '#17BC5B' : 'transparent' }}>
                { payment.status === 'inactive' ? "INACTIVE" : payment.status === 'awaiting' ? 'AWAITING' : payment.status === 'paid' ? 'PAID' : ''}
              </div>
            </div>
            <div className="usersLineColumn paymentsTotalColumn">{formatPaymentTotal(payment)}</div>
          </div>)
        : loadedPayments ? <div className="loadingTranslations">No payments.</div> : <div className="loadingTranslations">Loading payments...</div>
        }
        { loadedPayments && payments.length >= 50 && nextPointer.current &&
          <div className="loadMoreUsersButton" onClick={() => loadMorePayments()}>
            Load more payments...
          </div>
        }
      </div>
    </div>
  );
};

export default OwnPayments;