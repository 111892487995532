import React, { useRef } from "react";
import { animated } from "react-spring";

import { Trash2, StopCircle } from "react-feather";

import "./FileUploaderButton.css";

import UploadImg from "../assets/upload-orange.png";

const FileUploaderButton = ({
  text,
  fontSize = 1.1,
  files,
  handleFiles,
  handleDeleteFile,
  display = false,
  backend = false,
}) => {
  const fileInput = useRef();
  const wrapperRef = useRef();

  // const handleClickAddFile = () => {
  //   fileInput.current.click();
  // }

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => {
    wrapperRef.current.classList.remove("dragover");
    handleReset();
  };

  const formatSize = (size) => {
    if (!size) {
      return "";
    }

    if (size > 1000000) {
      return "(" + (size / 1000000).toFixed(2) + " MB)";
    } else {
      return "(" + (size / 1000).toFixed(2) + " KB)";
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const splitted = date.toDateString().split(" ");

    return (
      splitted[1] +
      " " +
      splitted[2] +
      ", " +
      splitted[3] +
      " @ " +
      date.toTimeString().substr(0, 5)
    );
  };

  const handleReset = () => {
    // if (fileInput.current) {
    //   fileInput.current.value = "";
    //   fileInput.current.type = "text";
    //   fileInput.current.type = "file";
    // }
  };

  return (
    <div className="fileUploaderContainer">
      {display
        ? files.map((file, index) => (
            <div key={index} className="fileUploaderLineOuter">
              <div className="fileUploaderLine">
                <div className="fileUploaderLineInfo">
                  <p className="fileUploaderLineName">{file.name}</p>
                  {!file.timestamp && (
                    <p className="fileUploaderLineSize">
                      {formatSize(file.file ? file.file.size : file.size)}
                    </p>
                  )}
                </div>
                {file.status === "uploading" ? (
                  <StopCircle
                    className="fileUploaderLineIcon"
                    onClick={() => handleDeleteFile(file)}
                  />
                ) : !file.timestamp ? (
                  <Trash2
                    className="fileUploaderLineIcon"
                    onClick={() => handleDeleteFile(file)}
                  />
                ) : null}
              </div>
              {!file.timestamp && (
                <div className="fileUploaderLineProgressContainer">
                  <animated.div
                    style={{
                      width: `${file.progress}%`,
                      borderColor:
                        file.status === "complete" ? "#4CAF50" : "#FF7C1D",
                    }}
                    className="fileUploaderLineProgress"
                  />
                  <p className="fileUploaderLineProgressText">
                    {file.status === "complete"
                      ? "Completed"
                      : file.status === "failed"
                      ? "Upload Failed."
                      : file.status === "deleting"
                      ? "Deleting..."
                      : "Uploading (" + file.progress + "%)"}
                  </p>
                </div>
              )}
              {file.timestamp && (
                <p
                  className="fileUploaderFormattedTimestamp"
                  style={{ alignSelf: "flex-start" }}
                >{`Revision Source uploaded ${formatDate(file.timestamp)}`}</p>
              )}
            </div>
          ))
        : null}
      {/* <button style={{ paddingTop: `calc(${fontSize * 0.5}vw)`, paddingBottom: `calc(${fontSize * 0.5}vw)`, paddingRight: `calc(${fontSize * 2.2}vw)`, paddingLeft: `calc(${fontSize * 2.2}vw)`, borderRadius: backend ? '7px' : `calc(${fontSize * 3}vw)` }} className="altAnimatedButton" onClick={() => handleClickAddFile()}>
        <div className="altButtonBackground" />
        <p style={{ fontSize: `calc(${fontSize}vw)` }} className="altButtonText">{text}</p>
      </button> */}
      <div
        className="dragDropBox"
        ref={wrapperRef}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <img src={UploadImg} alt="" />
        <p style={{ fontSize: `calc(${fontSize}vw)` }}>
          Drag & Drop your files here
        </p>
        <input
          type="file"
          name="file"
          title={" "}
          multiple
          ref={fileInput}
          className="fileUploaderInput"
          id="file"
          onChange={(e) => handleFiles(e)}
          onClick={handleReset}
          accept=".pdf, .doc, .docx, .xls, .xlsx, image/png, image/jpeg"
        />
      </div>
    </div>
  );
};

export { FileUploaderButton };
