import React, { useState, useContext, useEffect, useRef } from 'react';
import { Editor, EditorState, RichUtils, convertToRaw } from 'draft-js';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { Bold, Underline, Italic, List } from 'react-feather';

import { FormSimpleTextInput } from '../../Components/FormSimpleTextInput';
import { AltAnimatedButton } from '../../Components/AltAnimatedButton';

import { NotificationContext } from '../../Context/NotificationContext';
import { DataContext } from '../../Context/DataContext';

import './Notifications.css';
import { useScreenTransition } from '../../Hooks/useScreenTransition';

const pageLimit = 20;

const Notifications = ({ windowSize }) => {

  const { transitionTo } = useScreenTransition()


  const { showNotification } = useContext(NotificationContext);

  const { userData } = useContext(DataContext);

  const [notifications, setNotifications] = useState(null);
  const [loadedNotifications, setLoadedNotifications] = useState(false);

  const [title, setTitle] = useState('');

  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());

  const [announcementSubmitButton, setAnnouncementSubmitButton] = useState('Send');

  let firstLoad = useRef();
  if (!firstLoad.current) firstLoad.current = true;

  let nextPointer = useRef();
  if (!nextPointer.current) nextPointer.current = null;

  const editor = React.useRef(null);

  function focusEditor() {
    editor.current.focus();
  }

  useEffect(() => {
    const firestore = firebase.firestore();

    const unsubscribe = 
      firestore.collection('notifications')
        .where('target', 'array-contains', userData.role)
        .orderBy('timestamp', 'desc')
        .limit(pageLimit)
        .onSnapshot(querySnapshot => {
          if (firstLoad.current) {
            nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];
            firstLoad.current = false;
            
            setNotifications(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
            setLoadedNotifications(true);
          } else {
            let newNotifications = [];

            querySnapshot.docChanges().forEach((change) => {
              if (change.type === "added") {
                newNotifications.push({ id: change.doc.id, ...change.doc.data() });
              }
            });
            
            setNotifications(u => newNotifications.concat(u));
            setLoadedNotifications(true);
          }
        }, error => {
          //console.log(error);
          setLoadedNotifications(false);
          showNotification('Error', "There was an error loading the notifications. Please, reload the page.", 'error');
        });

    return () => unsubscribe();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userData) {
      const firestore = firebase.firestore();

      const now = new Date();

      firestore.collection('users').doc(userData.id).update({
        readNotifications: now.getTime()
      });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMoreNotifications = () => {
    if (nextPointer.current) {

      const firestore = firebase.firestore();
      
      firestore.collection('notifications')
        .where('target', 'array-contains', userData.role)
        .orderBy('timestamp', 'desc')
        .startAfter(nextPointer.current)
        .limit(pageLimit)
        .get().then((querySnapshot) => {
          nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];

          let oldNotifications = [];

          querySnapshot.forEach((doc) => {
            oldNotifications.push({ id: doc.id, ...doc.data() });
          });
          
          setNotifications(u => u.concat(oldNotifications));
        })
        .catch((error) => {
          showNotification('Error', "There was an error loading the notifications. Please, reload the page.", 'error');
          //console.log("Error getting documents: ", error);
        });
    }
  }

  const formatDate = timestamp => {
    const date = new Date(timestamp);

    const splitted = date.toDateString().split(' ');

    return splitted[1] + ' ' + splitted[2] + ', ' + splitted[3] + ' @ ' + date.toTimeString().substr(0, 5);
  }

  const formatStatus = status => {
    return status.charAt(0).toUpperCase() + status.slice(1);
  }

  const handleBoldClick = () => {
    setEditorState(editorState => RichUtils.toggleInlineStyle(editorState, 'BOLD'))
  }

  const handleItalicClick = () => {
    setEditorState(editorState => RichUtils.toggleInlineStyle(editorState, 'ITALIC'))
  }

  const handleUnderlineClick = () => {
    setEditorState(editorState => RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'))
  }

  const toggleUL = () => {
    setEditorState(editorState => RichUtils.toggleBlockType(editorState,'unordered-list-item'))
  }

  const handleSendAnnouncement = () => {
    setAnnouncementSubmitButton('Sending...');

    const firestore = firebase.firestore();

    const now = new Date();

    firestore.collection('notifications').add({
      timestamp: now.getTime(),
      type: 'announcement',
      data: {
        title: title,
        message: convertToRaw(editorState.getCurrentContent()),
      },
      target: ['Translator', 'owner'],
      read: []
    })
      .then(() => {
        showNotification('Success', "The announcement has been successfully sent!", 'success');
        setTitle('');
        setEditorState(() => EditorState.createEmpty());
        setAnnouncementSubmitButton('Send');
      })
      .catch(error => {
        showNotification('Error', "There was an error sending the announcement. Please, try again.", 'error');
        setAnnouncementSubmitButton('Send');
      })
  }

  return (
    <div className="singleTranslationContainer">
      <div className="singleTranslationHeader">
        <p className="singleTranslationOrderNumber">Notifications</p>
      </div>
      {loadedNotifications
      ? <div className="singleTranslationDetailsOuter">
          <div className="singleTranslationDetailsMain" style={{ width: '100%' }}>
            {userData && (userData.role === 'owner' || userData.role === 'Support') && 
              <div className="singleTranslationDetailsDates">
                <div className="singlePaymentDecideTitleContainer">
                  <p className="singlePaymentDecideTitle">Create Announcement</p>
                </div>
                <div className="announcementContainer">
                  <div className="announcementTitleContainer">
                    <FormSimpleTextInput placeholder="Title*" value={title} onChange={setTitle} color='#666' />
                  </div>
                  <div className="announcementMessageContainer">
                    <div className="quoteRichTextButtons">
                      <Bold className="announcementRichTextButton" onClick={handleBoldClick} />
                      <Italic className="announcementRichTextButton" onClick={handleItalicClick} />
                      <Underline className="announcementRichTextButton" onClick={handleUnderlineClick} />
                      <List className="announcementRichTextButton" onClick={toggleUL} />
                    </div>
                    <div className="quoteRichTextEditor" onClick={focusEditor}>
                      <Editor
                        ref={editor}
                        editorState={editorState}
                        onChange={editorState => setEditorState(editorState)}
                      />
                    </div>
                  </div>
                  <div className="announcementSubmitButtonContainer">
                    <AltAnimatedButton text={announcementSubmitButton} backend disabled={title === '' || editorState.getCurrentContent() === ''} onClick={() => handleSendAnnouncement()} fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.7 : 1.1) : (windowSize.orientation === 'portrait' ? 3.5 : 1)} />
                  </div>
                </div>
              </div>
            }
            <div className="singleTranslationDetailsDates" style={{ paddingBottom: 30 }}>
              <div className="translationsTableHeader" style={{ marginTop: 0, width: '100%' }}>
                <div className="usersHeaderColumn notificationsSubjectColumn">Subject</div>
                <div className="usersHeaderColumn notificationsDateColumn">Date</div>
                <div className="usersHeaderColumn notificationsReadColumn">Status</div>
              </div>
              { loadedNotifications && notifications.length > 0
              ? notifications.map(notification => 
                <div key={notification.id} className="translationsTableLine" style={{ width: '100%' }}>
                  <div className="usersLineColumn notificationsSubjectColumn">
                    { notification.type === 'translatorStatus'
                        ? <p><span style={{ cursor: 'pointer', fontWeight: 700, color: '#444', textDecoration: 'underline' }} onClick={() => ('/office/user/' + notification.data.userID)}>{notification.data.name}</span>{` changed his status from `}<span style={{ fontWeight: 700, color: '#444' }}>{formatStatus(notification.data.oldStatus)}</span>{` to `}<span style={{ fontWeight: 700, color: '#444' }}>{formatStatus(notification.data.newStatus)}</span></p>
                        : notification.type === 'revisionReopen'
                          ? <p><span style={{ cursor: 'pointer', fontWeight: 700, color: '#444', textDecoration: 'underline' }} onClick={(e) => transitionTo('/office/user/' + notification.data.userID,e)}>{notification.data.name}</span>{` has reopened a revision request in the order #`}<span style={{ cursor: 'pointer', fontWeight: 700, color: '#444', textDecoration: 'underline' }} onClick={(e) => transitionTo('/office/translation/' + notification.data.orderID + '/details',e)}>{notification.data.orderNumber}</span></p>
                          : <p style={{ cursor: 'pointer', fontWeight: 700, color: '#444', textDecoration: 'underline' }} onClick={(e) => transitionTo('/office/notification/' + notification.id,e)}>{notification.data.title}</p>
                    }
                  </div>
                  <div className="usersLineColumn notificationsDateColumn">{formatDate(notification.timestamp)}</div>
                  <div className="usersLineColumn notificationsReadColumn">
                    <div className="translationsStatusContainer" style={{ backgroundColor: notification.type === 'revisionReopen' || notification.type === 'translatorStatus' || notification.read.includes(userData.id) ? '#17BC5B' : '#E1504A' }}>
                      {notification.type === 'revisionReopen' || notification.type === 'translatorStatus' || notification.read.includes(userData.id) ? 'Read' : 'Unread'}
                    </div>
                  </div>
                </div>
                )
              : loadedNotifications ? <div className="loadingTranslations">No notifications.</div> : <div className="loadingTranslations">Loading notifications...</div>
              }
              { loadedNotifications && notifications.length >= pageLimit && nextPointer.current &&
                <div className="loadMoreUsersButton" style={{ marginLeft: 0, marginTop: 30 }} onClick={() => loadMoreNotifications()}>
                  Load more notifications...
                </div>
              }
            </div>
          </div>
        </div>
      : null
      }
    </div>
  );
};

export default Notifications;